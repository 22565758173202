// @flow
import type { Element } from 'react';
import React from 'react';
import { SidebarLayout } from '#src/js/components/support/layout/SidebarLayout/index.js';
import MainPageContainer from '#components/support/layout/MainPageContainer/index.jsx';
import DashboardMainContent from '#components/pages/Components/Dashboard/Main/index.jsx';
import DashboardSidebar from '#components/pages/Components/Dashboard/Sidebar/index.jsx';
import DashboardSubNav from '#components/pages/Components/Dashboard/SubNav/index.jsx';
import Page from '#components/support/layout/Page/index.js';
import Header from '#components/support/layout/Header/index.js';

const ComponentsDashboard = (): Element<typeof Page> => (
  <Page className="components-dashboard-page">
    <Header />
    <MainPageContainer>
      <SidebarLayout>
        <DashboardSubNav />
        <DashboardSidebar />
        <DashboardMainContent />
      </SidebarLayout>
    </MainPageContainer>
  </Page>
);

export default ComponentsDashboard;
