// @flow
import type { Element } from 'react';

import React from 'react';
import Field from '#components/pages/Components/support/form/Field/Field.jsx';

type Props = {
  excludeInputGroup?: boolean,
  excludeLabel?: boolean,
  excludeTip?: boolean,
  item?: string,
  field: Object,
  readOnly?: boolean,
  render?: Function,
  visible: boolean,
};
const TableField = ({
  excludeInputGroup,
  excludeLabel,
  excludeTip,
  item,
  field,
  readOnly,
  render,
  visible,
}: Props): Element<typeof Field> | Element<'div'> => {
  if (!visible) {
    return (
      <div className="text-center">
        <span>---</span>
      </div>
    );
  }
  return (
    <Field
      excludeInputGroup={excludeInputGroup}
      excludeLabel={excludeLabel}
      excludeTip={excludeTip}
      field={field}
      item={item}
      readOnly={readOnly}
      render={render}
      visible
    />
  );
};

TableField.defaultProps = {
  excludeInputGroup: false,
  excludeLabel: true,
  excludeTip: true,
  item: null,
  readOnly: false,
  render: null,
};

export default TableField;
