// @flow
import type { Element, Node } from 'react';

import classNames from 'classnames';
import React from 'react';
import { Col } from 'reactstrap';
import type { columnProps } from '#components/support/layout/SidebarLayout/types/index.js';
import { sidebar, sidebarSticky } from './index.module.scss';

type Props = {
  children?: Node,
  className?: string,
  xs?: columnProps,
  sm?: columnProps,
  md?: columnProps,
  lg?: columnProps,
  xl?: columnProps,
  widths?: Array<any>,
  top?: string,
}

const Sidebar = ({
  children, xs, sm, md, lg, xl, widths, className, top,
}: Props): Element<typeof Col> => (
  <Col
    xs={xs}
    sm={sm}
    md={md}
    lg={lg}
    xl={xl}
    widths={widths}
    className={classNames([sidebar, 'sidebar', className])}
    style={{ top }}
  >
    <div className={sidebarSticky}>
      {children}
    </div>
  </Col>
);

Sidebar.defaultProps = {
  children: null,
  className: undefined,
  xs: undefined,
  sm: undefined,
  md: undefined,
  lg: undefined,
  xl: undefined,
  widths: undefined,
  top: '111px',
};

export default Sidebar;
