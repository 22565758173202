/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import curry from 'lodash/fp/curry.js';
import get from 'lodash/fp/get.js';
import isArray from 'lodash/fp/isArray.js';

const applyFieldValidators = (validators, value, values, state) => {
  const v = isArray(validators) ? validators : [validators];
  return v.reduce(
    (acc, validator) => {
      if (acc) return acc;
      const message = validator(value, values, state);
      return message || acc;
    },
    undefined
  );
};

const makeFormValidator = (fields) => curry((
  autofilled,
  state,
  values
) => fields.reduce(
  (acc, field) => {
    const { name, validation } = field;
    const isAutofilled = get(name, autofilled);
    if (!validation || isAutofilled) {
      return acc;
    }
    const errors = applyFieldValidators(validation, values[name], values, state);
    return errors
      ? { ...acc, [name]: errors }
      : acc;
  },
  {}
));

export default makeFormValidator;
