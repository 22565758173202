/**
 * Owner: Haselton Baker Risk Group, LLC
 * Date: 4/24/18
 * Copyright All Rights Reserved
 */
// @flow

import type { Element } from 'react';

import React from 'react';
import { Spinner } from 'reactstrap';
import classNames from 'classnames';
import { GoCircleSlash, GoCheck } from 'react-icons/go/index.js';

import {
  error as errorClass,
  loading as loadingClass,
  success as successClass,
} from './index.module.scss';

type Props = {|
  type: 'success' | 'error' | 'loading',
|};

const success = () => (
  <GoCheck
    className={classNames('text-success', successClass)}
  />
);

const error = () => (
  <GoCircleSlash
    className={classNames('text-danger', errorClass)}
  />
);

const loading = () => (
  <Spinner className={loadingClass} color="primary" />
);

type StatusElement = Element<typeof GoCheck>
  | Element<typeof GoCircleSlash>
  | Element<typeof Spinner>;

const StatusIcon = ({ type }: Props): StatusElement | Element<'div'> => {
  switch (type) {
    case 'success': return success();
    case 'error': return error();
    case 'loading': return loading();
    default:
  }
  return (
    <div />
  );
};

export default StatusIcon;
