import React from 'react';
import { SubNav } from '#components/support/layout/SubNav/index.js';

const ComponentsDashboard = () => (
  <SubNav height="55px">
    <h1>Components</h1>
  </SubNav>
);

export default ComponentsDashboard;
