/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { Auth } from 'aws-amplify';
import { SubmissionError } from 'redux-form';
import {
  COGNITO_LIMIT_EXCEEDED_EXCEPTION,
  COGNITO_NOT_AUTHORIZED_EXCEPTION,
  COGNITO_USER_NOT_FOUND_EXCEPTION,
} from '#constants/users/cognitoErrorCodes.js';
import { PROBLEM_WITH_REQUEST } from '#constants/errorMessages.js';
import ResetPasswordFormPage1 from './ResetPasswordFormPage1.jsx';
import connectPasswordResetFormPage from '../support/connectPasswordResetFormPage.jsx';

const onSubmit = (values, dispatch, nextPage, email, setEmail) => Auth.forgotPassword(values.email)
  .catch((error) => {
    if ([COGNITO_USER_NOT_FOUND_EXCEPTION].includes(error.code)) {
      return null;
    }
    return Promise.reject(error);
  })
  .then(() => {
    setEmail(values.email);
    nextPage();
  })
  .catch((error) => {
    const message = [
      COGNITO_NOT_AUTHORIZED_EXCEPTION, COGNITO_LIMIT_EXCEEDED_EXCEPTION,
    ].includes(error.code)
      ? error.message
      : PROBLEM_WITH_REQUEST;
    return Promise.reject(new SubmissionError({ _error: message }));
  });

export default connectPasswordResetFormPage({
  onSubmit,
})(ResetPasswordFormPage1);
