// @flow
import type { Element } from 'react';

import React, { Fragment } from 'react';
import { Col, Row } from 'reactstrap';
import {
  DESCRIPTION,
  DEFAULT_EDP,
  NAME,
  UNIT,
  UNIT_QUANTITY,
  COMPONENT_ID,
} from '@hbrisk/sp3-risk-model-support/components/translated/attributes/names/index.js';
import type { Component } from '#types/Component/index.js';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import { edpsByKey } from '#constants/edps/index.js';

type Props = {
  component: Component,
};
const ComponentSummaryTop = ({
  component,
}: Props): Element<typeof Fragment> => {
  const {
    [DEFAULT_EDP]: defaultEDP,
    [DESCRIPTION]: description,
    [COMPONENT_ID]: componentId,
    [NAME]: name,
    [UNIT]: unit,
    [UNIT_QUANTITY]: unitQuantity,
  } = component;
  return (
    <>
      <Row>
        <Col>
          <h1 className="component-summary-header">
            {componentId}
          </h1>
          <p>{name}</p>
          <h5>Description</h5>
          <p>{description}</p>
          <h5>
            Quantity per Unit
            {' '}
            <Tip tipId="quantityPerUnit" />
          </h5>
          <p>
            {unitQuantity}
            {' '}
            {unit}
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h5>Default Engineering Demand Parameter</h5>
          <p>{edpsByKey[defaultEDP].name}</p>
        </Col>
      </Row>
    </>
  );
};

export default ComponentSummaryTop;
