// @flow
import type { Element } from 'react';

import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
  Card, CardBody, Col, Row,
} from 'reactstrap';
import type { Component } from '#types/Component/index.js';
import ComponentSummaryTop
  from '#components/pages/Components/Dashboard/Main/ComponentSummary/ComponentSummaryTop/index.jsx';
import ComponentSummaryBottom
  from '#components/pages/Components/Dashboard/Main/ComponentSummary/ComponentSummaryBottom/index.jsx';

type Props = {
  component: Component,
}
const ComponentSummaryView = ({
  component,
}: Props): Element<typeof Row> | Element<typeof Fragment> => {
  if (!component) {
    return (
      <Row>
        <Col>
          <Card className="align-middle text-lg" style={{ minHeight: '800px', textAlign: 'center' }}>
            <CardBody className="text-muted text-lg" style={{ fontSize: '130%', paddingTop: '50%' }}>
              Select a component
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
  return (
    <>
      <Row>
        <Col>
          <Link className="float-right" to="general">Full details</Link>
        </Col>
      </Row>
      <ComponentSummaryTop component={component} />
      <ComponentSummaryBottom component={component} />
    </>
  );
};

export default ComponentSummaryView;
