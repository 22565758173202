/**
 * Owner: Haselton Baker Risk Group, LLC
 * Developer: Mike McGlone <mike@hbrisk.com>
 * Date: 12/14/2016
 * Copyright All Rights Reserved
 */

export const FETCH_ACTION = 'FETCH_ACTION';

export const SIGN_IN_USER = 'SIGN_IN_USER';

export const SIGN_IN_USER_REQUEST = 'SIGN_IN_USER_REQUEST';

export const SIGN_IN_USER_RESPONSE = 'SIGN_IN_USER_RESPONSE';

export const SIGN_OUT_USER = 'SIGN_OUT_USER';

export const CREATE_USER_PASSWORD = 'CREATE_USER_PASSWORD';

export const CREATE_USER_PASSWORD_REQUEST = 'CREATE_USER_PASSWORD_REQUEST';

export const CREATE_USER_PASSWORD_RESPONSE = 'CREATE_USER_PASSWORD_RESPONSE';

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';

export const FETCH_USER_RESPONSE = 'FETCH_USER_RESPONSE';

export const GEOCODE_REQUEST = 'GEOCODE_REQUEST';

export const GEOCODE_RESPONSE = 'GEOCODE_RESPONSE';

export const CREATE_MODEL_REQUEST = 'CREATE_MODEL_REQUEST';

export const CREATE_MODEL_RESPONSE = 'CREATE_MODEL_RESPONSE';

export const CREATE_ORGANIZATION_REQUEST = 'CREATE_ORGANIZATION_REQUEST';

export const CREATE_ORGANIZATION_RESPONSE = 'CREATE_ORGANIZATION_RESPONSE';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';

export const CREATE_USER_RESPONSE = 'CREATE_USER_RESPONSE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';

export const UPDATE_USER_RESPONSE = 'UPDATE_USER_RESPONSE';

export const CREATE_RUN_REQUEST = 'CREATE_RUN_REQUEST';

export const CREATE_RUN_RESPONSE = 'CREATE_RUN_RESPONSE';

export const FETCH_MODEL_REQUEST = 'FETCH_MODEL_REQUEST';

export const FETCH_MODEL_RESPONSE = 'FETCH_MODEL_RESPONSE';

export const FETCH_MODELS_REQUEST = 'FETCH_MODELS_REQUEST';

export const FETCH_MODELS_RESPONSE = 'FETCH_MODELS_RESPONSE';

export const FETCH_ORGANIZATION_REQUEST = 'FETCH_ORGANIZATION_REQUEST';

export const FETCH_ORGANIZATION_RESPONSE = 'FETCH_ORGANIZATION_RESPONSE';

export const SET_CURRENT_MODEL = 'SET_CURRENT_MODEL';

export const UPDATE_MODEL_REQUEST = 'UPDATE_MODEL_REQUEST';

export const UPDATE_MODEL_RESPONSE = 'UPDATE_MODEL_RESPONSE';

export const START_POLLING_MODEL = 'START_POLLING_MODEL';

export const STOP_POLLING_MODEL = 'STOP_POLLING_MODEL';

export const UPDATE_ORGANIZATION_REQUEST = 'UPDATE_ORGANIZATION_REQUEST';

export const UPDATE_ORGANIZATION_RESPONSE = 'UPDATE_ORGANIZATION_RESPONSE';

export const FETCH_DOWNLOAD_URL_REQUEST = 'FETCH_DOWNLOAD_URL_REQUEST';

export const FETCH_DOWNLOAD_URL_RESPONSE = 'FETCH_DOWNLOAD_URL_RESPONSE';

export const FETCH_DOWNLOAD_BLOB_REQUEST = 'FETCH_DOWNLOAD_BLOB_REQUEST';

export const FETCH_DOWNLOAD_BLOB_RESPONSE = 'FETCH_DOWNLOAD_BLOB_RESPONSE';

export const FETCH_UPLOAD_URL_REQUEST = 'FETCH_UPLOAD_URL_REQUEST';

export const FETCH_UPLOAD_URL_RESPONSE = 'FETCH_UPLOAD_URL_RESPONSE';

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';

export const UPLOAD_FILE_RESPONSE = 'UPLOAD_FILE_RESPONSE';

export const REDUX_FORM_ARRAY_PUSH = '@@redux-form/ARRAY_PUSH';

export const REDUX_FORM_ARRAY_REMOVE = '@@redux-form/ARRAY_REMOVE';

export const REDUX_FORM_ARRAY_UNSHIFT = '@@redux-form/ARRAY_UNSHIFT';

export const REDUX_FORM_CHANGE = '@@redux-form/CHANGE';

export const REDUX_FORM_UPDATE_SYNC_ERRORS = '@@redux-form/UPDATE_SYNC_ERRORS';

export const SHOW_FIELD = 'SHOW_FIELD';

export const HIDE_FIELD = 'HIDE_FIELD';

export const INITIALIZE_MODEL_PAGE = 'INITIALIZE_MODEL_PAGE';

export const RESET_MODEL_PAGE = 'RESET_MODEL_PAGE';

export const INITIALIZE_MODEL_FORM = 'INITIALIZE_MODEL_FORM';

export const AUTOFILL_MODEL_FORM_FIELD = '@@redux-form/AUTOFILL';

export const UNAUTOFILL_MODEL_FORM_FIELD = 'UNAUTOFILL_MODEL_FORM_FIELD';

export const FETCH_ORGANIZATIONS_REQUEST = 'FETCH_ORGANIZATIONS_REQUEST';

export const FETCH_ORGANIZATIONS_RESPONSE = 'FETCH_ORGANIZATIONS_RESPONSE';

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';

export const FETCH_USERS_RESPONSE = 'FETCH_USERS_RESPONSE';

export const MODELS_TABLE_SORT_CHANGE = 'MODELS_TABLE_SORT_CHANGE';

export const MODELS_TABLE_SET_FILTERS = 'MODELS_TABLE_SET_FILTERS';

export const MODELS_TABLE_CLEAR_FILTERS = 'MODELS_TABLE_CLEAR_FILTERS';

export const COPY_MODEL_START = 'COPY_MODEL_START';

export const COPY_MODEL_END = 'COPY_MODEL_END';

export const TOGGLE_MODEL_ARCHIVE_START = 'TOGGLE_MODEL_ARCHIVE_START';

export const TOGGLE_MODEL_ARCHIVE_END = 'TOGGLE_MODEL_ARCHIVE_END';

export const FETCH_APP_MAINTENANCE_MODE_REQUEST = 'FETCH_APP_MAINTENANCE_MODE_REQUEST';

export const FETCH_APP_MAINTENANCE_MODE_RESPONSE = 'FETCH_APP_MAINTENANCE_MODE_RESPONSE';

export const SET_MAINTENANCE_OVERRIDE = 'SET_MAINTENANCE_OVERRIDE';

export const FETCH_APP_VERSION_REQUEST = 'FETCH_APP_VERSION_REQUEST';

export const FETCH_APP_VERSION_RESPONSE = 'FETCH_APP_VERSION_RESPONSE';

export const ACCEPT_TERMS_REQUEST = 'ACCEPT_TERMS_REQUEST';

export const ACCEPT_TERMS_RESPONSE = 'ACCEPT_TERMS_RESPONSE';

export const FETCH_TERMS_REQUEST = 'FETCH_TERMS_REQUEST';

export const FETCH_TERMS_RESPONSE = 'FETCH_TERMS_RESPONSE';

export const INITIALIZE_FORM = 'INITIALIZE_FORM';

export const FETCH_COMPONENTS_LAST_UPDATED_REQUEST = 'FETCH_COMPONENTS_LAST_UPDATED_REQUEST';

export const FETCH_COMPONENTS_LAST_UPDATED_RESPONSE = 'FETCH_COMPONENTS_LAST_UPDATED_RESPONSE';

export const FETCH_COMPONENTS_BY_RANGE_REQUEST = 'FETCH_COMPONENTS_BY_RANGE_REQUEST';

export const FETCH_COMPONENTS_BY_RANGE_RESPONSE = 'FETCH_COMPONENTS_BY_RANGE_RESPONSE';

export const FETCH_COMPONENT_CATEGORIES_REQUEST = 'FETCH_COMPONENT_CATEGORIES_REQUEST';

export const FETCH_COMPONENT_CATEGORIES_RESPONSE = 'FETCH_COMPONENT_CATEGORIES_RESPONSE';

export const FETCH_COMPONENT_CATEGORIES_LAST_UPDATED_REQUEST = 'FETCH_COMPONENT_CATEGORIES_LAST_UPDATED_REQUEST';

export const FETCH_COMPONENT_CATEGORIES_LAST_UPDATED_RESPONSE = 'FETCH_COMPONENT_CATEGORIES_LAST_UPDATED_RESPONSE';
