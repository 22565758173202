import get from 'lodash/fp/get.js';
import { getFormValues } from 'redux-form';
import { createSelector } from 'reselect';
import {
  DAMAGE_STATE_CONSEQUENCES,
  DAMAGE_STATES,
} from '@hbrisk/sp3-risk-model-support/components/translated/attributes/names/index.js';
import { COMPONENT_FORM } from '#constants/components/form/name.js';

export const selectFormState = (state) => state.form[COMPONENT_FORM];

const selectFieldSchema = (_, props) => props.fieldSchema;
const selectFieldName = (_, props) => props.name;
const selectFieldNames = (_, props) => props.fields;

export const selectAuthUserId = (state) => state.entities.users.authenticated.uuid;

export const selectVisibleFields = createSelector(
  selectFormState,
  (state) => state.visibleFields,
);

export const selectSomeConsequenceHasVisibleInstancesOfFieldSchema = createSelector(
  selectFieldSchema,
  selectVisibleFields,
  (schema, visibleFields) => {
    if (visibleFields && Array.isArray(visibleFields[DAMAGE_STATES])) {
      return visibleFields[DAMAGE_STATES].some(
        (damageState) => damageState[DAMAGE_STATE_CONSEQUENCES].some(
          (consequence) => consequence[schema.name] === true
        )
      );
    }
    return null;
  },
);

export const selectSomeDamageStateHasVisibleInstancesOfFieldSchema = createSelector(
  selectFieldSchema,
  selectVisibleFields,
  (schema, visibleFields) => {
    if (visibleFields && Array.isArray(visibleFields[DAMAGE_STATES])) {
      return visibleFields[DAMAGE_STATES]
        .some((damageState) => damageState[schema.name] === true);
    }
    return null;
  }
);

export const selectFieldIsVisible = createSelector(
  selectFieldName,
  selectVisibleFields,
  (name, visibleFields) => !!get(name, visibleFields)
);

export const selectFieldValue = createSelector(
  selectFieldName,
  getFormValues(COMPONENT_FORM),
  (name, values) => !!get(name, values)
);

export const selectAtLeastOneFieldInArrayIsVisible = createSelector(
  selectFieldNames,
  selectVisibleFields,
  (fields, visibleFields) => (
    (visibleFields && (Array.isArray(fields) && fields.length > 0))
      ? fields.some(
        (field) => get(field, visibleFields) !== false
      )
      : true
  )
);
