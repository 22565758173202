import get from 'lodash/fp/get.js';

const emptyValues = [null, undefined, ''];

const isEmptyValue = (value) => emptyValues.includes(value);

const defaultDefaultValue = null;

// Given a field, get its updated value. In the case of a field array that supports
// item-level reducers, apply those reducers recursively and set values to null for any
// item fields that are invisible
const getUpdatedFieldValue = (field, formState, action, itemPath = null) => {
  const { name } = field;

  const unqualifiedName = itemPath
    ? `${itemPath}.${name}`
    : name;

  const isVisible = get(`visibleFields.${unqualifiedName}`, formState);
  if (isVisible === false) {
    return null;
  }

  const isAutofilled = get(`autofilled.${unqualifiedName}`, formState);
  const value = get(`values.${unqualifiedName}`, formState);
  if (isAutofilled === true) {
    return isEmptyValue(value)
      ? defaultDefaultValue
      : value;
  }

  // Handle FieldArrays that support item-level reducers
  if (field.type === 'FieldArray' && field.allowItemLevelReducers === true) {
    const items = value;
    const { itemSchema } = field;
    if (Array.isArray(items)) {
      return items.reduce((a1, item, i) => {
        const result = itemSchema.reduce((a2, itemFieldSchema) => ({
          ...a2,
          [itemFieldSchema.name]: getUpdatedFieldValue(itemFieldSchema, formState, action, `${unqualifiedName}[${i}]`),
        }), {});
        return [
          ...a1,
          result,
        ];
      }, []);
    }
    return [];
  }

  const reducer = get('reducers.value', field);
  if (reducer) {
    const result = itemPath
      ? reducer(get(`values.${itemPath}`, formState), itemPath)(formState, action)
      : reducer(formState, action);
    if (result !== undefined) {
      return result;
    }
  }

  // Otherwise, if current value is empty set value to default
  const currentValue = get(`values.${unqualifiedName}`, formState);
  if (isEmptyValue(currentValue)) {
    const { defaultValue } = field;
    return defaultValue === undefined
      ? defaultDefaultValue
      : defaultValue;
  }

  // Otherwise, just return current  value
  return currentValue;
};

const updateValues = (fields, formState, action) => {
  const values = fields.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]: getUpdatedFieldValue(field, formState, action),
    }),
    formState.values
  );
  return {
    ...formState,
    values,
  };
};
export default updateValues;
