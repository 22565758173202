/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { modelTypesById } from '#src/js/constants/models/modelTypes/index.js';
import withCustomHandleSubmit
  from '#components/pages/Models/Model/support/Router/withSectionFunctionality/withCustomHandleSubmit/index.js';
import ModelPagerSidebar from './ModelPageSidebar.jsx';

const mapStateToProps = (state, ownProps) => {
  const { modelType } = ownProps;
  const {
    selectActiveSectionGroups,
    selectActiveSectionsWithErrorCountsByGroup,
  } = modelTypesById[modelType];

  const activeSectionGroups = selectActiveSectionGroups(state);
  const activeSectionsWithErrorCountsByGroup = selectActiveSectionsWithErrorCountsByGroup(state);

  return ({
    modelType,
    sectionGroups: activeSectionGroups,
    sectionsByGroup: activeSectionsWithErrorCountsByGroup,
  });
};

export default connect(mapStateToProps)(withCustomHandleSubmit(ModelPagerSidebar));
