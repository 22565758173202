// @flow
import type { Element } from 'react';

import get from 'lodash/fp/get.js';
import noop from 'lodash/fp/noop.js';

import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import {
  Link, Route, Routes, useNavigate,
} from 'react-router-dom';
import sectionComponentsById
  from '#components/pages/Components/Component/support/Router/sectionComponentsById.js';
import withSectionFunctionality
  from '#components/pages/Components/Component/support/Router/withSectionFunctionality/index.js';
import componentPagerSections from '#constants/components/componentPage/sections/index.js';
import { COMPONENT_FORM } from '#constants/components/form/name.js';

const getUrl = get('url');

const mapSectionsToRoutes = (
  sections,
  navigate,
  componentId,
  componentUuid,
  componentName,
  readOnly,
) => sections.map((section, index, arr) => {
  const {
    heading,
    pagerHeading,
    id,
  } = section;
  const SectionComponent = sectionComponentsById[id];

  const nextUrl = getUrl(arr[index + 1]);
  const backUrl = getUrl(arr[index - 1]);
  const path = get('path', section);

  const onNext = nextUrl
    ? () => {
      navigate(`./${nextUrl}`);
    }
    : noop;

  const onBack = backUrl
    ? () => {
      navigate(`./${backUrl}`);
    }
    : noop;

  const SectionComponentWithFunctionality = withSectionFunctionality(SectionComponent);
  return (
    <Route
      key={id}
      path={path}
      element={
        (
          <>
            <Row className="pb-4">
              <Col md="8">
                <h1>{componentId}</h1>
                <p>{componentName}</p>
              </Col>
              <Col md="4">
                <Link className="float-right" to="..">Summary</Link>
              </Col>
            </Row>
            <Row>
              <Col>
                <h2>{heading}</h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <SectionComponentWithFunctionality
                  onNext={onNext}
                  onBack={onBack}
                  heading={pagerHeading || heading}
                  componentUuid={componentUuid}
                  form={COMPONENT_FORM}
                  readOnly={readOnly}
                />
              </Col>
            </Row>
          </>
        )
      }
    />
  );
});

type Props = {
  componentId: string,
  componentUuid: string,
  name: string,
  handleUnmount: Function,
};

const Router = ({
  componentId,
  componentUuid,
  name,
  handleUnmount,
}: Props): Element<typeof Routes> => {
  const navigate = useNavigate();

  useEffect(() => handleUnmount, []);
  // Temporarily we just hardcode the value of "readOnly" here. When
  // We add custom components this will be set programmatically
  const readOnly = true;
  const routes = mapSectionsToRoutes(
    componentPagerSections,
    navigate,
    componentId,
    componentUuid,
    name,
    readOnly
  );
  return (
    <Routes>
      {routes}
    </Routes>
  );
};

export default Router;
