import React from 'react';
import { useParams } from 'react-router-dom';
import { COMPONENT_UUID } from '#constants/pathStrings.js';
import MainPageContainer from '#components/support/layout/MainPageContainer/index.jsx';
import {
  SidebarLayout, MainContentArea,
} from '#components/support/layout/SidebarLayout/index.js';
import Router from '#components/pages/Components/Component/support/Router/index.js';
import Sidebar from '#components/pages/Components/Component/Sidebar/index.jsx';

const ComponentDetailWindow = () => {
  const { [COMPONENT_UUID]: componentUuid } = useParams();
  return (
    <MainPageContainer withRoomForHeader={false}>
      <SidebarLayout>
        <Sidebar md="2" top="0" />
        <MainContentArea md="10" top="0">
          <Router componentUuid={componentUuid} />
        </MainContentArea>
      </SidebarLayout>
    </MainPageContainer>
  );
};

export default ComponentDetailWindow;
