// @flow
import type { AbstractComponent, Element, MixedElement } from 'react';
import React from 'react';
import { Field, FieldArray } from 'redux-form';
import type { OptionsProp, State } from '#components/pages/Components/support/types/options';
import renderInput from '#components/support/forms/RenderInput/index.jsx';
import { getOptionElements, getSelectedOptionText } from '#components/pages/Components/support/form/Field/BaseField/support/options.jsx';

type Props = {
  form: string,
  name: string,
  item: string,
  options?: OptionsProp,
  readOnly?: boolean,
  readOnlyComponent?: AbstractComponent<{}> | string,
  render?: Function,
  state: State,
  type: string,
  value: any,
  format: Function,
  normalize: Function,
};

const BaseField = ({
  form,
  name,
  item,
  options,
  readOnly,
  readOnlyComponent,
  render,
  state,
  type,
  value,
  format,
  normalize,
}: Props): Element<typeof Field | typeof FieldArray | AbstractComponent<{}>> | MixedElement => {
  if (type === 'FieldArray' && render) {
    return (
      <FieldArray
        item={item}
        component={render}
        form={form}
        name={name}
        readOnly={readOnly}
      />
    );
  }

  if (readOnly && readOnlyComponent) {
    const displayValue = type === 'select' && options
      ? getSelectedOptionText(options, value, state)
      : value;

    return React.createElement(
      readOnlyComponent,
      {},
      typeof format === 'function' ? format(displayValue) : displayValue,
    );
  }

  if (type === 'select' && options) {
    const optionElements = getOptionElements(options, state);
    return (
      <Field
        id={name}
        item={item}
        name={name}
        type={type}
        component={renderInput}
        disabled={readOnly}
      >
        {optionElements}
      </Field>
    );
  }

  return (
    <Field
      id={name}
      item={item}
      name={name}
      type={type}
      component={renderInput}
      readOnly={readOnly}
      format={format}
      normalize={normalize}
    />
  );
};

BaseField.defaultProps = {
  options: null,
  readOnly: false,
  readOnlyComponent: null,
  render: null,
};

export default BaseField;
