/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import BackButton from '#components/pages/Models/Model/sections/support/buttons/BackButton.jsx';
import NextButton from '#components/pages/Models/Model/sections/support/buttons/NextButton.jsx';
import StructuralResponsesTable from './StructuralResponsesTable/index.js';

type Props = {|
  form: string,
  heading: string,
  onBack: Function,
  onNext: Function,
|};

const StructuralResponseTableView = (props: Props): Element<'div'> => {
  const {
    form,
    heading,
    onBack,
    onNext,
  } = props;
  return (
    <div>
      <h2 className="pb-2">{heading}</h2>
      <StructuralResponsesTable form={form} />
      <div className="pt-3">
        <BackButton onClick={onBack} />
        <NextButton onClick={onNext} />
      </div>
    </div>
  );
};

export default StructuralResponseTableView;
