/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { pipe } from 'ramda';
import { reduxForm } from 'redux-form';
import withModelLoader from './withModelLoader/index.js';
import withStatusIndication from './withSectionLevelStatusIndication/index.js';
import withModelFormInitialization from './withFormInitialization/index.js';
import withOnSubmit from './withOnSubmit/index.js';
import withValidation from './withFormValidation/index.js';
import withCustomHandleSubmit from './withCustomHandleSubmit/index.js';

/*
Here we define a high order component to provide all of the input sections with the functionality
they all need.

withCustomHandleSubmit and withOnSubmit are intentionally separated from one another
below, even though they both have to do with submission. This may be a little confusing, so I want
to make a note about the reason for this. withCustomHandleSubmit provides a custom replacement
for the handleSubmit function injected by reduxForm. Because of this it must come above reduxForm
in this pipe. withOnSubmit, on the other and, in effect, assigns an onSubmit function to reduxForm's
onSubmit config. Because of this, withOnSubmit, must come below reduxForm in this pipe.
Moreover, because we support remote form submission from the sidebar, we must set reduxForm's
onSubmit config. We cannot use Redux Form's other methods of connecting an onSubmit function
to a form. (See the comments in Redux Forms' remote submission example for justification of
this fact (https://redux-form.com/8.3.0/examples/remotesubmit/);
 */
const withSectionFunctionality = (C) => pipe(
  withModelLoader,
  withStatusIndication,
  withModelFormInitialization,
  withCustomHandleSubmit,
  reduxForm({ destroyOnUnmount: false }),
  withOnSubmit,
  withValidation,
)(C);

export default withSectionFunctionality;
