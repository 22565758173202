import pipe from 'lodash/fp/pipe.js';
import Components from '#components/pages/Components/Components.jsx';
import withRouteParams from '#components/support/routing/withRouteParams/index.jsx';
import withLocation from '#components/support/routing/withLocation/index.jsx';
import withComponentsPolling from '#components/support/polling/withComponentsPolling/index.js';
import withComponentCategoriesPolling from '#components/support/polling/withComponentCategoriesPolling/index.js';

export default pipe(
  withComponentCategoriesPolling,
  withComponentsPolling,
  withRouteParams,
  withLocation,
)(Components);
