/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { reducer as reduxFormReducer } from 'redux-form';
import { modelTypes } from '#src/js/constants/models/modelTypes/index.js';
import makeModelFormReducerPlugin from '#reducers/ui/form/support/makeModelFormReducerPlugin.js';
import { COMPONENT_FORM } from '#constants/components/form/name.js';
import componentDetailFormReducerPlugin
  from '#reducers/ui/form/support/componentDetailFormReducerPlugin.js';

const modelFormPlugins = modelTypes.reduce((acc, modelType) => {
  const { modelFormName, fields } = modelType;
  return modelFormName
    ? ({
      ...acc,
      [modelFormName]: makeModelFormReducerPlugin({ name: modelFormName, fields }),
    })
    : acc;
}, {});

const formReducer = reduxFormReducer.plugin({
  ...modelFormPlugins,
  [COMPONENT_FORM]: componentDetailFormReducerPlugin,
});

export default formReducer;
