/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { Auth } from 'aws-amplify';
import { SubmissionError } from 'redux-form';
import {
  COGNITO_LIMIT_EXCEEDED_EXCEPTION,
  COGNITO_CODE_MISMATCH_EXCEPTION,
} from '#constants/users/cognitoErrorCodes.js';
import { PROBLEM_WITH_REQUEST } from '#constants/errorMessages.js';
import ResetPasswordFormPage2 from './ResetPasswordFormPage2.jsx';
import connectPasswordResetFormPage from '../support/connectPasswordResetFormPage.jsx';

const onSubmit = (
  values,
  dispatch,
  nextPage,
  email
) => Auth.forgotPasswordSubmit(email, values.confirmationCode, values.newPassword)
  .then(() => { nextPage(); })
  .catch((error) => {
    const message = [
      COGNITO_CODE_MISMATCH_EXCEPTION, COGNITO_LIMIT_EXCEEDED_EXCEPTION,
    ].includes(error.code)
      ? error.message
      : PROBLEM_WITH_REQUEST;
    return Promise.reject(new SubmissionError({ _error: message }));
  });

export default connectPasswordResetFormPage({
  onSubmit,
})(ResetPasswordFormPage2);
