import { connect } from 'react-redux';
import { UUID } from '@hbrisk/sp3-risk-model-support/components/translated/attributes/names/index.js';
import DamageStateTableCell
  from '#components/pages/Components/support/tables/DamageStateRowTable/DamageStateTableCell/DamageStateTableCell.jsx';
import { selectSomeDamageStateHasVisibleInstancesOfFieldSchema as formModeSelector } from '#selectors/ui/form/component.js';
import { someDamageStateHasVisibleInstancesOfFieldSchemaUuid as entityModeSelector } from '#selectors/entities/components.js';

const mapStateToProps = (state, ownProps) => {
  const { fieldSchema, entityMode, component } = ownProps;
  const visible = entityMode
    ? entityModeSelector(state, { fieldSchema, [UUID]: component[UUID] })
    : formModeSelector(state, { fieldSchema });
  return {
    visible,
  };
};

export default connect(mapStateToProps)(DamageStateTableCell);
