import get from 'lodash/fp/get.js';

const defaultDefaultAutofilled = false;

const updateAutofilled = (fields, formState, action) => {
  const autofilled = fields.reduce(
    (acc, field) => {
      const reducer = get('reducers.autofilled', field);
      if (reducer) {
        const result = reducer(formState, action);
        if (result !== undefined) {
          return {
            ...acc,
            [field.name]: result,
          };
        }
      }

      if (formState.autofilled[field.name] === undefined) {
        const { autofiller } = field;
        const value = formState.values[field.name];
        return {
          ...acc,
          [field.name]: value !== undefined || autofiller === undefined
            ? defaultDefaultAutofilled
            : true,
        };
      }
      return acc;
    },
    formState.autofilled
  );
  return {
    ...formState,
    autofilled,
  };
};

export default updateAutofilled;
