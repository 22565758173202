// @flow
import type { Element } from 'react';

import React from 'react';
import BasicReduxFormSelectMenu from '#components/support/forms/SelectMenus/BasicReduxFormSelectMenu/index.jsx';
import RenderInput from '#components/support/forms/RenderInput/index.jsx';

type Props = {|
  disabled?: boolean,
  className?: string,
  id: string,
  items: { id: string | number, name: string | number, value: number | string }[],
  name: string,
  includeEmptyOption?: boolean,
  inputClassName?: string,
  validate?: Array<Function>
|};

const ModelFormSelectMenu = ({
  disabled,
  className,
  id,
  items,
  name,
  includeEmptyOption,
  inputClassName,
  validate,
}: Props): Element<typeof BasicReduxFormSelectMenu> => (
  <BasicReduxFormSelectMenu
    disabled={disabled}
    component={RenderInput}
    id={id}
    className={className}
    name={name}
    includeEmptyOption={includeEmptyOption}
    inputClassName={inputClassName}
    items={items}
    validate={validate}
  />
);

ModelFormSelectMenu.defaultProps = {
  disabled: false,
  className: undefined,
  includeEmptyOption: true,
  inputClassName: undefined,
  validate: undefined,
};

export default ModelFormSelectMenu;
