// @flow
import type { Element } from 'react';
import React from 'react';
import {
  CONSEQUENCE_REPAIR_TIME_LOWER_QTY,
  CONSEQUENCE_REPAIR_TIME_UPPER_QTY,
  CONSEQUENCE_REPAIR_TIME_MIN_AMOUNT,
  CONSEQUENCE_REPAIR_TIME_MAX_AMOUNT,
} from '@hbrisk/sp3-risk-model-support/components/translated/attributes/names/index.js';
import type { Component } from '#types/Component/index.js';

import ConsequencePropertyRowTable
  from '#components/pages/Components/support/tables/ConsequencePropertyRowTable/index.jsx';

const itemFields = [
  CONSEQUENCE_REPAIR_TIME_LOWER_QTY,
  CONSEQUENCE_REPAIR_TIME_UPPER_QTY,
  CONSEQUENCE_REPAIR_TIME_MIN_AMOUNT,
  CONSEQUENCE_REPAIR_TIME_MAX_AMOUNT,
];

type Props = {
  component: Component,
};
const RepairTimeSummaryTable = ({
  component,
}: Props): Element<typeof ConsequencePropertyRowTable> => (
  <ConsequencePropertyRowTable
    entityMode
    component={component}
    itemFields={itemFields}
  />
);

export default RepairTimeSummaryTable;
