// @flow
import type { Element } from 'react';
import React from 'react';
import {
  DAMAGE_STATE_MIN_CALCULATED_MEDIAN,
  DAMAGE_STATE_MEDIAN,
  DAMAGE_STATE_TYPE,
} from '@hbrisk/sp3-risk-model-support/components/translated/attributes/names/index.js';
import type { Component } from '#types/Component/index.js';

import DamageStateRowTable from '#components/pages/Components/support/tables/DamageStateRowTable/index.jsx';

const itemFields = [
  DAMAGE_STATE_TYPE,
  DAMAGE_STATE_MEDIAN,
  DAMAGE_STATE_MIN_CALCULATED_MEDIAN,
];

type Props = {
  component: Component,
};

const DamageStateSummaryTable = ({ component }: Props): Element<typeof DamageStateRowTable> => (
  <DamageStateRowTable
    entityMode
    component={component}
    itemFields={itemFields}
  />
);

export default DamageStateSummaryTable;
