/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { getMaintenanceMode } from '#actions/app/maintenance.js';
import { getAppVersion } from '#actions/app/version.js';
import { fetchUser } from '#actions/users/users.js';
import { selectIsMaintenanceMode, selectIsNewAppVersion } from '#selectors/ui/app.js';
import { selectAuthUserId, selectAuthUserNeedsTermsOfService } from '#selectors/entities/users.js';
import Page from './Page.jsx';

const mapStateToProps = (state) => ({
  isAppMaintenanceMode: selectIsMaintenanceMode(state),
  hasNewAppVersion: selectIsNewAppVersion(state),
  userId: selectAuthUserId(state),
  userNeedsTermsOfService: selectAuthUserNeedsTermsOfService(state),
});

const mapDispatchToProps = (dispatch) => ({
  pollAppMaintenanceMode: () => {
    const POLLING_INTERVAL = 5000;
    dispatch(getMaintenanceMode);
    const intervalId = setInterval(() => {
      dispatch(getMaintenanceMode);
    }, POLLING_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  },
  pollAppVersion: () => {
    const POLLING_INTERVAL = 5000;
    dispatch(getAppVersion);
    const intervalId = setInterval(() => {
      dispatch(getAppVersion);
    }, POLLING_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  },
  makePollUser: (userId) => () => {
    if (userId) {
      const POLLING_INTERVAL = 5000;
      dispatch(fetchUser(userId));
      const intervalId = setInterval(() => {
        dispatch(fetchUser(userId));
      }, POLLING_INTERVAL);

      return () => {
        clearInterval(intervalId);
      };
    }
    return () => {};
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { makePollUser, ...restDispatchProps } = dispatchProps;
  const { userId } = stateProps;
  return {
    ...ownProps,
    ...stateProps,
    ...restDispatchProps,
    pollUser: makePollUser(userId),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Page);
