// @flow

import type { Element } from 'react';

import React from 'react';
import { Form } from 'reactstrap';
import {
  CONSEQUENCE_FEMA_P58_LONG_LEAD_TIME,
  CONSEQUENCE_CAN_RED_TAG,
  CONSEQUENCE_RED_TAG_MEDIAN,
  CONSEQUENCE_RED_TAG_BETA,
  DAMAGE_STATES,
} from '@hbrisk/sp3-risk-model-support/components/translated/attributes/names/index.js';
import type { SectionComponentProps } from '#components/pages/Components/Component/sections/support/types/componentPagerSection';
import BackButton
  from '#components/pages/Components/Component/sections/support/buttons/BackButton.jsx';
import { fieldsByName } from '#constants/components/form/fields/index.js';
import {
  Field,
  Fieldset,
} from '#components/pages/Components/support/form/index.js';
import renderConsequenceDetailsTable from '#components/pages/Components/Component/sections/Consequences/support/renderConsequencePropertyRowTable/index.jsx';

const renderLegacyFEMAP58Table = renderConsequenceDetailsTable([
  CONSEQUENCE_FEMA_P58_LONG_LEAD_TIME,
  CONSEQUENCE_CAN_RED_TAG,
  CONSEQUENCE_RED_TAG_MEDIAN,
  CONSEQUENCE_RED_TAG_BETA,
]);

const ConsequencesOther = ({
  onBack, readOnly,
}: SectionComponentProps): Element<'div'> => (
  <Form>
    <Fieldset>
      <legend>Legacy FEMA-P58</legend>
      <Field
        render={renderLegacyFEMAP58Table}
        field={fieldsByName[DAMAGE_STATES]}
        readOnly={readOnly}
        excludeLabel
        excludeInputGroup
      />
    </Fieldset>
    <BackButton onClick={onBack} />
  </Form>
);

export default ConsequencesOther;
