/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { Auth } from 'aws-amplify';
import { identity } from 'ramda';
import get from 'lodash/fp/get.js';
import { v4 as uuid } from 'uuid';
import config, { API_BASE_URL } from '#support/app/config.js';
import { signOutUser } from '#actions/users/users.js';
import { FETCH_ACTION } from '#constants/actionTypes.js';

/**
 * A Redux middleware that makes fetch requests, and dispatches appropriate actions before
 * making the request and on response.
 *
 * The Middleware makes a request whenever a FetchAction (see below) is dispatched.
 */

/**
 * FetchAction type definition
 * @typedef {Object} FetchAction
 * @property {Function} [dataTransform] - transforms success response to success action payload
 * @property {string|Function} endpoint
 * @property {Function} [errorTransform] - transforms error response to error action payload
 * @property {string} FETCH_ACTION - Indicates that the action should be processed by the middleware
 * @property {FetchOptions} [options] - options passed to fetch
 * @property {[string, string]} types - contains a request type and a response type
 * @property {boolean} [withAuth] - transforms error response to error action payload
 */

/**
 * Fetch Options type definition
 * @typedef {Object} FetchOptions - options to pass to fetch
 * @property {Object|string} [body] - fetch body option
 * @property {Object} [headers] - fetch headers option
 * @property {string} [method] - fetch method option
 * @property {string} [mode] - fetch mode option
 */

const response = (type, meta, error, data) => ({
  type,
  meta,
  payload: error || data,
  error: !!error,
});

/**
 * @param {string} token - an auth token
 * @param {string} method - fetch method option
 * @param {Object} overrides - fetch headers option
 * @return {Object}
 */
const calculateHeaders = (token, method, overrides) => {
  const authHeader = token
    ? { Authorization: token }
    : {};
  switch (method) {
    case 'POST':
    case 'PUT':
      return { ...authHeader, 'Content-Type': 'application/json', ...overrides };
    default:
      return authHeader;
  }
};

const stringifyIfNotString = (body) => (typeof body === 'string' ? body : JSON.stringify(body));

const isContentTypeJson = (headers) => headers['Content-Type'] === 'application/json';

const calculateBody = (body, headers) => {
  if (!body) {
    return {};
  }
  if (isContentTypeJson(headers)) {
    return { body: stringifyIfNotString(body) };
  }
  return { body };
};

/**
 * Calculates the fetch options to use
 * @param {string} token
 * @param {FetchOptions} options
 * @return {FetchOptions}
 */
const calculateOptions = (token, options) => {
  const o = options || {};
  const method = o.method ? o.method : 'GET';
  const headers = calculateHeaders(token, method, o.headers);
  const mode = o.mode ? o.mode : 'cors';
  const body = calculateBody(o.body, headers);
  const base = ({
    headers,
    method,
    mode,
  });
  return o.body ? ({ ...base, ...body }) : base;
};

const parseResponse = (value) => {
  const contentType = value.headers.get('Content-Type');
  if (!contentType) {
    return Promise.resolve();
  }
  if (contentType.includes('json')) {
    return value.json();
  } if (contentType.includes('octet-stream')) {
    return value.blob();
  } if (contentType.includes('text/html')) {
    return value.text();
  }

  return Promise.reject(new Error('Unsupported content type in response header'));
};

/**
 * @param {string} url
 * @param {FetchOptions} options
 * @return {Promise}
 */
const callFetch = (url, options) => fetch(url, options)
  .then((value) => parseResponse(value)
    .then((content) => (value.ok ? content : Promise.reject(new Error(content)))));

const attributeSchemaIsValid = (session) => get('idToken.payload[custom:uuid]', session);

const getToken = (store) => Auth.currentSession()
  .then((session) => {
    if (attributeSchemaIsValid(session)) {
      return session.idToken.jwtToken;
    }
    return Promise.reject(new Error('Token attribute schema is invalid'));
  })
  .catch((error) => {
    store.dispatch(signOutUser());
    return Promise.reject(error);
  });

const fetchMiddleware = (store) => (next) => (action) => {
  const fetchActionData = action[FETCH_ACTION];

  if (typeof fetchActionData === 'undefined') {
    return next(action);
  }

  const {
    dataTransform,
    endpoint,
    errorTransform,
    meta: fetchActionMeta,
    options,
    types,
    withAuth,
  } = fetchActionData;

  const dTransform = dataTransform || identity;
  const eTransform = errorTransform || identity;

  const shouldAuthenticate = withAuth === undefined ? true : withAuth;

  const urlBase = config(API_BASE_URL);

  const urlTail = typeof endpoint === 'function'
    ? endpoint(store.getState())
    : endpoint;

  const url = urlTail.includes('http')
    ? urlTail
    : `${urlBase}${urlTail}`;

  const [requestType, responseType] = types;
  const requestId = uuid();
  const meta = { ...fetchActionMeta, url, requestId };

  next({
    type: requestType,
    meta,
    ...(options && options.body ? { payload: options.body } : {}),
  });

  const handleRejection = (e) => {
    const n = response(responseType, meta, eTransform(e instanceof Error ? e : new Error(e)));
    next(n);
    return Promise.reject(n);
  };

  const callFetchWithResponseActions = (u, o) => callFetch(u, o)
    .then((v) => next(response(responseType, meta, undefined, dTransform(v))))
    .catch(handleRejection);

  return shouldAuthenticate
    ? getToken(store)
      .catch(handleRejection)
      .then((token) => callFetchWithResponseActions(url, calculateOptions(token, options)))
    : callFetchWithResponseActions(url, calculateOptions(undefined, options));
};

export default fetchMiddleware;
