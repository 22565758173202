// @flow
import type { Element } from 'react';

import React from 'react';
import { Label as BaseLabel } from 'reactstrap';
import Tip from '#components/support/tooltips/Tip/index.jsx';

type Props = {
  field: {
    label: string,
    name: string,
    tipId?: string,
  },
  component?: typeof BaseLabel | 'span',
  excludeTip?: boolean,
  tipTargetId?: string,
};

const Label = ({
  field, component, excludeTip, tipTargetId,
}: Props): Element<typeof BaseLabel> | Element<'span'> => {
  const tip = excludeTip || !field.tipId
    ? null
    : (
      <>
        {' '}
        <Tip tipId={field.tipId} targetId={tipTargetId} />
      </>
    );

  let props = {};
  if (component === BaseLabel) {
    props = { htmlFor: field.name };
  }
  return React.createElement(
    component || BaseLabel,
    props,
    field.label,
    tip
  );
};

Label.defaultProps = {
  component: BaseLabel,
  excludeTip: false,
  tipTargetId: undefined,
};

export default Label;
