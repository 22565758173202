import { connect } from 'react-redux';
import { destroy } from 'redux-form';
import { COMPONENT_ID, NAME } from '@hbrisk/sp3-risk-model-support/components/translated/attributes/names/index.js';
import ComponentPageRouter
  from '#components/pages/Components/Component/support/Router/Router.jsx';
import { COMPONENT_FORM } from '#constants/components/form/name.js';
import { selectComponentByUuid } from '#selectors/entities/components.js';

const mapStateToProps = (state, ownProps) => {
  const { componentUuid } = ownProps;
  const component = selectComponentByUuid(
    state,
    { uuid: componentUuid }
  );
  const name = component ? component[NAME] : null;
  const componentId = component ? component[COMPONENT_ID] : null;
  return {
    componentId,
    name,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleUnmount: () => {
    dispatch(destroy(COMPONENT_FORM));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(ComponentPageRouter);
