// @flow
import type { Element, Node } from 'react';

import classNames from 'classnames';
import React from 'react';
import { subNavSnippet } from './index.module.scss';

type Props = {|
  children?: Node,
  className?: string,
|};

const SubNavSnippet = ({ children, className }: Props): Element<'span'> => (
  <span
    className={classNames('float-right', subNavSnippet, className)}
  >
    {children}
  </span>
);

SubNavSnippet.defaultProps = {
  children: null,
  className: null,
};

export default SubNavSnippet;
