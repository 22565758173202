// @flow
import type { Element, Node } from 'react';

import classNames from 'classnames';
import React from 'react';
import { Col } from 'reactstrap';
import type { columnProps } from '#components/support/layout/SidebarLayout/types/index.js';
import { mainContentArea } from './index.module.scss';

type Props = {
  children?: Node,
  className?: string,
  xs?: columnProps,
  sm?: columnProps,
  md?: columnProps,
  lg?: columnProps,
  xl?: columnProps,
  widths?: Array<any>,
  top?: string,
};

const MainContentArea = ({
  children, xs, sm, md, lg, xl, widths, className, top,
}: Props): Element<typeof Col> => (
  <Col
    xs={xs}
    sm={sm}
    md={md}
    lg={lg}
    xl={xl}
    widths={widths}
    className={classNames(mainContentArea, className)}
    role="main"
    style={{ top }}
  >
    {children}
  </Col>
);

MainContentArea.defaultProps = {
  children: null,
  className: undefined,
  xs: undefined,
  sm: undefined,
  md: undefined,
  lg: undefined,
  xl: undefined,
  widths: undefined,
  top: '85px',
};

export default MainContentArea;
