import React from 'react';
import {
  MainContentArea,
  SidebarLayout,
} from '#components/support/layout/SidebarLayout/index.js';
import ComponentSummary from '#components/pages/Components/Dashboard/Main/ComponentSummary/index.js';
import MainContainer from '#components/support/layout/MainPageContainer/index.jsx';

const ComponentSummaryWindow = () => (
  <MainContainer withRoomForHeader={false}>
    <SidebarLayout>
      <MainContentArea md="12" top="0">
        <ComponentSummary />
      </MainContentArea>
    </SidebarLayout>
  </MainContainer>
);

export default ComponentSummaryWindow;
