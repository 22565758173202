/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import {
  Button, Col, Form, FormGroup, Label, Row,
} from 'reactstrap';
import { Field } from 'redux-form';
import SubmitButton from '#components/support/buttons/SubmitButton/index.jsx';
import FieldFeedback from '#components/support/forms/FieldFeedback/index.js';
import RenderInput from '#components/support/forms/RenderInput/index.jsx';
import { emptyOrMaxLength100, required } from '#src/js/support/utility/form/validation/fieldValidators/index.js';

type Props = {|
  error?: string,
  form: string,
  handleSubmit: Function,
  submitSucceeded: boolean,
  submitting: boolean,
|};

const CreateOrganizationForm = (props: Props): Element<typeof Form> => {
  const {
    error, form, handleSubmit, submitting, submitSucceeded,
  } = props;
  const redirect = submitSucceeded ? <Navigate to=".." /> : null;
  const alert = error ? <div className="alert alert-danger">{error}</div> : null;
  return (
    <Form className="create-org-form" onSubmit={handleSubmit}>
      {alert || redirect}
      <FormGroup tag="fieldset">
        <legend>General</legend>
        <FormGroup row>
          <Col lg="6">
            <Label htmlFor="orgName">
              Name
            </Label>
            <Field
              id="orgName"
              name="orgName"
              type="text"
              component={RenderInput}
              validate={[required, emptyOrMaxLength100]}
            />
            <FieldFeedback name="orgName" form={form} />
          </Col>
        </FormGroup>
      </FormGroup>
      <FormGroup tag="fieldset">
        <legend>Permissions</legend>
        <Row>
          <Col lg="6">
            <h6>Model Types</h6>
          </Col>
        </Row>
        <FormGroup row check>
          <Col lg="5" className="ml-3">
            <Label check>
              <Field
                id="standard"
                name="standard"
                type="checkbox"
                component={RenderInput}
              />
              Standard
            </Label>
          </Col>
        </FormGroup>
        <FormGroup row check>
          <Col lg="5" className="ml-3">
            <Label check>
              <Field
                id="advanced"
                name="advanced"
                type="checkbox"
                component={RenderInput}
              />
              Advanced
            </Label>
          </Col>
        </FormGroup>
        <Row className="pt-2">
          <Col lg="6">
            <h6>Other Options</h6>
          </Col>
        </Row>
        <FormGroup row check>
          <Col lg="5" className="ml-3">
            <Label check>
              <Field
                id="allRealizations"
                name="allRealizations"
                type="checkbox"
                component={RenderInput}
              />
              All Realizations
            </Label>
          </Col>
        </FormGroup>
        <FormGroup row check>
          <Col lg="5" className="ml-3">
            <Label check>
              <Field
                id="recoveryTime"
                name="recoveryTime"
                type="checkbox"
                component={RenderInput}
              />
              Recovery Time
            </Label>
          </Col>
        </FormGroup>
      </FormGroup>
      <SubmitButton className="float-right" submitting={submitting}>
        Create
      </SubmitButton>
      <Button
        color="link"
        tag={Link}
        to=".."
      >
        Cancel
      </Button>
    </Form>
  );
};

CreateOrganizationForm.defaultProps = {
  error: '',
};

export default CreateOrganizationForm;
