/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import get from 'lodash/fp/get.js';
import keys from 'lodash/fp/keys.js';
import {
  EDP_LOCATION,
  IS_DIRECTIONAL,
  REQUIRES_SERVICE_LOCATIONS,
} from '@hbrisk/sp3-risk-model-support/components/translated/attributes/names/index.js';
import { YES_NO_VALUES } from '@hbrisk/sp3-risk-model-support/components/translated/attributes/values/index.js';
import { modelTypesById, MODEL_TYPE_2_ID } from '#constants/models/modelTypes/index.js';
import { selectComponentByUuid } from '#selectors/entities/components.js';
import {
  required,
  noTrailingComma,
  emptyOr0to100k,
  emptyOr0to1M,
  emptyOrp001to1M,
} from '#support/utility/form/validation/fieldValidators/index.js';
import isEdpFloor from '#support/models/isFloor.js';
import { getRange } from '#support/models/locationSpecifier/index.js';
import validateItemFields from '#support/utility/form/validation/validateItemFields.js';
import createValidatorForFieldArrayWithLocationSpecifier from '../support/createValidatorForFieldArrayWithLocationSpecifier.js';

const requiredIfDirectional = (
  value,
  values,
  array,
  index,
  state
) => (state[IS_DIRECTIONAL] === YES_NO_VALUES.YES ? required(value, values) : undefined);

const requiredIfNonDirectional = (
  value,
  values,
  array,
  index,
  state
) => (state[IS_DIRECTIONAL] === YES_NO_VALUES.YES ? undefined : required(value, values));

const requiredIfSupportsServiceLocations = (value, values, array, index, state) => (
  state[REQUIRES_SERVICE_LOCATIONS] === YES_NO_VALUES.YES
  && state.repairTimeMethodSupportsServiceLocations
    ? required(value, values)
    : undefined
);

const isValidStorySpecifier = (value, values) => {
  if (value === null || value === undefined) {
    return undefined;
  }
  const { error } = getRange(false, parseInt(values.numberOfStories, 10), value);
  return error ? 'Invalid range' : undefined;
};

const noDuplicatesInProp = (prop, message) => (value, values, array, index) => {
  const duplicate = array.some((item, i) => item[prop] === value && i !== index);
  return duplicate ? message : undefined;
};

const noDuplicateNames = noDuplicatesInProp('name', 'Duplicate name');

const emptyOr0to100kIfDir = (
  value,
  values,
  array,
  index,
  state
) => (state[IS_DIRECTIONAL] === YES_NO_VALUES.YES ? emptyOr0to100k(value, values) : undefined);

const emptyOr0to100kIfNonDir = (
  value,
  values,
  array,
  index,
  state
) => (state[IS_DIRECTIONAL] === YES_NO_VALUES.YES ? undefined : emptyOr0to100k(value, values));

const populationItemFieldsValidations = [
  {
    name: 'componentUuid',
    validation: [required],
  },
];

const performanceGroupFieldsValidations = [
  {
    name: 'edp',
    validation: [required],
  },
  {
    name: 'serviceLocations',
    validation: [requiredIfSupportsServiceLocations, noTrailingComma, isValidStorySpecifier],
  },
  {
    name: 'name',
    validation: [required, noDuplicateNames],
  },
];

const preliminaryLocationFieldValidations = [
  {
    name: 'capacityModifier',
    validation: [required, emptyOrp001to1M],
  },
  {
    name: 'costModifier',
    validation: [required, emptyOr0to1M],
  },
  {
    name: 'timeModifier',
    validation: [required, emptyOr0to1M],
  },
  {
    name: 'locationSpecifier',
    validation: [required, noTrailingComma],
  },
  {
    name: 'quantityDir1',
    validation: [requiredIfDirectional, emptyOr0to100kIfDir],
  },
  {
    name: 'quantityDir2',
    validation: [requiredIfDirectional, emptyOr0to100kIfDir],
  },
  {
    name: 'quantityNonDir',
    validation: [requiredIfNonDirectional, emptyOr0to100kIfNonDir],
  },
];

const validateLocations = createValidatorForFieldArrayWithLocationSpecifier({
  itemFieldValidations: preliminaryLocationFieldValidations,
  requireAtLeastOneItem: true,
  atLeastOneItemMessage: 'At least one location is required for each performance group',
  allowLocationOverlap: false,
  requireLocationExhaustiveness: false,
});

const validatePerformanceGroups = (performanceGroups, values, {
  [IS_DIRECTIONAL]: isDirectional,
  isFloor,
  [REQUIRES_SERVICE_LOCATIONS]: requiresServiceLocations,
  repairTimeMethodSupportsServiceLocations,
}) => {
  const performanceGroupsLength = get('length', performanceGroups);

  // Check to see whether there are items in the performanceGroups array, if not, return a
  // global error for that performanceGroups array
  if (performanceGroupsLength < 1) {
    return { _error: 'At least one performance group is required for each component' };
  }
  // Otherwise, check each item in the componetGroups array for errors and store them in
  // a performanceGroupErrors array;
  const performanceGroupErrors = [];
  // Begin looping through the performanceGroups items
  for (let j = 0; j < performanceGroupsLength; j += 1) {
    const performanceGroup = performanceGroups[j];

    // Run validations on the current performanceGroup's fields and store any errors in an object
    const performanceGroupItemErrors = validateItemFields(
      performanceGroupFieldsValidations,
      performanceGroup,
      values,
      performanceGroups,
      j,
      {
        [REQUIRES_SERVICE_LOCATIONS]: requiresServiceLocations,
        repairTimeMethodSupportsServiceLocations,
      }
    );

    // Validate the performanceGroups item's locations field array
    const { locations } = performanceGroup;
    const locationErrors = validateLocations(
      locations,
      values,
      { isDirectional, isFloor }
    );
    if (locationErrors) {
      performanceGroupItemErrors.locations = locationErrors;
    }
    // If current performance group has any errors, store those objects at the appropriate index
    // in the performanceGroupErrors array
    if (keys(performanceGroupItemErrors).length) {
      performanceGroupErrors[j] = performanceGroupItemErrors;
    }
  }
  // End looping through the performanceGroups items

  // If there are any errors for the performanceGroups array, return them as an array
  // Otherwise, return undefined
  return performanceGroupErrors.length ? performanceGroupErrors : undefined;
};

export const validateComponentPopulationFieldArray = (componentPopulation, values, state) => {
  const numberOfPopulationItems = get('length', componentPopulation);
  // Check to see whether there are items in the componentPopulation array, if not, return a
  // global error
  if (numberOfPopulationItems < 1) {
    return { _error: 'At least one component is required to populate your model' };
  }
  // Otherwise, check each item in the componentPopulation array for errors and store them in
  // a populationItemErrors array;
  const populationItemErrors = [];
  // Begin looping through the items in the componentPopulation
  for (let i = 0; i < numberOfPopulationItems; i += 1) {
    const populationItem = componentPopulation[i];
    const { componentUuid } = populationItem;

    const { selectRepairTimeMethodSupportsServiceLocations } = modelTypesById[MODEL_TYPE_2_ID];
    const repairTimeMethodSupportsServiceLocations = selectRepairTimeMethodSupportsServiceLocations(
      state
    );
    const component = selectComponentByUuid(state, { uuid: componentUuid });
    if (component === null) {
      return undefined;
    }
    const {
      [EDP_LOCATION]: edpLocation,
      [IS_DIRECTIONAL]: isDirectional,
      [REQUIRES_SERVICE_LOCATIONS]: requiresServiceLocations,
    } = component;

    const isFloor = isEdpFloor(edpLocation);

    // Run validations on the current populationItem's fields and store any errors in an object
    const populationItemFieldErrors = validateItemFields(
      populationItemFieldsValidations,
      populationItem,
      values,
      componentPopulation,
      i,
    );

    // Validate the populationItem's performanceGroups field array
    const { performanceGroups } = populationItem;
    const performanceGroupErrors = validatePerformanceGroups(
      performanceGroups,
      values,
      {
        [IS_DIRECTIONAL]: isDirectional,
        isFloor,
        [REQUIRES_SERVICE_LOCATIONS]: requiresServiceLocations,
        repairTimeMethodSupportsServiceLocations,
      }
    );

    if (performanceGroupErrors) {
      populationItemFieldErrors.performanceGroups = performanceGroupErrors;
    }

    // If there are any errors for the current populationItem, store those at the appropriate
    // index  in the populationItemErrors array
    if (keys(populationItemFieldErrors).length) {
      populationItemErrors[i] = populationItemFieldErrors;
    }
  }
  // If there are any errors for the populationItems, return them as an array.
  // Otherwise, return undefined
  return populationItemErrors.length > 0 ? populationItemErrors : undefined;
};
