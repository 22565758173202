import React from 'react';
import { Button } from 'reactstrap';
import {
  DAMAGE_STATE_MIN_CALCULATED_MEDIAN,
  DAMAGE_STATE_MEDIAN,
  DAMAGE_STATE_BETA,
  DAMAGE_STATE_TYPE,
} from '@hbrisk/sp3-risk-model-support/components/translated/attributes/names/index.js';
import DamageStateRowTable
  from '#components/pages/Components/support/tables/DamageStateRowTable/index.jsx';

const renderDamageStatesTable = ({ fields, readOnly }) => {
  const itemFields = [
    DAMAGE_STATE_TYPE,
    DAMAGE_STATE_BETA,
    DAMAGE_STATE_MEDIAN,
    DAMAGE_STATE_MIN_CALCULATED_MEDIAN,
  ];
  return (
    <>
      <DamageStateRowTable
        itemFields={itemFields}
        readOnly={readOnly}
        fields={fields}
      />
      {
        !readOnly
        && (
          <Button
            size="sm"
            id="addRowButton"
            className="float-right"
            color="primary"
            onClick={() => fields.push({ type: 'sequential', consequences: [{ }] })}
          >
            Add Damage State
          </Button>
        )
      }
    </>
  );
};

export default renderDamageStatesTable;
