import {
  COMPONENT_FORM,
} from '#constants/components/form/name.js';
import { INITIALIZE_FORM } from '#constants/actionTypes.js';

export const initializeComponentDetailForm = (inputs = {}) => ({
  type: INITIALIZE_FORM,
  meta: {
    form: COMPONENT_FORM,
  },
  payload: {
    ...(inputs.values ? { values: inputs.values } : {}),
    ...(inputs.visibleFields ? { visibleFields: inputs.visibleFields } : {}),
  },
});
