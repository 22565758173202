/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { UUID } from '@hbrisk/sp3-risk-model-support/components/translated/attributes/names/index.js';
import {
  FETCH_COMPONENT_CATEGORIES_RESPONSE,
  FETCH_COMPONENT_CATEGORIES_LAST_UPDATED_RESPONSE,
} from '#constants/actionTypes.js';

export const BY_UUID = 'byUuid';
export const LAST_UPDATED = 'lastUpdated';

const initialState = {
  [BY_UUID]: {},
  [LAST_UPDATED]: null,
};

const components = (state = initialState, action = {}) => {
  const {
    error, type, payload,
  } = action;

  switch (type) {
    case FETCH_COMPONENT_CATEGORIES_RESPONSE: {
      if (error) return state;
      const { [BY_UUID]: byUuid } = state;
      const payloadComponentCategories = payload.entities.componentCategories;
      return {
        ...state,
        [BY_UUID]: {
          ...Object.values(byUuid).reduce((acc, cat) => ({
            ...acc,
            [cat[UUID]]: cat,
          }), {}),
          ...payloadComponentCategories,
        },
      };
    }
    case FETCH_COMPONENT_CATEGORIES_LAST_UPDATED_RESPONSE: {
      if (error) return state;
      return {
        ...state,
        [LAST_UPDATED]: payload[LAST_UPDATED],
      };
    }
    default:
      return state;
  }
};

export default components;
