/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { Badge, Nav, NavItem } from 'reactstrap';
import { Sidebar, SidebarNavLink } from '#components/support/layout/SidebarLayout/index.js';
import SubmitButton from '#components/pages/Models/Model/support/ModelFormSubmitButton/index.js';
import { sectionGroup, sectionGroupHeading } from './ModelPageSidebar.module.scss';

type Section = {
  component: Function,
  heading: string,
  id: string,
  type: string,
  url: string,
  errors?: number,
};

type SectionGroup = {
  id: string,
  heading: string,
};

type Props = {|
handleSubmit: Function,
  modelId: string,
  modelType: number,
  sectionGroups: SectionGroup[],
  sectionsByGroup: {
    [key: string]: Section[],
  },
|}

const ErrorBadge = (errors) => (errors
  ? (
    <Badge color="danger">{errors}</Badge>
  ) : null
);

const ModelPageSidebar = ({
  handleSubmit,
  modelId,
  modelType,
  sectionGroups,
  sectionsByGroup,
}: Props): Element<typeof Nav> => {
  const subMenu = (group) => {
    const subNav = sectionsByGroup[group].map((section) => {
      const {
        url, heading, id, errors,
      } = section;
      return (
        <NavItem key={id} id={`${id}NavItem`}>
          <SidebarNavLink to={`./${url}`}>
            <span className="pr-2">{heading}</span>
          </SidebarNavLink>
          {ErrorBadge(errors)}
        </NavItem>
      );
    });
    return (
      <Nav vertical key={group}>
        {subNav}
      </Nav>
    );
  };

  return (
    <Sidebar md="2">
      <Nav vertical>
        {
          sectionGroups.map((group) => {
            const { heading, id } = group;
            return (
              <NavItem key={id} id={`${id}NavItem`} className={sectionGroup}>
                <h5 className={sectionGroupHeading}>{heading}</h5>
                {subMenu(id)}
              </NavItem>
            );
          })
        }
      </Nav>
      <SubmitButton
        modelType={modelType}
        modelId={modelId}
        className="mt-5"
        onClick={handleSubmit}
      />
    </Sidebar>
  );
};
export default ModelPageSidebar;
