// @flow
import type { Element, Node } from 'React';

import React from 'react';
import { Col } from 'reactstrap';

type columnProps = string
  | number
  | boolean
  | {|
    size: string | number | boolean,
    order: string | number,
    offset: string | number
|};

type Props = {
  children?: Node,
  visible?: boolean,
  xs?: columnProps,
  sm?: columnProps,
  md?: columnProps,
  lg?: columnProps,
  xl?: columnProps,
  widths?: Array<any>
}
/* eslint-disable react/jsx-props-no-spreading */
const FormGroupColumn = ({
  children, xs, sm, md, lg, xl, widths, visible,
}: Props): Element<typeof Col> | null => {
  if (visible === false) {
    return null;
  }
  return (
    <Col
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      widths={widths}
    >
      {children}
    </Col>
  );
};

FormGroupColumn.defaultProps = {
  children: null,
  visible: true,
  xs: undefined,
  sm: undefined,
  md: undefined,
  lg: undefined,
  xl: undefined,
  widths: undefined,
};

export default FormGroupColumn;
