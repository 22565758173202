// @flow
import type { Element, Node } from 'react';

import classNames from 'classnames';
import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import * as styles from './index.module.scss';

type Props = {|
  children: Node,
  disabled?: boolean,
  to: string,
  level?: number,
|};

const SidebarNavLink = ({
  disabled,
  to,
  children,
  level,
}: Props): Element<typeof NavLink> => {
  const baseClasses = level !== undefined && level > 1
    ? [styles.link, styles[`linkLevel${level}`]]
    : [styles.link];
  return (
    disabled
      ? (
        <NavLink className={classNames(['disabled', ...baseClasses])} tag="span">
          {children}
        </NavLink>
      )
      : (
        <NavLink to={to} tag={RouterNavLink} className={classNames(baseClasses)}>
          {children}
        </NavLink>
      )
  );
};

SidebarNavLink.defaultProps = {
  disabled: false,
  level: 1,
};

export default SidebarNavLink;
