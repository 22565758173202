/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

// @flow
import type { Element } from 'react';

import React from 'react';
import {
  Col, Form, FormGroup, Label, Row,
} from 'reactstrap';
import Field from '#components/pages/Models/Model/sections/support/forms/Field/index.js';
import RenderInput from '#components/support/forms/RenderInput/index.jsx';
import NextButton from '#components/pages/Models/Model/sections/support/buttons/NextButton.jsx';
import BackButton from '#components/pages/Models/Model/sections/support/buttons/BackButton.jsx';
import FieldFeedback from '#components/support/forms/FieldFeedback/index.js';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import SiteMap from './SiteMap/index.js';

type Props = {|
  form: string,
  heading: string,
  onBack: Function,
  onNext: Function,
|};

const SiteCoordinatesSection = ({
  form, heading, onBack, onNext,
}: Props): Element<'div'> => (
  <div>
    <h2>{heading}</h2>
    <Form noValidate>
      <FormGroup tag="fieldset">
        <legend>
          Site Coordinates
          {' '}
          <Tip tipId="siteCoordinates" />
        </legend>
        <Row>
          <Col lg="3" md="4">
            <FormGroup>
              <Label htmlFor="lat">
                Latitude
              </Label>
              <Field
                form={form}
                id="lat"
                name="lat"
                type="text"
                component={RenderInput}
              />
              <FieldFeedback name="lat" form={form} />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="lng">
                Longitude
              </Label>
              <Field
                form={form}
                id="lng"
                name="lng"
                type="text"
                component={RenderInput}
              />
              <FieldFeedback name="lng" form={form} />
            </FormGroup>
          </Col>
          <Col lg="9" md="8">
            <SiteMap form={form} />
          </Col>
        </Row>
      </FormGroup>
      <BackButton onClick={onBack} />
      <NextButton onClick={onNext} />
    </Form>
  </div>
);

export default SiteCoordinatesSection;
