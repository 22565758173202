// @flow
import type { Element } from 'react';

import React, { Fragment } from 'react';
import { InputGroup, InputGroupAddon } from 'reactstrap';
import BaseField from '#components/pages/Components/support/form/Field/BaseField/index.js';
import Label from '#components/pages/Components/support/form/Label/index.jsx';
import getUnqualifiedFiledName
  from '#support/components/form/getUnqualifiedFieldName.js';
import ReadOnlyView
  from '#components/pages/Components/support/form/Field/ReadOnlyView/index.jsx';

type Props = {
  excludeInputGroup?: boolean,
  excludeLabel?: boolean,
  excludeTip?: boolean,
  field: Object,
  item?: string,
  render?: Function,
  visible: boolean,
  readOnly?: boolean,
};
const Field = ({
  field, item, render, visible, readOnly, excludeLabel, excludeTip, excludeInputGroup,
}: Props): Element<typeof Fragment> | null => {
  if (!visible) {
    return null;
  }
  const unqualifiedName = getUnqualifiedFiledName(item, field.name);
  const f = excludeInputGroup || (!field.suffix && !field.prefix)
    ? (
      <BaseField
        form={field.form}
        item={item}
        name={unqualifiedName}
        options={field.options}
        type={field.type}
        readOnly={readOnly}
        readOnlyComponent={ReadOnlyView}
        render={render}
        format={field.format}
        normalize={field.normalize}
      />
    )
    : (
      <InputGroup>
        {
          field.prefix && (
            <InputGroupAddon addonType="prepend">{field.prefix}</InputGroupAddon>
          )
        }
        <BaseField
          form={field.form}
          item={item}
          name={unqualifiedName}
          options={field.options}
          type={field.type}
          readOnly={readOnly}
          readOnlyComponent={ReadOnlyView}
          render={render}
          format={field.format}
          normalize={field.normalize}
        />
        {
          field.suffix && (
            <InputGroupAddon addonType="prepend">{field.suffix}</InputGroupAddon>
          )
        }
      </InputGroup>
    );

  const l = excludeLabel || !field.label
    ? null
    : (
      <Label excludeTip={excludeTip} field={field} htmlFor={unqualifiedName}>
        {field.label}
      </Label>
    );

  return (
    <>
      {l}
      {f}
    </>
  );
};

Field.defaultProps = {
  excludeInputGroup: false,
  excludeLabel: false,
  excludeTip: false,
  item: null,
  readOnly: false,
  render: null,
};

export default Field;
