// @flow
import type { Element, Node } from 'react';

import React from 'react';
import RequireAuthorization from '#components/support/routing/RequireAuthorization/index.jsx';
import { MODELS } from '#constants/pathStrings.js';

type Props = {|
  children: Node,
  hasType2ModelPermissions: boolean,
  isAuthenticated: boolean,
  redirectTo?: string,
|};

const RequireType2ModelPermissions = (props: Props): Element<typeof RequireAuthorization> => {
  const {
    children,
    hasType2ModelPermissions,
    isAuthenticated,
    redirectTo = `/${MODELS}`,
  } = props;
  return (
    <RequireAuthorization
      isAuthorized={hasType2ModelPermissions && isAuthenticated}
      redirectTo={redirectTo}
    >
      {children}
    </RequireAuthorization>
  );
};

RequireType2ModelPermissions.defaultProps = {
  redirectTo: undefined,
};

export default RequireType2ModelPermissions;
