// @flow
import type { Element } from 'React';
import type { Fields } from 'redux-form/lib/FieldArrayProps.types';

import React from 'react';
import { Table } from 'reactstrap';
import ConsequencePropertyTable
  from '#components/pages/Components/support/tables/ConsequencePropertyRowTable/index.jsx';

type Props = {
  fields: Fields,
  readOnly?: boolean,
}
type Render = Props => Element<typeof Table>
const makeRender = (
  itemFields: Array<string>
): Render => {
  const render = ({
    fields,
    readOnly,
  }: Props): Element<typeof Table> => (
    <ConsequencePropertyTable
      fields={fields}
      itemFields={itemFields}
      readOnly={readOnly}
    />
  );
  render.defaultProps = {
    readOnly: false,
  };
  return render;
};

export default makeRender;
