import updateAutofilled
  from './updateAutofilled/index.js';
import updateVisibility
  from './updateVisibility/index.js';
import updateValues from './updateValues/index.js';

const updateFormInputs = (fields, formInputs, action) => {
  let nextFormInputs = updateAutofilled(fields, formInputs, action);
  nextFormInputs = updateVisibility(fields, nextFormInputs, action);
  nextFormInputs = updateValues(fields, nextFormInputs, action);
  return nextFormInputs;
};

export default updateFormInputs;
