// @flow
import type { Element } from 'react';
import React from 'react';
import {
  BreadCrumb,
  BreadCrumbDivider,
  BreadCrumbTrail,
  SubNav,
} from '#components/support/layout/SubNav/index.js';

type Props = {
  componentId: string,
}
const ComponentSubNav = ({ componentId }: Props): Element<typeof SubNav> => (
  <SubNav className="pt-2">
    <BreadCrumbTrail>
      <BreadCrumb to="./">Components</BreadCrumb>
      <BreadCrumbDivider />
      <BreadCrumb>
        {componentId}
      </BreadCrumb>
    </BreadCrumbTrail>
  </SubNav>
);

export default ComponentSubNav;
