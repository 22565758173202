/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React, { Fragment } from 'react';
import { Label } from 'reactstrap';
import { Field } from 'redux-form';
import RenderInput from '#components/support/forms/RenderInput/index.jsx';
import { emptyOrEmail, required } from '#src/js/support/utility/form/validation/fieldValidators/index.js';
import trimAndToLower from '#support/utility/string/trimAndToLower.js';
import FieldFeedback from '#components/support/forms/FieldFeedback/index.js';

type Props = {|
  form: string,
|};

const EmailField = ({ form }: Props): Element<typeof Fragment> => (
  <>
    <Label htmlFor="email">
      Email
    </Label>
    <Field
      id="email"
      name="email"
      type="text"
      component={RenderInput}
      validate={[required, emptyOrEmail]}
      normalize={trimAndToLower}
    />
    <FieldFeedback name="email" form={form} />
  </>
);

export default EmailField;
