import React from 'react';
import { useParams } from 'react-router-dom';
import {
  SidebarLayout, MainContentArea,
} from '#src/js/components/support/layout/SidebarLayout/index.js';
import Sidebar from '#components/pages/Components/Component/Sidebar/index.jsx';
import SubNav from '#components/pages/Components/Component/SubNav/index.js';
import Page from '#components/support/layout/Page/index.js';
import Header from '#components/support/layout/Header/index.js';
import MainPageContainer from '#components/support/layout/MainPageContainer/index.jsx';
import Router from '#components/pages/Components/Component/support/Router/index.js';
import { COMPONENT_UUID } from '#constants/pathStrings.js';

const Component = () => {
  const { [COMPONENT_UUID]: componentUuid } = useParams();
  return (
    <Page className="component-page">
      <Header />
      <MainPageContainer>
        <SubNav componentUuid={componentUuid} />
        <SidebarLayout>
          <Sidebar md="2" />
          <MainContentArea md="10">
            <Router componentUuid={componentUuid} />
          </MainContentArea>
        </SidebarLayout>
      </MainPageContainer>
    </Page>
  );
};

export default Component;
