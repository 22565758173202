/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import {
  Col, Form, FormGroup, Row,
} from 'reactstrap';
import SubmitButton from '#components/support/buttons/SubmitButton/index.jsx';
import EmailFieldWithLabelAndFeedback from '#components/support/forms/EmailField/index.jsx';

type Props = {|
  error?: string,
  form: string,
  handleSubmit: Function,
  submitting: boolean,
|};

const ResetPasswordFormPage1 = (props: Props): Element<typeof Form> => {
  const {
    error, form, handleSubmit, submitting,
  } = props;
  const alert = error ? <div className="alert alert-danger">{error}</div> : null;
  return (
    <Form className="reset-password-form reset-password-form-page-1" onSubmit={handleSubmit}>
      <Row>
        <Col lg="8">
          <p>
            Enter the email address for your account and we&#39;ll send you a confirmation
            code for resetting your password.
          </p>
        </Col>
      </Row>
      {alert}
      <FormGroup row>
        <Col lg="6">
          <EmailFieldWithLabelAndFeedback form={form} />
        </Col>
      </FormGroup>
      <SubmitButton color="primary" type="submit" formNoValidate submitting={submitting}>
        Submit
      </SubmitButton>
    </Form>
  );
};

ResetPasswordFormPage1.defaultProps = {
  error: '',
};

export default ResetPasswordFormPage1;
