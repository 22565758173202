import get from 'lodash/fp/get.js';
import keyBy from 'lodash/fp/keyBy.js';
import values from 'lodash/fp/values.js';
import { createSelector } from 'reselect';
import { NAME, CATEGORY_ID } from '@hbrisk/sp3-risk-model-support/componentCategories/attributes/names.js';
import { BY_UUID, LAST_UPDATED } from '#reducers/entities/componentCategories/index.js';

const selectRawComponentCategories = (state) => state.entities.componentCategories;

const selectComponentCategoriesByUuid = createSelector(
  selectRawComponentCategories,
  (categories) => categories[BY_UUID],
);

export const selectComponentCategoriesLastUpdated = createSelector(
  selectRawComponentCategories,
  (categories) => categories[LAST_UPDATED],
);

const getCategoryTree = (categoriesByUuid, uuid, acc) => {
  if (!Object.keys(categoriesByUuid).length) { return acc; }
  const category = categoriesByUuid[uuid];
  if (category.parentUuid) {
    return getCategoryTree(categoriesByUuid, category.parentUuid, [...acc, category]);
  }
  return [...acc, category];
};

export const selectComponentCategoryParentTree = createSelector(
  selectComponentCategoriesByUuid,
  (categoriesByUuid) => (uuid) => getCategoryTree(categoriesByUuid, uuid, []),
);

const selectComponentCategories = createSelector(
  selectComponentCategoriesByUuid,
  (categoriesByUuid) => values(categoriesByUuid),
);

const selectComponentCategoriesByCategoryId = createSelector(
  selectComponentCategories,
  (categories) => keyBy(CATEGORY_ID, categories),
);

export const selectComponentCategoryNameByCategoryId = createSelector(
  selectComponentCategoriesByCategoryId,
  (categoriesByCategoryId) => (categoryId) => get(`${categoryId}.${NAME}`, categoriesByCategoryId),
);
