/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import keys from 'lodash/fp/keys.js';
import pipe from 'lodash/fp/pipe.js';
import pickBy from 'lodash/fp/pickBy.js';
import flattenKeys from '#support/utility/object/flattenKeys/index.js';

const getFieldsFlattenedWithErrors = pipe(
  flattenKeys,
  pickBy((value) => value !== null),
  (errors) => keys(errors).reduce((acc, curr) => {
    const newKey = curr
      .replace(/^(.+)\[\d+\]$/, '$1');
    return {
      ...acc,
      [newKey]: (acc[newKey] || 0) + 1,
    };
  }, {}),
);

export default getFieldsFlattenedWithErrors;
