/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import Amplify from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';
import 'whatwg-fetch';
import '#src/scss/app.scss';
import store from '#store/index.js';
import App from '#components/App.jsx';
import config, {
  initializeConfigs,
  AWS_REGION,
  COGNITO_USER_POOL_ID,
  COGNITO_WEB_CLIENT_ID,
  ENVIRONMENT,
  ROLLBAR_ACCESS_TOKEN,
  ROLLBAR_ENABLED,
} from '#support/app/config.js';
import rollbarInit from '#support/app/rollbarInit.js';

initializeConfigs().then(() => {
  rollbarInit(config(ROLLBAR_ENABLED), config(ENVIRONMENT), config(ROLLBAR_ACCESS_TOKEN));

  Amplify.configure({
    Auth: {
      region: config(AWS_REGION),
      userPoolId: config(COGNITO_USER_POOL_ID),
      userPoolWebClientId: config(COGNITO_WEB_CLIENT_ID),
      authenticationFlowType: 'USER_PASSWORD_AUTH',
    },
  });

  const render = (Component) => {
    const root = document.getElementById('root');
    if (root !== null) {
      ReactDOM.render(
        <Component store={store} />,
        root
      );
    }
  };

  render(App);

  // If hot module replacement is enabled in development
  if (module.hot) {
    // When an update to the app is available
    module.hot.accept('#components/App', () => {
      // Render the updated app
      render(App);
    });
  }
});
