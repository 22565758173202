/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import debounce from 'lodash/fp/debounce.js';
import isEqual from 'lodash/fp/isEqual.js';
import omit from 'lodash/fp/omit.js';
import TreeView from '@material-ui/lab/TreeView/index.js';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore.js';
import ChevronRightIcon from '@material-ui/icons/ChevronRight.js';
import { pick } from 'ramda';
import React, { useState, useEffect, memo } from 'react';
import {
  Button,
  Card,
  CardBody,
  Collapse,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
} from 'reactstrap';
import renderTreeItems from '#components/pages/Components/support/ComponentSelector/renderTreeItems.jsx';
import { dropDownButton, treeViewClass } from '#components/pages/Components/support/ComponentSelector/index.module.scss';

const areEqual = (prevProps, nextProps) => {
  const prev = pick(['expanded', 'selected', 'componentTreeData'], prevProps);
  const next = pick(['expanded', 'selected', 'componentTreeData'], nextProps);
  const is = isEqual(prev, next);
  return is;
};
/* eslint-disable react/jsx-props-no-spreading */
const MemoizedTreeView = memo((props) => (
  <TreeView {...omit(['componentTreeData'], props)} />
), areEqual);
/* eslint-enable react/jsx-props-no-spreading */

type Props = {|
  onSelect: Function,
  getComponentTree: (searchText: string) => Object,
  componentId: string,
  componentUuid: string,
  collapsable: boolean,
|};

const ComponentSelector = (props: Props): Element<typeof FormGroup> => {
  const {
    componentUuid,
    componentId,
    onSelect,
    getComponentTree,
    collapsable,
  } = props;
  const [treeVisible, setTreeVisible] = useState(!componentUuid);
  const [expanded, setExpanded] = useState([]);
  const [componentTree, setComponentTree] = useState<Object>(getComponentTree);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const ct = getComponentTree(searchText);
    setComponentTree(ct);
    if (searchText) {
      setExpanded(ct.expandableNodes);
    }
  }, [getComponentTree]);

  const search = (val) => {
    const ct = getComponentTree(val);
    setComponentTree(ct);
    setExpanded(ct.expandableNodes);
  };

  const debouncedSearch = debounce(500, search);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchText(value);
    debouncedSearch(value);
  };

  const handleExpandAll = () => {
    setExpanded(componentTree.expandableNodes);
  };

  const handleCollapseAll = () => {
    setExpanded([]);
  };

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeId) => {
    if (!componentTree.expandableNodes.includes(nodeId)) {
      onSelect(nodeId);
      setTreeVisible(false);
    }
  };

  const baseItem = {
    category: 'Components',
    label: 'Components',
    className: 'hidden',
    children: componentTree.tree,
  };

  const baseComponentSelector = (
    <Card>
      <CardBody>
        <Row>
          <Col>
            <Input
              id="searchBox"
              name="searchBox"
              type="text"
              placeholder="Search..."
              value={searchText}
              onChange={handleSearchChange}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button id="expandAll" color="link" onClick={handleExpandAll}>
              Expand All
            </Button>
            <Button id="collapseAll" color="link" onClick={handleCollapseAll}>
              Collapse All
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <MemoizedTreeView
              componentTreeData={componentTree}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              expanded={expanded}
              selected={componentUuid || []}
              onNodeToggle={handleToggle}
              onNodeSelect={handleSelect}
              className={treeViewClass}
            >
              {renderTreeItems(baseItem, true)}
            </MemoizedTreeView>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );

  const toggleTreeVisible = () => setTreeVisible(!treeVisible);
  const collapsableComponentSelector = (
    <>
      <Input
        id="treeToggle"
        name="treeToggle"
        type="text"
        className={`custom-select text-left ${dropDownButton}`}
        readOnly
        onClick={toggleTreeVisible}
        role="button"
        value={componentId}
      />
      <Collapse
        isOpen={treeVisible}
      >
        {baseComponentSelector}
      </Collapse>
    </>
  );

  return (
    <FormGroup>
      <div>
        <Label for="treeToggle">Select a Component</Label>
      </div>
      <div>
        {
          collapsable
            ? collapsableComponentSelector
            : baseComponentSelector
        }
      </div>
    </FormGroup>
  );
};

export default ComponentSelector;
