/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import getOr from 'lodash/fp/getOr.js';
import trim from 'lodash/fp/trim.js';

import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import {
  Col, Form, FormGroup, Label, Row,
} from 'reactstrap';
import { Field } from 'redux-form';
import SubmitButton from '#components/support/buttons/SubmitButton/index.jsx';
import RenderInput from '#components/support/forms/RenderInput/index.jsx';
import { required } from '#src/js/support/utility/form/validation/fieldValidators/index.js';
import EmailFieldWithLabelAndFeedback from '#components/support/forms/EmailField/index.jsx';
import { MODELS, RESET_PASSWORD } from '#constants/pathStrings.js';

type Props = {|
  error?: string,
  form: string,
  handleSubmit: Function,
  location: {},
  submitting: boolean,
  tokenIsSet: boolean,
  orgIsSet: boolean,
|}

const SignInForm = (props: Props): Element<typeof Navigate | typeof Form> => {
  const {
    error, form, handleSubmit, location, submitting, tokenIsSet, orgIsSet,
  } = props;

  if (tokenIsSet && orgIsSet) {
    return (
      <Navigate to={{ pathname: getOr(`/${MODELS}`, 'state.from', location) }} />
    );
  }
  const alert = error ? <div className="alert alert-danger">{error}</div> : null;
  return (
    <Form className="sign-in-form" onSubmit={handleSubmit}>
      {alert}
      <FormGroup row>
        <Col lg="6">
          <EmailFieldWithLabelAndFeedback form={form} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col lg="6">
          <Label htmlFor="password">
            Password
          </Label>
          <Field
            id="password"
            name="password"
            type="password"
            component={RenderInput}
            validate={required}
            normalize={trim}
          />
        </Col>
      </FormGroup>
      <Row>
        <Col lg="6">
          <SubmitButton color="primary" type="submit" formNoValidate submitting={submitting}>
            Submit
          </SubmitButton>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col lg="6">
          <Link className="forgot-password-link" to={`/${RESET_PASSWORD}`}>Forgot password?</Link>
        </Col>
      </Row>
    </Form>
  );
};

SignInForm.defaultProps = {
  error: '',
};

export default SignInForm;
