// @flow
import type { Element } from 'react';

import React from 'react';
import { Form } from 'reactstrap';
import {
  CONSEQUENCE_REPAIR_COST_LOWER_QTY,
  CONSEQUENCE_REPAIR_COST_UPPER_QTY,
  CONSEQUENCE_REPAIR_COST_MIN_AMOUNT,
  CONSEQUENCE_REPAIR_COST_MAX_AMOUNT,
  CONSEQUENCE_REPAIR_COST_UNCERTAINTY,
  CONSEQUENCE_REPAIR_COST_CURVE_TYPE,
  DAMAGE_STATES,
} from '@hbrisk/sp3-risk-model-support/components/translated/attributes/names/index.js';
import type { SectionComponentProps } from '#components/pages/Components/Component/sections/support/types/componentPagerSection';
import BackButton
  from '#components/pages/Components/Component/sections/support/buttons/BackButton.jsx';
import NextButton
  from '#components/pages/Components/Component/sections/support/buttons/NextButton.jsx';
import { fieldsByName } from '#constants/components/form/fields/index.js';
import {
  Field,
  Fieldset,
} from '#components/pages/Components/support/form/index.js';
import render from '#components/pages/Components/Component/sections/Consequences/support/renderConsequencePropertyRowTable/index.jsx';

const renderRepairCostTable = render([
  CONSEQUENCE_REPAIR_COST_LOWER_QTY,
  CONSEQUENCE_REPAIR_COST_UPPER_QTY,
  CONSEQUENCE_REPAIR_COST_MIN_AMOUNT,
  CONSEQUENCE_REPAIR_COST_MAX_AMOUNT,
  CONSEQUENCE_REPAIR_COST_UNCERTAINTY,
  CONSEQUENCE_REPAIR_COST_CURVE_TYPE,
]);
const ConsequencesRepairCost = ({
  onBack, onNext, readOnly,
}: SectionComponentProps): Element<'div'> => (
  <Form>
    <Fieldset>
      <legend>Repair Cost</legend>
      <Field
        render={renderRepairCostTable}
        field={fieldsByName[DAMAGE_STATES]}
        readOnly={readOnly}
        excludeLabel
        excludeInputGroup
      />
    </Fieldset>
    <BackButton onClick={onBack} />
    <NextButton onClick={onNext} />
  </Form>
);

export default ConsequencesRepairCost;
