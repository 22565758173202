// @flow
import type { Element } from 'react';

import React from 'react';
import cloneDeep from 'lodash/fp/cloneDeep.js';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Row,
} from 'reactstrap';
import { FieldArray } from 'redux-form';
import GridField from '#components/pages/Models/Model/sections/support/forms/GridField/index.jsx';
import renderLocations from './renderLocations/index.js';
import CalculatedCapacities from './CalculatedCapacities/index.jsx';

type Props = {|
form: string,
  autofilled: boolean,
  componentUuid: string,
  edpUnit: string,
  fields: {
    get: Function,
    map: Function,
    push: Function,
    remove: Function,
  },
  performanceGroup: string,
  index: number,
  showCalculatedCapacity: boolean,
  showServiceLocations: boolean,
  calculatedCapacities: Array<number>,
|};

const PerformanceGroup = (props: Props): Element<typeof Card> => {
  const {
    form,
    autofilled,
    componentUuid,
    edpUnit,
    fields,
    performanceGroup,
    index,
    showCalculatedCapacity,
    showServiceLocations,
    calculatedCapacities,
  } = props;

  const group = fields.get(index);

  return (
    <Card key={performanceGroup}>
      <CardBody>
        <Row>
          <Col>
            <ButtonGroup className="float-right">
              <Button
                id={`${performanceGroup}.deleteBtn`}
                className="pt-0 pb-0"
                color="link"
                onClick={() => fields.remove(index)}
                disabled={autofilled}
              >
                Delete
              </Button>
              <Button
                id={`${performanceGroup}.copyBtn`}
                className="pt-0 pb-0"
                color="link"
                onClick={
                  () => fields.push({
                    ...group,
                    name: '',
                    locations: cloneDeep(group.locations),
                  })
                }
                disabled={autofilled}
              >
                Copy
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <GridField
              name={`${performanceGroup}.name`}
              form={form}
              options={{
                ComponentType: 'text',
                includeFormatting: true,
                labelText: 'Group',
                externalAutofillSource: 'componentPopulation',
              }}
            />
          </Col>
        </Row>
        {showCalculatedCapacity && (
          <CalculatedCapacities
            calculatedCapacities={calculatedCapacities}
            edpUnit={edpUnit}
          />
        )}
        {showServiceLocations && (
          <Row>
            <Col lg="6">
              <GridField
                name={`${performanceGroup}.serviceLocations`}
                form={form}
                options={{
                  ComponentType: 'text',
                  includeFormatting: true,
                  includeToolTip: true,
                  labelText: 'Service Locations',
                  toolTipId: 'serviceLocations',
                  externalAutofillSource: 'componentPopulation',
                }}
              />
            </Col>
          </Row>
        )}
        <FieldArray
          autofilled={autofilled}
          form={form}
          componentUuid={componentUuid}
          name={`${performanceGroup}.locations`}
          component={renderLocations}
        />
      </CardBody>
    </Card>
  );
};

export default PerformanceGroup;
