/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { fetchModels } from '#actions/models/model.js';
import store from '#src/js/store/index.js';
import { sortChange } from '#actions/models/modelsTable.js';
import { modelsFiltered, selectFetchingModels } from '#selectors/entities/models.js';
import { selectAuthUserOrgId } from '#selectors/entities/users.js';
import { selectModelsTableFilters, selectModelsTableSorted } from '#selectors/ui/modelsTable.js';
import ModelsTable from './ModelsTable.jsx';

const mapStateToProps = (state) => {
  const filters = selectModelsTableFilters(state);
  const data = modelsFiltered(state, { ...filters });
  const orgId = selectAuthUserOrgId(state);
  const sorted = selectModelsTableSorted(state);

  return {
    data,
    orgId,
    sorted,
  };
};

const mapDispatchToProps = (dispatch) => ({
  makeHandleMount: (orgId) => () => {
    // Down the line we may want to handle this with a general polling middle ware
    let alreadyFetchingModels = selectFetchingModels(store.getState());
    const POLLING_INTERVAL = 5000;
    if (orgId && !alreadyFetchingModels) {
      dispatch(fetchModels(orgId));
    }
    return setInterval(() => {
      alreadyFetchingModels = selectFetchingModels(store.getState());
      if (orgId && !alreadyFetchingModels) {
        dispatch(fetchModels(orgId));
      }
    }, POLLING_INTERVAL);
  },
  handleSortedChange: (sorted) => {
    dispatch(sortChange(sorted));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { orgId } = stateProps;
  const { makeHandleMount, handleSortedChange } = dispatchProps;
  return {
    ...ownProps,
    ...stateProps,
    handleMount: makeHandleMount(orgId),
    handleSortedChange,
  };
};

const ModelsTableContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(ModelsTable);

export default ModelsTableContainer;
