import { pipe } from 'ramda';
import { reduxForm } from 'redux-form';
import withInitialization
  from '#components/pages/Components/Component/support/Router/withSectionFunctionality/withComponentForm/withInitialization/index.js';
import { COMPONENT_FORM } from '#constants/components/form/name.js';

const withComponentForm = (C) => pipe(
  withInitialization,
  reduxForm({ destroyOnUnmount: false, form: COMPONENT_FORM }),
)(C);

export default withComponentForm;
