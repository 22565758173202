/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import countBy from 'lodash/fp/countBy.js';
import get from 'lodash/fp/get.js';
import intersection from 'lodash/fp/intersection.js';
import keys from 'lodash/fp/keys.js';
import reduce from 'lodash/fp/reduce.js';
import { difference } from 'ramda';
import validateItemFields from '#support/utility/form/validation/validateItemFields.js';
import { getRange } from '#support/models/locationSpecifier/index.js';

const createValidatorForFieldArrayWithLocationSpecifier = ({
  itemFieldValidations,
  requireAtLeastOneItem = true,
  atLeastOneItemMessage = 'At least one entry is required',
  allowLocationOverlap = true,
  overlapMessages = {
    singular: (isFloor) => `Duplicate ${isFloor ? 'floor' : 'story'}`,
    plural: (isFloor) => `Duplicate ${isFloor ? 'floors' : 'stories'}`,
  },
  requireLocationExhaustiveness = false,
  missingMessages = {
    singular: (missingString, isFloor) => `Every ${isFloor ? 'floor' : 'story'} needs an entry. Missing entry for ${isFloor ? 'floor' : 'story'} ${missingString}`,
    plural: (missingString, isFloor) => `Every ${isFloor ? 'floor' : 'story'} needs an entry. Missing entries for ${isFloor ? 'floors' : 'stories'} ${missingString}`,
  },
}) => (items, values, state) => {
  const itemsLength = get('length', items);
  const { isFloor } = state;
  if (requireAtLeastOneItem && itemsLength < 1) {
    return { _error: atLeastOneItemMessage };
  }

  const itemsErrors = [];
  const ranges = [];
  const combinedRanges = [];
  let hasLocationSpecifierRangeErrors = false;
  let locationSpecifierErrorCount = 0;
  const { numberOfStories } = values;
  if (items) {
    // Begin looping through the items for a first time
    for (let k = 0; k < itemsLength; k += 1) {
      const item = items[k];

      // Run preliminary field validations on the current item and store any errors in an
      // object
      const itemErrors = validateItemFields(
        itemFieldValidations,
        item,
        values,
        items,
        k,
        state
      );

      // If the locationSpecifier doesn't already have an error
      // collect data for subsequent locationSpecifier validations
      // outside of this loop and collect additional errors if the
      // locationSpecifier has an invalid range
      if (!itemErrors.locationSpecifier) {
        const { locationSpecifier } = item;
        const {
          error,
          range,
        } = getRange(isFloor, parseInt(numberOfStories, 10), locationSpecifier);
        if (error === 'invalidRange') {
          hasLocationSpecifierRangeErrors = true;
          itemErrors.locationSpecifier = 'Invalid range';
        } else if (!hasLocationSpecifierRangeErrors) {
          ranges[k] = range;
          combinedRanges.push(...range);
        }
      }

      // If the current item has any errors, add them to
      // that item's errors at the appropriate index
      if (keys(itemErrors).length > 0) {
        itemsErrors[k] = itemErrors;
        if (itemErrors.locationSpecifier) {
          // increment the locationSpecifierErrorCount so that we can efficiently determine
          // later whether every locationSpecifier for the current performanceGroup has
          // has an error
          locationSpecifierErrorCount += 1;
        }
      }
    }
    // End looping through the items for the first time

    // If there are no locationSpecifier range errors and at least
    // one item's locationSpecifier doesn't already have an error,
    // use the data aggregated in the first pass through the items
    // to further validate the locationSpecifiers
    if (!hasLocationSpecifierRangeErrors && locationSpecifierErrorCount < itemsLength) {
      const countedStories = countBy((a) => a, combinedRanges);

      // Using the data aggregated above, determine which locations are duplicated
      // across multiple locationSpecifiers
      const locationDuplicates = reduce((cAcc, cCurr) => {
        const isError = countedStories[cCurr] > 1;
        return [
          ...cAcc,
          ...(isError ? [parseInt(cCurr, 10)] : []),
        ];
      }, [], keys(countedStories));

      // check for duplicate locations across location specifiers if desired
      if (!allowLocationOverlap) {
        // Begin looping through the locations items for a second time
        for (let k = 0; k < itemsLength; k += 1) {
          // if the locationSpecifier for the current location doesn't already
          // have an error, determine whether its locationSpecifier's range includes
          // any of the locations that are duplicated. If it does, merge a locationSpecifier
          // error into any other errors stored for that that location.
          if (get(`[${k}].locationSpecifier`, itemsErrors) === undefined) {
            const itemErrors = {};
            const range = ranges[k];
            const result = intersection(range, locationDuplicates);
            const resultLength = result.length;
            const hasDuplicates = resultLength > 0;
            if (!allowLocationOverlap && hasDuplicates) {
              itemErrors.locationSpecifier = resultLength > 1
                ? overlapMessages.plural(isFloor)
                : overlapMessages.singular(isFloor);
            }
            if (keys(itemErrors).length) {
              itemsErrors[k] = itemsErrors[k]
                ? { ...itemErrors, ...itemsErrors[k] }
                : itemErrors;
            }
          }
        }
        // End looping through the locations items for the second time
      }

      // Finally, if there are no other errors and the number of stories in the building is defined,
      // check for missing stories if desired
      if (requireLocationExhaustiveness && numberOfStories && itemsErrors.length < 1) {
        const expectedStories = Array(parseInt(numberOfStories, 10)).fill().map((x, i) => i + 1);
        const missing = difference(expectedStories, combinedRanges);
        const missingString = missing.join(', ');
        const missingLength = missing.length;
        if (missingLength > 0) {
          return {
            _error: missingLength > 1
              ? missingMessages.plural(missingString, isFloor)
              : missingMessages.singular(missingString, isFloor),
          };
        }
      }
    }
  }
  return itemsErrors.length > 0 ? itemsErrors : undefined;
};

export default createValidatorForFieldArrayWithLocationSpecifier;
