/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import join from 'lodash/fp/join.js';
import last from 'lodash/fp/last.js';
import mapKeys from 'lodash/fp/mapKeys.js';
import pickBy from 'lodash/fp/pickBy.js';
import pipe from 'lodash/fp/pipe.js';
import slice from 'lodash/fp/slice.js';
import split from 'lodash/fp/split.js';
import flattenKeys from '#support/utility/object/flattenKeys/index.js';

const getTouchedFromMeta = pipe(
  // flatten the objects keys
  flattenKeys,
  // filter out meta keys that are for 'touched" and items with values other than true
  // This includes keys with values of null in gappy arrays
  pickBy((value, key) => last(split('.', key)) === 'touched' && value === true),
  // Remove the final '.touched' from all of the keys
  mapKeys(pipe(
    split('.'),
    slice(0, -1),
    join('.')
  )),
);

export default getTouchedFromMeta;
