/**
 * Owner: Haselton Baker Risk Group, LLC
 * Product: SP3 - SP3-Ui
 * Developer: Mike McGlone <mike@hbrisk.com>
 * Collaborators: Patrick Barnum <patrick@hbrisk.com>
 * Date: 11/21/17
 * Copyright All Rights Reserved
 */
// @flow
/* eslint-disable react/jsx-props-no-spreading */
import type { ComponentType } from 'react';
import type { HOC } from 'recompose';

import classNames from 'classnames';
import React from 'react';
import {
  withScriptjs, withGoogleMap, GoogleMap, Marker,
} from 'react-google-maps';
import { compose, withProps } from 'recompose';
import config, { GOOGLE_MAPS_API_KEY } from '#support/app/config.js';
import googleMapURL from '#support/models/locationHelpers/maps/googleMapURL.js';

export const EnhancedGoogleMap: any = withGoogleMap(({
  markerProps, center, ...rest
}) => {
  const marker = (
    <Marker
      {...markerProps}
      defaultAnimation={0}
    />
  );

  return (
    <GoogleMap
      center={center}
      {...rest}
    >
      { marker }
    </GoogleMap>
  );
});

type Props = {|
  center: {
    lat: number,
    lng: number,
  },
  defaultZoom?: number,
  mapHeight?: number | string,
  markerProps?: Object,
  wrapperClassName?: string,
  wrapperHeight: number | string,
|};

const BaseMap = ({
  wrapperHeight, wrapperClassName, mapHeight, markerProps, center, ...rest
}: Props) => (
  <EnhancedGoogleMap
    containerElement={
      <div className={classNames(wrapperClassName)} style={{ height: wrapperHeight }} />
      }
    mapElement={
      <div style={{ height: mapHeight }} />
      }
    markerProps={markerProps}
    center={center}
    {...rest}
  />
);

BaseMap.defaultProps = {
  defaultZoom: 15,
  mapHeight: '100%',
  markerProps: {},
  wrapperClassName: '',
};

const addScript: HOC<*, {}> = compose(
  withProps({
    googleMapURL: googleMapURL(config(GOOGLE_MAPS_API_KEY)),
    loadingElement: <div />,
  }),
  withScriptjs
);

const BaseMapWithScript: ComponentType<any> = addScript(BaseMap);

export default BaseMapWithScript;
