/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import range from 'lodash/fp/range.js';
import last from 'lodash/fp/last.js';
import head from 'lodash/fp/head.js';
import validator from 'validator';

const { isInt } = validator;

const GROUND = 'G';
const ROOF = 'R';

const withGroundAndRoofReplaced = (isFloor, numberOfStories, locationSpecifier) => (
  isFloor
    ? locationSpecifier.replace(GROUND, '1').replace(ROOF, `${numberOfStories + 1}`)
    : locationSpecifier
);

export const getRange = (isFloor, numberOfStories, locationSpecifier) => {
  if (locationSpecifier === undefined || locationSpecifier.length === 0) {
    return {
      error: 'emptyRange',
      range: [],
    };
  }

  if ([undefined, NaN].includes(numberOfStories)) {
    return {
      error: 'numberOfStoriesUndefined',
      range: [],
    };
  }

  return (
    withGroundAndRoofReplaced(isFloor, numberOfStories, locationSpecifier)
      .replace(/\s/g, '')
      .split(',')
      .reduce((acc, curr) => {
        const [startStr, endStr] = curr.split('-');
        const start = parseInt(startStr, 10);
        const end = parseInt(endStr, 10);
        const max = isFloor ? numberOfStories + 1 : numberOfStories;
        const startError = !isInt(`${startStr}`, { min: 1, max });
        const endError = !isInt(`${endStr}`, { min: 1, max });
        const startGreaterThanEndError = start >= end;
        const currError = startError
          || (endStr !== undefined && endError)
          || startGreaterThanEndError;

        return {
          error: acc.error || currError ? 'invalidRange' : undefined,
          range: [
            ...acc.range,
            ...(!startError && !endError ? range(start, end + 1) : []),
            ...(!startError && endStr === undefined ? [start] : []),
          ],
        };
      }, { error: false, range: [] })
  );
};

const findRangeBreaks = (arr) => {
  const indexes = [];
  arr.forEach((item, index) => {
    if (index !== 0 && arr[index - 1] + 1 !== item) {
      indexes.push(index);
    }
  });
  indexes.push(arr.length);
  return indexes;
};

const getRangeArrays = (arr, rangeBreakIndexes) => {
  let lastIndex = 0;
  return rangeBreakIndexes.map((index) => {
    const rangeRes = arr.slice(lastIndex, index);
    lastIndex = index;
    return rangeRes;
  });
};

const withGroundAndRoof = (item, numberOfStories) => {
  const roof = numberOfStories + 1;
  if (item === 1) { return GROUND; }
  if (item === roof) { return ROOF; }
  return `${item}`;
};

const getRangeStartAndEnd = (isFloor, numberOfStories, arr) => {
  const firstItem = head(arr);
  const lastItem = last(arr);
  return {
    firstItem: isFloor ? withGroundAndRoof(firstItem, numberOfStories) : firstItem,
    lastItem: isFloor ? withGroundAndRoof(lastItem, numberOfStories) : lastItem,
  };
};

const getRanges = (isFloor, numberOfStories, rangeArrays) => {
  const str = (
    rangeArrays.reduce((acc, curr) => {
      const {
        firstItem,
        lastItem,
      } = getRangeStartAndEnd(isFloor, numberOfStories, curr);

      if (curr.length === 0) {
        return ',';
      }
      if (curr.length === 1) {
        return acc.concat(`${lastItem},`);
      }
      if (curr.length === 2) {
        return acc.concat(`${firstItem},${lastItem},`);
      }
      return acc.concat(`${firstItem}-${lastItem},`);
    }, '')
      .slice(0, -1)
  );

  if (str === '') {
    return 'None';
  }
  if (str === 'G-R') {
    return 'All Floors';
  }
  if (!isFloor && str === `1-${numberOfStories}`) {
    return 'All Stories';
  }
  if (str === 'G') {
    return 'Ground Only';
  }
  if (str === 'R') {
    return 'Roof Only';
  }
  if (str.includes(',') || str.includes('-')) {
    return isFloor ? `Floors ${str}` : `Stories ${str}`;
  }
  return isFloor ? `Floor ${str}` : `Story ${str}`;
};

export const getLocationStringFromArray = (isFloor, numberOfStories, arr) => {
  const rangeBreaks = findRangeBreaks(arr);
  const rangeArrays = getRangeArrays(arr, rangeBreaks);
  return getRanges(isFloor, numberOfStories, rangeArrays);
};
