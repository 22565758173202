/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import isFinite from 'lodash/fp/isFinite.js';
import numeral from 'numeral';

const formatWholeNumberPortion = (value) => {
  const decimalPointPosition = value.indexOf('.');
  const wholeNumberLength = decimalPointPosition !== -1 ? decimalPointPosition : value.length;

  const wholeNumber = value.substring(0, wholeNumberLength);
  return numeral(wholeNumber).format('0,0');
};

const formatDecimalPortion = (value) => {
  const decimalPointPosition = value.indexOf('.');
  return decimalPointPosition !== -1
    ? value.substring(decimalPointPosition, value.length)
    : '';
};

export const formatWithCommas = (value) => {
  if ([undefined, null].includes(value)) {
    return '';
  }

  const numeralVal = numeral(value).value();

  if (isFinite(numeralVal)) {
    const wholeNumberPortion = formatWholeNumberPortion(value);
    const decimalPortion = formatDecimalPortion(value);
    return `${wholeNumberPortion}${decimalPortion}`;
  }
  return value;
};

export const normalizeWithCommas = (value) => {
  const numeralVal = numeral(value);
  if (isFinite(numeralVal.value())) {
    return value.replaceAll(',', '');
  }
  return value;
};
