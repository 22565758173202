/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import pipe from 'lodash/fp/pipe.js';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import { createUserPassword, fetchUser } from '#actions/users/users.js';
import { CREATE_PASSWORD_FORM } from '#constants/users/form/createPassword/name.js';
import { selectAuthUserTokenIsSet, selectAuthUserOrgIsSet } from '#selectors/entities/users.js';
import CreatePasswordForm from './CreatePasswordForm.jsx';

const onSubmit = (values, dispatch) => (
  dispatch(createUserPassword(values.user, values.newPassword, dispatch))
    .then((response) => dispatch(fetchUser(response.payload.authUserId)))
    .catch((response) => Promise.reject(new SubmissionError({ _error: response.payload.message })))
);
const mapStateToProps = (state) => (
  {
    tokenIsSet: selectAuthUserTokenIsSet(state),
    orgIsSet: selectAuthUserOrgIsSet(state),
  }
);

export default pipe(
  reduxForm({
    form: CREATE_PASSWORD_FORM,
    onSubmit,
  }),
  connect(mapStateToProps)
)(CreatePasswordForm);
