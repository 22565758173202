// @flow
import type { Element, Node } from 'react';

import React from 'react';
import { Input } from 'reactstrap';
import { isEmpty } from 'lodash';

type Props = {|
  input: {},
  id: string,
  type: string,
  disabled?: boolean,
  meta: {
    touched: boolean,
    error: string,
  },
  children?: Node,
  inputClassName: string,
  readOnly?: boolean,
|};

const RenderInput = (props: Props): Element<typeof Input> => {
  const {
    disabled,
    input,
    id,
    type,
    meta,
    children,
    inputClassName,
    readOnly,
  } = props;

  const { touched, error } = meta;
  const hasError = touched && !isEmpty(error);
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Input
      {...input}
      id={id}
      type={type}
      invalid={hasError}
      className={inputClassName}
      disabled={disabled}
      readOnly={readOnly}
    >
      {children}

    </Input>
  );
  /* eslint-enable react/jsx-props-no-spreading */
};

RenderInput.defaultProps = {
  children: null,
  readOnly: false,
  disabled: false,
};

export default RenderInput;
