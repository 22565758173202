/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { COMPONENT_ID, DEFAULT_EDP } from '@hbrisk/sp3-risk-model-support/components/translated/attributes/names/index.js';
import { selectComponentByUuid } from '#selectors/entities/components.js';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import renderPerformanceGroups from './renderPerformanceGroups.jsx';

const mapStateToProps = (_, ownProps) => {
  const { form, componentUuid } = ownProps;
  const { makeFieldIsAutofilledSelector } = modelTypesByForm[form];
  const selectFieldIsAutofilled = makeFieldIsAutofilledSelector();
  return (state) => {
    const component = selectComponentByUuid(state, { uuid: componentUuid });
    const defaultEdp = component ? component[DEFAULT_EDP] : null;
    const componentId = component ? component[COMPONENT_ID] : null;
    const autofilled = selectFieldIsAutofilled(state, { name: 'componentPopulation' });
    return ({
      autofilled,
      componentId,
      defaultEdp,
    });
  };
};

export default connect(mapStateToProps)(renderPerformanceGroups);
