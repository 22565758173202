// @flow
import type { Element } from 'react';

import React from 'react';
import {
  CONSEQUENCE_PROBABILITY,
  CONSEQUENCE_DESCRIPTION,
} from '@hbrisk/sp3-risk-model-support/components/translated/attributes/names/index.js';
import type { Component } from '#types/Component/index.js';

import ConsequenceRowTable from '#components/pages/Components/support/tables/ConsequenceRowTable/index.jsx';

const itemFields = [
  CONSEQUENCE_PROBABILITY,
  CONSEQUENCE_DESCRIPTION,
];

type Props = {
  component: Component,
};
const ConsequenceSummaryTable = ({
  component,
}: Props): Element<typeof ConsequenceRowTable> => (
  <ConsequenceRowTable
    entityMode
    component={component}
    itemFields={itemFields}
  />
);
export default ConsequenceSummaryTable;
