import get from 'lodash/fp/get.js';
import {
  REDUX_FORM_CHANGE,
  INITIALIZE_FORM,
  SHOW_FIELD,
  HIDE_FIELD,
} from '#constants/actionTypes.js';
import resolveFormInputs from '#src/js/support/utility/form/resolveFormInputs/index.js';
import { COMPONENT_FORM } from '#constants/components/form/name.js';
import fields from '#src/js/constants/components/form/fields/index.js';

const componentDetailFormReducerPlugin = (state = {}, action = null) => {
  const name = COMPONENT_FORM;
  const actionForm = get('meta.form', action);
  if (actionForm !== name) {
    return state;
  }
  const { type, payload } = action;

  switch (type) {
    case INITIALIZE_FORM:
      return {
        ...state,
        ...resolveFormInputs(
          fields,
          payload,
          action
        ),
        initial: payload.values,
      };
    case SHOW_FIELD:
      return {
        ...state,
        ...resolveFormInputs(
          fields,
          {
            ...state,
            visibleFields: {
              ...state.visibleFields,
              [payload.name]: true,
            },
          },
          action
        ),
      };
    case REDUX_FORM_CHANGE:
      return {
        ...state,
        ...resolveFormInputs(
          fields,
          state,
          action
        ),
      };
    case HIDE_FIELD: {
      return {
        ...state,
        ...resolveFormInputs(
          fields,
          {
            ...state,
            visibleFields: {
              ...state.visibleFields,
              [payload.name]: false,
            },
          },
          action
        ),
      };
    }
    default:
      return state;
  }
};

export default componentDetailFormReducerPlugin;
