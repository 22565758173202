/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import {
  Button, Col, Form, FormGroup, Label, Row,
} from 'reactstrap';
import { Field } from 'redux-form';
import RenderInput from '#components/support/forms/RenderInput/index.jsx';
import DatePickerField from '#components/support/forms/DatePickerField/index.jsx';
import { emptyOrGreaterThanStart } from '#src/js/support/utility/form/validation/fieldValidators/index.js';
import ModelLevelSelectMenu from './ModelLevelSelectMenu/index.jsx';

const greaterThanCreatedStart = emptyOrGreaterThanStart('createdStart');
const greaterThanUpdatedStart = emptyOrGreaterThanStart('updatedStart');

type Props = {|
  form: string,
  handleClearFilters: Function,
  handleSubmit: Function,
|};

const ModelsFilterForm = (props: Props): Element<typeof Form> => {
  const {
    form, handleClearFilters, handleSubmit,
  } = props;

  return (
    <Form className="models-filter-form" onSubmit={handleSubmit}>
      <FormGroup tag="fieldset" className="pb-0">
        <legend>Filters</legend>
        <Row>
          <Col lg="4">
            <FormGroup className="d-flex">
              <Label className="p-2" htmlFor="modelName">
                Model
              </Label>
              <Field
                id="modelName"
                name="modelName"
                type="text"
                component={RenderInput}
                inputClassName="p-2"
              />
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup className="d-flex">
              <Label className="p-2" htmlFor="createdStart">
                Created
              </Label>
              <DatePickerField
                form={form}
                name="createdStart"
              />
              <span className="p-2">to</span>
              <DatePickerField
                form={form}
                name="createdEnd"
                validate={[greaterThanCreatedStart]}
              />
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup className="d-flex">
              <Label className="p-2" htmlFor="author">
                Author
              </Label>
              <Field
                id="author"
                name="author"
                type="text"
                component={RenderInput}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <FormGroup className="d-flex">
              <Label className="p-2" htmlFor="project">
                Project
              </Label>
              <Field
                id="projectName"
                name="projectName"
                type="Text"
                component={RenderInput}
              />
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup className="d-flex">
              <Label className="p-2" htmlFor="updatedStart">
                Updated
              </Label>
              <DatePickerField
                form={form}
                name="updatedStart"
              />
              <span className="p-2">to</span>
              <DatePickerField
                form={form}
                name="updatedEnd"
                validate={[greaterThanUpdatedStart]}
              />
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup className="d-flex">
              <Label className="p-2" htmlFor="modelLevel">
                Level
              </Label>
              <ModelLevelSelectMenu
                name="modelLevel"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="8" />
          <Col lg="2">
            <FormGroup check>
              <Field
                id="includeArchived"
                name="includeArchived"
                type="checkbox"
                component={RenderInput}
              />
              <Label htmlFor="includeArchived" check>
                Include Archived?
              </Label>
            </FormGroup>
          </Col>
          <Col lg="2">
            <Button
              id="clearFilters"
              className="float-right pt-0 pb-2"
              color="link"
              size="sm"
              onClick={handleClearFilters}
            >
              Clear Filters
            </Button>
          </Col>
        </Row>
      </FormGroup>
    </Form>
  );
};

export default ModelsFilterForm;
