// @flow
import type { Element } from 'react';

import React from 'react';
import { Form } from 'reactstrap';
import {
  IS_CORRELATED,
  IS_DIRECTIONAL,
  DEFAULT_EDP,
  EDP_UNIT,
  EDP_LOCATION,
  IS_OFFICIAL_P58,
} from '@hbrisk/sp3-risk-model-support/components/translated/attributes/names/index.js';
import type { SectionComponentProps } from '#components/pages/Components/Component/sections/support/types/componentPagerSection';
import {
  Field, Col, FormGroup, Fieldset,
} from '#components/pages/Components/support/form/index.js';
import { fieldsByName } from '#constants/components/form/fields/index.js';
import NextButton from '../support/buttons/NextButton.jsx';
import BackButton from '../support/buttons/BackButton.jsx';

const FragilityInfo = ({
  onBack, onNext, readOnly,
}: SectionComponentProps): Element<'div'> => (
  <Form noValidate>
    <Fieldset>
      <legend>Fragility Info</legend>
      <FormGroup fields={[IS_CORRELATED]} row>
        <Col lg="6">
          <Field field={fieldsByName[IS_CORRELATED]} readOnly={readOnly} />
        </Col>
      </FormGroup>
      <FormGroup fields={[IS_DIRECTIONAL]} row>
        <Col lg="6">
          <Field field={fieldsByName[IS_DIRECTIONAL]} readOnly={readOnly} />
        </Col>
      </FormGroup>
      <FormGroup fields={[DEFAULT_EDP]} row>
        <Col lg="6">
          <Field field={fieldsByName[DEFAULT_EDP]} readOnly={readOnly} />
        </Col>
      </FormGroup>
      <FormGroup fields={[EDP_UNIT]} row>
        <Col lg="6">
          <Field field={fieldsByName[EDP_UNIT]} readOnly={readOnly} />
        </Col>
      </FormGroup>
      <FormGroup fields={[EDP_LOCATION]} row>
        <Col lg="6">
          <Field field={fieldsByName[EDP_LOCATION]} readOnly={readOnly} />
        </Col>
      </FormGroup>
      <FormGroup fields={[IS_OFFICIAL_P58]} row>
        <Col lg="6">
          <Field field={fieldsByName[IS_OFFICIAL_P58]} readOnly={readOnly} />
        </Col>
      </FormGroup>
    </Fieldset>
    <BackButton onClick={onBack} />
    <NextButton onClick={onNext} />
  </Form>
);

export default FragilityInfo;
