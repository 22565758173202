/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import trim from 'lodash/fp/trim.js';
import React from 'react';
import {
  Form, FormGroup, Label, Col, Row,
} from 'reactstrap';
import { Field } from 'redux-form';
import SubmitButton from '#components/support/buttons/SubmitButton/index.jsx';
import NewPasswordField from '#components/support/forms/NewPasswordField/index.jsx';
import ConfirmPasswordField from '#components/support/forms/ConfirmPasswordField/index.jsx';
import FieldFeedback from '#components/support/forms/FieldFeedback/index.js';
import RenderInput from '#components/support/forms/RenderInput/index.jsx';
import { required } from '#src/js/support/utility/form/validation/fieldValidators/index.js';
import Tip from '#components/support/tooltips/Tip/index.jsx';

type Props = {|
  error?: string,
  form: string,
  handleSubmit: Function,
  submitting: boolean,
|};

const ResetPasswordFormPage2 = (props: Props): Element<typeof Form> => {
  const {
    error, form, handleSubmit, submitting,
  } = props;
  const alert = error ? <div className="alert alert-danger">{error}</div> : null;
  return (
    <Form className="reset-password-form reset-password-form-page-2" onSubmit={handleSubmit}>
      <Row>
        <Col lg="8">
          <p>
            Check your email inbox for your confirmation code and then use it to set your
            new password below.
          </p>
        </Col>
      </Row>
      {alert}
      <FormGroup row>
        <Col lg="6">
          <Label htmlFor="confirmationCode">
            Confirmation Code
          </Label>
          {' '}
          <Tip tipId="confirmationCode" />
          <Field
            id="confirmationCode"
            name="confirmationCode"
            type="text"
            component={RenderInput}
            validate={[required]}
            normalize={trim}
          />
          <FieldFeedback name="confirmationCode" form={form} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col lg="6">
          <NewPasswordField form={form} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col lg="6">
          <ConfirmPasswordField form={form} />
        </Col>
      </FormGroup>
      <SubmitButton color="primary" type="submit" formNoValidate submitting={submitting}>
        Submit
      </SubmitButton>
    </Form>
  );
};

ResetPasswordFormPage2.defaultProps = {
  error: '',
};

export default ResetPasswordFormPage2;
