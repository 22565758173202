/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import flat from 'flat';
import mapKeys from 'lodash/fp/mapKeys.js';
import pipe from 'lodash/fp/pipe.js';

const { flatten } = flat;

const flattenKeys = pipe(
  flatten,
  mapKeys((key) => key.replace(/\.(\d+)/g, '[$1]')),
);

export default flattenKeys;
