/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import debounce from 'lodash/fp/debounce.js';
import get from 'lodash/fp/get.js';

import { change, formValueSelector } from 'redux-form';
import { putModel } from '#actions/models/model.js';
import { geocode } from '#actions/models/geocode.js';
import { MODEL_FORM_NAMES } from '#constants/models/form/model/names.js';
import {
  AUTOFILL_MODEL_FORM_FIELD,
  FETCH_MODEL_RESPONSE,
  HIDE_FIELD,
  REDUX_FORM_ARRAY_PUSH,
  REDUX_FORM_ARRAY_REMOVE, REDUX_FORM_ARRAY_UNSHIFT,
  REDUX_FORM_CHANGE,
  SHOW_FIELD,
  UNAUTOFILL_MODEL_FORM_FIELD,
} from '#constants/actionTypes.js';
import { modelTypesByForm, modelTypesById } from '#src/js/constants/models/modelTypes/index.js';
import { currentModelId } from '#selectors/entities/models.js';
import googleGeocodeUrl from '#support/models/geocoding/googleGeocodeUrl.js';
import format from '#support/models/formatCoordinateValue.js';
import calculateModelPutPayload from '#support/models/calculateModelPutPayload.js';
import { updateSyncErrors } from '#actions/models/modelForm.js';

const setCoordinateFieldValuesBase = (form, dispatch, reduxState) => {
  const {
    street, city, state, country,
  } = formValueSelector(form)(reduxState, 'street', 'city', 'state', 'country');
  if (street && city && state && country) {
    dispatch(geocode(googleGeocodeUrl(street, city, state, country)))
      .then(({ payload }) => {
        if (payload) {
          const { lat, lng } = payload;
          dispatch(change(form, 'lat', format(lat)));
          dispatch(change(form, 'lng', format(lng)));
        }
      });
  }
};

const setCoordinateFieldValues = debounce(500, setCoordinateFieldValuesBase);

/**
 * A list of redux action types to trigger saves on
 */
const saveTypes = [
  HIDE_FIELD,
  AUTOFILL_MODEL_FORM_FIELD,
  REDUX_FORM_ARRAY_PUSH,
  REDUX_FORM_ARRAY_REMOVE,
  REDUX_FORM_ARRAY_UNSHIFT,
  REDUX_FORM_CHANGE,
  SHOW_FIELD,
  UNAUTOFILL_MODEL_FORM_FIELD,
];

const saveModelBase = (dispatch, modelId, values) => {
  dispatch(putModel(modelId, values));
};

const saveModel = debounce(2000, saveModelBase);

const modelFormMiddleware = (store) => (next) => (action) => {
  // Pass the action to the next middleware and continue
  const result = next(action);

  const { type } = action;

  const form = get('meta.form', action);
  if (MODEL_FORM_NAMES.includes(form)) {
    const { dispatch, getState } = store;
    const state = getState();

    // Check to see whether the action is a REDUX_FORM_CHANGE
    if (type === REDUX_FORM_CHANGE) {
      // if it's a REDUX_FORM_CHANGE action in one of the model form's address field,
      // geocode the site location
      const { meta: { field } } = action;
      if (['street', 'city', 'state', 'zip', 'country'].includes(field)) {
        setCoordinateFieldValues(form, dispatch, state);
      }
    }

    const {
      selectFieldsVisibility,
      selectAllFormValues,
      selectAutofilledFormFields,
      selectFormInitialized,
    } = modelTypesByForm[form];

    // Check to see whether the action has a saveType and whether the form has initialized
    const initialized = selectFormInitialized(state);
    if (saveTypes.includes(type) && initialized) {
      const modelId = currentModelId(state);
      const values = selectAllFormValues(state);
      const visibility = selectFieldsVisibility(state);
      const autofilled = selectAutofilledFormFields(state);
      const formInputs = calculateModelPutPayload(autofilled, visibility, values);
      saveModel(dispatch, modelId, { formInputs });
    }
    return result;
  }

  // If the the action is a model fetch response, run form validation
  if (type === FETCH_MODEL_RESPONSE) {
    const { dispatch, getState } = store;
    const { payload: { type: modelTypeId } } = action;
    const {
      modelFormName,
      validate,
      selectAllFormValues,
      selectAutofilledFormFields,
    } = modelTypesById[modelTypeId];
    const state = getState();
    const values = selectAllFormValues(state);
    if (values) {
      const autofilled = selectAutofilledFormFields(state);
      const syncErrors = validate(autofilled, state)(values);
      dispatch(updateSyncErrors(modelFormName, syncErrors));
    }
    return result;
  }

  return result;
};

export default modelFormMiddleware;
