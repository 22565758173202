/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import moment from 'moment';
import React, { useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import {
  Button, Col, Form, FormGroup, Input, Label,
} from 'reactstrap';
import { Field } from 'redux-form';
import SubmitButton from '#components/support/buttons/SubmitButton/index.jsx';
import FieldFeedback from '#components/support/forms/FieldFeedback/index.js';
import RenderInput from '#components/support/forms/RenderInput/index.jsx';
import EmailFieldWithLabelAndFeedback from '#components/support/forms/EmailField/index.jsx';
import { emptyOrMaxLength100, required } from '#src/js/support/utility/form/validation/fieldValidators/index.js';
import type { User } from '#types/User.js';
import OrgSelectMenu from '../../support/OrgSelectMenu/index.js';

const formatDate = (date) => moment(date).format('MM/DD/YYYY');

type Props = {|
  error?: string,
  form: string,
  handleSubmit: Function,
  initialize: Function,
  user: User,
  submitSucceeded: boolean,
  submitting: boolean,
|};

const EditUserForm = (props: Props): Element<typeof Form> => {
  const {
    error, form, handleSubmit, initialize, user, submitting, submitSucceeded,
  } = props;

  useEffect(() => {
    if (user) {
      initialize({
        active: user.active,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        orgId: user.orgId,
        isAdmin: user.permissions.isAdmin,
      });
    }
  }, [user]);

  const redirect = submitSucceeded ? <Navigate to=".." /> : null;
  const alert = error ? <div className="alert alert-danger">{error}</div> : null;
  return (
    <Form className="edit-user-form" onSubmit={handleSubmit}>
      {alert || redirect}
      <FormGroup tag="fieldset">
        <legend>General</legend>
        <FormGroup row>
          <Col lg="6">
            <Label htmlFor="firstName">
              Name
            </Label>
            <Field
              id="firstName"
              name="firstName"
              type="text"
              component={RenderInput}
              validate={[required, emptyOrMaxLength100]}
            />
            <FieldFeedback name="firstName" form={form} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col lg="6">
            <Label htmlFor="lastName">
              Name
            </Label>
            <Field
              id="lastName"
              name="lastName"
              type="text"
              component={RenderInput}
              validate={[required, emptyOrMaxLength100]}
            />
            <FieldFeedback name="lastName" form={form} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col lg="6">
            <EmailFieldWithLabelAndFeedback form={form} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col lg="6">
            <Label htmlFor="orgId">
              Organization
            </Label>
            <OrgSelectMenu name="orgId" validate={[required]} />
            <FieldFeedback name="orgId" form={form} />
          </Col>
        </FormGroup>
        <FormGroup row check>
          <Col lg="5" className="mb-3">
            <Label check>
              <Field
                id="active"
                name="active"
                type="checkbox"
                component={RenderInput}
              />
              Active
            </Label>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col lg="6">
            <Label htmlFor="createdAt">
              Created
            </Label>
            <Input
              id="createdAt"
              type="text"
              readOnly
              value={formatDate(user.createdAt)}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col lg="6">
            <Label htmlFor="updatedAt">
              Last Updated
            </Label>
            <Input
              id="updatedAt"
              type="text"
              readOnly
              value={formatDate(user.updatedAt)}
            />
          </Col>
        </FormGroup>
      </FormGroup>
      <FormGroup tag="fieldset">
        <legend>Permissions</legend>
        <FormGroup row check>
          <Col lg="5" className="ml-3">
            <Label check>
              <Field
                id="isAdmin"
                name="isAdmin"
                type="checkbox"
                component={RenderInput}
              />
              Is Admin
            </Label>
          </Col>
        </FormGroup>
      </FormGroup>
      <SubmitButton className="float-right" submitting={submitting}>
        Save
      </SubmitButton>
      <Button
        color="link"
        tag={Link}
        to=".."
      >
        Cancel
      </Button>
    </Form>
  );
};

EditUserForm.defaultProps = {
  error: '',
};

export default EditUserForm;
