import {
  CAPACITY_CALCULATION,
  COMPONENT_GROUPING,
  CONSEQUENCES,
  CONSEQUENCES_REPAIR_COST,
  CONSEQUENCES_OTHER,
  CONSEQUENCES_LIFE_SAFETY,
  CONSEQUENCES_RECOVERY_TIME,
  CONSEQUENCES_REPAIR_TIME,
  DAMAGE_STATES,
  GENERAL,
  FRAGILITY_INFO,
  RECOVERY_TIME,
  SYSTEM_SPECIFIC_INFO,
} from '#constants/components/componentPage/sections/index.js';
import CapacityCalculation from '#components/pages/Components/Component/sections/CapacityCalculation/index.jsx';
import ComponentGrouping from '#components/pages/Components/Component/sections/ComponentGrouping/index.jsx';
import ConsequencesMain from '#components/pages/Components/Component/sections/Consequences/Main/index.jsx';
import ConsequencesOther from '#components/pages/Components/Component/sections/Consequences/Other/index.jsx';
import ConsequencesCost from '#components/pages/Components/Component/sections/Consequences/RepairCost/index.jsx';
import ConsequencesRepairTime from '#components/pages/Components/Component/sections/Consequences/RepairTime/index.jsx';
import DamageStates from '#components/pages/Components/Component/sections/DamageStates/index.jsx';
import General from '#components/pages/Components/Component/sections/General/index.jsx';
import FragilityInfo from '#components/pages/Components/Component/sections/FragilityInfo/index.jsx';
import RecoveryTime from '#components/pages/Components/Component/sections/RecoveryTime/index.jsx';
import SystemSpecificInfo from '#components/pages/Components/Component/sections/SystemSpecificInfo/index.jsx';
import ConsequencesLifeSaftey
  from '#components/pages/Components/Component/sections/Consequences/LifeSafety/index.jsx';
import ConsequencesRecoveryTime
  from '#components/pages/Components/Component/sections/Consequences/RecoveryTime/index.jsx';

const sectionComponentsById = {
  [CAPACITY_CALCULATION]: CapacityCalculation,
  [COMPONENT_GROUPING]: ComponentGrouping,
  [CONSEQUENCES]: ConsequencesMain,
  [CONSEQUENCES_REPAIR_COST]: ConsequencesCost,
  [CONSEQUENCES_REPAIR_TIME]: ConsequencesRepairTime,
  [CONSEQUENCES_LIFE_SAFETY]: ConsequencesLifeSaftey,
  [CONSEQUENCES_RECOVERY_TIME]: ConsequencesRecoveryTime,
  [CONSEQUENCES_OTHER]: ConsequencesOther,
  [DAMAGE_STATES]: DamageStates,
  [GENERAL]: General,
  [FRAGILITY_INFO]: FragilityInfo,
  [RECOVERY_TIME]: RecoveryTime,
  [SYSTEM_SPECIFIC_INFO]: SystemSpecificInfo,
};

export default sectionComponentsById;
