/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import {
  Button, Col, Form, FormGroup, Label,
} from 'reactstrap';
import { Field } from 'redux-form';
import SubmitButton from '#components/support/buttons/SubmitButton/index.jsx';
import EmailFieldWithLabelAndFeedback from '#components/support/forms/EmailField/index.jsx';
import FieldFeedback from '#components/support/forms/FieldFeedback/index.js';
import RenderInput from '#components/support/forms/RenderInput/index.jsx';
import { emptyOrMaxLength100, required } from '#src/js/support/utility/form/validation/fieldValidators/index.js';
import OrgSelectMenu from '../../support/OrgSelectMenu/index.js';

type Props = {|
  error?: string,
  form: string,
  handleSubmit: Function,
  submitSucceeded: boolean,
  submitting: boolean,
|};

const CreateUserForm = (props: Props): Element<typeof Form> => {
  const {
    error, form, handleSubmit, submitting, submitSucceeded,
  } = props;
  const redirect = submitSucceeded ? <Navigate to=".." /> : null;
  const alert = error ? <div className="alert alert-danger">{error}</div> : null;
  return (
    <Form className="create-user-form" onSubmit={handleSubmit}>
      {alert || redirect}
      <FormGroup tag="fieldset">
        <legend>General</legend>
        <FormGroup row>
          <Col lg="6">
            <Label htmlFor="firstName">
              First Name
            </Label>
            <Field
              id="firstName"
              name="firstName"
              type="text"
              component={RenderInput}
              validate={[required, emptyOrMaxLength100]}
            />
            <FieldFeedback name="firstName" form={form} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col lg="6">
            <Label htmlFor="lastName">
              Last Name
            </Label>
            <Field
              id="lastName"
              name="lastName"
              type="text"
              component={RenderInput}
              validate={[required, emptyOrMaxLength100]}
            />
            <FieldFeedback name="lastName" form={form} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col lg="6">
            <EmailFieldWithLabelAndFeedback form={form} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col lg="6">
            <Label htmlFor="orgId">
              Organization
            </Label>
            <OrgSelectMenu name="orgId" validate={[required]} />
            <FieldFeedback name="orgId" form={form} />
          </Col>
        </FormGroup>
      </FormGroup>
      <FormGroup tag="fieldset">
        <legend>Permissions</legend>
        <FormGroup row check>
          <Col lg="5" className="ml-3">
            <Label check>
              <Field
                id="isAdmin"
                name="isAdmin"
                type="checkbox"
                component={RenderInput}
              />
              Is Admin
            </Label>
          </Col>
        </FormGroup>
      </FormGroup>
      <SubmitButton className="float-right" submitting={submitting}>
        Create
      </SubmitButton>
      <Button
        color="link"
        tag={Link}
        to=".."
      >
        Cancel
      </Button>
    </Form>
  );
};

CreateUserForm.defaultProps = {
  error: '',
};

export default CreateUserForm;
