/**
 * Owner: Haselton Baker Risk Group, LLC
 * Developer: Mike McGlone <mike@hbrisk.com>
 * Date: 12/14/2016
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import classNames from 'classnames';
import React from 'react';
import { Col, Row } from 'reactstrap';
import Footer from '#components/support/layout/Footer/index.jsx';
import Main from '#src/js/components/support/layout/MainPageContainer/index.jsx';
import Page from '#components/support/layout/Page/index.js';
import SP3Logo from '#components/support/logos/SP3Logo/index.jsx';
import CreatePasswordForm from './CreatePasswordForm/index.js';
import SignInForm from './SignInForm/index.js';
import {
  col, mainContainerWrapper, logo, signInPage,
} from './index.module.scss';

type Props = {|
  shouldShowSignIn?: boolean,
|};

const SignInPage = ({ shouldShowSignIn }: Props): Element<typeof Page> => (
  <Page className={classNames(signInPage, 'sign-in-page')}>
    <div className={mainContainerWrapper}>
      <Main withRoomForHeader={false}>
        <Row>
          <Col sm={{ size: 6, order: 2 }} className={col}>
            <SP3Logo className={logo} />
            {
              // By default, we show a form for signing in. If on sign in, it is determined that
              // the user needs to create a password, we replace the sign form with one for creating
              // a password
              shouldShowSignIn
                ? (
                  <>
                    <h2>Sign In</h2>
                    <SignInForm />
                  </>
                )
                : (
                  <>
                    <h2>Create Your Password</h2>
                    <p>To continue, create your custom password.</p>
                    <CreatePasswordForm />
                  </>
                )
            }
          </Col>
          <Col sm={{ size: 6, order: 1 }} className={col}>
            <ul>
              <li>State-of-the-art seismic risk assessment</li>
              <li>Leverages decades of research</li>
              <li>Building-specific and site-specific</li>
              <li>Comprehensive and credible</li>
              <li>Supports both quick and detailed risk studies</li>
              <li>Standardized analysis methods</li>
              <li>Fast and easy PDF reports</li>
            </ul>
          </Col>
        </Row>
      </Main>
    </div>
    <Footer />
  </Page>
);

SignInPage.defaultProps = {
  shouldShowSignIn: true,
};

export default SignInPage;
