// @flow
import type { Element, Node } from 'react';

import React from 'react';
import { Button } from 'reactstrap';

type Props = {|
  children?: Node,
  onClick: Function,
|};

const NextButton = ({ onClick, children }: Props): Element<typeof Button> => (
  <Button color="primary" id="next" className="float-right" onClick={onClick}>
    {children}
  </Button>
);

NextButton.defaultProps = {
  children: 'Next',
};

export default NextButton;
