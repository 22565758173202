// @flow
import type { Element } from 'react';

import React from 'react';
import { Form } from 'reactstrap';
import {
  CONSEQUENCE_SAFETY_CLASS,
  CONSEQUENCE_AFFECTS_ENVELOPE_SAFETY,
  CONSEQUENCE_POSES_EXTERIOR_FALLING_HAZARD,
  CONSEQUENCE_POSES_INTERIOR_FALLING_HAZARD,
  CONSEQUENCE_HAZARDOUS_MATERIAL_SCOPE,
  CONSEQUENCE_AFFECTS_ACCESS,
  CONSEQUENCE_DAMAGES_ENVELOPE_SEAL,
  CONSEQUENCE_IMPAIRS_SYSTEM_OPERATION,
  CONSEQUENCE_OBSTRUCTS_INTERIOR_SPACE,
  CONSEQUENCE_WEAKENS_FIRE_BREAK,
  CONSEQUENCE_CAUSES_FLOODING,
  CONSEQUENCE_CREW_SIZE,
  CONSEQUENCE_PERMIT_TYPE,
  CONSEQUENCE_REQUIRES_REDESIGN,
  CONSEQUENCE_ATC_138_LONG_LEAD_TIME,
  CONSEQUENCE_REQUIRES_SHORING,
  CONSEQUENCE_RESOLVED_BY_SCAFFOLDING,
  CONSEQUENCE_TEMPORARY_REPAIR_CLASS,
  CONSEQUENCE_TEMPORARY_REPAIR_TIME_LOWER,
  CONSEQUENCE_TEMPORARY_REPAIR_TIME_UPPER,
  CONSEQUENCE_TEMPORARY_REPAIR_CREW_SIZE,
  CONSEQUENCE_REDI_REPAIR_CLASS,
  DAMAGE_STATES,
  CONSEQUENCE_INTERIOR_AREA_FACTOR,
  CONSEQUENCE_INTERIOR_AREA_CONVERSION_TYPE,
  CONSEQUENCE_EXTERIOR_SURFACE_AREA_FACTOR,
  CONSEQUENCE_EXTERIOR_FALLING_LENGTH_FACTOR,
  CONSEQUENCE_AFFECTS_ROOF_FUNCTION,
} from '@hbrisk/sp3-risk-model-support/components/translated/attributes/names/index.js';
import type { SectionComponentProps } from '#components/pages/Components/Component/sections/support/types/componentPagerSection';
import BackButton
  from '#components/pages/Components/Component/sections/support/buttons/BackButton.jsx';
import NextButton
  from '#components/pages/Components/Component/sections/support/buttons/NextButton.jsx';
import { fieldsByName } from '#constants/components/form/fields/index.js';
import {
  Field,
  Fieldset,
} from '#components/pages/Components/support/form/index.js';
import render from '#components/pages/Components/Component/sections/Consequences/support/renderConsequencePropertyRowTable/index.jsx';

const renderRedTagTable = render([
  CONSEQUENCE_SAFETY_CLASS,
]);
const renderEnvelopeTable = render([
  CONSEQUENCE_AFFECTS_ROOF_FUNCTION,
  CONSEQUENCE_AFFECTS_ENVELOPE_SAFETY,
  CONSEQUENCE_DAMAGES_ENVELOPE_SEAL,
  CONSEQUENCE_EXTERIOR_SURFACE_AREA_FACTOR,
]);
const renderExteriorFallingHazardTable = render([
  CONSEQUENCE_POSES_EXTERIOR_FALLING_HAZARD,
  CONSEQUENCE_EXTERIOR_FALLING_LENGTH_FACTOR,
]);

const renderInteriorSpaceTable = render([
  CONSEQUENCE_POSES_INTERIOR_FALLING_HAZARD,
  CONSEQUENCE_OBSTRUCTS_INTERIOR_SPACE,
  CONSEQUENCE_INTERIOR_AREA_CONVERSION_TYPE,
  CONSEQUENCE_INTERIOR_AREA_FACTOR,
]);

const renderRepairScheduleTable = render([
  CONSEQUENCE_CREW_SIZE,
  CONSEQUENCE_PERMIT_TYPE,
  CONSEQUENCE_REQUIRES_REDESIGN,
  CONSEQUENCE_ATC_138_LONG_LEAD_TIME,
  CONSEQUENCE_REQUIRES_SHORING,
  CONSEQUENCE_RESOLVED_BY_SCAFFOLDING,
  CONSEQUENCE_TEMPORARY_REPAIR_CLASS,
  CONSEQUENCE_TEMPORARY_REPAIR_TIME_LOWER,
  CONSEQUENCE_TEMPORARY_REPAIR_TIME_UPPER,
  CONSEQUENCE_TEMPORARY_REPAIR_CREW_SIZE,
]);

const renderMiscellaneousTable = render([

  CONSEQUENCE_WEAKENS_FIRE_BREAK,
  CONSEQUENCE_IMPAIRS_SYSTEM_OPERATION,
  CONSEQUENCE_CAUSES_FLOODING,
  CONSEQUENCE_AFFECTS_ACCESS,
  CONSEQUENCE_HAZARDOUS_MATERIAL_SCOPE,
]);

const renderREDiTable = render([
  CONSEQUENCE_REDI_REPAIR_CLASS,
]);

const ConsequencesLifeSafety = ({
  onBack, onNext, readOnly,
}: SectionComponentProps): Element<'div'> => (
  <Form>
    <Fieldset>
      <legend>ATC-138</legend>
      <Fieldset>
        <legend>Red Tag</legend>
        <Field
          render={renderRedTagTable}
          field={fieldsByName[DAMAGE_STATES]}
          readOnly={readOnly}
          excludeLabel
          excludeInputGroup
        />
      </Fieldset>
      <Fieldset>
        <legend>Envelope</legend>
        <Field
          render={renderEnvelopeTable}
          field={fieldsByName[DAMAGE_STATES]}
          readOnly={readOnly}
          excludeLabel
          excludeInputGroup
        />
      </Fieldset>
      <Fieldset>
        <legend>Exterior Falling Hazard</legend>
        <Field
          render={renderExteriorFallingHazardTable}
          field={fieldsByName[DAMAGE_STATES]}
          readOnly={readOnly}
          excludeLabel
          excludeInputGroup
        />
      </Fieldset>
      <Fieldset>
        <legend>Interior Space</legend>
        <Field
          render={renderInteriorSpaceTable}
          field={fieldsByName[DAMAGE_STATES]}
          readOnly={readOnly}
          excludeLabel
          excludeInputGroup
        />
      </Fieldset>
      <Fieldset>
        <legend>Repair Schedule</legend>
        <Field
          render={renderRepairScheduleTable}
          field={fieldsByName[DAMAGE_STATES]}
          readOnly={readOnly}
          excludeLabel
          excludeInputGroup
        />
      </Fieldset>
      <Fieldset>
        <legend>Miscellaneous</legend>
        <Field
          render={renderMiscellaneousTable}
          field={fieldsByName[DAMAGE_STATES]}
          readOnly={readOnly}
          excludeLabel
          excludeInputGroup
        />
      </Fieldset>
    </Fieldset>
    <Fieldset>
      <legend>REDi</legend>
      <Fieldset>
        <legend>REDi</legend>
        <Field
          render={renderREDiTable}
          field={fieldsByName[DAMAGE_STATES]}
          readOnly={readOnly}
          excludeLabel
          excludeInputGroup
        />
      </Fieldset>
    </Fieldset>
    <BackButton onClick={onBack} />
    <NextButton onClick={onNext} />
  </Form>
);

export default ConsequencesLifeSafety;
