/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { fetchUsers } from '#actions/users/users.js';
import ViewUsers from './ViewUsers.jsx';

const mapDispatchToProps = (dispatch) => ({
  poll: () => {
    const POLLING_INTERVAL = 5000;
    dispatch(fetchUsers);
    const pollingId = setInterval(() => {
      dispatch(fetchUsers);
    }, POLLING_INTERVAL);

    return () => {
      clearInterval(pollingId);
    };
  },
});

const ViewUsersContainer = connect(undefined, mapDispatchToProps)(ViewUsers);

export default ViewUsersContainer;
