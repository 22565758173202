/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { Auth } from 'aws-amplify';
import { SIGN_OUT_USER } from '#constants/actionTypes.js';

const signOut = () => (next) => (action) => {
  const { type } = action;
  if (type === SIGN_OUT_USER) {
    Auth.signOut();
    localStorage.clear();
  }
  return next(action);
};

export default signOut;
