/**
 * Owner: Haselton Baker Risk Group, LLC
 * Developer: Mike McGlone <mike@hbrisk.com>
 * Date: 4/21/17
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, Navbar, NavItem } from 'reactstrap';
import SP3Logo from '#components/support/logos/SP3Logo/index.jsx';
import {
  ADMIN, COMPONENTS,
  MODELS,
  SIGN_IN,
} from '#constants/pathStrings.js';
import SignOutLink from './SignOutLink/index.js';
import {
  header,
  navItem,
  nav as headerNav,
  productAttribution,
  productName,
} from './Header.module.scss';

type Props = {|
  hasType2ModelPermissions?: boolean,
  showLinks?: boolean,
  inApp?: boolean,
  isAdmin?: boolean,
|};

const NavDivider = () => <NavItem className={navItem}>|</NavItem>;

const Header = ({
  inApp,
  isAdmin,
  showLinks,
  hasType2ModelPermissions,
}: Props): Element<typeof Navbar> => {
  const logoLinksTo = inApp ? `/${MODELS}` : `/${SIGN_IN}`;
  const adminLink = isAdmin
    ? (
      <>
        <NavItem className={navItem}>
          <Link to={`/${ADMIN}`}>Admin</Link>
        </NavItem>
        <NavDivider />
      </>
    ) : null;

  const componentDatabaseLink = hasType2ModelPermissions
    ? (
      <>
        <NavItem className={navItem}>
          <Link to={`/${COMPONENTS}`}>Components</Link>
        </NavItem>
        <NavDivider />
      </>
    ) : null;

  const nav = inApp
    ? (
      <Nav className={headerNav}>
        {adminLink}
        <NavItem className={navItem}>
          <Link to={`/${MODELS}`}>Models</Link>
        </NavItem>
        <NavDivider />
        {componentDatabaseLink}
        <NavItem className={navItem}>
          <a href="https://support.sp3risk.com/space/KnowledgeBase/1777632906/SP3-RiskModel" rel="noreferrer" target="_blank">Help</a>
        </NavItem>
        <NavDivider />
        <NavItem className={navItem}>
          <SignOutLink />
        </NavItem>
      </Nav>
    ) : (
      <Nav className={headerNav}>
        <NavItem className={navItem}>
          <Link to={`/${SIGN_IN}`}>Sign In</Link>
        </NavItem>
      </Nav>
    );

  const logo = showLinks
    ? <Link to={logoLinksTo}><SP3Logo /></Link>
    : <SP3Logo />;
  return (
    <Navbar className={header} fixed="top">
      {logo}
      <span className={productName}> Risk Model </span>
      <span className={productAttribution}> by Haselton Baker Risk Group</span>
      {showLinks ? nav : null}
    </Navbar>
  );
};

Header.defaultProps = {
  hasType2ModelPermissions: false,
  showLinks: true,
  inApp: true,
  isAdmin: false,
};

export default Header;
