import * as names from '@hbrisk/sp3-risk-model-support/components/translated/attributes/names/index.js';
import * as values from '@hbrisk/sp3-risk-model-support/components/translated/attributes/values/index.js';
import consequences from '#constants/components/form/fields/fieldArrays/damageStates/consequences/index.js';
import * as sections from '#constants/components/componentPage/sections/index.js';
import * as options from '#constants/components/form/fields/options/index.js';

import addFieldDefaults
  from '#support/components/form/addFieldDefaults.js';

export * from '#constants/components/form/fields/fieldArrays/damageStates/consequences/index.js';

const itemSchemaWithoutDefaults = [
  {
    name: names.DAMAGE_STATE_TYPE,
    section: sections.DAMAGE_STATES,
    defaultValue: values.DAMAGE_STATE_TYPE_VALUES.SEQUENTIAL,
    type: 'select',
    options: options.DAMAGE_STATE_TYPE_OPTIONS,
  },
  {
    name: names.DAMAGE_STATE_MEDIAN,
    section: sections.DAMAGE_STATES,
    type: 'text',
    reducers: {
      visible: () => (state) => {
        const {
          [names.IS_CALCULATED]: isCalculated,
          [names.CALCULATION_TYPE]: calculationType,
        } = state.values;
        return (
          isCalculated !== values.YES_NO_VALUES.YES
          || values.damageStateMedianSupportingCalcTypes.includes(calculationType)
        );
      },
    },
  },
  {
    name: names.DAMAGE_STATE_MIN_CALCULATED_MEDIAN,
    label: 'Minimum Calculated Median',
    section: sections.DAMAGE_STATES,
    type: 'text',
    reducers: {
      visible: () => (state) => {
        const {
          [names.IS_CALCULATED]: isCalculated,
          [names.CALCULATION_TYPE]: calculationType,
        } = state.values;
        return (
          isCalculated === values.YES_NO_VALUES.YES
          && values.damageStateMinCalMedianSupportingCalcTypes.includes(calculationType)
        );
      },
    },
  },
  {
    name: names.DAMAGE_STATE_BETA,
    section: sections.DAMAGE_STATES,
    type: 'text',
  },
  consequences,
];

const itemSchema = addFieldDefaults(itemSchemaWithoutDefaults);

const damageStates = {
  name: names.DAMAGE_STATES,
  section: sections.DAMAGE_STATES,
  type: 'FieldArray',
  allowItemLevelReducers: true,
  itemSchema,
  initializeFrom: names.DAMAGE_STATES,
};

export default damageStates;
