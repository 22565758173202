/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import classNames from 'classnames';
import React, { Component } from 'react';
import get from 'lodash/fp/get.js';
import ReactTable from 'react-table';
import authorColumn from '#components/pages/Models/Dashboard/ModelsTable/columns/authorColumn.jsx';
import modelColumn from '#components/pages/Models/Dashboard/ModelsTable/columns/modelColumn.jsx';
import projectColumn from '#components/pages/Models/Dashboard/ModelsTable/columns/projectColumn.jsx';
import levelColumn from '#components/pages/Models/Dashboard/ModelsTable/columns/levelColumn.jsx';
import selColumn from '#components/pages/Models/Dashboard/ModelsTable/columns/selColumn.jsx';
import sulColumn from '#components/pages/Models/Dashboard/ModelsTable/columns/sulColumn.jsx';
import createdDateColumn from '#components/pages/Models/Dashboard/ModelsTable/columns/createdDateColumn.jsx';
import updatedDateColumn from '#components/pages/Models/Dashboard/ModelsTable/columns/updatedDateColumn.jsx';
import statusColumn from '#components/pages/Models/Dashboard/ModelsTable/columns/statusColumn/index.jsx';
import actionColumn from '#components/pages/Models/Dashboard/ModelsTable/columns/actionColumn/index.jsx';
import {
  highlight,
  fixedHeader,
  modelsTable,
  modelsTableTd,
  modelsTableTh,
  td,
  th,
} from './ModelsTable.module.scss';

type Model = {
  name: string,
  updatedAt: string,
  createdAt: string,
  currentRun: {
    sel: number,
    status: string,
    updatedAt: string,
    site: {
      name: string,
    }
  },
  createdBy: {
    firstName: 'string',
    lastName: 'string'
  }
}

const columnsWithDefaults = (withoutDefaults: any) => withoutDefaults.map((column) => (
  {
    ...{
      className: classNames(
        td,
        modelsTableTd,
        ...(column.additionalClasses ? column.additionalClasses : [])
      ),
      headerClassName: classNames('font-weight-bold', th, modelsTableTh, `${column.id}Th`),
    },
    ...column,
  }
));

const myColumns = columnsWithDefaults([
  modelColumn,
  projectColumn,
  levelColumn,
  selColumn,
  sulColumn,
  authorColumn,
  createdDateColumn,
  updatedDateColumn,
  statusColumn,
  actionColumn,
]);

type SortedProps = [{ id: string, desc: boolean }];

type Props = {|
  handleMount: () => IntervalID,
  handleSortedChange: SortedProps => any,
  data: Model[],
  sorted: SortedProps,
|};

class ModelsTable extends Component <Props> {
  intervalId: null | IntervalID = null;

  columns: any = myColumns;

  constructor() {
    super();
    this.intervalId = null;
  }

  componentDidMount(): void {
    const { handleMount } = this.props;
    this.intervalId = handleMount();
  }

  componentWillUnmount(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  render(): Element<typeof ReactTable> {
    const { data, handleSortedChange, sorted } = this.props;
    return (
      <ReactTable
        className={classNames(['models-table', modelsTable, '-striped', 'rounded'])}
        columns={this.columns}
        data={data}
        minRows={20}
        showPagination={false}
        defaultPageSize={100000}
        defaultSorted={sorted}
        onSortedChange={handleSortedChange}
        resizable={false}
        style={{ height: '700px' }}
        getTheadProps={() => ({ className: fixedHeader })}
        getTdProps={(state, rowInfo, column) => ({ className: column.id })}
        getTrProps={(_, rowInfo) => {
          const id = `row-${get('original.uuid', rowInfo)}`;
          const togglingArchive = get('original.togglingArchive', rowInfo);
          const recentlyCreated = get('original.recentlyCreated', rowInfo);
          const withTogglingArchive = togglingArchive ? [highlight] : [];
          const withRecentlyCreated = recentlyCreated ? [highlight] : [];
          const trClasses = [...withTogglingArchive, ...withRecentlyCreated];
          return {
            id,
            className: classNames(trClasses),
          };
        }}
      />
    );
  }
}

export default ModelsTable;
