// @flow
import type { Element, Node } from 'react';

import classNames from 'classnames';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { subNavRow, subNavRowSticky } from './index.module.scss';

type Props = {
  children: Node,
  className?: string,
  height?: string,
};
const SubNav = ({ children, className, height }: Props): Element<typeof Row> => (
  <Row className={subNavRow} style={{ height }}>
    <Col className={classNames(subNavRowSticky, className)} style={{ height }}>
      {children}
    </Col>
  </Row>
);

SubNav.defaultProps = {
  className: null,
  height: '50px',
};

export default SubNav;
