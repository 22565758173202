// @flow
import type { Element, Node } from 'react';

import React from 'react';
import { readOnlyView } from './index.module.scss';

type Props = {
  children: Node,
};

const ReadOnlyView = ({ children }: Props): Element<'div'> => (
  <div className={readOnlyView}>
    {children}
  </div>
);

export default ReadOnlyView;
