import isEqual from 'lodash/fp/isEqual.js';
import updateFormInputs from './updateFormInputs/index.js';

const ITERATION_MAX = 10;

const resolveFormInputs = (fields, formState = {}, action = null) => {
  let input = {
    autofilled: formState.autofilled || {},
    values: formState.values || {},
    visibleFields: formState.visibleFields || {},
  };

  let nextFormInputs;

  for (let i = 0; i <= ITERATION_MAX; i += 1) {
    nextFormInputs = updateFormInputs(fields, input, action);

    if (isEqual(input, nextFormInputs)) {
      break;
    }

    if (i === ITERATION_MAX) {
      throw new Error(`Could not resolve formInputs within limit of ${ITERATION_MAX} iterations`);
    }

    input = nextFormInputs;

    i += 1;
  }
  return nextFormInputs;
};

export default resolveFormInputs;
