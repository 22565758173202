/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import merge from 'lodash/fp/merge.js';
import { normalize, schema } from 'normalizr';
import {
  CREATE_MODEL_REQUEST,
  CREATE_MODEL_RESPONSE,
  CREATE_RUN_REQUEST,
  CREATE_RUN_RESPONSE,
  FETCH_ACTION,
  FETCH_MODELS_REQUEST,
  FETCH_MODELS_RESPONSE,
  FETCH_MODEL_REQUEST,
  FETCH_MODEL_RESPONSE,
  START_POLLING_MODEL,
  STOP_POLLING_MODEL,
  SET_CURRENT_MODEL,
  UPDATE_MODEL_REQUEST,
  UPDATE_MODEL_RESPONSE,
  COPY_MODEL_START,
  COPY_MODEL_END,
  TOGGLE_MODEL_ARCHIVE_START,
  TOGGLE_MODEL_ARCHIVE_END,
} from '#constants/actionTypes.js';
import calculateCopyModelPostPayload from '#support/models/calculateCopyModelPostPayload/index.js';
import { selectAuthUserPermissions } from '#selectors/entities/users.js';

const model = new schema.Entity('models', undefined, {
  idAttribute: 'uuid',
});

export const startPollingModel = (modelId) => ({
  type: START_POLLING_MODEL,
  payload: {
    modelId,
    pollingId: null, // will be updated by middleware
  },
});

export const stopPollingModel = (modelId) => ({
  type: STOP_POLLING_MODEL,
  payload: { modelId },
});

export const postModel = (body) => ({
  [FETCH_ACTION]: {
    types: [CREATE_MODEL_REQUEST, CREATE_MODEL_RESPONSE],
    endpoint: '/model',
    options: {
      method: 'POST',
      body,
    },
  },
});

export const putModel = (modelId, body) => ({
  [FETCH_ACTION]: {
    types: [UPDATE_MODEL_REQUEST, UPDATE_MODEL_RESPONSE],
    endpoint: `/model/${modelId}`,
    options: {
      method: 'PUT',
      body,
    },
    meta: {
      modelId,
    },
  },
});

const modelResponseTransform = (entity) => {
  const { age, ...rest } = entity;
  return {
    recentlyCreated: age <= 5000,
    ...rest,
  };
};
export const fetchModel = (modelId) => ({
  [FETCH_ACTION]: {
    types: [FETCH_MODEL_REQUEST, FETCH_MODEL_RESPONSE],
    endpoint: `/model/${modelId}`,
    dataTransform: modelResponseTransform,
    meta: {
      modelId,
    },
  },
});

export const fetchModels = (orgId) => ({
  [FETCH_ACTION]: {
    types: [FETCH_MODELS_REQUEST, FETCH_MODELS_RESPONSE],
    endpoint: `/org/${orgId}/model`,
    dataTransform: (json) => {
      const defaultEntities = { models: {} };
      const { result, entities } = normalize(json.map(modelResponseTransform), [model]);
      return {
        result,
        entities: merge(defaultEntities, entities),
      };
    },
  },
});

export const setCurrentModel = (modelId) => ({
  type: SET_CURRENT_MODEL,
  payload: modelId,
});

export const postRun = (modelId) => ({
  [FETCH_ACTION]: {
    types: [CREATE_RUN_REQUEST, CREATE_RUN_RESPONSE],
    endpoint: `/model/${modelId}/run`,
    options: {
      method: 'POST',
      body: {},
    },
    meta: {
      modelId,
    },
  },
});

const copyModelStart = (modelId) => ({
  type: COPY_MODEL_START,
  meta: { modelId },
});

const copyModelEnd = (modelId) => ({
  type: COPY_MODEL_END,
  meta: { modelId },
});

export const copyModel = (userId, orgId, modelId) => (dispatch, getState) => {
  const state = getState();
  const permissions = selectAuthUserPermissions(state);
  dispatch(copyModelStart(modelId));
  dispatch(fetchModel(modelId))
    .then(
      ({ payload }) => calculateCopyModelPostPayload(
        payload,
        userId,
        orgId,
        permissions
      )
    ).then((body) => dispatch(postModel(body)))
    .then(({ payload }) => {
      const { uuid: newModelId } = payload;
      return dispatch(fetchModel(newModelId));
    })
    .then(() => {
      dispatch(copyModelEnd(modelId));
    });
};

const toggleModelArchiveStart = (modelId) => ({
  type: TOGGLE_MODEL_ARCHIVE_START,
  meta: { modelId },
});

const toggleModelArchiveEnd = (modelId) => ({
  type: TOGGLE_MODEL_ARCHIVE_END,
  meta: { modelId },
});

export const toggleModelArchive = (modelId, archived) => (dispatch) => {
  dispatch(toggleModelArchiveStart(modelId));
  dispatch(putModel(modelId, { archived }))
    .then(() => dispatch(fetchModel(modelId)))
    .then(() => {
      dispatch(toggleModelArchiveEnd(modelId));
    });
};
