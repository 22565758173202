import getOr from 'lodash/fp/getOr.js';
import { mapObjIndexed } from 'ramda';

const calculateModelPutPayload = (autofilledValues, visibilityValues, values) => {
  const formInputs = mapObjIndexed((val, key) => {
    const autofilled = getOr(false, key, autofilledValues);
    const value = autofilled ? null : getOr(null, key, values);

    return ({
      value,
      visible: val,
      autofilled,
    });
  }, visibilityValues);

  return formInputs;
};

export default calculateModelPutPayload;
