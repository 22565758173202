import React from 'react';
import {
  Button,
  Table,
} from 'reactstrap';
import {
  DAMAGE_STATE_CONSEQUENCES,
  CONSEQUENCE_PROBABILITY,
  CONSEQUENCE_DESCRIPTION,
  CONSEQUENCE_REPAIR_MEASURES,
} from '@hbrisk/sp3-risk-model-support/components/translated/attributes/names/index.js';
import {
  Fieldset,
  TableField,
  Label,
} from '#components/pages/Components/support/form/index.js';
import { fieldArraysByName } from '#constants/components/form/fields/index.js';
import getUnqualifiedFieldName
  from '#support/components/form/getUnqualifiedFieldName.js';
import consequenceNameFromIndexes from '#support/components/consequenceNameFromIndexes.js';
import damageStateNameFromIndex from '#support/components/damageStateNameFromIndex.js';
import { consequencesTd } from '#components/pages/Components/Component/sections/Consequences/Main/renderMainConsequencesTable/index.module.scss';

const fieldsToShow = [
  CONSEQUENCE_PROBABILITY,
  CONSEQUENCE_DESCRIPTION,
  CONSEQUENCE_REPAIR_MEASURES,
];

const INDEX_PATTERN = /(\d+)/g;

const renderTable = ({ fields, readOnly, item }) => {
  const damageState = item;
  const { itemSchema } = fieldArraysByName[DAMAGE_STATE_CONSEQUENCES];
  const filteredSchema = itemSchema
    .filter((fieldSchema) => fieldsToShow.includes(fieldSchema.name));

  const rows = fields.map((field, consequenceIndex) => {
    const indexes = field.match(INDEX_PATTERN);
    const damageStateIndex = parseInt(indexes[0], 10);
    const hasSiblings = fields.length > 1;
    return (
      <tr key={field}>
        <td className={consequencesTd}>
          {consequenceNameFromIndexes(damageStateIndex, consequenceIndex, hasSiblings)}
        </td>
        {
          filteredSchema.map((fieldSchema) => (
            <td className={consequencesTd} key={getUnqualifiedFieldName(field, fieldSchema.name)}>
              <TableField
                item={field}
                field={fieldSchema}
                readOnly={readOnly}
              />
            </td>
          ))
        }
        {
          !readOnly
          && (
            <td className={consequencesTd}>
              <Button
                color="link"
                onClick={() => fields.remove(consequenceIndex)}
              >
                Delete
              </Button>
            </td>
          )
        }
      </tr>
    );
  });
  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th>Consequence</th>
            {
              filteredSchema.map((fieldSchema) => (
                <th key={fieldSchema.name}>
                  <Label field={fieldSchema} tipTargetId={`${damageState.replace(/[.[\]]/g, '')}${fieldSchema.name}TipTarget`} component="span" />
                </th>
              ))
            }
            {
              !readOnly
              && (<th>Action</th>)
            }
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
      {
        !readOnly
        && (
          <Button
            size="sm"
            id="addRowButton"
            className="float-right"
            color="primary"
            onClick={() => fields.push({})}
          >
            Add Consequence
          </Button>
        )
      }
    </>
  );
};

const renderMainConsequencesTables = ({ fields, readOnly }) => fields.map((item, index) => (
  <Fieldset key={item}>
    <legend>{damageStateNameFromIndex(index)}</legend>
    <TableField
      item={item}
      field={fieldArraysByName[DAMAGE_STATE_CONSEQUENCES]}
      render={renderTable}
      readOnly={readOnly}
    />
  </Fieldset>
));

export default renderMainConsequencesTables;
