/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { CREATE, ORG_ID } from '#constants/pathStrings.js';
import CreateOrganization from './CreateOrganization/index.jsx';
import EditOrganization from './EditOrganization/index.js';
import ViewOrganizations from './ViewOrganizations/index.js';

const OrganizationsSection = (): Element<typeof Routes> => (
  <Routes>
    <Route index element={<ViewOrganizations />} />
    <Route path={`${CREATE}`} element={<CreateOrganization />} />
    <Route path={`:${ORG_ID}`} element={<EditOrganization />} />
    <Route path="*" element={<Navigate to="." />} />
  </Routes>
);

export default OrganizationsSection;
