import get from 'lodash/fp/get.js';
import keyBy from 'lodash/fp/keyBy.js';
import set from 'lodash/fp/set.js';
import union from 'lodash/fp/union.js';
import uniq from 'lodash/fp/uniq.js';
import { edpKeys as allEdpKeys } from '@hbrisk/sp3-risk-model-support/edps/edps.js';
import { REDUX_FORM_CHANGE } from '#constants/actionTypes.js';
import structuralResponsesDefaultValueArray
  from '#constants/models/form/model/support/structuralResponsesDefaultValueArray.js';
import { buildingTypesById } from '#constants/buildingTypes/buildingTypes.js';

const defaultStructuralResponseValuesByEdpKey = keyBy('edpKey', structuralResponsesDefaultValueArray);

const adjustEDPsOnBuildingTypeChange = (prev) => {
  const { buildingTypeDir1, buildingTypeDir2, structuralResponses } = prev.values;
  const edpKeysDir1 = get(`[${buildingTypeDir1}].edps`, buildingTypesById);
  const edpKeysDir2 = get(`[${buildingTypeDir2}].edps`, buildingTypesById);

  const edpKeys = edpKeysDir1 && edpKeysDir2
    ? uniq(union(edpKeysDir1, edpKeysDir2))
    : allEdpKeys;

  const nextStructuralResponses = edpKeys.reduce((acc, edpKey) => {
    const existing = Array.isArray(structuralResponses)
      ? structuralResponses.find((response) => response.edpKey === edpKey)
      : [];
    return existing
      ? [...acc, existing]
      : [...acc, defaultStructuralResponseValuesByEdpKey[edpKey]];
  }, []);
  return nextStructuralResponses;
};

const clearUploadFieldOnMethodChange = (prev, action) => {
  if (action) {
    const { meta, type } = action;
    if (type === REDUX_FORM_CHANGE) {
      const { field } = meta;
      const pattern = /^structuralResponses\[(\d+)].method$/;
      const match = field.match(pattern);
      if (match) {
        const { structuralResponses } = prev.values;
        const index = match[1];
        return set(`[${index}].upload`, null, structuralResponses);
      }
    }
  }
  return undefined;
};

export const structuralResponsesValueReducer = (prev, action) => {
  const { structuralResponses: prevStructuralResponses } = prev.values;
  const reducers = [adjustEDPsOnBuildingTypeChange, clearUploadFieldOnMethodChange];
  const name = 'structuralResponses';
  let nextStructuralResponses = reducers.reduce((acc, reducer) => {
    const nextState = acc === undefined
      ? prev
      : {
        ...prev,
        values: {
          ...prev.values || {},
          [name]: acc,
        },
      };
    const result = reducer(nextState, action);
    return result === undefined
      ? acc
      : result;
  }, undefined);

  nextStructuralResponses = nextStructuralResponses || prevStructuralResponses;

  return nextStructuralResponses;
};
