/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React, { useState, useEffect } from 'react';
import {
  Col,
  Form,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';
import { Field } from 'redux-form';
import RenderInput from '#components/support/forms/RenderInput/index.jsx';
import SubmitButton from '#components/support/buttons/SubmitButton/index.jsx';

type Props = {|
  areTermsChecked: boolean,
  error?: string,
  handleSubmit: Function,
  submitting: boolean,
|};

const TermsOfServiceForm = (props: Props): Element<typeof Form> => {
  const {
    areTermsChecked,
    error,
    handleSubmit,
    submitting,
  } = props;
  const [shouldSpin, setShouldSpin] = useState(false);

  useEffect(() => {
    if (submitting) {
      setShouldSpin(true);
    } else if (error) {
      setShouldSpin(false);
    }
  }, [submitting, error]);

  const alert = error ? <div className="alert alert-danger">{error}</div> : null;
  return (
    <Form className="terms-of-service" onSubmit={handleSubmit}>
      {alert}
      <Row className="align-items-center pb-2">
        <Col lg="5" />
        <Col lg="4">
          <FormGroup check>
            <Field
              id="areTermsChecked"
              name="areTermsChecked"
              type="checkbox"
              component={RenderInput}
            />
            <Label check for="areTermsChecked">
              I agree to the above terms
            </Label>
          </FormGroup>
        </Col>
        <Col lg="3">
          <SubmitButton
            disabled={!areTermsChecked}
            submitting={shouldSpin}
            submittingText="Submitting..."
          >
            Done
          </SubmitButton>
        </Col>
      </Row>
    </Form>
  );
};

TermsOfServiceForm.defaultProps = {
  error: '',
};

export default TermsOfServiceForm;
