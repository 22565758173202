// @flow

import type { Element } from 'react';

import React from 'react';
import { Table } from 'reactstrap';
import { DAMAGE_STATE_CONSEQUENCES } from '@hbrisk/sp3-risk-model-support/components/translated/attributes/names/index.js';
import type { TableProps } from '#components/pages/Components/support/tables/support/types/tableProps';
import { TableField } from '#src/js/components/pages/Components/support/form/index.js';
import { fieldArraysByName } from '#src/js/constants/components/form/fields/index.js';
import consequenceNameFromIndexes
  from '#support/components/consequenceNameFromIndexes.js';
import consequencesFieldArrayItemNameFromIndexes
  from '#support/components/form/consequencesFieldArrayItemNameFromIndexes.js';
import EntityModeTableCellContent
  from '#src/js/components/pages/Components/support/tables/support/EntityModeTableCellContent/EntityModeTableCellContent.jsx';

/* eslint-disable react/destructuring-assignment */

// In this component we purposefully avoid destructuring props so that flow can properly
// handle TableProps as union type
const ConsequenceRowTable = (props: TableProps): Element<typeof Table> => {
  const { itemSchema } = fieldArraysByName[DAMAGE_STATE_CONSEQUENCES];
  const filteredItemSchema = itemSchema
    .filter((fieldSchema) => (props.itemFields.includes(fieldSchema.name)));

  const dss = props.entityMode
    ? props.component.formInputs.values.damageStates
    : props.fields.getAll();

  const rows = dss.reduce((acc, damageState, i) => [
    ...acc,
    ...damageState.consequences.map((consequence, j) => {
      const item = consequencesFieldArrayItemNameFromIndexes(i, j);
      const hasSiblings = damageState.consequences.length > 1;
      return (
        <tr key={item}>
          <td>{consequenceNameFromIndexes(i, j, hasSiblings)}</td>
          {
            filteredItemSchema.map((fieldSchema) => (
              <td key={fieldSchema.name}>
                {
                  props.entityMode
                    ? (
                      <EntityModeTableCellContent
                        component={props.component}
                        item={item}
                        field={fieldSchema}
                      />
                    ) : (
                      <TableField
                        item={item}
                        field={fieldSchema}
                        readOnly={props.readOnly}
                      />
                    )
                }
              </td>
            ))
          }
        </tr>
      );
    }),
  ], []);
  return (
    <Table className="consequences-table">
      <thead>
        <tr>
          <th>Consequence</th>
          {
            filteredItemSchema.map((fieldSchema) => (
              <th key={fieldSchema.name}>
                {fieldSchema.label}
              </th>
            ))
          }
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </Table>
  );
};

export default ConsequenceRowTable;
