/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import get from 'lodash/fp/get.js';
import pipe from 'lodash/fp/pipe.js';
import { connect } from 'react-redux';
import { COMPONENT_UUID } from '#constants/pathStrings.js';
import { selectComponentTree } from '#selectors/entities/components.js';
import withRouteParams from '#components/support/routing/withRouteParams/index.jsx';
import ComponentSelector
  from '#components/pages/Components/support/ComponentSelector/index.jsx';

const mapStateToProps = (state, ownProps) => {
  const { routeParams } = ownProps;
  const componentUuid = get(COMPONENT_UUID, routeParams);
  return {
    getComponentTree: selectComponentTree(state),
    componentUuid,
  };
};

export default pipe(
  connect(mapStateToProps),
  withRouteParams,
)(ComponentSelector);
