/**
 * Owner: Haselton Baker Risk Group, LLC
 * Developer: Mike McGlone <mike@hbrisk.com>
 * Date: 5/30/19
 * Copyright All Rights Reserved
 */

import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import promiseMiddleware from 'redux-promise';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction.js';
import throttle from 'lodash/throttle.js';
import fetchMiddleware from '#middlewares/fetch.js';
import modelFormMiddleware from '#middlewares/modelForm.js';
import modelPollerMiddleWare from '#middlewares/modelPoll.js';
import signOut from '#middlewares/signOut.js';
import { loadState, saveState } from '#support/app/localStorageHelpers.js';
import rootReducer from '#reducers/index.js';

let middleware = [
  signOut,
  modelPollerMiddleWare,
  fetchMiddleware,
  modelFormMiddleware,
  thunkMiddleware,
  typeof promiseMiddleware === 'function'
    ? promiseMiddleware
    : promiseMiddleware.default,
];
if (process.env.NODE_ENV !== 'production') {
  middleware = [...middleware, createLogger()];
}

/**
 * Read persisted data from local storage
 */
const persistedState = loadState();

function configureStore() {
  return createStore(
    rootReducer,
    persistedState,
    composeWithDevTools(applyMiddleware(...middleware))
  );
}

const store = configureStore();

/**
 * Listen for changes to the Redux store and, when throttle allows, save slices of the store
 * to local storage
 */
store.subscribe(throttle(() => {
  const state = store.getState();
  const {
    entities: { users },
    ui,
  } = state;
  const { authenticated, byId } = users;
  const authUserId = authenticated.uuid;
  const authUser = byId[authUserId];
  const storedById = authUser ? { [authUserId]: authUser } : {};
  const { modelsTable, app } = ui;
  const maintenance = app && app.maintenance;

  saveState({
    entities: {
      users: {
        authenticated,
        byId: storedById,
      },
    },
    ui: {
      modelsTable,
      app: {
        maintenance,
      },
    },
  });
}, 1000));

// Note: The store is added to window for functional test debugging only. This is not intended
// for access from application source code
window.store = store;

export default store;
