/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { selectEdpKeys } from '#selectors/ui/form/model.js';

import StructuralResponseViewWithPicker from './StructuralResponseViewWithPicker.jsx';

const mapStateToProps = (state, ownProps) => {
  const { form } = ownProps;
  const edpList = selectEdpKeys(form)(state);
  return ({
    edpList,
  });
};
export default connect(mapStateToProps)(StructuralResponseViewWithPicker);
