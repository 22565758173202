// @flow
import type { Element, Node } from 'react';

import React from 'react';

type Props = {
  children?: Node,
  tag: 'td' | 'th',
  visible: boolean,
}
const DamageStateTableCell = ({ tag, children, visible }: Props): Element<'td' | 'th'> | null => {
  if (!visible) {
    return null;
  }
  return React.createElement(tag, null, children);
};

DamageStateTableCell.defaultProps = {
  children: null,
};

export default DamageStateTableCell;
