import pipe from 'lodash/fp/pipe.js';
import { connect } from 'react-redux';
import getComponent from '#components/support/polling/withComponentsPolling/withComponentsPolling.jsx';
import { fetchComponents } from '#actions/components/components.js';
import { selectAuthUserOrgId } from '#selectors/entities/users.js';

const getContainer = (C) => {
  const mapStateToProps = (state) => {
    const orgId = selectAuthUserOrgId(state);
    return ({
      orgId,
    });
  };

  const mapDispatchToProps = (dispatch) => ({
    makePoll: (orgId) => () => {
      const POLLING_INTERVAL = 5000;
      dispatch(fetchComponents(orgId));
      const pollingId = setInterval(() => {
        dispatch(fetchComponents(orgId));
      }, POLLING_INTERVAL);

      return () => {
        clearInterval(pollingId);
      };
    },
  });

  const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { orgId } = stateProps;
    const { makePoll } = dispatchProps;
    return {
      ...stateProps,
      ...ownProps,
      pollComponents: makePoll(orgId),
    };
  };
  return connect(mapStateToProps, mapDispatchToProps, mergeProps)(C);
};

export default pipe(
  getComponent,
  getContainer,
);
