/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import classNames from 'classnames';
import React from 'react';
import { sp3Logo } from './index.module.scss';

type Props = {|
  className?: string,
|};

const SP3Logo = ({ className }: Props): Element<'span'> => (
  <span className={classNames(className, sp3Logo)} />
);

SP3Logo.defaultProps = {
  className: '',
};

export default SP3Logo;
