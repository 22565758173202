import get from 'lodash/fp/get.js';

const defaultDefaultValue = true;
const defaultDefaultValueForArrayFieldsThatAllowItemLevelReducers = [];

// Given a field, get its updated visibility. In the case of a field array that supports
// item-level reducers, include the visibility of every item field falling under that
// field array. In the case of a field array that does not support item-level reducers,
// return a boolean value for its visibility, just like a normal field.
const getUpdatedFieldVisibility = (field, formState, action, itemPath = null) => {
  const { name } = field;
  let nextVisibility;
  const unqualifiedName = itemPath
    ? `${itemPath}.${name}`
    : name;

  const reducer = get('reducers.visible', field);

  if (reducer) {
    nextVisibility = itemPath
      ? reducer(get(`values.${itemPath}`, formState), itemPath)(formState, action)
      : reducer(formState, action);
  }

  if (field.type !== 'FieldArray' && nextVisibility !== undefined) {
    return nextVisibility;
  }

  if (field.type === 'FieldArray' && nextVisibility === false) {
    return false;
  }

  if (field.type === 'FieldArray' && field.allowItemLevelReducers === true) {
    const items = get(`values.${unqualifiedName}`, formState);
    const { itemSchema } = field;
    if (Array.isArray(items)) {
      return items.reduce((a1, item, i) => {
        const itemResult = itemSchema.reduce((a2, itemFieldSchema) => ({
          ...a2,
          [itemFieldSchema.name]: getUpdatedFieldVisibility(itemFieldSchema, formState, action, `${unqualifiedName}[${i}]`),
        }), {});
        return [
          ...a1,
          itemResult,
        ];
      }, []);
    }
    return defaultDefaultValueForArrayFieldsThatAllowItemLevelReducers;
  }

  const currentVisibility = get(`visibleFields.${unqualifiedName}`, formState);
  if (currentVisibility === undefined) {
    const { defaultVisible } = field;
    return defaultVisible === undefined
      ? defaultDefaultValue
      : defaultVisible;
  }
  return currentVisibility;
};

const updateVisibility = (fields, formState, action) => {
  const visibleFields = fields.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]: getUpdatedFieldVisibility(field, formState, action),
    }),
    formState.visibleFields
  );
  return {
    ...formState,
    visibleFields,
  };
};

export default updateVisibility;
