/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { Input } from 'reactstrap';
import mapItemsToOptions from '#components/support/forms/SelectMenus/mapItemsToOptions/index.jsx';

type Props = {|
  id: string,
  name: string,
  onChange: Function,
  items: { id: string, name: string, value: string }[],
  value: string,
|};

const BasicSelectMenu = (props: Props): Element<typeof Input> => {
  const {
    id,
    name,
    onChange,
    items,
    value,
  } = props;
  return (
    <Input
      id={id}
      name={name}
      onChange={onChange}
      value={value}
      type="select"
    >
      {mapItemsToOptions(items)}
    </Input>
  );
};

export default BasicSelectMenu;
