// @flow
import type { Element } from 'react';
import React from 'react';
import { Button, Table } from 'reactstrap';
import { DAMAGE_STATES } from '@hbrisk/sp3-risk-model-support/components/translated/attributes/names/index.js';
import type { TableProps } from '#components/pages/Components/support/tables/support/types/tableProps.js';

import TableCell
  from '#components/pages/Components/support/tables/DamageStateRowTable/DamageStateTableCell/index.js';
import getUnqualifiedFiledName
  from '#support/components/form/getUnqualifiedFieldName.js';
import {
  Label,
  TableField,
} from '#src/js/components/pages/Components/support/form/index.js';
import { fieldsByName } from '#src/js/constants/components/form/fields/index.js';
import damageStateFieldArrayItemNameFromIndex
  from '#support/components/form/damageStateFieldArrayItemNameFromIndex.js';
import EntityModeTableCellContent
  from '#components/pages/Components/support/tables/support/EntityModeTableCellContent/EntityModeTableCellContent.jsx';
import damageStateNameFromIndex from '#support/components/damageStateNameFromIndex.js';
/* eslint-disable react/destructuring-assignment */

// In this component we purposefully avoid destructuring props so that flow can properly
// handle TableProps as union type
const DamageStateRowTable = (props: TableProps): Element<typeof Table> => {
  const { itemSchema } = fieldsByName[DAMAGE_STATES];

  const filteredSchema = itemSchema
    .filter((fieldSchema) => (props.itemFields.includes(fieldSchema.name)));

  const damgeStates = props.entityMode
    ? props.component.formInputs.values.damageStates
    : props.fields.getAll();

  const rows = damgeStates.map((damageState, index) => {
    const field = damageStateFieldArrayItemNameFromIndex(index);
    return (
      <tr key={field}>
        <td>
          {damageStateNameFromIndex(index)}
        </td>
        {
          filteredSchema.map((fieldSchema) => (
            props.entityMode
              ? (
                <TableCell
                  tag="td"
                  fieldSchema={fieldSchema}
                  key={getUnqualifiedFiledName(field, fieldSchema.name)}
                  component={props.component}
                  entityMode
                >
                  <EntityModeTableCellContent
                    item={field}
                    field={fieldSchema}
                    component={props.component}
                  />
                </TableCell>
              ) : (
                <TableCell
                  tag="td"
                  fieldSchema={fieldSchema}
                  key={getUnqualifiedFiledName(field, fieldSchema.name)}
                >
                  <TableField
                    item={field}
                    field={fieldSchema}
                    readOnly={props.readOnly}
                  />
                </TableCell>
              )
          ))
        }
        {
          (!props.entityMode && !props.readOnly)
          && (
            <td>
              <Button
                color="link"
                onClick={() => props.fields.remove(index)}
              >
                Delete
              </Button>
            </td>
          )
        }
      </tr>
    );
  });
  return (
    <Table className="damage-states-table" responsive>
      <thead>
        <tr>
          <th>Damage State</th>
          {
            filteredSchema.map((fieldSchema) => (
              props.entityMode
                ? (
                  <TableCell
                    tag="th"
                    fieldSchema={fieldSchema}
                    key={fieldSchema.name}
                    entityMode
                    component={props.component}
                  >
                    <Label field={fieldSchema} component="span" />
                  </TableCell>
                ) : (
                  <TableCell
                    tag="th"
                    fieldSchema={fieldSchema}
                    key={fieldSchema.name}
                  >
                    <Label field={fieldSchema} component="span" />
                  </TableCell>
                )
            ))
          }
          {
            (!props.entityMode && !props.readOnly)
            && (<th>Action</th>)
          }
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </Table>
  );
};

export default DamageStateRowTable;
