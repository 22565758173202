/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import groupBy from 'lodash/fp/groupBy.js';
import isArray from 'lodash/fp/isArray.js';
import keys from 'lodash/fp/keys.js';
import set from 'lodash/fp/set.js';
import values from 'lodash/fp/values.js';
import uniq from 'lodash/fp/uniq.js';
import filter from 'lodash/fp/filter.js';
import orderBy from 'lodash/fp/orderBy.js';
import pipe from 'lodash/fp/pipe.js';
import { createSelector } from 'reselect';
import {
  UUID,
  CATEGORY_UUID,
  COMPONENT_ID,
  DAMAGE_STATES,
  DAMAGE_STATE_CONSEQUENCES,
  FORM_INPUTS,
  NAME,
  _FORM_ATTRIBUTES,
} from '@hbrisk/sp3-risk-model-support/components/translated/attributes/names/index.js';

import { NAME as CATEGORY_NAME, CATEGORY_ID } from '@hbrisk/sp3-risk-model-support/componentCategories/attributes/names.js';

import { selectComponentCategoryParentTree, selectComponentCategoryNameByCategoryId } from '#selectors/entities/componentCategories.js';
import fields from '#constants/components/form/fields/index.js';
import resolveFormInputs from '#support/utility/form/resolveFormInputs/index.js';
import { BY_UUID, LAST_UPDATED } from '#reducers/entities/components/index.js';

const selectOptions = (_, props = {}) => props.options;

const selectUuid = (_, props) => props[UUID];

const selectFieldSchema = (_, props) => props.fieldSchema;

export const selectComponentLastUpdated = (state) => state.entities.components[LAST_UPDATED];

const selectRawComponentsByUuid = (state) => state.entities.components[BY_UUID];

const selectRawComponentByUuid = createSelector(
  selectRawComponentsByUuid,
  selectUuid,
  (components, uuid) => components[uuid],
);

export const selectComponentsByUuid = createSelector(
  selectRawComponentsByUuid,
  (rawComponents) => {
    if (!rawComponents) {
      return null;
    }
    return Object.keys(rawComponents).reduce((acc, uuid) => {
      const rawComponent = rawComponents[uuid];
      const { [_FORM_ATTRIBUTES]: _formAttributes, ...rest } = rawComponent;
      return ({
        ...acc,
        [uuid]: {
          ...rest,
          ..._formAttributes,
        },
      });
    }, {});
  }
);

export const selectComponents = createSelector(
  selectComponentsByUuid,
  (componentsByUuid) => values(componentsByUuid),
);

export const selectComponentByUuid = createSelector(
  selectUuid,
  selectRawComponentsByUuid,
  selectOptions,
  (uuid, rawComponents, options) => {
    const rawComponent = rawComponents[uuid];
    if (!rawComponent) {
      return null;
    }
    const { [_FORM_ATTRIBUTES]: _formAttributes, ...rest } = rawComponent;
    const component = {
      ...rest,
      ..._formAttributes,
    };
    if (options && options.includeFormInputs) {
      return {
        ...component,
        [FORM_INPUTS]: resolveFormInputs(fields, { values: _formAttributes }),
      };
    }
    return component;
  },
);

export const selectComponentFormInputsByUuid = createSelector(
  selectRawComponentByUuid,
  (rawComponent) => {
    if (!rawComponent) {
      return null;
    }
    const { [_FORM_ATTRIBUTES]: _formAttributes } = rawComponent;
    return resolveFormInputs(fields, { values: _formAttributes });
  },
);

const selectVisibleFieldsByUuid = createSelector(
  selectComponentFormInputsByUuid,
  (formInputs) => (formInputs ? formInputs.visibleFields : null),
);

const buildTree = (categoryNameByCategoryId, initialTree) => {
  const recurse = (tree) => {
    if (isArray(tree)) {
      return tree.map((component) => ({
        [COMPONENT_ID]: component[COMPONENT_ID],
        [UUID]: component[UUID],
        [NAME]: component[NAME],
      }));
    }
    return keys(tree).map((category) => {
      const label = categoryNameByCategoryId(category);
      return {
        category,
        label,
        children: recurse(tree[category]),
      };
    });
  };
  return recurse(initialTree);
};

export const selectComponentTree = createSelector(
  selectComponents,
  selectComponentCategoryParentTree,
  selectComponentCategoryNameByCategoryId,
  (components, getParentTree, getCategoryNameByCatId) => (searchText = '') => {
    const filteredComponents = pipe(
      filter((component) => {
        const {
          [COMPONENT_ID]: componentId,
          [CATEGORY_UUID]: categoryUuid,
          [NAME]: name,
        } = component;
        const tree = getParentTree(categoryUuid);
        if (searchText) {
          const categoryNames = tree.map((category) => category[CATEGORY_NAME]);

          return (
            searchText
              .toLowerCase()
              .split(' ')
              .every((searchTextWord) => (
                componentId.toLowerCase().indexOf(searchTextWord) !== -1
                  || name.toLowerCase().indexOf(searchTextWord) !== -1
                  || categoryNames.some((cat) => (cat.toLowerCase().indexOf(searchTextWord) !== -1))
              ))
          );
        }
        return true;
      }),
      orderBy([COMPONENT_ID], ['asc'])
    )(components);

    const grouped = groupBy(CATEGORY_UUID, filteredComponents);
    const expandableNodes = [];
    let tree = {};
    keys(grouped).forEach((categoryUuid) => {
      const categoryIds = (
        getParentTree(categoryUuid)
          .map((category) => category[CATEGORY_ID])
      );
      tree = set(categoryIds.reverse().join('.'), grouped[categoryUuid], tree);
      expandableNodes.push(...categoryIds);
    });

    return {
      tree: buildTree(getCategoryNameByCatId, tree),
      expandableNodes: uniq(expandableNodes),
    };
  },
);

export const someConsequenceHasVisibleInstancesOfFieldSchemaUuid = createSelector(
  selectFieldSchema,
  selectVisibleFieldsByUuid,
  (schema, visibleFields) => {
    if (visibleFields && Array.isArray(visibleFields[DAMAGE_STATES])) {
      return visibleFields[DAMAGE_STATES].some(
        (damageState) => damageState[DAMAGE_STATE_CONSEQUENCES].some(
          (consequence) => consequence[schema.name] === true
        )
      );
    }
    return null;
  },
);

export const someDamageStateHasVisibleInstancesOfFieldSchemaUuid = createSelector(
  selectFieldSchema,
  selectVisibleFieldsByUuid,
  (schema, visibleFields) => {
    if (visibleFields && Array.isArray(visibleFields[DAMAGE_STATES])) {
      return visibleFields[DAMAGE_STATES]
        .some((damageState) => damageState[schema.name] === true);
    }
    return null;
  }
);
