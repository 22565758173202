// @flow
import type { Element } from 'react';

import React from 'react';
import { Col, Row } from 'reactstrap';
import {
  BreadCrumb, BreadCrumbDivider, BreadCrumbTrail, SubNav,
} from '#components/support/layout/SubNav/index.js';
import Snippet from '#components/pages/Models/Model/SubNav/Snippet/index.js';

type Props = {
  modelName: string,
  includeDebug: boolean,
  modelType: number,
  modelId: string,
  modelTypeName: string,
}
const ModelPageSubNav = ({
  modelName, includeDebug, modelType, modelId, modelTypeName,
}: Props): Element<typeof SubNav> => {
  const newModelName = modelTypeName
    ? `New ${modelTypeName}`
    : 'New Model';
  return (
    <SubNav className="pt-2">
      <Row>
        <Col>
          <BreadCrumbTrail>
            <BreadCrumb to="..">Models</BreadCrumb>
            <BreadCrumbDivider />
            <BreadCrumb>{modelName || newModelName}</BreadCrumb>
          </BreadCrumbTrail>
        </Col>
        <Col>
          {
            modelType
            && (
              <Snippet
                includeDebug={includeDebug}
                modelType={modelType}
                modelId={modelId}
              />
            )
          }
        </Col>
      </Row>
    </SubNav>
  );
};

export default ModelPageSubNav;
