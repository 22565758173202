/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import {
  Link, Navigate, useNavigate, useParams,
} from 'react-router-dom';
import {
  Button, Card, CardBody, Col, FormGroup, Label, Row,
} from 'reactstrap';
import type { EdpKey, EdpList } from '#constants/edps/index.js';
import Select from '#components/support/forms/SelectMenus/BasicSelectMenu/index.jsx';

import { edps, edpKeys } from '#constants/edps/index.js';

import StructuralResponseView from './StructuralResponseView/index.jsx';

type Props = {|
  modelId: string,
  edpList: EdpList,
  form: string,
|};

const StructuralResponseViewWithPicker = (
  props: Props
): (
  Element<typeof Card> | Element<typeof Navigate>
) => {
  const {
    modelId,
    edpList,
    form,
  } = props;

  const navigate = useNavigate();
  const { edpKey } = useParams();

  if (!edpKeys.includes(edpKey)) {
    return (
      <Navigate to=".." />
    );
  }

  const onChange = (e) => {
    const { value } = e.target;
    navigate(`../${value}`);
  };

  const items = edps
    .filter((edp) => edpList.includes(edp.key))
    .map((edp) => {
      const { key, name } = edp;
      return {
        id: (key: string),
        name,
        value: (key: string),
      };
    });

  // cast to EdpKey through any
  const edpKeyCasted = ((edpKey: any): EdpKey);

  return (
    <Card>
      <CardBody>
        <Row>
          <Col>
            <h2>Structural Response - Detail</h2>
          </Col>
          <Col>
            <span className="float-right">
              <Button id="topCloseButton" tag={Link} color="link" to="..">Close</Button>
            </span>
          </Col>
        </Row>
        <FormGroup row>
          <Col lg="6">
            <Label for="edpKey">Engineering Demand Parameter</Label>
            <Select
              id="edpKey"
              name="edpKey"
              onChange={onChange}
              items={items}
              value={edpKey || ''}
            />
          </Col>
        </FormGroup>
        { edpKey
          ? (
            <Row>
              <Col lg="12">
                <StructuralResponseView
                  form={form}
                  edpKey={edpKeyCasted}
                  modelId={modelId}
                />
              </Col>
            </Row>
          )
          : null}
        <Row>
          <Col>
            <Button id="bottomCloseButton" tag={Link} to="..">Back to Structural Responses Table</Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default StructuralResponseViewWithPicker;
