/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { isSubmitting } from 'redux-form';
import { RUN_STARTED } from '@hbrisk/sp3-risk-model-support/models/runStatuses.js';
import { modelTypesById } from '#src/js/constants/models/modelTypes/index.js';
import { selectHasUploadsInProgress, selectModelStatus } from '#selectors/entities/models.js';
import ModelFormSubmitButton from './ModelFormSubmitButton.jsx';

const mapStateToProps = (state, ownProps) => {
  const { modelType } = ownProps;
  const { modelFormName } = modelTypesById[modelType];
  const running = isSubmitting(modelFormName)(state)
    || (selectModelStatus(state, ownProps) === RUN_STARTED);
  const hasUploadsInProgress = selectHasUploadsInProgress(state, ownProps);
  return ({
    submitting: running,
    disabled: hasUploadsInProgress,
  });
};

export default connect(mapStateToProps)(ModelFormSubmitButton);
