/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import getOr from 'lodash/fp/getOr.js';
import get from 'lodash/fp/get.js';
import keys from 'lodash/fp/keys.js';
import getFieldsFlattenedWithErrors from '#support/utility/form/validation/getFieldsFlattenedWithErrors/index.js';
import getTouchedFromMeta from '#support/utility/form/getTouchedFromMeta/index.js';
import { underscoreErrorRegex } from '#constants/regularExpressions.js';

const countFieldArrayItemErrors = (name, index, errors, meta) => {
  const itemPath = `${name}[${index}]`;
  const errorItem = getOr({}, itemPath, errors);
  const fieldsWithErrors = getFieldsFlattenedWithErrors(errorItem);
  const touchedFields = getTouchedFromMeta(getOr({}, itemPath, meta));
  return keys(fieldsWithErrors).reduce((acc, field) => {
    const res = get(field, touchedFields) !== undefined || underscoreErrorRegex.test(field);
    return acc + (res ? fieldsWithErrors[field] : 0);
  }, 0);
};

export default countFieldArrayItemErrors;
