import get from 'lodash/fp/get.js';
import head from 'lodash/fp/head.js';
import * as names from '@hbrisk/sp3-risk-model-support/components/translated/attributes/names/index.js';
import booleanToYesNo
  from '@hbrisk/sp3-risk-model-support/components/translation/booleanToYesNo.js';
import * as values from '@hbrisk/sp3-risk-model-support/components/translated/attributes/values/index.js';
import * as sections from '#constants/components/componentPage/sections/index.js';
import * as options from '#constants/components/form/fields/options/index.js';

import defaultFieldLabelFromName from '#support/components/form/defaultFieldLabelFromName.js';
import { COMPONENT_FORM } from '#constants/components/form/name.js';

import addFieldDefaults
  from '#support/components/form/addFieldDefaults.js';
import { formatWithCommas, normalizeWithCommas } from '#support/utility/form/withCommas.js';

const dotPattern = /\./;

const itemSchemaWithoutDefaults = [
  {
    name: names.CONSEQUENCE_PROBABILITY,
    section: sections.CONSEQUENCES_DEFINITION,
    type: 'text',
    suffix: '%',
    reducers: {
      visible: (item, itemPath) => (state) => {
        const damageStatePath = head(itemPath.split(dotPattern));
        const damageState = get(damageStatePath, state.values);
        return damageState.type !== values.DAMAGE_STATE_TYPE_VALUES.SEQUENTIAL;
      },
    },
  },
  {
    name: names.CONSEQUENCE_DESCRIPTION,
    section: sections.CONSEQUENCES_DEFINITION,
    type: 'textarea',
  },
  {
    name: names.CONSEQUENCE_REPAIR_MEASURES,
    section: sections.CONSEQUENCES_DEFINITION,
    type: 'textarea',
  },
  {
    name: names.CONSEQUENCE_REPAIR_COST_LOWER_QTY,
    section: sections.CONSEQUENCES_REPAIR_COST,
    label: 'Low Quantity',
    type: 'text',
  },
  {
    name: names.CONSEQUENCE_REPAIR_COST_UPPER_QTY,
    section: sections.CONSEQUENCES_REPAIR_COST,
    label: 'High Quantity',
    type: 'text',
  },
  {
    name: names.CONSEQUENCE_REPAIR_COST_MAX_AMOUNT,
    section: sections.CONSEQUENCES_REPAIR_COST,
    label: 'Low Quantity Repair Cost per Unit',
    type: 'text',
    format: formatWithCommas,
    normalize: normalizeWithCommas,
    prefix: '$',
  },
  {
    name: names.CONSEQUENCE_REPAIR_COST_MIN_AMOUNT,
    section: sections.CONSEQUENCES_REPAIR_COST,
    label: 'High Quantity Repair Cost per Unit',
    type: 'text',
    format: formatWithCommas,
    normalize: normalizeWithCommas,
    prefix: '$',
  },

  {
    name: names.CONSEQUENCE_REPAIR_COST_UNCERTAINTY,
    section: sections.CONSEQUENCES_REPAIR_COST,
    label: 'Uncertainty',
    type: 'text',
  },
  {
    name: names.CONSEQUENCE_REPAIR_COST_CURVE_TYPE,
    section: sections.CONSEQUENCES_REPAIR_COST,
    label: 'Curve Type',
    type: 'select',
    options: options.CURVE_TYPE_OPTIONS,
  },
  {
    name: names.CONSEQUENCE_REPAIR_TIME_LOWER_QTY,
    section: sections.CONSEQUENCES_REPAIR_TIME,
    label: 'Low Quantity',
    type: 'text',
  },
  {
    name: names.CONSEQUENCE_REPAIR_TIME_UPPER_QTY,
    section: sections.CONSEQUENCES_REPAIR_TIME,
    label: 'High Quantity',
    type: 'text',
  },
  {
    name: names.CONSEQUENCE_REPAIR_TIME_MAX_AMOUNT,
    section: sections.CONSEQUENCES_REPAIR_TIME,
    label: 'Low Quantity Worker Days per Unit',
    type: 'text',
  },
  {
    name: names.CONSEQUENCE_REPAIR_TIME_MIN_AMOUNT,
    section: sections.CONSEQUENCES_REPAIR_TIME,
    label: 'High Quantity Worker Days per Unit',
    type: 'text',
  },
  {
    name: names.CONSEQUENCE_REPAIR_TIME_UNCERTAINTY,
    section: sections.CONSEQUENCES_REPAIR_TIME,
    label: 'Uncertainty',
    type: 'text',
  },
  {
    name: names.CONSEQUENCE_REPAIR_TIME_CURVE_TYPE,
    section: sections.CONSEQUENCES_REPAIR_TIME,
    label: 'Curve Type',
    type: 'select',
    options: options.CURVE_TYPE_OPTIONS,
  },
  {
    name: names.CONSEQUENCE_POSES_LIFE_SAFETY_ISSUE,
    section: sections.CONSEQUENCES_LIFE_SAFETY,
    type: 'select',
    label: 'Poses Life Safety Issue',
    options: options.YES_NO_OPTIONS,
  },
  {
    name: names.CONSEQUENCE_AFFECTED_AREA_SQ_FT,
    section: sections.CONSEQUENCES_LIFE_SAFETY,
    label: 'Affected Area',
    type: 'text',
    suffix: 'sq ft',
    reducers: {
      visible: (item) => () => {
        const { [names.CONSEQUENCE_POSES_LIFE_SAFETY_ISSUE]: value } = item;
        return value !== values.YES_NO_VALUES.NO;
      },
    },
  },
  {
    name: names.CONSEQUENCE_FATALITY_MEDIAN,
    section: sections.CONSEQUENCES_LIFE_SAFETY,
    label: 'Fatality Median',
    type: 'text',
    reducers: {
      visible: (item) => () => {
        const { [names.CONSEQUENCE_POSES_LIFE_SAFETY_ISSUE]: value } = item;
        return value !== values.YES_NO_VALUES.NO;
      },
    },
  },
  {
    name: names.CONSEQUENCE_FATALITY_BETA,
    section: sections.CONSEQUENCES_LIFE_SAFETY,
    label: 'Fatality Beta',
    type: 'text',
    reducers: {
      visible: (item) => () => {
        const { [names.CONSEQUENCE_POSES_LIFE_SAFETY_ISSUE]: value } = item;
        return value !== values.YES_NO_VALUES.NO;
      },
    },
  },
  {
    name: names.CONSEQUENCE_INJURY_MEDIAN,
    section: sections.CONSEQUENCES_LIFE_SAFETY,
    label: 'Injury Median',
    type: 'text',
    reducers: {
      visible: (item) => () => {
        const { [names.CONSEQUENCE_POSES_LIFE_SAFETY_ISSUE]: value } = item;
        return value !== values.YES_NO_VALUES.NO;
      },
    },
  },
  {
    name: names.CONSEQUENCE_INJURY_BETA,
    section: sections.CONSEQUENCES_LIFE_SAFETY,
    label: 'Injury Beta',
    type: 'text',
    reducers: {
      visible: (item) => () => {
        const { [names.CONSEQUENCE_POSES_LIFE_SAFETY_ISSUE]: value } = item;
        return value !== values.YES_NO_VALUES.NO;
      },
    },
  },

  {
    name: names.CONSEQUENCE_SAFETY_CLASS,
    section: sections.CONSEQUENCES_RECOVERY_TIME,
    label: 'Safety Class',
    type: 'select',
    options: options.CONSEQUENCE_SAFETY_CLASS_OPTIONS,
  },

  {
    name: names.CONSEQUENCE_AFFECTS_ROOF_FUNCTION,
    section: sections.CONSEQUENCES_RECOVERY_TIME,
    label: 'Affects Roof Function?',
    type: 'select',
    options: options.YES_NO_OPTIONS,
  },
  {
    name: names.CONSEQUENCE_AFFECTS_ENVELOPE_SAFETY,
    section: sections.CONSEQUENCES_RECOVERY_TIME,
    label: 'Affects Envelope Safety?',
    type: 'select',
    options: options.YES_NO_OPTIONS,
  },
  {
    name: names.CONSEQUENCE_DAMAGES_ENVELOPE_SEAL,
    section: sections.CONSEQUENCES_RECOVERY_TIME,
    label: 'Damages Envelope Seal?',
    type: 'select',
    options: options.YES_NO_OPTIONS,
  },
  {
    name: names.CONSEQUENCE_EXTERIOR_SURFACE_AREA_FACTOR,
    section: sections.CONSEQUENCES_RECOVERY_TIME,
    type: 'text',
    reducers: {
      visible: (item) => () => {
        const {
          [names.CONSEQUENCE_AFFECTS_ENVELOPE_SAFETY]: affectsEnvelopeSafety,
          [names.CONSEQUENCE_DAMAGES_ENVELOPE_SEAL]: damagesEnvelopeSeal,
        } = item;
        return [
          affectsEnvelopeSafety,
          damagesEnvelopeSeal,
        ].some((value) => value !== values.YES_NO_VALUES.NO);
      },
    },
  },
  {
    name: names.CONSEQUENCE_POSES_EXTERIOR_FALLING_HAZARD,
    section: sections.CONSEQUENCES_RECOVERY_TIME,
    label: 'Poses an Exterior Falling Hazard?',
    type: 'select',
    options: options.YES_NO_OPTIONS,
  },
  {
    name: names.CONSEQUENCE_EXTERIOR_FALLING_LENGTH_FACTOR,
    section: sections.CONSEQUENCES_RECOVERY_TIME,
    type: 'text',
    reducers: {
      visible: (item) => () => {
        const {
          [names.CONSEQUENCE_POSES_EXTERIOR_FALLING_HAZARD]: posesExteriorFallingHazard,
        } = item;
        return posesExteriorFallingHazard !== values.YES_NO_VALUES.NO;
      },
    },
  },
  {
    name: names.CONSEQUENCE_POSES_INTERIOR_FALLING_HAZARD,
    section: sections.CONSEQUENCES_RECOVERY_TIME,
    label: 'Poses an Interior Falling Hazard?',
    type: 'select',
    options: options.YES_NO_OPTIONS,
  },
  {
    name: names.CONSEQUENCE_OBSTRUCTS_INTERIOR_SPACE,
    section: sections.CONSEQUENCES_RECOVERY_TIME,
    label: 'Obstructs Interior Space?',
    type: 'select',
    options: options.YES_NO_OPTIONS,
  },
  {
    name: names.CONSEQUENCE_INTERIOR_AREA_CONVERSION_TYPE,
    section: sections.CONSEQUENCES_RECOVERY_TIME,
    type: 'select',
    options: options.CONSEQUENCE_INTERIOR_AREA_CONVERSION_TYPE_OPTIONS,
    reducers: {
      visible: (item) => () => {
        const {
          [names.CONSEQUENCE_POSES_INTERIOR_FALLING_HAZARD]: posesInteriorFallingHazard,
          [names.CONSEQUENCE_OBSTRUCTS_INTERIOR_SPACE]: obstructsInteriorSpace,
        } = item;
        return [
          posesInteriorFallingHazard,
          obstructsInteriorSpace,
        ].some((value) => value !== values.YES_NO_VALUES.NO);
      },
    },
  },
  {
    name: names.CONSEQUENCE_INTERIOR_AREA_FACTOR,
    section: sections.CONSEQUENCES_RECOVERY_TIME,
    type: 'text',
    reducers: {
      visible: (item) => () => {
        const {
          [names.CONSEQUENCE_POSES_INTERIOR_FALLING_HAZARD]: posesInteriorFallingHazard,
          [names.CONSEQUENCE_OBSTRUCTS_INTERIOR_SPACE]: obstructsInteriorSpace,
        } = item;
        return [
          posesInteriorFallingHazard,
          obstructsInteriorSpace,
        ].some((value) => value !== values.YES_NO_VALUES.NO);
      },
    },
  },
  {
    name: names.CONSEQUENCE_CREW_SIZE,
    section: sections.CONSEQUENCES_RECOVERY_TIME,
    label: 'Crew Size',
    type: 'text',
  },
  {
    name: names.CONSEQUENCE_PERMIT_TYPE,
    section: sections.CONSEQUENCES_RECOVERY_TIME,
    label: 'Permit Type',
    type: 'select',
    options: options.CONSEQUENCE_PERMIT_TYPE_OPTIONS,
  },
  {
    name: names.CONSEQUENCE_REQUIRES_REDESIGN,
    section: sections.CONSEQUENCES_RECOVERY_TIME,
    label: 'Requires Redesign?',
    type: 'select',
    options: options.YES_NO_OPTIONS,
    initialize: (consequence) => {
      const { [names.CONSEQUENCE_REQUIRES_REDESIGN]: value } = consequence;
      return booleanToYesNo(value);
    },
  },
  {
    name: names.CONSEQUENCE_ATC_138_LONG_LEAD_TIME,
    section: sections.CONSEQUENCES_RECOVERY_TIME,
    label: 'Has a Long Lead Time?',
    type: 'select',
    options: options.YES_NO_OPTIONS,
    initialize: (consequence) => {
      const { [names.CONSEQUENCE_ATC_138_LONG_LEAD_TIME]: value } = consequence;
      return booleanToYesNo(value);
    },
  },
  {
    name: names.CONSEQUENCE_REQUIRES_SHORING,
    section: sections.CONSEQUENCES_RECOVERY_TIME,
    label: 'Requires Shoring?',
    type: 'select',
    options: options.YES_NO_OPTIONS,
    initialize: (consequence) => {
      const { [names.CONSEQUENCE_REQUIRES_SHORING]: value } = consequence;
      return booleanToYesNo(value);
    },
  },
  {
    name: names.CONSEQUENCE_RESOLVED_BY_SCAFFOLDING,
    section: sections.CONSEQUENCES_RECOVERY_TIME,
    label: 'Resolved By Scaffolding?',
    type: 'select',
    options: options.YES_NO_OPTIONS,
    initialize: (consequence) => {
      const { [names.CONSEQUENCE_RESOLVED_BY_SCAFFOLDING]: value } = consequence;
      return booleanToYesNo(value);
    },
  },
  {
    name: names.CONSEQUENCE_TEMPORARY_REPAIR_CLASS,
    section: sections.CONSEQUENCES_RECOVERY_TIME,
    label: 'Temporary Repair Class',
    type: 'select',
    options: options.CONSEQUENCE_TEMPORARY_REPAIR_CLASS_OPTIONS,
  },
  {
    name: names.CONSEQUENCE_TEMPORARY_REPAIR_TIME_LOWER,
    section: sections.CONSEQUENCES_RECOVERY_TIME,
    label: 'Temporary Repair Time Lower (Days)',
    type: 'text',
    reducers: {
      visible: (item) => () => {
        const { [names.CONSEQUENCE_TEMPORARY_REPAIR_CLASS]: value } = item;
        return value !== values.CONSEQUENCE_TEMPORARY_REPAIR_CLASS_VALUES.NONE;
      },
    },
  },
  {
    name: names.CONSEQUENCE_TEMPORARY_REPAIR_TIME_UPPER,
    section: sections.CONSEQUENCES_RECOVERY_TIME,
    label: 'Temporary Repair Time Upper (Days)',
    type: 'text',
    reducers: {
      visible: (item) => () => {
        const { [names.CONSEQUENCE_TEMPORARY_REPAIR_CLASS]: value } = item;
        return value !== values.CONSEQUENCE_TEMPORARY_REPAIR_CLASS_VALUES.NONE;
      },
    },
  },
  {
    name: names.CONSEQUENCE_TEMPORARY_REPAIR_CREW_SIZE,
    section: sections.CONSEQUENCES_RECOVERY_TIME,
    label: 'Temporary Repair Crew Size',
    type: 'text',
    reducers: {
      visible: (item) => () => {
        const { [names.CONSEQUENCE_TEMPORARY_REPAIR_CLASS]: value } = item;
        return value !== values.CONSEQUENCE_TEMPORARY_REPAIR_CLASS_VALUES.NONE;
      },
    },
  },
  {
    name: names.CONSEQUENCE_WEAKENS_FIRE_BREAK,
    section: sections.CONSEQUENCES_RECOVERY_TIME,
    label: 'Weakens Fire Break?',
    type: 'select',
    options: options.YES_NO_OPTIONS,
  },
  {
    name: names.CONSEQUENCE_IMPAIRS_SYSTEM_OPERATION,
    section: sections.CONSEQUENCES_RECOVERY_TIME,
    label: 'Impairs System Operation?',
    type: 'select',
    options: options.YES_NO_OPTIONS,
  },
  {
    name: names.CONSEQUENCE_CAUSES_FLOODING,
    section: sections.CONSEQUENCES_RECOVERY_TIME,
    label: 'Causes Water Damage?',
    type: 'select',
    options: options.YES_NO_OPTIONS,
  },
  {
    name: names.CONSEQUENCE_AFFECTS_ACCESS,
    section: sections.CONSEQUENCES_RECOVERY_TIME,
    label: 'Affects Access?',
    type: 'select',
    options: options.YES_NO_OPTIONS,
  },
  {
    name: names.CONSEQUENCE_HAZARDOUS_MATERIAL_SCOPE,
    section: sections.CONSEQUENCES_RECOVERY_TIME,
    label: 'Hazardous Material Scope',
    type: 'select',
    options: options.CONSEQUENCE_HAZARDOUS_MATERIAL_SCOPE_OPTIONS,
  },
  {
    name: names.CONSEQUENCE_REDI_REPAIR_CLASS,
    section: sections.CONSEQUENCES_RECOVERY_TIME,
    label: 'REDi Repair Class',
    type: 'select',
    options: options.CONSEQUENCE_REDI_REPAIR_CLASS_OPTIONS,
  },
  {
    name: names.CONSEQUENCE_FEMA_P58_LONG_LEAD_TIME,
    section: sections.CONSEQUENCES_OTHER,
    label: 'Has a Long Lead Time?',
    type: 'select',
    options: options.YES_NO_OPTIONS,
  },
  {
    name: names.CONSEQUENCE_CAN_RED_TAG,
    section: sections.CONSEQUENCES_OTHER,
    label: 'Can Red Tag?',
    type: 'select',
    options: options.YES_NO_OPTIONS,
  },
  {
    name: names.CONSEQUENCE_RED_TAG_MEDIAN,
    section: sections.CONSEQUENCES_OTHER,
    label: 'Red Tag Median',
    type: 'text',
    reducers: {
      visible: (item) => () => {
        const { [names.CONSEQUENCE_CAN_RED_TAG]: value } = item;
        return value !== values.YES_NO_VALUES.NO;
      },
    },
  },
  {
    name: names.CONSEQUENCE_RED_TAG_BETA,
    section: sections.CONSEQUENCES_OTHER,
    label: 'Red Tag Beta',
    type: 'text',
    reducers: {
      visible: (item) => () => {
        const { [names.CONSEQUENCE_CAN_RED_TAG]: value } = item;
        return value !== values.YES_NO_VALUES.NO;
      },
    },
  },
];

const itemSchema = addFieldDefaults(itemSchemaWithoutDefaults);

const consequences = {
  name: names.DAMAGE_STATE_CONSEQUENCES,
  label: defaultFieldLabelFromName(names.DAMAGE_STATE_CONSEQUENCES),
  type: 'FieldArray',
  allowItemLevelReducers: true,
  itemSchema,
  form: COMPONENT_FORM,
  initializeFrom: names.DAMAGE_STATE_CONSEQUENCES,
};
export default consequences;
