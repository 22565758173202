/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { setMaintenanceOverride } from '#actions/app/maintenance.js';
import { selectIsMaintenanceMode } from '#selectors/ui/app.js';
import Maintenance from './Maintenance.jsx';

const mapStateToProps = (state) => ({
  isMaintenanceMode: selectIsMaintenanceMode(state),

});

const mapDispatchToProps = (dispatch) => ({
  setMaintenanceOverride: (override) => {
    dispatch(setMaintenanceOverride(override));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Maintenance);
