/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { setSubmitFailed, submit } from 'redux-form';
import { touchAllModelFormFields, updateSyncErrors } from '#actions/models/modelForm.js';
import { modelTypesById } from '#src/js/constants/models/modelTypes/index.js';

// Here we manually update syncErrors, touch all fields, and prevent submission when there are sync
// errors in any expected fields. We do this manually because we expect fields that are
// not registered and because redux-form does not handle such fields the way that we need to handle
// them. In particular, redux-form doesn't touch un-registered fields when a form is submitted
// and it doesn't block submission when there are sync errors in un-registered fields

const withCustomHandleSubmit = (C) => {
  const mapStateToProps = (state, ownProps) => {
    const { modelType } = ownProps;
    const {
      selectAllFormValues,
      selectAutofilledFormFields,
      selectFieldsVisibility,
      validate,
    } = modelTypesById[modelType];
    const autofilled = selectAutofilledFormFields(state);
    return {
      validate: (values) => validate(autofilled, state)(values),
      values: selectAllFormValues(state),
      visibility: selectFieldsVisibility(state),
    };
  };

  const mapDispatchToProps = (dispatch, ownProps) => {
    const { modelType } = ownProps;
    const { modelFormName } = modelTypesById[modelType];
    return ({
      makeHandleSubmit: (validate, values, visibility) => (e) => {
        e.preventDefault();
        const syncErrors = validate(values);
        dispatch(updateSyncErrors(modelFormName, syncErrors));
        const visibleFieldsHaveSyncErrors = Object.keys(syncErrors)
          .some((syncErrorKey) => visibility[syncErrorKey] !== false);

        if (visibleFieldsHaveSyncErrors) {
          dispatch(touchAllModelFormFields(modelFormName, values));
          dispatch(setSubmitFailed(modelFormName));
        } else {
          dispatch(submit(modelFormName));
        }
      },
    });
  };

  const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { validate, values, visibility } = stateProps;
    const { makeHandleSubmit } = dispatchProps;
    return ({
      ...ownProps,
      handleSubmit: makeHandleSubmit(validate, values, visibility),
    });
  };
  return connect(mapStateToProps, mapDispatchToProps, mergeProps)(C);
};

export default withCustomHandleSubmit;
