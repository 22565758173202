// @flow
import type { Element } from 'React';

import React from 'react';
import { FormGroup } from 'reactstrap';

type Props = {
  fields?: Array<string>,
}
/* eslint-disable react/jsx-props-no-spreading */
const FieldSet = ({ fields, ...rest }: Props): Element<typeof FormGroup> => (<FormGroup {...rest} tag="fieldset" />);

FieldSet.defaultProps = {
  fields: null,
};

export default FieldSet;
