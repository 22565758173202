import React from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import {
  Sidebar,
} from '#src/js/components/support/layout/SidebarLayout/index.js';
import ComponentSelector from '#components/pages/Components/Dashboard/Sidebar/ComponentSelector/index.js';

const ComponentsDashboardSidebar = () => {
  const navigate = useNavigate();
  const setSelected = (componentUuid) => {
    navigate(`../${componentUuid}`);
  };

  return (
    <Sidebar md="4" top="116px">
      <ComponentSelector onSelect={setSelected} collapsable={false} />
    </Sidebar>
  );
};

export default ComponentsDashboardSidebar;
