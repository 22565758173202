/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import {
  Col,
  FormGroup,
  Row,
  Table,
} from 'reactstrap';
import numeral from 'numeral';
import Tip from '#components/support/tooltips/Tip/index.jsx';
import damageStateNameFromIndex from '#support/components/damageStateNameFromIndex.js';

const calculatedCapacity = (dsIndex, capacity, edpUnit) => (
  <tr>
    <td>
      {damageStateNameFromIndex(dsIndex)}
    </td>
    <td>
      {numeral(capacity).format('0.0000')}
      {' '}
      {edpUnit}
    </td>
  </tr>
);

type Props = {|
  calculatedCapacities: Array<number>,
  edpUnit: string,
|};

const CalculatedCapacities = (props: Props): Element<typeof Row> => {
  const { calculatedCapacities, edpUnit } = props;

  return (
    <Row>
      <Col lg="6">
        <FormGroup tag="fieldset">
          <legend>
            Calculated Median Capacity
            {' '}
            <Tip tipId="calculatedMedianCapacity" />
          </legend>
          <Table id="calculated-median-capacities">
            <thead>
              <tr>
                <th>Damage State</th>
                <th>Capacity</th>
              </tr>
            </thead>
            <tbody>
              {
                calculatedCapacities.map((
                  capacity,
                  dsIndex
                ) => calculatedCapacity(dsIndex, capacity, edpUnit))
              }
            </tbody>
          </Table>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default CalculatedCapacities;
