// @flow
import type { Node, Element } from 'react';

import classNames from 'classnames';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { breadCrumbTrail } from './index.module.scss';

type Props = {
  children: Node,
  className?: string,
}

const BreadCrumbTrial = ({ className, children }: Props): Element<typeof Row> => (
  <Row className={classNames(breadCrumbTrail, 'bread-crumb-trail', className)}>
    <Col>
      {children}
    </Col>
  </Row>
);

BreadCrumbTrial.defaultProps = {
  className: '',
};

export default BreadCrumbTrial;
