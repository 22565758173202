/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import { COMPONENT_ID } from '@hbrisk/sp3-risk-model-support/components/translated/attributes/names/index.js';
import { selectComponentByUuid, selectComponentTree } from '#selectors/entities/components.js';
import ComponentSelector from '#components/pages/Components/support/ComponentSelector/index.jsx';

const mapStateToProps = (state, ownProps) => {
  const { componentUuid } = ownProps;
  const component = selectComponentByUuid(state, { uuid: componentUuid });
  const componentId = component ? component[COMPONENT_ID] : null;
  return ({
    getComponentTree: selectComponentTree(state),
    componentId,
  });
};

export default connect(mapStateToProps)(ComponentSelector);
