import * as names from '@hbrisk/sp3-risk-model-support/components/translated/attributes/names/index.js';
import { edps } from '@hbrisk/sp3-risk-model-support/edps/edps.js';
import * as values from '@hbrisk/sp3-risk-model-support/components/translated/attributes/values/index.js';
import * as displayText from '#constants/components/form/fields/optionDisplayText.js';
import groupToStructuralOrNonStructural from '#support/components/form/groupToStructuralOrNonStructural.js';

export const CURVE_TYPE_OPTIONS = [
  {},
  {
    text: 'Normal',
    value: values.CURVE_TYPE_VALUES.NORMAL,
  },
  {
    text: 'LogNormal',
    value: values.CURVE_TYPE_VALUES.LOG_NORMAL,
  },
];

export const EDP_OPTIONS = [
  {},
  ...edps.map((edp) => ({
    text: edp.name,
    value: edp.key,
  })),
];

export const EDP_LOCATION_OPTIONS = [
  {},
  {
    text: 'Floor',
    value: values.EDP_LOCATION_VALUES.FLOOR,
  },
  {
    text: 'Story',
    value: values.EDP_LOCATION_VALUES.STORY,
  },
];

export const DAMAGE_STATE_TYPE_OPTIONS = [
  {},
  {
    text: 'Mutually Exclusive',
    value: values.DAMAGE_STATE_TYPE_VALUES.MUTUALLY_EXCLUSIVE,
  },
  {
    text: 'Simultaneous',
    value: values.DAMAGE_STATE_TYPE_VALUES.SIMULTANEOUS,
  },
  {
    text: 'Sequential',
    value: values.DAMAGE_STATE_TYPE_VALUES.SEQUENTIAL,
  },
];

export const RATINGS_OPTIONS = [
  {},
  {
    text: values.RATING_VALUES.SUPERIOR,
    value: values.RATING_VALUES.SUPERIOR,
  },
  {
    text: values.RATING_VALUES.SUPERIOR,
    value: values.RATING_VALUES.AVERAGE,
  },
  {
    text: values.RATING_VALUES.SUPERIOR,
    value: values.RATING_VALUES.MARGINAL,
  },
  {
    text: values.RATING_VALUES.SUPERIOR,
    value: values.RATING_VALUES.NOT_RATED,
  },
];

export const YES_NO_OPTIONS = [
  {},
  {
    text: 'Yes',
    value: values.YES_NO_VALUES.YES,
  },
  {
    text: 'No',
    value: values.YES_NO_VALUES.NO,
  },
];

export const STRUCTURAL_SYSTEM_OPTIONS = [
  {},
  {
    text: 'None',
    value: values.STRUCTURAL_SYSTEM_VALUES.NONE,
  },
  {
    text: 'RC Shear Walls',
    value: values.STRUCTURAL_SYSTEM_VALUES.RC_SHEAR_WALLS,
  },
  {
    text: 'Reinforced Masonry Walls',
    value: values.STRUCTURAL_SYSTEM_VALUES.REINFORCED_MASONRY_WALLS,
  },
  {
    text: 'Precast Tilt Up Walls',
    value: values.STRUCTURAL_SYSTEM_VALUES.PRECAST_TILT_UP_WALLS,
  },
  {
    text: 'Light Frame Walls',
    value: values.STRUCTURAL_SYSTEM_VALUES.LIGHT_FRAME_WALLS,
  },
  {
    text: 'Steel Braces',
    value: values.STRUCTURAL_SYSTEM_VALUES.STEEL_BRACES,
  },
  {
    text: 'Steel moment Frames',
    value: values.STRUCTURAL_SYSTEM_VALUES.STEEL_MOMENT_FRAMES,
  },
  {
    text: 'RC and PC moment Frames',
    value: values.STRUCTURAL_SYSTEM_VALUES.RC_AND_PC_MOMENT_FRAMES,
  },
  {
    text: 'RC Floor Slabs',
    value: values.STRUCTURAL_SYSTEM_VALUES.RC_FLOOR_SLABS,
  },
  {
    text: 'Steel gravity columns',
    value: values.STRUCTURAL_SYSTEM_VALUES.STEEL_GRAVITY_COLUMNS,
  },
  {
    text: 'Flexible Wood diaphragms',
    value: values.STRUCTURAL_SYSTEM_VALUES.FLEXIBLE_WOOD_DIAPHRAGMS,
  },
  {
    text: 'Viscous Damping',
    value: values.STRUCTURAL_SYSTEM_VALUES.VISCOUS_DAMPING,
  },
];

export const GROUP_OPTIONS = [
  {},
  {
    text: 'Exterior Cladding',
    value: values.GROUP_VALUES.EXTERIOR_CLADDING,
  },
  {
    text: 'Interior Finishes',
    value: values.GROUP_VALUES.INTERIOR_FINISHES,
  },
  {
    text: 'Partition Walls',
    value: values.GROUP_VALUES.PARTITION_WALLS,
  },
  {
    text: 'Plumbing/HVAC',
    value: values.GROUP_VALUES.PLUMBING_HVAC,
  },
  {
    text: displayText.GROUP_STRUCTURAL,
    value: values.GROUP_VALUES.STRUCTURAL,
  },
  {
    text: 'Other',
    value: values.GROUP_VALUES.OTHER,
  },
];

const groupingValuesToOptions = {
  [displayText.GROUP_NON_STRUCTURAL]: {
    [values.DETAILED_GROUPING_MAJOR_VALUES.CONTENTS]: [
      values.DETAILED_GROUPING_MINOR_VALUES.GENERAL,
      values.DETAILED_GROUPING_MINOR_VALUES.MEDICAL,
      values.DETAILED_GROUPING_MINOR_VALUES.RACKS,
    ],
    [values.DETAILED_GROUPING_MAJOR_VALUES.CONVEYING]: [
      values.DETAILED_GROUPING_MINOR_VALUES.ELEVATORS,
    ],
    [values.DETAILED_GROUPING_MAJOR_VALUES.ELECTRICAL]: [
      values.DETAILED_GROUPING_MINOR_VALUES.BATTERY,
      values.DETAILED_GROUPING_MINOR_VALUES.DISTRIBUTION,
      values.DETAILED_GROUPING_MINOR_VALUES.GENERATOR,
      values.DETAILED_GROUPING_MINOR_VALUES.MCC,
      values.DETAILED_GROUPING_MINOR_VALUES.SWITCHGEAR,
      values.DETAILED_GROUPING_MINOR_VALUES.TRANSFORMER,
    ],
    [values.DETAILED_GROUPING_MAJOR_VALUES.ENVELOPE]: [
      values.DETAILED_GROUPING_MINOR_VALUES.CLADDING,
      values.DETAILED_GROUPING_MINOR_VALUES.GLAZING,
      values.DETAILED_GROUPING_MINOR_VALUES.CFS,
      values.DETAILED_GROUPING_MINOR_VALUES.WLF,
      values.DETAILED_GROUPING_MINOR_VALUES.INFILL,
    ],
    [values.DETAILED_GROUPING_MAJOR_VALUES.HVAC]: [
      values.DETAILED_GROUPING_MINOR_VALUES.AHU,
      values.DETAILED_GROUPING_MINOR_VALUES.AIR_DIST,
      values.DETAILED_GROUPING_MINOR_VALUES.CHILLER,
      values.DETAILED_GROUPING_MINOR_VALUES.COMPRESSOR,
      values.DETAILED_GROUPING_MINOR_VALUES.CONTROL_PANEL,
      values.DETAILED_GROUPING_MINOR_VALUES.COOLING_TOWER,
      values.DETAILED_GROUPING_MINOR_VALUES.CHILLED,
      values.DETAILED_GROUPING_MINOR_VALUES.STEAM,
    ],
    [values.DETAILED_GROUPING_MAJOR_VALUES.INTERIOR]: [
      values.DETAILED_GROUPING_MINOR_VALUES.CEILINGS,
      values.DETAILED_GROUPING_MINOR_VALUES.FLOORS,
      values.DETAILED_GROUPING_MINOR_VALUES.LIGHTING,
      values.DETAILED_GROUPING_MINOR_VALUES.PARTITION_WALLS,
    ],
    [values.DETAILED_GROUPING_MAJOR_VALUES.PLUMBING]: [
      values.DETAILED_GROUPING_MINOR_VALUES.DOMESTIC,
      values.DETAILED_GROUPING_MINOR_VALUES.WASTE,
    ],
    [values.DETAILED_GROUPING_MAJOR_VALUES.FIRE]: [
      values.DETAILED_GROUPING_MINOR_VALUES.PIPING,
      values.DETAILED_GROUPING_MINOR_VALUES.DROPS,
    ],
    [values.DETAILED_GROUPING_MAJOR_VALUES.ROOF]: [
      values.DETAILED_GROUPING_MINOR_VALUES.CHIMNEY,
      values.DETAILED_GROUPING_MINOR_VALUES.COVERING,
      values.DETAILED_GROUPING_MINOR_VALUES.PARAPET,
    ],
    [values.DETAILED_GROUPING_MAJOR_VALUES.STAIRS]: [
      values.DETAILED_GROUPING_MINOR_VALUES.STAIRS,
    ],
  },
  [displayText.GROUP_STRUCTURAL]: {
    [values.DETAILED_GROUPING_MAJOR_VALUES.CFS]: [
      values.DETAILED_GROUPING_MINOR_VALUES.WALLS,
    ],
    [values.DETAILED_GROUPING_MAJOR_VALUES.CONCRETE]: [
      values.DETAILED_GROUPING_MINOR_VALUES.GRAVITY,
      values.DETAILED_GROUPING_MINOR_VALUES.LINK_BEAM,
      values.DETAILED_GROUPING_MINOR_VALUES.MOMENT_CONNECTIONS,
      values.DETAILED_GROUPING_MINOR_VALUES.SHEAR_WALLS,
    ],
    [values.DETAILED_GROUPING_MAJOR_VALUES.ENCLOSURE]: [
      values.DETAILED_GROUPING_MINOR_VALUES.WLF,
    ],
    [values.DETAILED_GROUPING_MAJOR_VALUES.MASONRY]: [
      values.DETAILED_GROUPING_MINOR_VALUES.WALLS,
    ],
    [values.DETAILED_GROUPING_MAJOR_VALUES.ROOF]: [
      values.DETAILED_GROUPING_MINOR_VALUES.FLEXIBLE_DIAPHRAGM,
      values.DETAILED_GROUPING_MINOR_VALUES.OOP_CONN,
    ],
    [values.DETAILED_GROUPING_MAJOR_VALUES.STEEL]: [
      values.DETAILED_GROUPING_MINOR_VALUES.BASE_PLATES,
      values.DETAILED_GROUPING_MINOR_VALUES.BRACES,
      values.DETAILED_GROUPING_MINOR_VALUES.GRAVITY,
      values.DETAILED_GROUPING_MINOR_VALUES.MOMENT_CONNECTIONS,
      values.DETAILED_GROUPING_MINOR_VALUES.SPLICES,
    ],
    [values.DETAILED_GROUPING_MAJOR_VALUES.WLF]: [
      values.DETAILED_GROUPING_MINOR_VALUES.CRIPPLE_WALL,
      values.DETAILED_GROUPING_MINOR_VALUES.WALLS,
    ],
    [values.DETAILED_GROUPING_MAJOR_VALUES.DAMPERS]: [
      values.DETAILED_GROUPING_MINOR_VALUES.VISCOUS_DAMPERS,
    ],
  },
};

export const MAJOR_OPTIONS = (state) => {
  const { [names.GROUP]: value } = state.values;
  const strOrNonStr = groupToStructuralOrNonStructural(value);
  const atValue = groupingValuesToOptions[strOrNonStr];
  if (atValue) {
    return [
      {},
      ...Object.keys(atValue),
    ];
  }
  return Object.values(groupingValuesToOptions)
    .reduce((acc, v) => [
      ...acc,
      ...Object.keys(v),
    ], [{}]);
};

export const MINOR_OPTIONS = (state) => {
  const {
    [names.MAJOR]: major,
    [names.GROUP]: group,
  } = state.values;

  const strOrNonStr = groupToStructuralOrNonStructural(group);
  const atStructuralOrNonStructuralValue = groupingValuesToOptions[strOrNonStr];
  if (atStructuralOrNonStructuralValue) {
    const atMajorValue = atStructuralOrNonStructuralValue[major];
    if (atMajorValue) {
      return [
        {},
        ...atMajorValue,
      ];
    }

    return Object.values(atStructuralOrNonStructuralValue)
      .reduce((acc, value) => [...acc, ...value], [{}]);
  }
  return Object.values(groupingValuesToOptions)
    .reduce((acc1, vals) => [
      ...acc1,
      ...Object.values(vals)
        .reduce((acc2, options) => [...acc2, ...options], []),
    ], [{}]);
};

export const ANCHORAGE_DUCTILITY_OPTIONS = [
  {},
  {
    text: 'Ductile',
    value: values.ANCHORAGE_DUCTILITY_VALUES.DUCTILE,
  },
  {
    text: 'Brittle',
    value: values.ANCHORAGE_DUCTILITY_VALUES.BRITTLE,
  },
  {
    text: 'Average',
    value: values.ANCHORAGE_DUCTILITY_VALUES.AVERAGE,
  },
];

export const ID_COMPONENT_CLASS_OPTIONS = [
  {},
  {
    label: 'Architectural Veneer',
    options: [
      {
        text: 'Limited deformability elements',
        value: values.ID_COMPONENT_CLASS_VALUES.ARCH_LIM_DEFORM,
      },
      {
        text: 'Low deformability elements',
        value: values.ID_COMPONENT_CLASS_VALUES.ARCH_LOW_DEFORM,
      },
    ],
  },
  {
    label: 'Mechanical and Electrical',
    options: [
      {
        text: 'Air-Side HVAC',
        value: values.ID_COMPONENT_CLASS_VALUES.HVAC_AIR,
      },
      {
        text: 'Wet-side HVAC',
        value: values.ID_COMPONENT_CLASS_VALUES.HVAC_WET,
      },
      {
        text: 'Non-skirt-supported engines and pressure vessels',
        value: values.ID_COMPONENT_CLASS_VALUES.PUMPS_COMPRESSORS,
      },
      {
        text: 'Skirt-supported pressure vessels',
        value: values.ID_COMPONENT_CLASS_VALUES.SKIRT_SUPPORTED_PRESSURE,
      },
      {
        text: 'Elevator and escalator components',
        value: values.ID_COMPONENT_CLASS_VALUES.ELEVATOR_ESCALATOR,
      },
      {
        text: 'Generators, batteries, inverters, motors, transformers',
        value: values.ID_COMPONENT_CLASS_VALUES.GENERATOR,
      },
      {
        text: 'Motor control centers, panels, other sheet metal framed components',
        value: values.ID_COMPONENT_CLASS_VALUES.MCC,
      },
      {
        text: 'Communication equipment, computers',
        value: values.ID_COMPONENT_CLASS_VALUES.COMMUNICATION,
      },
      {
        text: 'Chimney braced above the center of mass',
        value: values.ID_COMPONENT_CLASS_VALUES.CHIMNEY_BRACED_ABOVE,
      },
      {
        text: 'Chimney braced below the center of mass',
        value: values.ID_COMPONENT_CLASS_VALUES.CHIMNEY_BRACED_BELOW,
      },
      {
        text: 'Lighting fixtures',
        value: values.ID_COMPONENT_CLASS_VALUES.LIGHTING,
      },
      {
        text: 'Other mechanical or electrical components',
        value: values.ID_COMPONENT_CLASS_VALUES.MECH_OTHER,
      },
    ],
  },
  {
    label: 'Vibration Isolation',
    options: [
      {
        text: 'Neoprene isolation',
        value: values.ID_COMPONENT_CLASS_VALUES.NEOPRENE_ISOLATED,
      },
      {
        text: 'Spring isolation',
        value: values.ID_COMPONENT_CLASS_VALUES.SPRING_ISOLATED,
      },
      {
        text: 'Internal isolation',
        value: values.ID_COMPONENT_CLASS_VALUES.INTERNALLY_ISOLATED,
      },
      {
        text: 'Suspended',
        value: values.ID_COMPONENT_CLASS_VALUES.SUSPENDED_ISOLATED,
      },
    ],
  },
  {
    label: 'Distribution Systems',
    options: [
      {
        text: 'ASME B31 piping with welded/brazed joints',
        value: values.ID_COMPONENT_CLASS_VALUES.PIPING_B31_WELDED_COUPLING,
      },
      {
        text: 'ASME B31 piping with threaded (non-welded) joints',
        value: values.ID_COMPONENT_CLASS_VALUES.PIPING_B31_FLEXIBLE_COUPLING,
      },
      {
        text: 'Non-ASME B31 piping with welded/brazed joints',
        value: values.ID_COMPONENT_CLASS_VALUES.PIPING_NON_B31_WELDED_COUPLING,
      },
      {
        text: 'Non-ASME B31 piping with threaded (non-welded) joints',
        value: values.ID_COMPONENT_CLASS_VALUES.PIPING_NON_B31_FLEXIBLE_COUPLING,
      },
      {
        text: 'Low deformability piping',
        value: values.ID_COMPONENT_CLASS_VALUES.PIPING_LOW_DEFORM,
      },
      {
        text: 'Welded ductwork with high deformability materials',
        value: values.ID_COMPONENT_CLASS_VALUES.DUCT_WELDED,
      },
      {
        text: 'Non-welded ductwork with high/limited deformability materials',
        value: values.ID_COMPONENT_CLASS_VALUES.DUCT_NON_WELD,
      },
      {
        text: 'Low deformability ductwork',
        value: values.ID_COMPONENT_CLASS_VALUES.DUCT_LOW_DEFORM_MAT,
      },
      {
        text: 'Electrical conduit',
        value: values.ID_COMPONENT_CLASS_VALUES.ELEC_CONDUIT,
      },
      {
        text: 'Cable trays',
        value: values.ID_COMPONENT_CLASS_VALUES.CABLE_TRAYS,
      },
      {
        text: 'Bus ducts',
        value: values.ID_COMPONENT_CLASS_VALUES.BUS_DUCTS,
      },
      {
        text: 'Plumbing',
        value: values.ID_COMPONENT_CLASS_VALUES.PLUMBING,
      },
      {
        text: 'Manufacturing or process conveyors (nonpersonnel)',
        value: values.ID_COMPONENT_CLASS_VALUES.NONPERSONNEL_CONVEYORS,
      },
    ],
  },
];
export const CAPACITY_CALCULATION_TYPE_OPTIONS = [
  {},
  {
    text: 'Anchorage',
    value: values.CALCULATION_TYPE_VALUES.ANCHORAGE,
  },
  {
    text: 'Braced Piping',
    value: values.CALCULATION_TYPE_VALUES.BRACED_PIPING,
  },
  {
    text: 'Braced Sprinkler',
    value: values.CALCULATION_TYPE_VALUES.BRACED_SPRINKLER,
  },
  {
    text: 'Braced Sprinkler (prescriptive)',
    value: values.CALCULATION_TYPE_VALUES.BRACED_SPRINKLER_PRESCRIPTIVE,
  },
  {
    text: 'Cladding Anchorage',
    value: values.CALCULATION_TYPE_VALUES.CLADDING_ANCHORAGE,
  },
  {
    text: 'Cladding Drift',
    value: values.CALCULATION_TYPE_VALUES.CLADDING_DRIFT,
  },
  {
    text: 'Anchorage (Combined Fragility)',
    value: values.CALCULATION_TYPE_VALUES.COMBINED_ANCHORAGE,
  },
  {
    text: 'Elevator',
    value: values.CALCULATION_TYPE_VALUES.ELEVATOR,
  },
  {
    text: 'OOP Force',
    value: values.CALCULATION_TYPE_VALUES.OOP_FORCE,
  },
  {
    text: 'Pipe Bracing',
    value: values.CALCULATION_TYPE_VALUES.PIPE_BRACING,
  },
  {
    text: 'Prequalified',
    value: values.CALCULATION_TYPE_VALUES.PREQUALIFIED,
  },
  {
    text: 'Fire Sprinkler Bracing',
    value: values.CALCULATION_TYPE_VALUES.SPRINKLER_BRACING,
  },
  {
    text: 'Fire Sprinkler Bracing (prescriptive)',
    value: values.CALCULATION_TYPE_VALUES.SPRINKLER_BRACING_PRESCRIPTIVE,
  },
  {
    text: 'Stair',
    value: values.CALCULATION_TYPE_VALUES.STAIR,
  },
  {
    text: 'Taylor Damped Moment Frame',
    value: values.CALCULATION_TYPE_VALUES.TDMF,
  },
  {
    text: 'Simpson Strong-Tie Yield-Link Connection',
    value: values.CALCULATION_TYPE_VALUES.YLBF,
  },
];

export const RECOVERY_TIME_SYSTEM_OPTIONS = [
  {},
  {
    text: 'Structural',
    value: values.SYSTEM_VALUES.STRUCTURAL,
  },
  {
    text: 'Exterior',
    value: values.SYSTEM_VALUES.EXTERIOR,
  },
  {
    text: 'Interior',
    value: values.SYSTEM_VALUES.INTERIOR,
  },
  {
    text: 'Stairs',
    value: values.SYSTEM_VALUES.STAIRS,
  },
  {
    text: 'Elevators',
    value: values.SYSTEM_VALUES.ELEVATORS,
  },
  {
    text: 'Plumbing',
    value: values.SYSTEM_VALUES.PLUMBING,
  },
  {
    text: 'Electrical',
    value: values.SYSTEM_VALUES.ELECTRICAL,
  },
  {
    text: 'HVAC',
    value: values.SYSTEM_VALUES.HVAC,
  },
  {
    text: 'Fire',
    value: values.SYSTEM_VALUES.FIRE,
  },
  {
    text: 'Contents',
    value: values.SYSTEM_VALUES.CONTENTS,
  },
  {
    text: 'Data',
    value: values.SYSTEM_VALUES.DATA,
  },
];

const systemValuesToSubsystemOptions = {
  [values.SYSTEM_VALUES.STRUCTURAL]: [
    {
      text: 'Roof: Structure',
      value: values.SUBSYSTEM_VALUES.ROOF_STRUCTURE,
    },
  ],
  [values.SYSTEM_VALUES.EXTERIOR]: [
    {
      text: 'Roof: Weatherproofing',
      value: values.SUBSYSTEM_VALUES.ROOF_WEATHERPROOFING,
    },
  ],
  [values.SYSTEM_VALUES.INTERIOR]: [],
  [values.SYSTEM_VALUES.STAIRS]: [],
  [values.SYSTEM_VALUES.ELEVATORS]: [
    {
      text: 'Motor Control Center',
      value: values.SUBSYSTEM_VALUES.MOTOR_CONTROL_CENTER,
    },
    {
      text: 'Elevator Cab',
      value: values.SUBSYSTEM_VALUES.ELEVATOR_CAB,
    },
    {
      text: 'Elevator Lift Mechanism',
      value: values.SUBSYSTEM_VALUES.ELEVATOR_LIFT_MECHANISM,
    },
    {
      text: 'Elevator Guide Mechanism',
      value: values.SUBSYSTEM_VALUES.ELEVATOR_GUIDE_MECHANISM,
    },
  ],
  [values.SYSTEM_VALUES.PLUMBING]: [
    {
      text: 'Fluid Distribution: Potable',
      value: values.SUBSYSTEM_VALUES.FLUID_DISTRIBUTION_POTABLE,
    },
    {
      text: 'Fluid Distribution: Sanitary',
      value: values.SUBSYSTEM_VALUES.FLUID_DISTRIBUTION_SANITARY,
    },
  ],
  [values.SYSTEM_VALUES.ELECTRICAL]: [
    {
      text: 'General Building Equipment',
      value: values.SUBSYSTEM_VALUES.GENERAL_BUILDING_EQUIPMENT,
    },
    {
      text: 'Utility Backup Equipment',
      value: values.SUBSYSTEM_VALUES.UTILITY_BACKUP_EQUIPMENT,
    },
  ],
  [values.SYSTEM_VALUES.HVAC]: [
    {
      text: 'Air Distribution: Ducts Branches',
      value: values.SUBSYSTEM_VALUES.AIR_DISTRIBUTION_DUCTS_BRANCHES,
    },
    {
      text: 'Air Distribution: In-line Fans',
      value: values.SUBSYSTEM_VALUES.AIR_DISTRIBUTION_INLINE_FANS,
    },
    {
      text: 'Air Distribution: Drops/Diffusers',
      value: values.SUBSYSTEM_VALUES.AIR_DISTRIBUTION_DROPS_DIFFUSERS,
    },
    {
      text: 'Air Distribution: Variable Air Volume Boxes',
      value: values.SUBSYSTEM_VALUES.AIR_DISTRIBUTION_VARIABLE_AIR_VOLUME_BOXES,
    },
    {
      text: 'Fluid Distribution: Heating',
      value: values.SUBSYSTEM_VALUES.FLUID_DISTRIBUTION_HEATING,
    },
    {
      text: 'Fluid Distribution: Chilling',
      value: values.SUBSYSTEM_VALUES.FLUID_DISTRIBUTION_CHILLING,
    },
    {
      text: 'HVAC Equipment: Chillers',
      value: values.SUBSYSTEM_VALUES.HVAC_EQUIPMENT_CHILLERS,
    },
    {
      text: 'HVAC Equipment: Cooling Towers',
      value: values.SUBSYSTEM_VALUES.HVAC_EQUIPMENT_COOLING_TOWERS,
    },
    {
      text: 'HVAC Equipment: Roof Top units',
      value: values.SUBSYSTEM_VALUES.HVAC_EQUIPMENT_ROOF_TOP_UNITS,
    },
    {
      text: 'HVAC Equipment: Exhaust Fans',
      value: values.SUBSYSTEM_VALUES.HVAC_EQUIPMENT_EXHAUST_FANS,
    },
    {
      text: 'HVAC Equipment: Air Handling Units',
      value: values.SUBSYSTEM_VALUES.HVAC_EQUIPMENT_AIR_HANDLING_UNITS,
    },
    {
      text: 'HVAC Equipment: Control Panel',
      value: values.SUBSYSTEM_VALUES.HVAC_EQUIPMENT_CONTROL_PANEL,
    },
    {
      text: 'Air Distribution: Duct Mains',
      value: values.SUBSYSTEM_VALUES.AIR_DISTRIBUTION_DUCT_MAINS,
    },
  ],
  [values.SYSTEM_VALUES.FIRE]: [
    {
      text: 'Fire Sprinkler Drops',
      value: values.SUBSYSTEM_VALUES.FIRE_SPRINKLER_DROPS,
    },
  ],
  [values.SYSTEM_VALUES.CONTENTS]: [
  ],
  [values.SYSTEM_VALUES.DATA]: [
  ],
};
export const RECOVERY_TIME_SUB_SYSTEM_OPTIONS = (state) => {
  const zeroOption = {
    text: 'None',
    value: values.SUBSYSTEM_VALUES.NONE,
  };
  const { [names.SYSTEM]: value } = state.values;
  if (value) {
    const systemSpecificOptions = systemValuesToSubsystemOptions[value];
    return [
      {},
      zeroOption,
      ...systemSpecificOptions,
    ];
  }
  return Object.keys(systemValuesToSubsystemOptions).reduce(
    (acc, key) => [...acc, ...systemValuesToSubsystemOptions[key]],

    [{}, zeroOption]
  );
};

export const SERVICE_LOCATION_OPTIONS = [
  {},
  {
    text: 'Building',
    value: values.SERVICE_LOCATION_VALUES.BUILDING,
  },
  {
    text: 'Unit',
    value: values.SERVICE_LOCATION_VALUES.UNIT,
  },
];

export const STRUCTURAL_SERIES_OPTIONS = [
  {},
  {
    text: values.STRUCTURAL_SERIES_VALUES.ZERO,
    value: values.STRUCTURAL_SERIES_VALUES.ZERO,
  },
  {
    text: values.STRUCTURAL_SERIES_VALUES.ONE,
    value: values.STRUCTURAL_SERIES_VALUES.ONE,
  },
  {
    text: values.STRUCTURAL_SERIES_VALUES.TWO,
    value: values.STRUCTURAL_SERIES_VALUES.TWO,
  },
  {
    text: values.STRUCTURAL_SERIES_VALUES.THREE,
    value: values.STRUCTURAL_SERIES_VALUES.THREE,
  },
];

export const REDI_SEQUENCE_OPTIONS = [
  {},
  {
    text: 'Structure',
    value: values.REDI_SEQUENCE_VALUES.STRUCTURE,
  },
  {
    text: 'Interior',
    value: values.REDI_SEQUENCE_VALUES.INTERIOR,
  },
  {
    text: 'Exterior',
    value: values.REDI_SEQUENCE_VALUES.EXTERIOR,
  },
  {
    text: 'Mechanical',
    value: values.REDI_SEQUENCE_VALUES.MECHANICAL,
  },
  {
    text: 'Electrical',
    value: values.REDI_SEQUENCE_VALUES.ELECTRICAL,
  },
  {
    text: 'Elevator',
    value: values.REDI_SEQUENCE_VALUES.ELEVATOR,
  },
  {
    text: 'Stairs',
    value: values.REDI_SEQUENCE_VALUES.STAIRS,
  },
];

export const EGRESS_FLAG_OPTIONS = [
  {},
  {
    text: 'Ceiling',
    value: values.EGRESS_FLAG_VALUES.CEILING,
  },
  {
    text: 'Parapet',
    value: values.EGRESS_FLAG_VALUES.PARAPET,
  },
  {
    text: 'HVAC',
    value: values.EGRESS_FLAG_VALUES.HVAC,
  },
  {
    text: 'Stair',
    value: values.EGRESS_FLAG_VALUES.STAIR,
  },
  {
    text: 'Not Applicable',
    value: values.EGRESS_FLAG_VALUES.NA,
  },
];

export const CONSEQUENCE_SAFETY_CLASS_OPTIONS = [
  {},
  {
    text: 'Not Applicable',
    value: values.CONSEQUENCE_SAFETY_CLASS_VALUES.NOT_APPLICABLE,
  },
  {
    text: 'Safety Class 1',
    value: values.CONSEQUENCE_SAFETY_CLASS_VALUES.SAFETY_CLASS_1,
  },
  {
    text: 'Safety Class 2',
    value: values.CONSEQUENCE_SAFETY_CLASS_VALUES.SAFETY_CLASS_2,
  },
  {
    text: 'Safety Class 3',
    value: values.CONSEQUENCE_SAFETY_CLASS_VALUES.SAFETY_CLASS_3,
  },
];

export const CONSEQUENCE_HAZARDOUS_MATERIAL_SCOPE_OPTIONS = [
  {},
  {
    text: 'Building',
    value: values.CONSEQUENCE_HAZARDOUS_MATERIAL_SCOPE_VALUES.BUILDING,
  },
  {
    text: 'Tenant',
    value: values.CONSEQUENCE_HAZARDOUS_MATERIAL_SCOPE_VALUES.TENANT,
  },
  {
    text: 'None',
    value: values.CONSEQUENCE_HAZARDOUS_MATERIAL_SCOPE_VALUES.NONE,
  },
];

export const CONSEQUENCE_INTERIOR_AREA_CONVERSION_TYPE_OPTIONS = [
  {},
  {
    text: 'Bay',
    value: values.CONSEQUENCE_INTERIOR_AREA_CONVERSION_TYPE_VALUES.BAY,
  },
  {
    text: 'Building',
    value: values.CONSEQUENCE_INTERIOR_AREA_CONVERSION_TYPE_VALUES.BUILDING,
  },
  {
    text: 'Component',
    value: values.CONSEQUENCE_INTERIOR_AREA_CONVERSION_TYPE_VALUES.COMPONENT,
  },
];

export const CONSEQUENCE_PERMIT_TYPE_OPTIONS = [
  {},
  {
    text: 'Rapid',
    value: values.CONSEQUENCE_PERMIT_TYPE_VALUES.RAPID,
  },
  {
    text: 'Full',
    value: values.CONSEQUENCE_PERMIT_TYPE_VALUES.FULL,
  },
  {
    text: 'Not Applicable',
    value: values.CONSEQUENCE_PERMIT_TYPE_VALUES.NA,
  },
];

export const CONSEQUENCE_TEMPORARY_REPAIR_CLASS_OPTIONS = [
  {},
  {
    text: 'None',
    value: values.CONSEQUENCE_TEMPORARY_REPAIR_CLASS_VALUES.NONE,
  },
  {
    text: 'Janitorial/General',
    value: values.CONSEQUENCE_TEMPORARY_REPAIR_CLASS_VALUES.JANITORIAL_GENERAL,
  },
  {
    text: 'Basic Carpentry/Labor',
    value: values.CONSEQUENCE_TEMPORARY_REPAIR_CLASS_VALUES.BASIC_CARPENTRY_LABOR,
  },
  {
    text: 'Skilled Labor',
    value: values.CONSEQUENCE_TEMPORARY_REPAIR_CLASS_VALUES.SKILLED_LABOR,
  },
  {
    text: 'Specialized Labor and Equipment',
    value: values.CONSEQUENCE_TEMPORARY_REPAIR_CLASS_VALUES.SPECIALIZED_LABOR_AND_EQUIPMENT,
  },
  {
    text: 'Skilled Labor (Shoring)',
    value: values.CONSEQUENCE_TEMPORARY_REPAIR_CLASS_VALUES.SKILLED_LABOR_SHORING,
  },
];

export const CONSEQUENCE_REDI_REPAIR_CLASS_OPTIONS = [
  {},
  {
    text: 'Cosmetic Damage',
    value: values.CONSEQUENCE_REDI_REPAIR_CLASS_VALUES.COSMETIC_DAMAGE,
  },
  {
    text: 'Non-Structural Damage that Impairs Function',
    value: values.CONSEQUENCE_REDI_REPAIR_CLASS_VALUES.NON_STRUCTURAL_DAMAGE_THAT_IMPAIRS_FUNCTION,
  },
  {
    text: 'Heavy Damage That Poses a Risk to Life Safety',
    value: values
      .CONSEQUENCE_REDI_REPAIR_CLASS_VALUES
      .HEAVY_DAMAGE_THAT_POSES_A_RISK_TO_LIFE_SAFETY,
  },
];
