// @flow
import type { Element } from 'react';

import React from 'react';
import { Form } from 'reactstrap';
import {
  COMPONENT_ID,
  UNIT,
  NAME,
  UNIT_QUANTITY,
  SHORT_NAME,
  AUTHOR,
  DESCRIPTION,
  DATA_QUALITY,
  DATA_RELEVANCE,
  DOCUMENTATION,
  RATIONALITY,
} from '@hbrisk/sp3-risk-model-support/components/translated/attributes/names/index.js';
import {
  Col, Field, FormGroup, Fieldset,
} from '#components/pages/Components/support/form/index.js';
import { fieldsByName } from '#constants/components/form/fields/index.js';
import NextButton from '../support/buttons/NextButton.jsx';

type Props = {
  onNext: () => {},
  readOnly?: boolean,
};

const General = ({
  onNext, readOnly,
}: Props): Element<'div'> => (
  <Form noValidate>
    <Fieldset>
      <legend>General</legend>
      <FormGroup fields={[COMPONENT_ID, UNIT]} row>
        <Col lg="6" field={COMPONENT_ID}>
          <Field field={fieldsByName[COMPONENT_ID]} readOnly={readOnly} />
        </Col>
        <Col lg="6">
          <Field field={fieldsByName[UNIT]} readOnly={readOnly} />
        </Col>
      </FormGroup>
      <FormGroup fields={[NAME, UNIT_QUANTITY]} row>
        <Col lg="6">
          <Field field={fieldsByName[NAME]} readOnly={readOnly} />
        </Col>
        <Col lg="6">
          <Field field={fieldsByName[UNIT_QUANTITY]} readOnly={readOnly} />
        </Col>
      </FormGroup>
      <FormGroup fields={[SHORT_NAME, AUTHOR]} row>
        <Col lg="6">
          <Field field={fieldsByName[SHORT_NAME]} readOnly={readOnly} />
        </Col>
        <Col lg="6">
          <Field field={fieldsByName[AUTHOR]} readOnly={readOnly} />
        </Col>
      </FormGroup>
      <FormGroup fields={[DESCRIPTION]}>
        <Field field={fieldsByName[DESCRIPTION]} readOnly={readOnly} />
      </FormGroup>
    </Fieldset>
    <Fieldset>
      <legend>Ratings</legend>
      <FormGroup fields={[DATA_QUALITY]} row>
        <Col lg="6">
          <Field field={fieldsByName[DATA_QUALITY]} readOnly={readOnly} />
        </Col>
      </FormGroup>
      <FormGroup fields={[DATA_RELEVANCE]} row>
        <Col lg="6">
          <Field field={fieldsByName[DATA_RELEVANCE]} readOnly={readOnly} />
        </Col>
      </FormGroup>
      <FormGroup fields={[DOCUMENTATION]} row>
        <Col lg="6">
          <Field field={fieldsByName[DOCUMENTATION]} readOnly={readOnly} />
        </Col>
      </FormGroup>
      <FormGroup fields={[RATIONALITY]} row>
        <Col lg="6">
          <Field field={fieldsByName[RATIONALITY]} readOnly={readOnly} />
        </Col>
      </FormGroup>
    </Fieldset>
    <NextButton onClick={onNext} />
  </Form>
);

General.defaultProps = {
  readOnly: false,
};

export default General;
