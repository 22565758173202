/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import classNames from 'classnames';
import React from 'react';
import BaseMap from './BaseMap/index.jsx';
import { map } from './SiteMap.module.scss';

type Props = {|
  handleMarkerDragEnd: Function,
  markerPosition: {
    lat: number,
    lng: number,
  },
|};

const SiteMap = ({
  markerPosition, handleMarkerDragEnd,
}: Props): Element<typeof BaseMap> => (
  <BaseMap
    center={markerPosition}
    markerProps={{
      position: markerPosition,
      onDragEnd: handleMarkerDragEnd,
      draggable: true,
    }}
    wrapperClassName={classNames(map, 'site-map')}
    wrapperHeight="350px"
  />
);

export default SiteMap;
