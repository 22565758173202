/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { ActionRow, ActionRowButton } from '#components/support/layout/ActionRow/index.jsx';
import { CREATE } from '#constants/pathStrings.js';
import ViewOrganizationsTable from './ViewOrganizationsTable/index.js';

type Props = {|
  poll: Function,
|};

const ViewOrganizations = ({ poll }: Props): Element<'div'> => {
  useEffect(poll);

  return (
    <div>
      <Row>
        <Col md="12">
          <h1>Organizations</h1>
        </Col>
      </Row>
      <ActionRow>
        <Col md="12" className="col">
          <ActionRowButton
            className="float-right create-organization-button"
            tag={Link}
            to={`${CREATE}`}
          >
            Create Organization +
          </ActionRowButton>
        </Col>
      </ActionRow>
      <Row>
        <Col md="12">
          <ViewOrganizationsTable />
        </Col>
      </Row>
    </div>
  );
};

export default ViewOrganizations;
