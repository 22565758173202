/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import StructuralResponsesTable from './StructuralResponsesTable.jsx';

const mapStateToProps = (state, ownProps) => {
  const { selectEdps } = modelTypesByForm[ownProps.form];
  return ({
    data: selectEdps(state),
  });
};

const ModelsTableContainer = connect(mapStateToProps)(StructuralResponsesTable);

export default ModelsTableContainer;
