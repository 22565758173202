/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { modelTypesById } from '#src/js/constants/models/modelTypes/index.js';
import SubmitButton from '#components/support/buttons/SubmitButton/index.jsx';

type Props = {|
  className: string,
  disabled?: boolean,
  modelType: number,
  onClick?: Function,
  submitting: boolean
|};

const ModelFormSubmitButton = ({
  className, disabled, modelType, onClick, submitting,
}: Props): Element<typeof SubmitButton> => {
  const { runButtonText, runButtonRunningText } = modelTypesById[modelType];
  return (
    <SubmitButton
      className={className}
      disabled={disabled}
      submitting={submitting}
      submittingText={runButtonRunningText}
      onClick={onClick}
    >
      {runButtonText}
    </SubmitButton>
  );
};

ModelFormSubmitButton.defaultProps = {
  disabled: false,
  onClick: () => {},
};

export default ModelFormSubmitButton;
