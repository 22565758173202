import get from 'lodash/fp/get.js';
import defaultFieldLabelFromName
  from '#support/components/form/defaultFieldLabelFromName.js';
import { COMPONENT_FORM } from '#constants/components/form/name.js';

const addFieldDefaults = (fieldsWithoutDefaults) => fieldsWithoutDefaults.map((field) => ({
  ...field,
  label: field.label || defaultFieldLabelFromName(field.name),
  form: COMPONENT_FORM,
  initialize: field.initialize || get(field.name),
  initialize2: field.initialize2 || get(field.name),
}));

export default addFieldDefaults;
