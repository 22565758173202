/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import get from 'lodash/fp/get.js';
import getOr from 'lodash/fp/getOr.js';
import groupBy from 'lodash/fp/groupBy.js';
import kebabCase from 'lodash/fp/kebabCase.js';
import pick from 'lodash/fp/pick.js';
import { map, pipe, values } from 'ramda';
import { getFormSyncErrors } from 'redux-form';
import { createSelector } from 'reselect';
import { underscoreErrorRegex } from '#constants/regularExpressions.js';
import { selectInvisibleFields, selectTouchedFields } from '#selectors/ui/form/model.js';
import getFieldBaseName from '#support/utility/form/getFieldBaseName.js';
import getFieldsFlattenedWithErrors from '#src/js/support/utility/form/validation/getFieldsFlattenedWithErrors/index.js';

const pathBaseFromSection = (section) => {
  const { id, group } = pick(['id', 'group'], section);
  return id ? `${kebabCase(group)}/${kebabCase(id)}` : undefined;
};

// Alias to make usage clearer
const urlFromSection = pathBaseFromSection;

const pathFromSection = (section) => {
  const hasSubsections = get('hasSubsections', section);
  const base = pathBaseFromSection(section);
  if (!base) return undefined;
  return hasSubsections
    ? `${base}/*`
    : base;
};

export const selectInitialized = (type) => (state) => getOr(false, `ui.modelPage.type${type}.initialized`, state);

export const selectActiveSectionGroupIds = (type) => (state) => getOr({}, `ui.modelPage.type${type}.activeSectionGroupIds`, state);

export const selectActiveSectionIds = (type) => (state) => getOr({}, `ui.modelPage.type${type}.activeSectionIds`, state);

export const selectActiveSectionGroups = (type, groups) => createSelector(
  selectActiveSectionGroupIds(type),
  (ids) => groups.filter((group) => !!ids[group.id])
);

export const selectActiveSectionsInActiveGroups = (type, sections) => createSelector(
  selectActiveSectionIds(type),
  selectActiveSectionGroupIds(type),
  (sectionIds, groupIds) => sections
    .filter((section) => !!sectionIds[section.id] && !!groupIds[section.group])
    .map((section) => ({
      ...section,
      path: pathFromSection(section),
      url: urlFromSection(section),
    }))
);

const calculateErrorCountBySection = (formErrors, touched, fieldsByName, invisibleFields) => pipe(
  (errors) => getFieldsFlattenedWithErrors(errors),
  (errors) => Object.keys(errors).reduce((acc, errorPath) => {
    const firstField = getFieldBaseName(errorPath);
    const shouldIgnoreField = (
      (
        invisibleFields.includes(errorPath)
        || get(errorPath, touched) === undefined
      )
      && !underscoreErrorRegex.test(errorPath)
    );

    if (shouldIgnoreField) {
      return acc;
    }

    const { section } = fieldsByName[firstField];
    return {
      ...acc,
      [section]: getOr(0, section, acc) + errors[errorPath],
    };
  }, {}),
)(formErrors);

export const selectErrorCountBySection = (fieldsByName, modelFormName) => createSelector(
  getFormSyncErrors(modelFormName),
  selectTouchedFields(modelFormName, values(fieldsByName)),
  selectInvisibleFields(modelFormName),
  (errors, touched, invisible) => calculateErrorCountBySection(
    errors,
    touched,
    fieldsByName,
    invisible
  )
);

const calculateActiveSectionsWithErrorCountsByGroup = (
  activeSectionsInActiveGroups,
  errorCountBySection
) => pipe(
  map((section) => ({ ...section, errors: errorCountBySection[section.id] })),
  groupBy('group'),
)(activeSectionsInActiveGroups);

export const selectActiveSectionsWithErrorCountsByGroup = (
  fieldsByName,
  modelFormName,
  modelType,
  sections
) => createSelector(
  selectActiveSectionsInActiveGroups(modelType, sections),
  selectErrorCountBySection(fieldsByName, modelFormName),
  calculateActiveSectionsWithErrorCountsByGroup,
);

const calculatePageHeadingText = (modelName, modelTypeName) => (modelName ? `${modelTypeName} - ${modelName}` : `New ${modelTypeName} Model`);

export const selectModelPageHeadingText = (form, modelTypeName) => createSelector(
  (state) => get(`form[${form}].values.modelName`, state),
  (modelName) => calculatePageHeadingText(modelName, modelTypeName),
);

export const selectModelTypeName = (form, modelTypeName) => createSelector(
  (state) => get(`form[${form}].values.modelName`, state),
  (modelName) => calculatePageHeadingText(modelName, modelTypeName),
);
