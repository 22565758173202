// extracted by mini-css-extract-plugin
export var disabled = "eSFo1SltWAdTHkJ96mYE";
export var fixedHeader = "orZcHNLROSXWgU_qNAKH";
export var highlight = "iZse4CiIwONmR0brNaqd";
export var modelsTable = "NEDGGFD2ZSRBNRlau12U";
export var modelsTableTd = "iqzgeEE1UJ_e7X33JAq8";
export var modelsTableTh = "WSu6NbBDnT3bX6enkaQN";
export var primary = "#067370";
export var td = "uc7ADauDjNRYzlVWD9fR";
export var th = "zL0BICEjRLR4HVA6xEJO";
export var wrap = "lOW3Cq0IlwarICdTDlT2";