/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element, Node } from 'react';

import React from 'react';
import { Button, Spinner } from 'reactstrap';
import { spinner } from './index.module.scss';

type Props = {
  children?: Node,
  className?: string,
  disabled?: boolean,
  onClick?: Function,
  submitting?: boolean,
  submittingText?: string,
  type?: string
}

const SubmitButton = ({
  children, className, disabled, onClick, submitting, submittingText, type,
}: Props): Element<typeof Button> => (
  submitting
    ? (
      <Button className={className} color="primary" type={type} disabled>
        <Spinner size="sm" className={spinner} />
        {' '}
        {submittingText}
      </Button>
    )
    : (
      <Button
        disabled={disabled}
        className={className}
        color="primary"
        onClick={onClick}
        type={type}
      >
        {children}
      </Button>
    )
);

SubmitButton.defaultProps = {
  children: 'Submit',
  className: '',
  disabled: false,
  onClick: () => {},
  submitting: false,
  submittingText: 'Submitting...',
  type: 'submit',
};

export default SubmitButton;
