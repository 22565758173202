import curry from 'lodash/fp/curry.js';
import isEmpty from 'lodash/fp/isEmpty.js';
import validator from 'validator';

const {
  isInt, isFloat, isEmail, isLength,
} = validator;
export const makeRequired = (message) => (val) => (!isEmpty(val) ? undefined : message);

export const required = makeRequired('Required');

export const requiredIfNotDefaulted = makeRequired('Required if not defaulted');

export const emptyOrEmail = (val) => (
  isEmpty(val) || isEmail(`${val}`)
    ? undefined
    : 'Must be a valid email'
);

const emptyOrMaxLength = curry((max, val) => (
  isLength(`${val}`, { max }) ? undefined : `Must be less than ${max} characters`
));

const emptyOrMinLength = curry((min, val) => (
  isLength(`${val}`, { min }) ? undefined : `Must be at least ${min} characters`
));

export const emptyOrMaxLength100 = emptyOrMaxLength(100);

export const emptyOrMaxLength300 = emptyOrMaxLength(300);

export const emptyOrMaxLength1000 = emptyOrMaxLength(1000);

export const emptyOrMinLength8 = emptyOrMinLength(8);

const commaSeparatedRegEx = /^[^,]+(,[^,]*)*$/;
export const commaSeparatedList = (message) => (val) => {
  const res = commaSeparatedRegEx.test(val.replace(/\s/g, ''));
  return res ? undefined : message;
};

export const noTrailingComma = (val) => {
  if (!val) {
    return undefined;
  }
  return val.replace(/\s/g, '').endsWith(',') ? 'Must not end with a comma' : undefined;
};

const emptyOrIntMin = curry((min, val) => (
  isEmpty(val) || isInt(val, { min }) ? undefined : `Must be an integer greater than ${min}`
));

export const emptyOrNonNegativeInt = emptyOrIntMin(0);

const emptyOrFloatMinMax = curry((min, max, val) => {
  if (isEmpty(val) || isFloat(`${val}`, { min, max })) {
    return false;
  }
  return `Must be a number between ${min} and ${max}`;
});

const emptyOrFloatGtMinLeMax = curry((min, max, val) => {
  if (isEmpty(val) || isFloat(`${val}`, { gt: min, max })) {
    return false;
  }
  return `Must be a number greater than ${min} and less than or equal to ${max}`;
});

export const emptyOrGt0Le2 = emptyOrFloatGtMinLeMax(0, 2);

export const emptyOr0to1 = emptyOrFloatMinMax(0, 1);

export const emptyOrAlmost0toAlmost100 = emptyOrFloatMinMax(0.001, 99.999);

export const emptyOr0to10 = emptyOrFloatMinMax(0.0001, 10);

export const emptyOrAlmost0to25 = emptyOrFloatMinMax(0.0001, 25);

export const emptyOr0to100 = emptyOrFloatMinMax(0, 100);

export const emptyOr2to100 = emptyOrFloatMinMax(2, 100);

export const emptyOr1to10 = emptyOrFloatMinMax(1, 10);

export const emptyOr1to100 = emptyOrFloatMinMax(1, 100);

export const emptyOr1to100k = emptyOrFloatMinMax(1, 10e5);

export const emptyOr10to10M = emptyOrFloatMinMax(10, 10e7);

export const emptyOr100to10M = emptyOrFloatMinMax(100, 10e7);

export const emptyOr0to100k = emptyOrFloatMinMax(0, 10e5);

export const emptyOrp1to10 = emptyOrFloatMinMax(0.1, 10);

export const emptyOrp01to20 = emptyOrFloatMinMax(0.01, 20);

export const emptyOrp01to100 = emptyOrFloatMinMax(0.01, 100);

export const emptyOr1to10k = emptyOrFloatMinMax(1, 10000);

export const emptyOrp05to2 = emptyOrFloatMinMax(0.05, 2.0);

export const emptyOrp01to10 = emptyOrFloatMinMax(0.01, 10);

export const emptyOrp001to1K = emptyOrFloatMinMax(0.001, 1000);

export const emptyOrp001to1M = emptyOrFloatMinMax(0.001, 1e6);

export const emptyOr0to1M = emptyOrFloatMinMax(0, 1e6);

export const emptyOrp01to3 = emptyOrFloatMinMax(0.01, 3);

const specialCharacterRegex = /[=+\-^$*[\]{}()?!@#&%|_~]/;
export const hasSpecialCharacters = (value) => (
  (value === undefined || specialCharacterRegex.test(value))
    ? undefined
    : 'Must include at least one of these special characters: = + - ^ $ * [ ] { } ( ) ? ! @ # & % | _ ~'
);

const lowerCaseRegex = /[a-z]/;

export const hasLowerCaseLetters = (value) => (
  lowerCaseRegex.test(value)
    ? undefined
    : 'Must include a lower case letter'
);

export const sameAsNewPassword = (value, allValues) => {
  const { newPassword } = allValues;
  if ([newPassword, value].includes(undefined)) {
    return undefined;
  }
  return value === newPassword ? undefined : 'Must be the same as new password';
};

export const emptyOrGreaterThanStart = (startField) => (value, allValues) => {
  const startFieldStr = allValues[startField];
  const startValue = Date.parse(startFieldStr);
  const thisValue = Date.parse(value);
  return isEmpty(startFieldStr)
  || isEmpty(value)
  || startValue <= thisValue
    ? undefined
    : 'Must be greater than start date';
};
