/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Component, Element, Node } from 'react';

import classNames from 'classnames';
import React from 'react';
import { Button, Row } from 'reactstrap';
import { actionRow, actionRowButton } from './index.module.scss';

type ActionRowProps = {|
  children: Node,
  className?: string,
|}

export const ActionRow = ({
  children,
  className,
}: ActionRowProps): Element<typeof Row> => (
  <Row className={classNames(actionRow, className)}>
    {children}
  </Row>
);

ActionRow.defaultProps = {
  className: '',
};

type ActionRowLinkProps = {|
   children: Node,
   className?: string,
   onClick?: Function,
   tag?: Component<any> | string,
   to?: string,
|};

export const ActionRowButton = ({
  children,
  className,
  onClick,
  tag,
  to,
}: ActionRowLinkProps): Element<typeof Button> => (
  <Button
    className={classNames(actionRowButton, className)}
    color="link"
    onClick={onClick}
    tag={tag}
    to={to}
  >
    {children}
  </Button>
);

ActionRowButton.defaultProps = {
  className: '',
  onClick: undefined,
  tag: undefined,
  to: undefined,
};
