/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Node } from 'react';

import getOr from 'lodash/fp/getOr.js';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Form, FormGroup, Col } from 'reactstrap';
import SubmitButton from '#components/support/buttons/SubmitButton/index.jsx';
import NewPasswordField from '#components/support/forms/NewPasswordField/index.jsx';
import ConfirmPasswordField from '#components/support/forms/ConfirmPasswordField/index.jsx';
import { MODELS } from '#constants/pathStrings.js';

type Props = {|
error?: string,
  form: string,
  handleSubmit: Function,
  submitting: boolean,
  tokenIsSet: boolean,
  orgIsSet: boolean,
|}

const CreatePasswordForm = (props: Props): Node => {
  const {
    error, form, handleSubmit, submitting, tokenIsSet, orgIsSet,
  } = props;
  const location = useLocation();
  if (tokenIsSet && orgIsSet) {
    return (
      <Navigate to={{ pathname: getOr(`/${MODELS}`, 'state.from', location) }} />
    );
  }
  const alert = error ? <div className="alert alert-danger">{error}</div> : null;
  return (
    <Form className="create-password-form" onSubmit={handleSubmit}>
      {alert}
      <FormGroup row>
        <Col lg="6">
          <NewPasswordField form={form} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col lg="6">
          <ConfirmPasswordField form={form} />
        </Col>
      </FormGroup>
      <SubmitButton color="primary" type="submit" formNoValidate submitting={submitting}>
        Submit
      </SubmitButton>
    </Form>
  );
};

CreatePasswordForm.defaultProps = {
  error: '',
};

export default CreatePasswordForm;
