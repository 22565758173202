/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import { connect } from 'react-redux';
import { fetchOrgs } from '#actions/organizations/organizations.js';
import ViewOrganizations from './ViewOrganizations.jsx';

const mapDispatchToProps = (dispatch) => ({
  poll: () => {
    const POLLING_INTERVAL = 5000;
    dispatch(fetchOrgs);
    const pollingId = setInterval(() => {
      dispatch(fetchOrgs);
    }, POLLING_INTERVAL);

    return () => {
      clearInterval(pollingId);
    };
  },
});

const ViewOrganizationsContainer = connect(undefined, mapDispatchToProps)(ViewOrganizations);

export default ViewOrganizationsContainer;
