/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { ATC_138_ID } from '@hbrisk/sp3-risk-model-support/models/repairTimeMethods.js';
import { DAMAGE_CORRELATION_MODEL_ATC_138_ID } from '#constants/models/damageCorrelationModels.js';
import {
  emptyOr0to1,
  emptyOr0to10,
  emptyOr0to100,
  emptyOr100to10M,
  emptyOr1to10,
  emptyOr1to100,
  emptyOr1to100k,
  emptyOr1to10k,
  emptyOr2to100,
  emptyOrAlmost0to25,
  emptyOrAlmost0toAlmost100,
  emptyOrGt0Le2,
  emptyOrMaxLength100,
  emptyOrMaxLength1000,
  emptyOrMaxLength300,
  emptyOrNonNegativeInt,
  emptyOrp001to1K,
  emptyOrp01to10,
  emptyOrp01to100,
  emptyOrp01to20,
  emptyOrp01to3,
  emptyOrp05to2,
  emptyOrp1to10,
  noTrailingComma,
  required,
  requiredIfNotDefaulted,
} from '#support/utility/form/validation/fieldValidators/index.js';
import {
  allIReturnPeriodsAreIntegersBetween1And99999,
  buildingTypeByDir,
  canUploadModeShape,
  commaSeparatedListOfReturnPeriods,
  countryAndCoordsMatchUSorJapan,
  emptyOrInt1ToNumberOfStories,
  emptyOrMaxRealizationsByStory,
  greaterThanMinCodeYear,
  greaterThanMinYearOfConstruction,
  hasExpectedModeShapeFloors,
  hasExpectedModeShapeModes,
  maxStoriesForBuildingType,
  mceGreaterThanDE,
  mustHaveAtLeast2ReturnPeriods,
  mustHaveFewerThan20ReturnPeriods,
  noBackendGroundMotionUploadErrors,
  noBackendModeShapeUploadErrors,
  noDuplicateReturnPeriods,
  period2,
  period3,
  returnPeriodsInclude475,
  validateComponentPopulationFieldArray,
  validateFloorPlanByStoryFieldArray,
  validateStructuralResponsesFieldArray,
} from '#support/models/form/modelForm/validators/index.js';
import getComponentQuestionFields from '#support/models/getComponentQuestionFields.js';
import getFromOutputs from '#support/models/getFromOutputs.js';

import {
  COLLAPSE_METHOD_FEMA,
} from '#constants/models/collapseDefinitionMethods.js';
import { COMPONENT_SELECTION_METHOD_AUTOMATIC } from '#constants/models/componentSelectionMethods.js';
import {
  AND,
  atLeastOneBuildingTypeIsEmptyOrHasFEMATypeIn,
  atLeastOneBuildingTypeIsWoodLightFrame,
  atLeastOneBuildingTypeIsRWFD,
  atLeastOneBuildingTypeIsLightFrameOrRWFD,
  clearBuildingTypeIfInconsistentWithCountry,
  clearOnCountryChange,
  clearServiceLocationsIfRepairTimeMethodIsREDI,
  collapseDefinitionMethodIsEmptyOrFEMA,
  collapseDefinitionMethodIsMedian,
  componentPopulationMethodIsAutomatic,
  componentPopulationMethodIsCustom,
  countryIsJapan,
  countryIsUS,
  countryIsUSOrJapan,
  everyBuildingTypeIsWoodLightFrame,
  atLeastOneBuildingTypeIsEmptyOrConcreteShearWall,
  atLeastOneBuildingTypeIsEmptyOrMasonryShearWall,
  includeRepairTimeIsTrue,
  includeSurgeDemandIsYes,
  isWoodLightFrame,
  isSimpsonYieldLinkBraceFrame,
  isTaylorDampedMomentFrame,
  isLightFrameOrRWFD,
  isEmptyOrRWFD,
  isBRB,
  isInfilled,
  groundMotionMethodSupportsUpload,
  medianIfCountryIsJapan,
  needElevatorForFunctionIsYes,
  NOT,
  OR,
  repairTimeMethodIsATC138,
  modeShapeMethodIsUpload,
  isWoodOrSteelLightFrame,
  numberOfStoriesIsOne,
  numberOfStoriesIsOneOR2,
  collapseDefinitionMethodIsMCE,
  includeDesignIntensitiesIsTrue,
  isGreaterThanOrEqualToOne,
  everyBuildingTypeIsLightFrameOrRWFD,
  designCodeYearVisibilityReducer,
  structuralResponsesValueReducer,
  yearOfConstructionVisibilityReducer,
  useSmartEquipmentComponentsIsYes,
  prequalifiedDesignCodeYearIsOverride,
  equipmentPrequalifiedIsYes,
} from '../support/fieldLevelReducers/index.js';
import {
  ADDITIONAL_NOTES_SECTION,
  ANALYSIS_OPTIONS_SECTION,
  BUILDING_COMPONENTS_TABLE_SECTION,
  BUILDING_DESIGN_SECTION,
  BUILDING_LAYOUT_SECTION,
  BUILDING_MODIFIERS_CHECKLIST_SECTION,
  BUILDING_STABILITY_SECTION,
  COMPONENT_CHECKLIST_SECTION,
  GROUND_MOTION_SECTION,
  MODEL_AND_SITE_INFO_SECTION,
  PRIMARY_BUILDING_INFO_SECTION,
  REPAIR_TIME_OPTIONS_SECTION,
  SITE_COORDINATES_SECTION,
  STRUCTURAL_PROPERTIES_SECTION,
  STRUCTURAL_RESPONSES_SECTION,
} from '../../../modelPage/sectionIds.js';
import structuralResponsesDefaultValueArray from '../support/structuralResponsesDefaultValueArray.js';

const fields = [
  ...getComponentQuestionFields(),
  {
    name: 'allowComponentsToAffectStructuralProperties',
    defaultValue: false,
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        atLeastOneBuildingTypeIsWoodLightFrame,
        componentPopulationMethodIsCustom
      ),
    },
    section: STRUCTURAL_PROPERTIES_SECTION,
  },
  {
    name: 'allowFireWatchToBeSufficient',
    section: REPAIR_TIME_OPTIONS_SECTION,
    autofiller: getFromOutputs('intermediate.allowFireWatchToBeSufficient'),
    validation: [requiredIfNotDefaulted],
    reducers: {
      visible: AND(includeRepairTimeIsTrue, repairTimeMethodIsATC138),
    },
  },
  {
    name: 'allowTemporaryRepairs',
    section: REPAIR_TIME_OPTIONS_SECTION,
    autofiller: getFromOutputs('intermediate.allowTemporaryRepairs'),
    validation: [requiredIfNotDefaulted],
    reducers: {
      visible: AND(includeRepairTimeIsTrue, repairTimeMethodIsATC138),
    },
  },
  {
    name: 'allowTemporaryShoring',
    section: REPAIR_TIME_OPTIONS_SECTION,
    autofiller: getFromOutputs('intermediate.allowTemporaryShoring'),
    validation: [requiredIfNotDefaulted],
    reducers: {
      visible: AND(includeRepairTimeIsTrue, repairTimeMethodIsATC138),
    },
  },
  {
    name: 'baseShearStrengthDir1',
    validation: [requiredIfNotDefaulted, emptyOrAlmost0to25],
    section: STRUCTURAL_PROPERTIES_SECTION,
    autofiller: getFromOutputs('intermediate.baseShearStrengthDir1'),
  },
  {
    name: 'baseShearStrengthDir2',
    validation: [requiredIfNotDefaulted, emptyOrAlmost0to25],
    section: STRUCTURAL_PROPERTIES_SECTION,
    autofiller: getFromOutputs('intermediate.baseShearStrengthDir2'),
  },
  {
    name: 'brbToDiaphragmRatioDir1',
    validation: [requiredIfNotDefaulted, emptyOrGt0Le2],
    section: STRUCTURAL_PROPERTIES_SECTION,
    autofiller: getFromOutputs('intermediate.brbToDiaphragmRatioDir1'),
    reducers: {
      visible: AND(
        isEmptyOrRWFD('buildingTypeDir1'),
        isBRB('typeOfResistanceDir1'),
      ),
    },
  },
  {
    name: 'brbToDiaphragmRatioDir2',
    validation: [requiredIfNotDefaulted, emptyOrGt0Le2],
    section: STRUCTURAL_PROPERTIES_SECTION,
    autofiller: getFromOutputs('intermediate.brbToDiaphragmRatioDir2'),
    reducers: {
      visible: AND(
        isEmptyOrRWFD('buildingTypeDir2'),
        isBRB('typeOfResistanceDir2'),
      ),
    },
  },
  {
    name: 'buildingAspectRatio',
    section: BUILDING_LAYOUT_SECTION,
    validation: [requiredIfNotDefaulted, emptyOrp1to10],
    autofiller: getFromOutputs('intermediate.buildingAspectRatio'),
  },
  {
    name: 'buildingModsColumnPierSpandrelRatio',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        atLeastOneBuildingTypeIsEmptyOrHasFEMATypeIn([
          'C1', 'C2', 'C3', 'PC1', 'PC2', 'RM1', 'RM2',
        ])
      ),
    },
  },
  {
    name: 'buildingModsColumnRatioLessThanTypical',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        atLeastOneBuildingTypeIsEmptyOrHasFEMATypeIn([
          'C1', 'C2', 'C3', 'PC1', 'PC2', 'RM1', 'RM2',
        ])
      ),
    },
  },
  {
    name: 'buildingModsConstructionQuality',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'buildingModsCrossGrainOopConn',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        atLeastOneBuildingTypeIsEmptyOrHasFEMATypeIn(['PC1', 'RM1'])
      ),
    },
  },
  {
    name: 'buildingModsDiaphramOpen',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'buildingModsFlatPlateAsBeam',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        atLeastOneBuildingTypeIsEmptyOrHasFEMATypeIn(['C1'])
      ),
    },
  },
  {
    name: 'buildingModsFullStoryGrade',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        atLeastOneBuildingTypeIsEmptyOrHasFEMATypeIn([
          'W2', 'S1', 'S2', 'S3', 'S4', 'S5',
          'C1', 'C2', 'C3',
          'PC1', 'PC2', 'RM1', 'RM2',
        ])
      ),
    },
  },
  {
    name: 'buildingModsFullStoryGradeWLF',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        atLeastOneBuildingTypeIsEmptyOrHasFEMATypeIn(['W1'])
      ),
    },
  },
  {
    name: 'buildingModsInteriorWallDensity',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        atLeastOneBuildingTypeIsEmptyOrHasFEMATypeIn(['PC1', 'RM1'])
      ),
    },
  },
  {
    name: 'buildingModsKBracing',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        atLeastOneBuildingTypeIsEmptyOrHasFEMATypeIn(['S2'])
      ),
    },
  },
  {
    name: 'buildingModsMaterialDeterioration',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'buildingModsNonParallel',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'buildingModsNonStructuralConstructionQuality',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'buildingModsOopOffset',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        atLeastOneBuildingTypeIsEmptyOrHasFEMATypeIn(['C1', 'C2'])
      ),
    },
  },
  {
    name: 'buildingModsOtherPlanIrregularity',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'buildingModsOtherVerticalIrregularity',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'buildingModsRedundancy',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'buildingModsReentrant',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'buildingModsSetbackInboard',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'buildingModsSetbackInPlaneLfrs',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'buildingModsSetbackOutboard',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'buildingModsSplitLevel',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'buildingReplacementTime',
    section: REPAIR_TIME_OPTIONS_SECTION,
    validation: [requiredIfNotDefaulted, emptyOr1to10k],
    autofiller: getFromOutputs('intermediate.buildingReplacementTime'),
    reducers: {
      visible: includeRepairTimeIsTrue,
    },
  },
  {
    name: 'buildingSquareFootage',
    section: BUILDING_LAYOUT_SECTION,
    validation: [requiredIfNotDefaulted, emptyOr100to10M],
    autofiller: getFromOutputs('intermediate.buildingSquareFootage'),
  },
  {
    name: 'buildingTypeDir1',
    section: PRIMARY_BUILDING_INFO_SECTION,
    validation: required,
    reducers: {
      value: clearBuildingTypeIfInconsistentWithCountry('buildingTypeDir1'),
    },
  },
  {
    name: 'buildingTypeDir2',
    section: PRIMARY_BUILDING_INFO_SECTION,
    validation: [required, buildingTypeByDir('buildingTypeDir1')],
    reducers: {
      value: clearBuildingTypeIfInconsistentWithCountry('buildingTypeDir2'),
    },
  },
  {
    name: 'cashOnHand',
    section: REPAIR_TIME_OPTIONS_SECTION,
    autofiller: () => 2,
    validation: [requiredIfNotDefaulted, emptyOr0to100],
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138
      ),
    },
  },
  {
    name: 'city',
    section: MODEL_AND_SITE_INFO_SECTION,
    reducers: {
      value: clearOnCountryChange('city'),
    },
  },
  {
    name: 'collapseCapacityBeta',
    validation: [requiredIfNotDefaulted, emptyOrp01to10],
    section: BUILDING_STABILITY_SECTION,
    autofiller: getFromOutputs('intermediate.collapseCapacityBeta'),
  },
  {
    name: 'collapseCapacityMedian',
    validation: [requiredIfNotDefaulted, emptyOrp001to1K],
    section: BUILDING_STABILITY_SECTION,
    autofiller: getFromOutputs('intermediate.collapseCapacityMedian'),
    reducers: {
      visible: collapseDefinitionMethodIsMedian,
    },
  },
  {
    name: 'collapseDefinitionMethod',
    defaultValue: COLLAPSE_METHOD_FEMA,
    validation: [required],
    section: BUILDING_STABILITY_SECTION,
    reducers: {
      value: medianIfCountryIsJapan('collapseDefinitionMethod'),
    },
  },
  {
    name: 'componentImportanceFactor',
    section: BUILDING_DESIGN_SECTION,
    validation: [requiredIfNotDefaulted, emptyOr1to10],
    autofiller: getFromOutputs('intermediate.componentImportanceFactor'),
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'componentPopulationMethod',
    defaultValue: COMPONENT_SELECTION_METHOD_AUTOMATIC,
    section: BUILDING_COMPONENTS_TABLE_SECTION,
  },
  {
    name: 'concreteShearWallsLoadBearing',
    section: COMPONENT_CHECKLIST_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138,
        atLeastOneBuildingTypeIsEmptyOrConcreteShearWall,
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'costPerSqFt',
    section: BUILDING_LAYOUT_SECTION,
    validation: [requiredIfNotDefaulted, emptyOr1to100k],
    autofiller: getFromOutputs('intermediate.costPerSqFt'),
  },
  {
    name: 'country',
    defaultValue: 'US',
    section: MODEL_AND_SITE_INFO_SECTION,
  },
  {
    name: 'damageCorrelationModel',
    defaultValue: DAMAGE_CORRELATION_MODEL_ATC_138_ID,
    section: ANALYSIS_OPTIONS_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138
      ),
    },
  },
  {
    name: 'dateCostMultiplier',
    section: ANALYSIS_OPTIONS_SECTION,
    validation: [requiredIfNotDefaulted, emptyOrp01to20],
    autofiller: getFromOutputs('intermediate.dateCostMultiplier'),
  },
  {
    name: 'designCodeYear',
    defaultVisible: false,
    validation: [required, greaterThanMinCodeYear],
    section: PRIMARY_BUILDING_INFO_SECTION,
    reducers: {
      visible: designCodeYearVisibilityReducer,
    },
  },
  {
    name: 'designPeriod1Dir1',
    validation: [requiredIfNotDefaulted, emptyOrAlmost0to25],
    section: STRUCTURAL_PROPERTIES_SECTION,
    autofiller: getFromOutputs('intermediate.designPeriod1Dir1'),
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        NOT(isLightFrameOrRWFD('buildingTypeDir1')),
      ),
    },
  },
  {
    name: 'designPeriod1Dir2',
    validation: [requiredIfNotDefaulted, emptyOrAlmost0to25],
    section: STRUCTURAL_PROPERTIES_SECTION,
    autofiller: getFromOutputs('intermediate.designPeriod1Dir2'),
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        NOT(isLightFrameOrRWFD('buildingTypeDir2')),
      ),
    },
  },
  {
    name: 'detailLevelDir1',
    section: BUILDING_DESIGN_SECTION,
    validation: requiredIfNotDefaulted,
    autofiller: getFromOutputs('intermediate.detailLevelDir1'),
  },
  {
    name: 'detailLevelDir2',
    section: BUILDING_DESIGN_SECTION,
    validation: requiredIfNotDefaulted,
    autofiller: getFromOutputs('intermediate.detailLevelDir2'),
  },
  {
    name: 'driftLimitDir1',
    section: BUILDING_DESIGN_SECTION,
    validation: [requiredIfNotDefaulted, emptyOrp01to3],
    autofiller: getFromOutputs('intermediate.driftLimitDir1'),
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'driftLimitDir2',
    section: BUILDING_DESIGN_SECTION,
    validation: [requiredIfNotDefaulted, emptyOrp01to3],
    autofiller: getFromOutputs('intermediate.driftLimitDir2'),
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'equipmentPrequalified',
    section: REPAIR_TIME_OPTIONS_SECTION,
    autofiller: getFromOutputs('intermediate.equipmentPrequalified'),
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138,
        useSmartEquipmentComponentsIsYes,
      ),
    },
  },
  {
    name: 'fema154Score',
    section: BUILDING_STABILITY_SECTION,
    validation: [requiredIfNotDefaulted, emptyOr0to10],
    autofiller: getFromOutputs('intermediate.fema154Score'),
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        collapseDefinitionMethodIsEmptyOrFEMA,
      ),
    },
  },
  {
    name: 'floorPlanByStory',
    type: 'FieldArray',
    defaultVisible: false,
    section: BUILDING_LAYOUT_SECTION,
    itemSchema: [
      { name: 'locationSpecifier' },
      { name: 'ftDir1' },
      { name: 'ftDir2' },
    ],
    validation: validateFloorPlanByStoryFieldArray,
  },
  {
    name: 'firstStoryFullWallRatioDir1',
    section: BUILDING_LAYOUT_SECTION,
    validation: [requiredIfNotDefaulted, emptyOr0to1],
    autofiller: getFromOutputs('intermediate.firstStoryFullWallRatioDir1'),
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        isWoodLightFrame('buildingTypeDir1')
      ),
    },
  },
  {
    name: 'firstStoryFullWallRatioDir2',
    section: BUILDING_LAYOUT_SECTION,
    validation: [requiredIfNotDefaulted, emptyOr0to1],
    autofiller: getFromOutputs('intermediate.firstStoryFullWallRatioDir2'),
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        isWoodLightFrame('buildingTypeDir2')
      ),
    },
  },
  {
    name: 'firstStoryHeight',
    section: BUILDING_LAYOUT_SECTION,
    validation: [requiredIfNotDefaulted, emptyOr1to100],
    autofiller: getFromOutputs('intermediate.firstStoryHeight'),
  },
  {
    name: 'firstStoryInfilledWallRatioDir1',
    section: BUILDING_LAYOUT_SECTION,
    validation: [requiredIfNotDefaulted, emptyOr0to1],
    autofiller: getFromOutputs('intermediate.firstStoryInfilledWallRatioDir1'),
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        isInfilled('buildingTypeDir1'),
      ),
    },
  },
  {
    name: 'firstStoryInfilledWallRatioDir2',
    section: BUILDING_LAYOUT_SECTION,
    validation: [requiredIfNotDefaulted, emptyOr0to1],
    autofiller: getFromOutputs('intermediate.firstStoryInfilledWallRatioDir2'),
    reducers: {
      visible: AND(
        NOT(countryIsJapan),
        isInfilled('buildingTypeDir2'),
      ),
    },
  },
  {
    name: 'componentPopulation',
    type: 'FieldArray',
    section: BUILDING_COMPONENTS_TABLE_SECTION,
    autofiller: getFromOutputs('intermediate.componentPopulation', []),
    itemSchema: [
      {
        name: 'performanceGroups',
        itemFields: [
          { name: 'edp' },
          {
            name: 'locations',
            itemFields: [
              { name: 'capacityModifier' },
              { name: 'costModifier' },
              { name: 'timeModifier' },
              { name: 'locationSpecifier' },
              { name: 'quantityDir1' },
              { name: 'quantityDir2' },
              { name: 'quantityNonDir' },
            ],
          },
          { name: 'name' },
          { name: 'serviceLocations' },
        ],
      },
      { name: 'componentUuid' },
    ],
    validation: validateComponentPopulationFieldArray,
    reducers: {
      value: clearServiceLocationsIfRepairTimeMethodIsREDI,
      autofilled: componentPopulationMethodIsAutomatic,
    },
  },
  {
    name: 'groundMotionMethod',
    defaultValue: 'usgs',
    section: GROUND_MOTION_SECTION,
  },
  {
    name: 'groundMotionUpload',
    section: GROUND_MOTION_SECTION,
    validation: [required, noBackendGroundMotionUploadErrors],
    reducers: {
      visible: groundMotionMethodSupportsUpload,
    },
  },
  {
    name: 'includeFloodingImpact',
    section: REPAIR_TIME_OPTIONS_SECTION,
    defaultValue: 'yes',
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138,
      ),
    },
  },
  {
    name: 'includeImpedanceContractor',
    defaultValue: true,
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: includeRepairTimeIsTrue,
    },
  },
  {
    name: 'includeImpedanceEngineering',
    defaultValue: true,
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: includeRepairTimeIsTrue,
    },
  },
  {
    name: 'includeImpedanceFinancing',
    defaultValue: true,
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: includeRepairTimeIsTrue,
    },
  },
  {
    name: 'includeImpedanceInspection',
    defaultValue: true,
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: includeRepairTimeIsTrue,
    },
  },
  {
    name: 'includeImpedanceLongLeadTime',
    defaultValue: true,
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138
      ),
    },
  },
  {
    name: 'includeImpedancePermitting',
    defaultValue: true,
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: includeRepairTimeIsTrue,
    },
  },
  {
    name: 'includeCollapse',
    defaultValue: true,
    section: ANALYSIS_OPTIONS_SECTION,
  },
  {
    name: 'includeDesignIntensities',
    defaultValue: true,
    section: GROUND_MOTION_SECTION,
    reducers: {
      visible: OR(
        countryIsUS,
        AND(
          NOT(countryIsJapan),
          groundMotionMethodSupportsUpload
        )
      ),
    },
  },
  {
    name: 'includePerRealizationOutput',
    defaultVisible: false,
    defaultValue: false,
    permission: 'perRealization',
    section: ANALYSIS_OPTIONS_SECTION,
  },
  {
    name: 'includeRepairTime',
    defaultValue: true,
    permission: 'recoveryTime',
    section: ANALYSIS_OPTIONS_SECTION,
  },
  {
    name: 'includeResidualDrift',
    defaultValue: true,
    section: 'buildingDesign',
  },
  {
    name: 'includeSurgeDemand',
    section: REPAIR_TIME_OPTIONS_SECTION,
    autofiller: getFromOutputs('intermediate.includeSurgeDemand'),
    validation: [requiredIfNotDefaulted],
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138
      ),
    },
  },
  {
    name: 'includeTimeBasedAnalysis',
    section: ANALYSIS_OPTIONS_SECTION,
    defaultValue: false,
  },
  {
    name: 'includeUSRC',
    defaultValue: false,
    section: ANALYSIS_OPTIONS_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'isDenseUrbanArea',
    section: REPAIR_TIME_OPTIONS_SECTION,
    autofiller: getFromOutputs('intermediate.isDenseUrbanArea'),
    validation: [requiredIfNotDefaulted],
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138,
        includeSurgeDemandIsYes
      ),
    },
  },
  {
    name: 'lat',
    section: SITE_COORDINATES_SECTION,
    validation: [required, countryAndCoordsMatchUSorJapan],
  },
  {
    name: 'linesOfResistanceDir1',
    validation: [requiredIfNotDefaulted, emptyOrNonNegativeInt],
    section: STRUCTURAL_PROPERTIES_SECTION,
    autofiller: getFromOutputs('intermediate.linesOfResistanceDir1'),
    reducers: {
      visible: isEmptyOrRWFD('buildingTypeDir1'),
    },
  },
  {
    name: 'linesOfResistanceDir2',
    validation: [requiredIfNotDefaulted, emptyOrNonNegativeInt],
    section: STRUCTURAL_PROPERTIES_SECTION,
    autofiller: getFromOutputs('intermediate.linesOfResistanceDir2'),
    reducers: {
      visible: isEmptyOrRWFD('buildingTypeDir2'),
    },
  },
  {
    name: 'lng',
    section: SITE_COORDINATES_SECTION,
    validation: [required, countryAndCoordsMatchUSorJapan],
  },
  {
    name: 'masonryShearWallsLoadBearing',
    section: COMPONENT_CHECKLIST_SECTION,
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138,
        atLeastOneBuildingTypeIsEmptyOrMasonryShearWall,
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'maxWalkableStory',
    section: REPAIR_TIME_OPTIONS_SECTION,
    autofiller: getFromOutputs('intermediate.maxWalkableStory'),
    validation: [requiredIfNotDefaulted, emptyOrInt1ToNumberOfStories],
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138,
        needElevatorForFunctionIsYes,
      ),
    },
  },
  {
    name: 'minimumNumberOfExitsRequired',
    section: REPAIR_TIME_OPTIONS_SECTION,
    autofiller: getFromOutputs('intermediate.minimumNumberOfExitsRequired'),
    validation: [requiredIfNotDefaulted, emptyOr0to100],
    reducers: {
      visible: AND(includeRepairTimeIsTrue, repairTimeMethodIsATC138),
    },
  },
  {
    name: 'mitigationContractor',
    defaultVisible: true,
    section: REPAIR_TIME_OPTIONS_SECTION,
    autofiller: getFromOutputs('intermediate.mitigationContractor'),
    reducers: {
      visible: includeRepairTimeIsTrue,
    },
  },
  {
    name: 'mitigationEngineer',
    defaultValue: false,
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: includeRepairTimeIsTrue,
    },
  },
  {
    name: 'mitigationFundingSource',
    section: REPAIR_TIME_OPTIONS_SECTION,
    autofiller: () => 'private',
    reducers: {
      visible: includeRepairTimeIsTrue,
    },
  },
  {
    name: 'mitigationInspector',
    defaultValue: false,
    section: REPAIR_TIME_OPTIONS_SECTION,
    reducers: {
      visible: includeRepairTimeIsTrue,
    },
  },
  {
    name: 'modelName',
    section: MODEL_AND_SITE_INFO_SECTION,
    validation: [required, emptyOrMaxLength300],
  },
  {
    name: 'modeShapeMethod',
    defaultValue: 'sp3',
    section: STRUCTURAL_PROPERTIES_SECTION,
    validation: [required, canUploadModeShape],
    reducers: {
      visible: NOT(atLeastOneBuildingTypeIsRWFD),
    },
  },
  {
    name: 'modeShapeUpload',
    section: STRUCTURAL_PROPERTIES_SECTION,
    validation: [
      required,
      noBackendModeShapeUploadErrors,
      hasExpectedModeShapeModes,
      hasExpectedModeShapeFloors,
    ],
    reducers: {
      visible: AND(
        NOT(atLeastOneBuildingTypeIsLightFrameOrRWFD),
        modeShapeMethodIsUpload,
      ),
    },
  },
  {
    name: 'occupancyCostMultiplier',
    validation: [requiredIfNotDefaulted, emptyOrp01to20],
    section: ANALYSIS_OPTIONS_SECTION,
    autofiller: getFromOutputs('intermediate.occupancyCostMultiplier'),
  },
  {
    name: 'needCoolingForFunction',
    section: REPAIR_TIME_OPTIONS_SECTION,
    autofiller: getFromOutputs('intermediate.needCoolingForFunction'),
    validation: [requiredIfNotDefaulted],
    reducers: {
      visible: AND(includeRepairTimeIsTrue, repairTimeMethodIsATC138),
    },
  },
  {
    name: 'needCoolingForReoccupancy',
    section: REPAIR_TIME_OPTIONS_SECTION,
    autofiller: getFromOutputs('intermediate.needCoolingForReoccupancy'),
    validation: [requiredIfNotDefaulted],
    reducers: {
      visible: AND(includeRepairTimeIsTrue, repairTimeMethodIsATC138),
    },
  },
  {
    name: 'needElectricalPowerForFunction',
    section: REPAIR_TIME_OPTIONS_SECTION,
    autofiller: getFromOutputs('intermediate.needElectricalPowerForFunction'),
    validation: [requiredIfNotDefaulted],
    reducers: {
      visible: AND(includeRepairTimeIsTrue, repairTimeMethodIsATC138),
    },
  },
  {
    name: 'needElectricalPowerForReoccupancy',
    section: REPAIR_TIME_OPTIONS_SECTION,
    autofiller: getFromOutputs('intermediate.needElectricalPowerForReoccupancy'),
    validation: [requiredIfNotDefaulted],
    reducers: {
      visible: AND(includeRepairTimeIsTrue, repairTimeMethodIsATC138),
    },
  },
  {
    name: 'needElevatorForFunction',
    section: REPAIR_TIME_OPTIONS_SECTION,
    autofiller: getFromOutputs('intermediate.needElevatorForFunction'),
    validation: [requiredIfNotDefaulted],
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138
      ),
    },
  },
  {
    name: 'needExhaustForFunction',
    section: REPAIR_TIME_OPTIONS_SECTION,
    autofiller: getFromOutputs('intermediate.needExhaustForFunction'),
    validation: [requiredIfNotDefaulted],
    reducers: {
      visible: AND(includeRepairTimeIsTrue, repairTimeMethodIsATC138),
    },
  },
  {
    name: 'needExhaustForReoccupancy',
    section: REPAIR_TIME_OPTIONS_SECTION,
    autofiller: getFromOutputs('intermediate.needExhaustForReoccupancy'),
    validation: [requiredIfNotDefaulted],
    reducers: {
      visible: AND(includeRepairTimeIsTrue, repairTimeMethodIsATC138),
    },
  },
  {
    name: 'needHeatingForFunction',
    section: REPAIR_TIME_OPTIONS_SECTION,
    autofiller: getFromOutputs('intermediate.needHeatingForFunction'),
    validation: [requiredIfNotDefaulted],
    reducers: {
      visible: AND(includeRepairTimeIsTrue, repairTimeMethodIsATC138),
    },
  },
  {
    name: 'needHeatingForReoccupancy',
    section: REPAIR_TIME_OPTIONS_SECTION,
    autofiller: getFromOutputs('intermediate.needHeatingForReoccupancy'),
    validation: [requiredIfNotDefaulted],
    reducers: {
      visible: AND(includeRepairTimeIsTrue, repairTimeMethodIsATC138),
    },
  },
  {
    name: 'needPotableWaterForFunction',
    section: REPAIR_TIME_OPTIONS_SECTION,
    autofiller: getFromOutputs('intermediate.needPotableWaterForFunction'),
    validation: [requiredIfNotDefaulted],
    reducers: {
      visible: AND(includeRepairTimeIsTrue, repairTimeMethodIsATC138),
    },
  },
  {
    name: 'needPotableWaterForReoccupancy',
    section: REPAIR_TIME_OPTIONS_SECTION,
    autofiller: getFromOutputs('intermediate.needPotableWaterForReoccupancy'),
    validation: [requiredIfNotDefaulted],
    reducers: {
      visible: AND(includeRepairTimeIsTrue, repairTimeMethodIsATC138),
    },
  },
  {
    name: 'needSanitationPlumbingForFunction',
    section: REPAIR_TIME_OPTIONS_SECTION,
    autofiller: getFromOutputs('intermediate.needSanitationPlumbingForFunction'),
    validation: [requiredIfNotDefaulted],
    reducers: {
      visible: AND(includeRepairTimeIsTrue, repairTimeMethodIsATC138),
    },
  },
  {
    name: 'needSanitationPlumbingForReoccupancy',
    section: REPAIR_TIME_OPTIONS_SECTION,
    autofiller: getFromOutputs('intermediate.needSanitationPlumbingForReoccupancy'),
    validation: [requiredIfNotDefaulted],
    reducers: {
      visible: AND(includeRepairTimeIsTrue, repairTimeMethodIsATC138),
    },
  },
  {
    name: 'needVentilationForFunction',
    section: REPAIR_TIME_OPTIONS_SECTION,
    autofiller: getFromOutputs('intermediate.needVentilationForFunction'),
    validation: [requiredIfNotDefaulted],
    reducers: {
      visible: AND(includeRepairTimeIsTrue, repairTimeMethodIsATC138),
    },
  },
  {
    name: 'needVentilationForReoccupancy',
    section: REPAIR_TIME_OPTIONS_SECTION,
    autofiller: getFromOutputs('intermediate.needVentilationForReoccupancy'),
    validation: [requiredIfNotDefaulted],
    reducers: {
      visible: AND(includeRepairTimeIsTrue, repairTimeMethodIsATC138),
    },
  },
  {
    name: 'notes',
    validation: emptyOrMaxLength1000,
    section: ADDITIONAL_NOTES_SECTION,
  },
  {
    name: 'numRealizations',
    section: ANALYSIS_OPTIONS_SECTION,
    validation: [requiredIfNotDefaulted, emptyOrMaxRealizationsByStory],
    autofiller: () => '2500',
  },
  {
    name: 'numberOfStories',
    section: PRIMARY_BUILDING_INFO_SECTION,
    validation: [requiredIfNotDefaulted, maxStoriesForBuildingType],
  },
  {
    name: 'occupancy',
    section: PRIMARY_BUILDING_INFO_SECTION,
    validation: required,
  },
  {
    name: 'percentGlazing',
    validation: emptyOr0to100,
    section: COMPONENT_CHECKLIST_SECTION,
    autofiller: getFromOutputs('intermediate.percentGlazing'),
    reducers: {
      visible: NOT(everyBuildingTypeIsLightFrameOrRWFD),
    },
  },
  {
    name: 'percentOfExitsRequired',
    section: REPAIR_TIME_OPTIONS_SECTION,
    autofiller: getFromOutputs('intermediate.percentOfExitsRequired'),
    validation: [requiredIfNotDefaulted],
    reducers: {
      visible: AND(includeRepairTimeIsTrue, repairTimeMethodIsATC138),
    },
  },
  {
    name: 'percentOfPartitionsAreFireBrake',
    section: COMPONENT_CHECKLIST_SECTION,
    validation: emptyOr0to100,
    autofiller: getFromOutputs('intermediate.percentOfPartitionsAreFireBrake'),
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138,
        NOT(countryIsJapan),
        NOT(everyBuildingTypeIsWoodLightFrame)
      ),
    },
  },
  {
    name: 'period1Dir1',
    validation: [requiredIfNotDefaulted, emptyOrAlmost0to25],
    section: STRUCTURAL_PROPERTIES_SECTION,
    autofiller: getFromOutputs('intermediate.period1Dir1'),
  },
  {
    name: 'period1Dir2',
    validation: [requiredIfNotDefaulted, emptyOrAlmost0to25],
    section: STRUCTURAL_PROPERTIES_SECTION,
    autofiller: getFromOutputs('intermediate.period1Dir2'),
  },
  {
    name: 'period2Dir1',
    validation: [requiredIfNotDefaulted, emptyOrAlmost0to25, period2(1)],
    section: STRUCTURAL_PROPERTIES_SECTION,
    autofiller: getFromOutputs('intermediate.period2Dir1'),
    reducers: {
      visible: AND(
        NOT(isWoodOrSteelLightFrame('buildingTypeDir1')),
        NOT(numberOfStoriesIsOne),
      ),
    },
  },
  {
    name: 'period2Dir2',
    validation: [requiredIfNotDefaulted, emptyOrAlmost0to25, period2(2)],
    section: STRUCTURAL_PROPERTIES_SECTION,
    autofiller: getFromOutputs('intermediate.period2Dir2'),
    reducers: {
      visible: AND(
        NOT(isWoodOrSteelLightFrame('buildingTypeDir2')),
        NOT(numberOfStoriesIsOne),
      ),
    },
  },
  {
    name: 'period3Dir1',
    validation: [requiredIfNotDefaulted, emptyOrAlmost0to25, period3(1)],
    section: STRUCTURAL_PROPERTIES_SECTION,
    autofiller: getFromOutputs('intermediate.period3Dir1'),
    reducers: {
      visible: AND(
        NOT(isWoodOrSteelLightFrame('buildingTypeDir1')),
        NOT(numberOfStoriesIsOneOR2)
      ),
    },
  },
  {
    name: 'period3Dir2',
    validation: [requiredIfNotDefaulted, emptyOrAlmost0to25, period3(2)],
    section: STRUCTURAL_PROPERTIES_SECTION,
    autofiller: getFromOutputs('intermediate.period3Dir2'),
    reducers: {
      visible: AND(
        NOT(isWoodOrSteelLightFrame('buildingTypeDir2')),
        NOT(numberOfStoriesIsOneOR2)
      ),
    },
  },
  {
    name: 'planTorsion',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
  },
  {
    name: 'prequalifiedAccGround',
    section: REPAIR_TIME_OPTIONS_SECTION,
    validation: [required, emptyOrp01to100],
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138,
        useSmartEquipmentComponentsIsYes,
        equipmentPrequalifiedIsYes,
        prequalifiedDesignCodeYearIsOverride,
      ),
    },
  },
  {
    name: 'prequalifiedAccRoof',
    section: REPAIR_TIME_OPTIONS_SECTION,
    validation: [required, emptyOrp01to100],
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138,
        useSmartEquipmentComponentsIsYes,
        equipmentPrequalifiedIsYes,
        prequalifiedDesignCodeYearIsOverride,
      ),
    },
  },
  {
    name: 'prequalifiedDesignCodeYear',
    section: REPAIR_TIME_OPTIONS_SECTION,
    validation: [required],
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138,
        useSmartEquipmentComponentsIsYes,
        equipmentPrequalifiedIsYes,
      ),
    },
  },
  {
    name: 'probCollapseMce',
    section: BUILDING_STABILITY_SECTION,
    validation: [requiredIfNotDefaulted, emptyOrAlmost0toAlmost100],
    autofiller: getFromOutputs('intermediate.probCollapseMce'),
    reducers: {
      visible: collapseDefinitionMethodIsMCE,
    },
  },
  {
    name: 'projectName',
    section: MODEL_AND_SITE_INFO_SECTION,
    validation: [required, emptyOrMaxLength100],
  },
  {
    name: 'regionCostMultiplier',
    validation: [requiredIfNotDefaulted, emptyOrp01to20],
    section: ANALYSIS_OPTIONS_SECTION,
    autofiller: getFromOutputs('intermediate.regionCostMultiplier'),
  },
  {
    name: 'repairCostsScaleWithBuildingValue',
    defaultValue: true,
    section: ANALYSIS_OPTIONS_SECTION,
  },
  {
    name: 'repairTimeMethod',
    defaultValue: ATC_138_ID,
    section: REPAIR_TIME_OPTIONS_SECTION,
    defaultVisible: true,
    reducers: {
      visible: includeRepairTimeIsTrue,
    },
  },
  {
    name: 'returnPeriodDE',
    section: GROUND_MOTION_SECTION,
    validation: [required],
    reducers: {
      visible: AND(
        groundMotionMethodSupportsUpload,
        includeDesignIntensitiesIsTrue,
      ),
    },
  },
  {
    name: 'returnPeriodMCE',
    section: GROUND_MOTION_SECTION,
    validation: [required, mceGreaterThanDE],
    reducers: {
      visible: AND(
        groundMotionMethodSupportsUpload,
        includeDesignIntensitiesIsTrue,
      ),
    },
  },
  {
    name: 'returnPeriods',
    section: GROUND_MOTION_SECTION,
    validation: [
      requiredIfNotDefaulted,
      commaSeparatedListOfReturnPeriods,
      noTrailingComma,
      allIReturnPeriodsAreIntegersBetween1And99999,
      noDuplicateReturnPeriods,
      returnPeriodsInclude475,
      mustHaveAtLeast2ReturnPeriods,
      mustHaveFewerThan20ReturnPeriods,
    ],
    autofiller: () => '22, 43, 72, 108, 144, 224, 475, 975, 2475',
    reducers: {
      visible: NOT(groundMotionMethodSupportsUpload),
    },
  },
  {
    name: 'riskCategory',
    section: BUILDING_DESIGN_SECTION,
    validation: requiredIfNotDefaulted,
    autofiller: getFromOutputs('intermediate.riskCategory'),
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'seismicImportanceFactor',
    section: BUILDING_DESIGN_SECTION,
    validation: [requiredIfNotDefaulted, emptyOr1to10],
    autofiller: getFromOutputs('intermediate.seismicImportanceFactor'),
    reducers: {
      visible: NOT(countryIsJapan),
    },
  },
  {
    name: 'seismicityDesign',
    section: PRIMARY_BUILDING_INFO_SECTION,
    validation: [required],
    reducers: {
      visible: NOT(countryIsUSOrJapan),
    },
  },
  {
    name: 'seismicityShaking',
    section: PRIMARY_BUILDING_INFO_SECTION,
    validation: [required],
    reducers: {
      visible: NOT(countryIsUSOrJapan),
    },
  },
  {
    name: 'siteClass',
    section: GROUND_MOTION_SECTION,
    validation: [requiredIfNotDefaulted],
    autofiller: getFromOutputs('intermediate.siteClass'),
  },
  {
    name: 'state',
    section: MODEL_AND_SITE_INFO_SECTION,
    reducers: {
      value: clearOnCountryChange('state'),
    },
  },
  {
    name: 'street',
    section: MODEL_AND_SITE_INFO_SECTION,
    reducers: {
      value: clearOnCountryChange('street'),
    },
  },
  {
    name: 'structuralResponses',
    type: 'FieldArray',
    defaultValue: structuralResponsesDefaultValueArray,
    section: STRUCTURAL_RESPONSES_SECTION,
    itemSchema: [
      { name: 'edpKey' },
      { name: 'method' },
      { name: 'upload' },
    ],
    validation: validateStructuralResponsesFieldArray,
    reducers: {
      value: structuralResponsesValueReducer,
    },
  },
  {
    name: 'tdmfDamperConfigDir1',
    section: BUILDING_LAYOUT_SECTION,
    validation: [requiredIfNotDefaulted],
    autofiller: getFromOutputs('intermediate.tdmfDamperConfigDir1'),
    reducers: {
      visible: AND(
        isTaylorDampedMomentFrame('buildingTypeDir1'),
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'tdmfBayLengthDir1',
    validation: [requiredIfNotDefaulted, emptyOr2to100],
    autofiller: getFromOutputs('intermediate.tdmfBayLengthDir1'),
    section: BUILDING_LAYOUT_SECTION,
    reducers: {
      visible: AND(
        isTaylorDampedMomentFrame('buildingTypeDir1'),
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'tdmfDamperExposureDir1',
    validation: [requiredIfNotDefaulted],
    autofiller: getFromOutputs('intermediate.tdmfDamperExposureDir1'),
    section: BUILDING_LAYOUT_SECTION,
    reducers: {
      visible: AND(
        isTaylorDampedMomentFrame('buildingTypeDir1'),
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'tdmfDamperConfigDir2',
    validation: [requiredIfNotDefaulted],
    autofiller: getFromOutputs('intermediate.tdmfDamperConfigDir2'),
    section: BUILDING_LAYOUT_SECTION,
    reducers: {
      visible: AND(
        isTaylorDampedMomentFrame('buildingTypeDir2'),
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'tdmfBayLengthDir2',
    validation: [requiredIfNotDefaulted, emptyOr2to100],
    autofiller: getFromOutputs('intermediate.tdmfBayLengthDir2'),
    section: BUILDING_LAYOUT_SECTION,
    reducers: {
      visible: AND(
        isTaylorDampedMomentFrame('buildingTypeDir2'),
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'tdmfDamperExposureDir2',
    validation: [requiredIfNotDefaulted],
    autofiller: getFromOutputs('intermediate.tdmfDamperExposureDir2'),
    section: BUILDING_LAYOUT_SECTION,
    reducers: {
      visible: AND(
        isTaylorDampedMomentFrame('buildingTypeDir2'),
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'typeOfResistanceDir1',
    validation: requiredIfNotDefaulted,
    section: STRUCTURAL_PROPERTIES_SECTION,
    autofiller: getFromOutputs('intermediate.typeOfResistanceDir1'),
    reducers: {
      visible: AND(
        isEmptyOrRWFD('buildingTypeDir1'),
        isGreaterThanOrEqualToOne('linesOfResistanceDir1')
      ),
    },
  },
  {
    name: 'typeOfResistanceDir2',
    validation: requiredIfNotDefaulted,
    section: STRUCTURAL_PROPERTIES_SECTION,
    autofiller: getFromOutputs('intermediate.typeOfResistanceDir2'),
    reducers: {
      visible: AND(
        isEmptyOrRWFD('buildingTypeDir2'),
        isGreaterThanOrEqualToOne('linesOfResistanceDir2'),
      ),
    },
  },
  {
    name: 'upperStoryFullWallRatioDir1',
    section: BUILDING_LAYOUT_SECTION,
    validation: [requiredIfNotDefaulted, emptyOr0to1],
    autofiller: getFromOutputs('intermediate.upperStoryFullWallRatioDir1'),
    reducers: {
      visible: AND(
        isWoodLightFrame('buildingTypeDir1'),
        NOT(countryIsJapan),
        NOT(numberOfStoriesIsOne),
      ),
    },
  },
  {
    name: 'upperStoryFullWallRatioDir2',
    section: BUILDING_LAYOUT_SECTION,
    validation: [requiredIfNotDefaulted, emptyOr0to1],
    autofiller: getFromOutputs('intermediate.upperStoryFullWallRatioDir2'),
    reducers: {
      visible: AND(
        isWoodLightFrame('buildingTypeDir2'),
        NOT(countryIsJapan),
        NOT(numberOfStoriesIsOne),
      ),
    },
  },
  {
    name: 'upperStoryHeight',
    section: BUILDING_LAYOUT_SECTION,
    validation: [requiredIfNotDefaulted, emptyOr1to100],
    autofiller: getFromOutputs('intermediate.upperStoryHeight'),
    reducers: {
      visible: NOT(numberOfStoriesIsOne),
    },
  },
  {
    name: 'upperStoryInfilledWallRatioDir1',
    section: BUILDING_LAYOUT_SECTION,
    validation: [requiredIfNotDefaulted, emptyOr0to1],
    autofiller: getFromOutputs('intermediate.upperStoryInfilledWallRatioDir1'),
    reducers: {
      visible: AND(
        isInfilled('buildingTypeDir1'),
        NOT(countryIsJapan),
        NOT(numberOfStoriesIsOne)
      ),
    },
  },
  {
    name: 'upperStoryInfilledWallRatioDir2',
    section: BUILDING_LAYOUT_SECTION,
    validation: [requiredIfNotDefaulted, emptyOr0to1],
    autofiller: getFromOutputs('intermediate.upperStoryInfilledWallRatioDir2'),
    reducers: {
      visible: AND(
        isInfilled('buildingTypeDir2'),
        NOT(countryIsJapan),
        NOT(numberOfStoriesIsOne)
      ),
    },
  },
  {
    name: 'useSmartEquipmentComponents',
    section: REPAIR_TIME_OPTIONS_SECTION,
    defaultValue: 'yes',
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138,
      ),
    },
  },
  {
    name: 'useSmartPipingComponents',
    section: REPAIR_TIME_OPTIONS_SECTION,
    defaultValue: 'yes',
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138,
      ),
    },
  },
  {
    name: 'useUpdatedElevatorComponents',
    section: REPAIR_TIME_OPTIONS_SECTION,
    defaultValue: 'yes',
    reducers: {
      visible: AND(
        includeRepairTimeIsTrue,
        repairTimeMethodIsATC138,
      ),
    },
  },
  {
    name: 'verticalIrregularity',
    section: BUILDING_MODIFIERS_CHECKLIST_SECTION,
  },
  {
    name: 'yearOfConstruction',
    section: PRIMARY_BUILDING_INFO_SECTION,
    defaultVisible: true,
    validation: [required, greaterThanMinYearOfConstruction],
    reducers: {
      visible: yearOfConstructionVisibilityReducer,
    },
  },
  {
    name: 'yieldStoryDriftDir1',
    section: STRUCTURAL_PROPERTIES_SECTION,
    validation: [requiredIfNotDefaulted, emptyOrp05to2],
    autofiller: getFromOutputs('intermediate.yieldStoryDriftDir1'),
  },
  {
    name: 'yieldStoryDriftDir2',
    section: STRUCTURAL_PROPERTIES_SECTION,
    validation: [requiredIfNotDefaulted, emptyOrp05to2],
    autofiller: getFromOutputs('intermediate.yieldStoryDriftDir2'),
  },
  {
    name: 'ylbfBayLengthDir1',
    section: BUILDING_LAYOUT_SECTION,
    validation: [requiredIfNotDefaulted, emptyOr2to100],
    autofiller: getFromOutputs('intermediate.ylbfBayLengthDir1'),
    reducers: {
      visible: AND(
        isSimpsonYieldLinkBraceFrame('buildingTypeDir1'),
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'ylbfBayLengthDir2',
    section: BUILDING_LAYOUT_SECTION,
    validation: [requiredIfNotDefaulted, emptyOr2to100],
    autofiller: getFromOutputs('intermediate.ylbfBayLengthDir2'),
    reducers: {
      visible: AND(
        isSimpsonYieldLinkBraceFrame('buildingTypeDir2'),
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'ylbfBraceConfigDir1',
    section: BUILDING_LAYOUT_SECTION,
    validation: [requiredIfNotDefaulted],
    autofiller: getFromOutputs('intermediate.ylbfBraceConfigDir1'),
    reducers: {
      visible: AND(
        isSimpsonYieldLinkBraceFrame('buildingTypeDir1'),
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'ylbfBraceConfigDir2',
    section: BUILDING_LAYOUT_SECTION,
    validation: [requiredIfNotDefaulted],
    autofiller: getFromOutputs('intermediate.ylbfBraceConfigDir2'),
    reducers: {
      visible: AND(
        isSimpsonYieldLinkBraceFrame('buildingTypeDir2'),
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'ylbfBraceExposureDir1',
    section: BUILDING_LAYOUT_SECTION,
    validation: [requiredIfNotDefaulted],
    autofiller: getFromOutputs('intermediate.ylbfBraceExposureDir1'),
    reducers: {
      visible: AND(
        isSimpsonYieldLinkBraceFrame('buildingTypeDir1'),
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'ylbfBraceExposureDir2',
    section: BUILDING_LAYOUT_SECTION,
    validation: [requiredIfNotDefaulted],
    autofiller: getFromOutputs('intermediate.ylbfBraceExposureDir2'),
    reducers: {
      visible: AND(
        isSimpsonYieldLinkBraceFrame('buildingTypeDir2'),
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'ylbfNumFusesPerBraceDir1',
    section: BUILDING_LAYOUT_SECTION,
    validation: [requiredIfNotDefaulted],
    autofiller: getFromOutputs('intermediate.ylbfNumFusesPerBraceDir1'),
    reducers: {
      visible: AND(
        isSimpsonYieldLinkBraceFrame('buildingTypeDir1'),
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'ylbfNumFusesPerBraceDir2',
    section: BUILDING_LAYOUT_SECTION,
    validation: [requiredIfNotDefaulted],
    autofiller: getFromOutputs('intermediate.ylbfNumFusesPerBraceDir2'),
    reducers: {
      visible: AND(
        isSimpsonYieldLinkBraceFrame('buildingTypeDir2'),
        NOT(countryIsJapan)
      ),
    },
  },
  {
    name: 'zip',
    section: MODEL_AND_SITE_INFO_SECTION,
    reducers: {
      value: clearOnCountryChange('zip'),
    },
  },
];

export default fields;
