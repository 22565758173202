/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { Col, FormGroup, Row } from 'reactstrap';
import { FieldArray } from 'redux-form';
import { formatWithCommas, normalizeWithCommas } from '#support/utility/form/withCommas.js';
import FieldVisibilityToggle
  from '#components/pages/Models/Model/sections/support/forms/FieldVisibilityToggle/index.js';
import GridField from '#components/pages/Models/Model/sections/support/forms/GridField/index.jsx';
import renderFloorPlanByStory from './renderFloorPlanByStory/index.jsx';

type Props = {|
  afterToggleOn: Function,
  defineByStory: boolean,
  form: string,
  supportDefineByStory: boolean,
|};

const FloorPlanFieldset = ({
  afterToggleOn,
  defineByStory,
  form,
  supportDefineByStory,
}: Props): Element<typeof FormGroup> => (
  <FormGroup tag="fieldset">
    <legend>Floor Plan</legend>
    <Row>
      {
        supportDefineByStory
        && (
        <Col>
          <FieldVisibilityToggle
            afterToggleOn={afterToggleOn}
            className="float-right define-floor-plan-toggle"
            form={form}
            on={defineByStory}
            onFields={['floorPlanByStory']}
            onText="Use simplified"
            offFields={['buildingSquareFootage', 'buildingAspectRatio']}
            offText="Define by story"
          />
        </Col>
        )
      }
    </Row>
    {
      defineByStory
        ? (
          <FieldArray
            form={form}
            name="floorPlanByStory"
            component={renderFloorPlanByStory}
          />
        )
        : (
          <>
            <GridField
              name="buildingSquareFootage"
              form={form}
              options={{
                columnSize: 3,
                labelText: 'Total Building Square Footage',
                ComponentType: 'text',
                includeToolTip: true,
                format: formatWithCommas,
                normalize: normalizeWithCommas,
              }}
            />
            <GridField
              name="buildingAspectRatio"
              form={form}
              options={{
                columnSize: 3,
                labelText: 'Building Aspect Ratio (Plan)',
                ComponentType: 'text',
                includeToolTip: true,
              }}
            />
          </>
        )
    }
  </FormGroup>
);

export default FloorPlanFieldset;
