import { Auth } from 'aws-amplify';
import { merge } from 'lodash';
import get from 'lodash/fp/get.js';
import { change } from 'redux-form';
import { normalize, schema } from 'normalizr';
import {
  CREATE_USER_PASSWORD,
  CREATE_USER_PASSWORD_REQUEST,
  CREATE_USER_PASSWORD_RESPONSE,
  CREATE_USER_REQUEST,
  CREATE_USER_RESPONSE,
  FETCH_ACTION,
  FETCH_USER_REQUEST,
  FETCH_USER_RESPONSE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_RESPONSE,
  SIGN_IN_USER,
  SIGN_IN_USER_REQUEST,
  SIGN_IN_USER_RESPONSE,
  SIGN_OUT_USER,
  UPDATE_USER_REQUEST,
  UPDATE_USER_RESPONSE,
} from '#constants/actionTypes.js';
import { COGNITO_NEW_PASSWORD_REQUIRED } from '#constants/users/cognitoAuthChallenges.js';
import { COGNITO_NOT_AUTHORIZED_EXCEPTION, COGNITO_USER_NOT_FOUND_EXCEPTION } from '#constants/users/cognitoErrorCodes.js';
import { INVALID_CREDENTIALS } from '#constants/users/errorMessages.js';
import { PROBLEM_WITH_REQUEST } from '#constants/errorMessages.js';
import { CREATE_PASSWORD_FORM } from '#constants/users/form/createPassword/name.js';

const userSchema = new schema.Entity('users', undefined, {
  idAttribute: 'uuid',
});

export const fetchUser = (userId) => ({
  [FETCH_ACTION]: {
    types: [FETCH_USER_REQUEST, FETCH_USER_RESPONSE],
    endpoint: `/user/${userId}`,
    options: {
      method: 'GET',
    },
    errorTransform: () => new Error(PROBLEM_WITH_REQUEST),
  },
});

export const fetchUsers = ({
  [FETCH_ACTION]: {
    types: [FETCH_USERS_REQUEST, FETCH_USERS_RESPONSE],
    endpoint: '/user',
    options: {
      method: 'GET',
    },
    dataTransform: (json) => {
      const defaultEntities = { users: {} };
      const { result, entities } = normalize(json, [userSchema]);
      return {
        result,
        entities: merge(defaultEntities, entities),
      };
    },
  },
});

export const postUser = (body) => ({
  [FETCH_ACTION]: {
    types: [CREATE_USER_REQUEST, CREATE_USER_RESPONSE],
    endpoint: '/user',
    options: {
      method: 'POST',
      body,
    },
  },
});

export const putUser = (userId, body) => ({
  [FETCH_ACTION]: {
    types: [UPDATE_USER_REQUEST, UPDATE_USER_RESPONSE],
    endpoint: `/user/${userId}`,
    options: {
      method: 'PUT',
      body,
    },
  },
});

const signInUserRequest = () => ({
  type: SIGN_IN_USER_REQUEST,
});

const signInUserResponse = (error, data) => ({
  type: SIGN_IN_USER_RESPONSE,
  payload: error || data,
  error: !!error,
});

export const signOutUser = () => ({
  type: SIGN_OUT_USER,
});

const makeSignInUserPayload = (email, password, dispatch) => Promise.resolve()
  .then(() => dispatch(signInUserRequest()))
  .then(() => Auth.signOut()) // Makes sure local storage for cognito is clear and ready to go
  .then(() => Auth.signIn(email, password))
  .then((user) => {
    // If a new password is required, pass the required user data along to CREATE_PASSWORD_FORM
    // and dispatch a null, but non-error sign in user response action
    if (user.challengeName === COGNITO_NEW_PASSWORD_REQUIRED) {
      dispatch(change(CREATE_PASSWORD_FORM, 'user', user));
      dispatch(signInUserResponse(
        undefined,
        {
          token: null,
          user: {
            uuid: null,
          },
        }
      ));
      return { authUserId: null };
    }
    // Otherwise the user is authenticated, continue with the sign in
    return Auth.currentAuthenticatedUser()
      .then((authUser) => {
        const { attributes, signInUserSession } = authUser;
        const authUserId = get('custom:uuid', attributes);
        dispatch(signInUserResponse(
          undefined,
          {
            token: signInUserSession.idToken.jwtToken,
            user: {
              ...attributes,
              uuid: authUserId,
            },
          }
        ));
        return { authUserId };
      });
  })
  .catch((error) => {
    const message = [
      COGNITO_NOT_AUTHORIZED_EXCEPTION,
      COGNITO_USER_NOT_FOUND_EXCEPTION,
    ].includes(error.code)
      ? INVALID_CREDENTIALS
      : PROBLEM_WITH_REQUEST;

    const errorAction = signInUserResponse(new Error(message));
    dispatch(errorAction);
    return Promise.reject(errorAction);
  });

export const signInUser = (email, password, dispatch) => ({
  type: SIGN_IN_USER,
  payload: makeSignInUserPayload(email, password, dispatch),
});

const createUserPasswordRequest = () => ({
  type: CREATE_USER_PASSWORD_REQUEST,
});

const createUserPasswordResponse = (error, data) => ({
  type: CREATE_USER_PASSWORD_RESPONSE,
  payload: error || data,
  error: !!error,
});

const makeCreateUserPasswordPayload = (user, password, dispatch) => Promise.resolve()
  .then(() => dispatch(createUserPasswordRequest()))
  .then(() => Auth.completeNewPassword(user, password))
  .then((authenticatedUser) => {
    const { challengeParam: { userAttributes: attributes }, signInUserSession } = authenticatedUser;
    const authUserId = get('custom:uuid', attributes);
    dispatch(createUserPasswordResponse(
      undefined,
      {
        token: signInUserSession.idToken.jwtToken,
        user: {
          ...attributes,
          uuid: authUserId,
        },
      }
    ));
    return { authUserId };
  });

export const createUserPassword = (user, password, dispatch) => ({
  type: CREATE_USER_PASSWORD,
  payload: makeCreateUserPasswordPayload(user, password, dispatch),
});
