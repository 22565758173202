/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import {
  REQUIRES_SERVICE_LOCATIONS,
  IS_CALCULATED,
  EDP_UNIT,
} from '@hbrisk/sp3-risk-model-support/components/translated/attributes/names/index.js';
import {
  YES_NO_VALUES,
} from '@hbrisk/sp3-risk-model-support/components/translated/attributes/values/index.js';
import { modelTypesByForm } from '#src/js/constants/models/modelTypes/index.js';
import { selectComponentByUuid } from '#selectors/entities/components.js';
import {
  currentModelId,
  makeComponentCalculatedCapacitiesSelector,
} from '#selectors/entities/models.js';

import PerformanceGroup from './PerformanceGroup.jsx';

const mapStateToProps = () => {
  const selectCalculatedCapacities = makeComponentCalculatedCapacitiesSelector();
  return (state, ownProps) => {
    const { form, componentUuid, index } = ownProps;
    const { selectRepairTimeMethodSupportsServiceLocations } = modelTypesByForm[form];
    const component = selectComponentByUuid(state, { uuid: componentUuid });
    const requiresServiceLocations = component ? component[REQUIRES_SERVICE_LOCATIONS] : null;
    const isCalculated = component ? component[IS_CALCULATED] : null;
    const edpUnit = component ? component[EDP_UNIT] : null;
    const modelId = currentModelId(state);
    const calculatedCapacities = selectCalculatedCapacities(
      state,
      { componentUuid, modelId, index }
    );
    const showCalculatedCapacity = (
      isCalculated === YES_NO_VALUES.YES
      && calculatedCapacities.length > 0
    );
    const showServiceLocations = requiresServiceLocations === YES_NO_VALUES.YES
      && selectRepairTimeMethodSupportsServiceLocations(state);
    return ({
      edpUnit,
      showCalculatedCapacity,
      showServiceLocations,
      calculatedCapacities,
    });
  };
};
export default connect(mapStateToProps)(PerformanceGroup);
