/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */

import keyBy from 'lodash/fp/keyBy.js';
import React from 'react';
import { componentQuestions } from '@hbrisk/sp3-risk-model-support/models/componentQuestions/index.js';
import SeismicityLevelLi from './SeismicityLevelLi/index.jsx';
import {
  storiesImage,
  floorsImage,
  locationsImage,
  chordRotationImage,
  couplingBeamRotationImage,
} from './index.module.scss';

const compQuestionTooltips = componentQuestions.reduce((acc, curr) => {
  const { name, tooltip } = curr;
  return [
    ...acc,
    tooltip ? {
      id: name,
      header: '',
      body: tooltip,
    } : {},
  ];
}, []);

const tips = [
  ...compQuestionTooltips,
  {
    id: 'allowComponentsToAffectStructuralProperties',
    header: 'Allow Components to Affect Structural Properties',
    body: (
      <div>
        <p>
          Allows wood light frame components to affect the default values of base shear
          strength and stiffness (period). Interior and exterior wood wall components,
          both structural and nonstructural, are used to determine the default strength
          and stiffness of the building.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>
            Global strength and stiffness defaulting
          </li>
        </ul>
        <b>Note:</b>
        <p>
          Exterior wood wall components include both full height and perforated wall segments.
          The full height portions of the exterior walls are used to determine the walls’
          contribution to the strength and stiffness of the building. The full height portion is
          the product of the component length and the full height exterior wall fraction
          (specified in the Building Layout page).
        </p>
        <p>
          The net effect of adding or removing structural components will vary from one building to
          the next, because buildings with fewer shear walls tend to have tighter nailing patterns,
          which means more strength and stiffness per foot of shear wall.
        </p>
        <b>Default:</b>
        <p>
          Building strength and stiffness defaults are determined based on the default wood light
          frame component inventory.
        </p>
      </div>
    ),
  },
  {
    id: 'allowFireWatchToBeSufficient',
    header: 'Allow Fire Watch to be Sufficient for Occupancy',
    body: (
      <p>
        Turn this on if the building&apos;s jurisdiction allows for occupancy with a fire watch
        if the building&apos;s fire suppression system is compromised.
      </p>
    ),
  },
  {
    id: 'allowTemporaryRepairs',
    header: 'Allow Temporary Repairs to Regain Occupancy/Function',
    body: (
      <p>
        Where possible, allow temporary measures to be taken immediately after the earthquake
        (before the full and permanent repairs are carried out) to mitigate
        reoccupancy/functionality issues.
      </p>
    ),
  },
  {
    id: 'allowTemporaryShoring',
    header: 'Allow Temporary Shoring to Regain Occupancy/Function',
    body: (
      <p>
        Where possible, allow temporary shoring to be installed immediately after the earthquake
        (before the full and permanent repairs are carried out) to mitigate
        reoccupancy/functionality issues.
      </p>
    ),
  },
  {
    id: 'annualRepairCost',
    header: 'Annual Repair Cost',
    body: (
      <div>
        <p>
          The average value of loss, per year, over a period of many years (FEMA P-58).
        </p>
      </div>
    ),
  },
  {
    id: 'atc138Output',
    header: 'ATC-138 Recovery Time (Beta) (with selected impeding factors)',
    body: (
      <p>
        The recovery times shown here include the impeding factors that are selected by the user.
      </p>
    ),
  },
  {
    id: 'averageAnnualLossBreakdown',
    header: 'Average Annual Loss Breakdown',
    body: (
      <p>
        The average annual loss is the average value of loss, per year,
        over a period of many years (FEMA P-58).
      </p>
    ),
  },
  {
    id: 'baseShearStrength',
    header: 'Base shear strength',
    body: (
      <div>
        <p>
          The ultimate base shear capacity, incorporating both
          overstrength and non-structural contributions.
          This is not the design base shear.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Structural response prediction</li>
          <li>Collapse capacity defaulting (for some systems)</li>
        </ul>
        <b>Defaults:</b>
        <p className="pl-3">
          Based on structural system, design code, level
          of seismicity of site, and building fundamental period.
        </p>
      </div>
    ),
  },
  {
    id: 'brbToDiaphragmRatio',
    header: 'BRB/Diaphragm Strength Ratio',
    body: (
      <div>
        <p>
          Ratio of the BRB design strength to the design strength of the diaphragm.
        </p>
        <p>
          This is essentially the ratio of design Cs values (or ratio of R values).
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Structural response prediction</li>
          <li>Global strength and stiffness defaulting</li>
          <li>Collapse capacity defaulting</li>
        </ul>
      </div>
    ),
  },
  {
    id: 'buildingAspectRatio',
    header: 'Building Aspect Ratio (Plan)',
    body: (
      <div>
        <p>
          Ratio of the maximum length in each principle direction (direction 2 / direction 1);
          e.g. a building that measures 100’ in direction 1 and 200’ in direction 2 would have
          an aspect ratio of 2.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Automated selection of structural components</li>
          <li>Automated selection of non-structural components</li>
          <li>Global strength and stiffness defaulting (wind design, tilt-up diaphragm design)</li>
          <li>Structural response prediction</li>
        </ul>
        <b>Default:</b>
        <p className="pl-3">
          Most buildings are assumed to be square in plan with the exception
          of Wood Light Frame structures.
        </p>
      </div>
    ),
  },
  {
    id: 'buildingComponentsChecklist',
    header: 'Building Components Checklist',
    body: (
      <div>
        <p>
          SP3 algorithms default the components likely to be in the building, based on code,
          occupancy, and structural system. Non-structural component capacities are also defaulted
          based on design code. When provided, responses to these checklist questions are
          incorporated into the SP3 algorithms for defaulting the building components model.
        </p>
      </div>
    ),
  },
  {
    id: 'buildingRepairTime',
    header: 'Building Recovery Time',
    body: (
      <div>
        <p>
          Time to repair the damaged building to meet a specified recovery
          level (i.e. re-occupancy, functional recovery, full recovery).
        </p>
      </div>
    ),
  },
  {
    id: 'buildingModsFullStoryGradeWLF',
    header: 'Full story grade change? (For FEMA Type W1 buildings)',
    body: (
      <div>
        <p>
          There is at least a full story grade change from one side of the building to the other.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Collapse capacity defaulting</li>
        </ul>
        <b>
          Default:
        </b>
        <p className="pl-3">
          No sloping site.
        </p>
      </div>
    ),
  },
  {
    id: 'buildingModsFullStoryGrade',
    header: 'Full story grade change? (For FEMA Type W1 buildings)',
    body: (
      <div>
        <p>
          There is at least a full story grade change from one side of the building to the other.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Collapse capacity defaulting</li>
        </ul>
        <b>
          Default:
        </b>
        <p className="pl-3">
          No sloping site.
        </p>
      </div>
    ),
  },
  {
    id: 'buildingModsSetbackOutboard',
    header: 'Outboard vertical elements of the lateral system at an upper story',
    body: (
      <div>
        <p>
          Vertical elements of the lateral system at an upper story are outboard of
          those at the story below causing the diaphragm to cantilever at the offset.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Collapse capacity defaulting</li>
        </ul>
        <b>
          Default:
        </b>
        <p className="pl-3">
          No setback.
        </p>
      </div>
    ),
  },
  {
    id: 'buildingModsSetbackInboard',
    header: 'Inboard vertical elements of the lateral system at upper stories',
    body: (
      <div>
        <p>
          Vertical elements of the lateral system at upper
          stories are inboard of those at lower stories.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Collapse capacity defaulting</li>
        </ul>
        <b>
          Default:
        </b>
        <p className="pl-3">
          No setback.
        </p>
      </div>
    ),
  },
  {
    id: 'buildingModsSetbackInPlaneLfrs',
    header: 'In-plane offset of the lateral elements greater their length',
    body: (
      <div>
        <p>
          There is an in-plane offset of the lateral elements that
          is greater than the length of the elements.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Collapse capacity defaulting</li>
        </ul>
        <b>
          Default:
        </b>
        <p className="pl-3">
          No setback.
        </p>
      </div>
    ),
  },
  {
    id: 'buildingModsColumnRatioLessThanTypical',
    header: 'Columns with height/depth ratios less than 50% of typical other columns at that level',
    body: (
      <div>
        <p>
          At least 20% of columns (or piers) along a column line in the lateral
          system have height/depth ratios less than 50% of the nominal height/depth
          ratio at that level.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Collapse capacity defaulting</li>
        </ul>
        <b>
          Default:
        </b>
        <p className="pl-3">
          No short column/pier.
        </p>
      </div>
    ),
  },
  {
    id: 'buildingModsColumnPierSpandrelRatio',
    header: 'Column depth less than spandrel depth, or column shortend by infill walls or adjacent floors',
    body: (
      <div>
        <p>
          The column depth (or pier width) is less than one half of the depth of
          the spandrel, or there are infill walls or adjacent floors that shorten
          the column.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Collapse capacity defaulting</li>
        </ul>
        <b>
          Default:
        </b>
        <p className="pl-3">
          No short column/pier.
        </p>
      </div>
    ),
  },
  {
    id: 'buildingModsSplitLevel',
    header: 'Split level at one of the floor levels or at the roof',
    body: (
      <div>
        <p>
          There is a split level at one of the floor levels or at the roof.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Collapse capacity defaulting</li>
        </ul>
        <b>
          Default:
        </b>
        <p className="pl-3">
          No split level.
        </p>
      </div>
    ),
  },
  {
    id: 'buildingModsOtherVerticalIrregularity',
    header: 'Other observable vertical irregularities',
    body: (
      <div>
        <p>
          There is another observable vertical irregularity that obviously
          affects the building&lsquo;s seismic performance.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Collapse capacity defaulting</li>
        </ul>
        <b>
          Default:
        </b>
        <p className="pl-3">
          No other vertical irregularities.
        </p>
      </div>
    ),
  },
  {
    id: 'buildingModsNonParallel',
    header: 'Non-parallel system',
    body: (
      <div>
        <p>
          There are one or more major vertical elements of the lateral
          system that are not orthogonal to each other.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Collapse capacity defaulting</li>
        </ul>
        <b>
          Default:
        </b>
        <p className="pl-3">
          No non-parallel system.
        </p>
      </div>
    ),
  },
  {
    id: 'buildingModsReentrant',
    header: 'Re-entrant corner projection 25% of plan dimension',
    body: (
      <div>
        <p>
          Both projections from an interior corner exceed 25% of
          the overall plan dimension in that direction.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Collapse capacity defaulting</li>
        </ul>
        <b>
          Default:
        </b>
        <p className="pl-3">
          No re-entrant corners.
        </p>
      </div>
    ),
  },
  {
    id: 'buildingModsDiaphramOpen',
    header: 'Diaphragm opening over 50% of the total diaphragm width',
    body: (
      <div>
        <p>
          There is an opening in the diaphragm with a width over
          50% of the total diaphragm width at that level.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Collapse capacity defaulting</li>
        </ul>
        <b>
          Default:
        </b>
        <p className="pl-3">
          No diaphragm openings.
        </p>
      </div>
    ),
  },
  {
    id: 'buildingModsOopOffset',
    header: 'Exterior beams do not align with the columns in plan',
    body: (
      <div>
        <p>
          Building out-of-plane offset: The exterior
          beams do not align with the columns in plan.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Collapse capacity defaulting</li>
        </ul>
        <b>
          Default:
        </b>
        <p className="pl-3">
          No misaligned beams and columns.
        </p>
      </div>
    ),
  },
  {
    id: 'buildingModsOtherPlanIrregularity',
    header: 'Other observable plan irregularity',
    body: (
      <div>
        <p>
          There is another observable plan irregularity that obviously
          affects the building&lsquo;s seismic performance.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Collapse capacity defaulting</li>
        </ul>
        <b>
          Default:
        </b>
        <p className="pl-3">
          No other plan irregularity.
        </p>
      </div>
    ),
  },
  {
    id: 'buildingModsRedundancy',
    header: 'At least two bays of lateral elements',
    body: (
      <div>
        <p>
          The building has at least two bays of lateral elements
          on each side of the building in each direction.
        </p>
        <p>
          Many buildings meet the requirements for redundancy.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Collapse capacity defaulting</li>
        </ul>
        <b>
          Default:
        </b>
        <p className="pl-3">
          No - System is not redundant.
        </p>
      </div>
    ),
  },
  {
    id: 'buildingModsKBracing',
    header: 'K bracing geometry is visible',
    body: (
      <div>
        <p>
          K bracing geometry is visible.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Strength and stiffness defaulting</li>
          <li>Collapse capacity defaulting</li>
        </ul>
        <b>
          Default:
        </b>
        <p className="pl-3">
          No K bracing.
        </p>
      </div>
    ),
  },
  {
    id: 'buildingModsFlatPlateAsBeam',
    header: 'Flat plate serves as the beam in the moment frame',
    body: (
      <div>
        <p>
          Flat plate serves as the beam in the moment frame.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Strength and stiffness defaulting</li>
          <li>Collapse capacity defaulting</li>
        </ul>
        <b>
          Default:
        </b>
        <p className="pl-3">
          No flat plate beams as lateral system.
        </p>
      </div>
    ),
  },
  {
    id: 'buildingModsCrossGrainOopConn',
    header: 'Roof-to-wall ties do not rely on cross-grain bending',
    body: (
      <div>
        <p>
          There are roof-to-wall ties that are visible or known from
          drawings that do not rely on cross-grain bending.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Collapse capacity defaulting</li>
        </ul>
        <b>
          Default:
        </b>
        <p className="pl-3">
          Depends on building era.
        </p>
        <b>Notes:</b>
        <p>
          This will have no effect on buildings UBC 1997 or later.
        </p>
        <p>
          For component defaulting of roof-to-wall ties (i.e. out of plane
          wall to diaphragm connections), use the building component
          checklist page
        </p>
      </div>
    ),
  },
  {
    id: 'buildingModsInteriorWallDensity',
    header: 'High interior wall density',
    body: (
      <div>
        <p>
          The building has closely spaced, full height interior walls (rather
          than an interior space with few walls such as in a warehouse).
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Collapse capacity defaulting</li>
        </ul>
        <b>
          Default:
        </b>
        <p className="pl-3">
          No - interior walls are not densely spaced.
        </p>
      </div>
    ),
  },
  {
    id: 'buildingModsConstructionQuality',
    header: 'Structural Construction Quality',
    body: (
      <div>
        <p>
          Modifies the global strength and stiffness of the building.
          Individual component capacities will not be affected.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Strength and Stiffness defaulting</li>
          <li>Collapse capacity defaulting</li>
        </ul>
        <b>
          Default:
        </b>
        <p className="pl-3">
          Average.
        </p>
      </div>
    ),
  },
  {
    id: 'buildingModsNonStructuralConstructionQuality',
    header: 'Non-structural Construction Quality',
    body: (
      <div>
        <p>
          Quality of the non-structural components.
          Only affects calculated component capacities.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Computed capacities of non-structural components</li>
        </ul>
        <b>
          Default:
        </b>
        <p className="pl-3">
          Average.
        </p>
      </div>
    ),
  },
  {
    id: 'buildingModsMaterialDeterioration',
    header: 'Structural Material Deterioration',
    body: (
      <div>
        <p>
          Deterioration of the structural system due to age and/or other factors.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Global strength and stiffness defaulting</li>
          <li>Collapse capacity defaulting</li>
        </ul>
        <b>
          Default:
        </b>
        <p className="pl-3">
          None.
        </p>
      </div>
    ),
  },
  {
    id: 'buildingReplacementTime',
    header: 'Building Replacement Time',
    body: (
      <div>
        <p>
          Total time to replace the building if it is damaged beyond repair.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Building downtime</li>
        </ul>
      </div>
    ),
  },
  {
    id: 'buildingSquareFootage',
    header: 'Building Square Footage',
    body: (
      <div>
        <p>
          Total square footage of the building (combined for all floors in the building).
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Automated selection of structural components</li>
          <li>Automated selection of non-structural components</li>
          <li>Default building replacement value</li>
          <li>Expected overstrength/additional stiffness defaulting</li>
        </ul>
        <b>
          Default:
        </b>
        <p className="pl-3">
          Most buildings are assumed to be 10,000 square feet per story
          with the exception of Wood Light Frame and Rigid Wall Flexible Diaphragm structures.
        </p>
      </div>
    ),
  },
  {
    id: 'buildingStability',
    header: 'Building Stability',
    body: (
      <div>
        <p>
          Parameters describing the collapse capacity of the building.
          The collapse capacity is modeled as a lognormal distribution and the
          default values are based on the FEMA 154 collapse assessment methodology.
        </p>
        <p>
          If the MCE is included as a shaking intensity, the FEMA P-154 probability of
          collapse is anchored to that shaking intensity.  Otherwise the FEMA P-154 probability
          of collapse is anchored at the modern design code MCE spectra.
        </p>
      </div>
    ),
  },
  {
    id: 'buildingValueModifier',
    header: 'Building Value Modifier',
    body: (
      <div>
        <p>
          This modifier scales component costs so that their repair
          costs relative to the value of the building is consistent
          with expected values, based on SP3 defaults.
        </p>
        <b>Example:</b>
        <p>
          If the user-defined Total Cost per Square
          Foot is double the SP3 default Total Cost per Square
          Foot, then a factor of 2.0 is applied to component
          repair costs.
        </p>
        <b>Note:</b>
        <p>
          Additional cost modifiers for component groups,
          which compound with this effect, can be specified in
          the building components page.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Building value modifier applied to component repair costs.</li>
        </ul>
      </div>
    ),
  },
  {
    id: 'calculatedMedianCapacity',
    header: 'Calculated Median Capacity',
    body: (
      <div>
        <p>
          This is a designed component, so there is no standard capacity.
        </p>
        <p>
          The capacity is calculated according to the building code at the time of construction.
        </p>
      </div>
    ),
  },
  {
    id: 'capacityScaleFactor',
    header: 'Capacity Scale Factor',
    body: (
      <div>
        <p>
          Scale factor on the capacity of the component;
          e.g. for a component with median capacity of 1g, a 1.5
          factor would increase the median capacity to 1.5g.
        </p>
        <b>Default:</b>
        <p className="pl-3">
          Default scale factor is 1.
        </p>
      </div>
    ),
  },
  {
    id: 'cashOnHand',
    header: 'Cash on Hand',
    body: (
      <div>
        <p>
          How much capital is available for financing in the event of earthquake shaking damage.
          If the cost of damage is less than the available capital, then financing will not delay
          the start of repairs (other impedance factors may still apply). The entered value is the
          percent of building replacement cost.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Downtime</li>
        </ul>
      </div>
    ),
  },
  {
    id: 'cbr',
    header: 'Coupling Beam Rotation',
    body: (
      <div>
        <p>
          Peak coupling beam rotation (see figure, ASCE 41-13)
          at each story and principal direction of the building.
        </p>
        <b>Diagram:</b>
        <div className="pt-3">
          <div className={couplingBeamRotationImage} />
        </div>
      </div>
    ),
  },
  {
    id: 'collapseCapacityBeta',
    header: 'Collapse Capacity Beta',
    body: (
      <div>
        <p>
          The uncertainty parameter of the collapse capacity lognormal
          distribution.
        </p>
        <b>Default:</b>
        <p className="pl-3">
          Based on FEMA P-154.
        </p>
      </div>
    ),
  },
  {
    id: 'collapseCapacityMedian',
    header: 'Median Collapse Capacity',
    body: (
      <div>
        <p>
          Spectral acceleration at the fundamental period of the building
          at which the building has a 50% probability of collapse
          (the mean of the lognormal distribution).
        </p>
        <b>Default:</b>
        <p className="pl-3">
          Based on P[col|MCE] calculated using FEMA P-154.
        </p>
      </div>
    ),
  },
  {
    id: 'componentCostMultiplier',
    header: 'Cost Modifier',
    body: (
      <div>
        <p>
          Cost consequences modifier (per component group).
          A value of 1.0 means that the cost of a component
          group relative to the building value is “typical”.
        </p>
        <p>
          Example: A building has high end marble partition finishes.
        </p>
        <p>
          Recommendation: Adjust the cost modifier to Very High
          for Partition Walls component group.
        </p>
      </div>
    ),
  },
  {
    id: 'componentCapacityMultiplier',
    header: 'Capacity Modifier',
    body: (
      <div>
        <p>
          Multiplier on the damage threshold of components (per component group).
        </p>
        <p>
          Example: The glazing on a building was detailed to accomodate story drifts two
          times the code requirement.
        </p>
        <p>
          Recommendation: Apply a capacity multiplier of 2 to the Exterior Finishes component group.
        </p>
      </div>
    ),
  },
  {
    id: 'componentGroupHeaderCostModifier',
    header: 'Cost Modifier',
    body: (
      <div>
        <p>
          Cost consequences modifier (per component group). A value of 1.0 means that
          the cost of a component group relative to the building value is “typical”.
        </p>
      </div>
    ),
  },
  {
    id: 'componentGroupHeaderMaximumRepairPotential',
    header: 'Maximum Repair Potential',
    body: (
      <div>
        <p>
          Cost to repair all components in a component group from their highest
          damage state. This value includes volume discounting as well as all
          of the cost scaling factors shown on this page (e.g. Region Cost
          Multiplier). Individual component cost modifiers that are manually
          input in in the custom components page (available in the Advance
          Risk Model only) are also included.
        </p>
        <p>
          Note: maximum repair potential is not necessarily equal to component value
        </p>
      </div>
    ),
  },
  {
    id: 'componentGroupHeaderPercentOfBuildingReplacementValue',
    header: 'Percent of Building Replacement Value',
    body: (
      <div>
        <p>
          Maximum repair potential relative to building replacement value.
        </p>
        <p>
          Note: the summation of maximum repair potential for all components
          may be more or less than the replacement cost of the building,
          depending on how many of a building&lsquo;s components are dameagable in
          an earthquake and if their maximum repair potential is less than or
          greater than the cost to install them new.
        </p>
      </div>
    ),
  },
  {
    id: 'componentImportanceFactor',
    header: (
      <span>
        Component Importance Factor, I
        <small>p</small>
      </span>
    ),
    body: (
      <div>
        <p>
          Multiplier on the design force for components and their anchorage.
          Changing this factor will not affect the risk category.
          This will only affect building codes UBC 1976 and later.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Automated selection of non-structural components</li>
          <li>Computed capacities of non-structural components</li>
        </ul>
        <b>Default:</b>
        <p className="pl-3">
          Based on design code, assigned risk category, and building component.
        </p>
      </div>
    ),
  },
  {
    id: 'componentPopulationMethod',
    header: 'Component Selection Method',
    body: (
      <div>
        <p>Specifies method to choose structural and non-structural components for your model</p>
        <b>Options</b>
        <ul>
          <li>
            SP3 Automated: Let the SP3 algorithms default the components likely to be in the
            building, based on code, occupancy, and structural system. Non-structural component
            capacities are also defaulted based on design code. The Building Components Table
            accounts for user inputs from other sections and will adjust as user-inputs are
            revised and the model is updated.
          </li>
          <li>
            User Defined: User-specified building components; this can be used to make small changes
            to defaulted components, or to create a full custom list.
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 'componentPopulationMethodCustomWarning',
    body: (
      <div>
        <p>
          When User Defined is selected, the Building Components Table is no longer automatically
          updated to reflect inputs from other sections.
        </p>
        <b>For example</b>
        <p>
          When User Defined is selected, changing the building square footage will no longer
          update component quantities.
        </p>
        <b>Warning</b>
        <p>
          Switching to SP3 Automated and updating your model will re-populate component data
          (all previously user defined component data will be lost).
        </p>
      </div>
    ),
  },
  {
    id: 'concreteShearWallsLoadBearing',
    header: 'Bearing Walls',
    body: (
      <div>
        <p>
          Designates walls as bearing walls. More stringent checks are used for red tagging
          of bearing walls than for walls that are not part of the gravity load path.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ui>
          <li>Red tagging</li>
        </ui>
      </div>
    ),
  },
  {
    id: 'confirmationCode',
    header: 'Confirmation Code',
    body: 'This is the code we sent to your email address',
  },
  {
    id: 'consequenceType',
    header: 'Consequence Type',
    body: (
      <div>
        <b>Options</b>
        <ul>
          <li>
            Sequential: Damage stated must occur in sequential order,
            with one state occurring before another is possible
            (see FEMA P-58 Vol. 1 Section 7.5.1)
          </li>
          <li>
            Mutually Exclusive: The occurrence of one damage
            state precludes the occurrence of another damage
            state (see FEMA P-58 Vol. 1 Section 7.5.2)
          </li>
          <li>
            Simultaneous: A series of potential damage states
            that are independent and unrelated in that may or
            may not occur at the same time
            (see FEMA P-58 Vol. 1 Section 7.5.3)
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 'correlation',
    header: 'Correlation',
    body: (
      <div>
        <p>
          Determines if the components on the same floor/story for
          a given performance group will damage together or can damage separately.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>
            Correlated: within a given performance group, all the
            components on the same floor/story will damage together.
          </li>
          <li>
            Uncorrelated: within a given performance group, some
            components can be damaged while others are undamaged.
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 'costPerSqFt',
    header: 'Total Cost per Square Foot',
    body: (
      <div>
        <p>
          The current replacement cost of the building per square foot. This is only the cost
          to rebuild and should not include the value of the land under the building. This value
          is pre-populated based on region, occupancy, and building square footage.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Building replacement value (denominator of loss ratio)</li>
          <li>
            If component repair costs are scaled to be consistent with this value,
            then Total Cost per Square Foot will not affect the loss ratio.
          </li>
        </ul>
        <b>Default:</b>
        <p className="pl-3">Value based on RSmeans, region cost multiplier, and date cost multiplier</p>
      </div>
    ),
  },
  {
    id: 'costScaleFactor',
    header: 'Cost Scale Factor',
    body: (
      <div>
        <p>
          Scale factor to apply to the component repair cost consequences;
          e.g. a scale factor of 1.5 would increase the component repair cost by 50%.
        </p>
        <b>Default:</b>
        <p className="pl-3">
          Default scale factor is 1.
        </p>
      </div>
    ),
  },
  {
    id: 'cr',
    header: 'Chord Rotation',
    body: (
      <div>
        <p>
          Peak chord rotation (see figure, ASCE 41-13)
          at each story and principal direction of the building
        </p>
        <b>Diagram:</b>
        <div className="pt-3">
          <div className={chordRotationImage} />
        </div>
      </div>
    ),
  },
  {
    id: 'damageCorrelationModel',
    header: 'Statistical Damage Correlation Model',
    body: (
      <div>
        <p>
          This is the correlation model used when simulating damage
          to individual building components when doing the FEMA P-58 Monte Carlo simulation.
        </p>
        <p>
          The ATC-138 updated method implements a partial correlation model based
          on the location in the building and level of similarity between components.
        </p>
        <b>Impact of Risk Assessment:</b>
        <ul>
          <li>
            Red tag probability
          </li>
          <li>
            Recovery time
          </li>
          <li>
            Loss variance
          </li>
        </ul>
        <b>Default:</b>
        <p className="pl-3">
          The Original FEMA P-58 model (although we anticipate this to change in the near future)
        </p>
      </div>
    ),
  },
  {
    id: 'dateCostMultiplier',
    header: 'Date Cost Multiplier',
    body: (
      <div>
        <p>
          Cost multiplier to convert 2011 dollars to present value dollars
        </p>
        <b>Impact of Risk Assessment:</b>
        <ul>
          <li>
            Defaults building cost per square foot and component repair costs.
          </li>
        </ul>
        <b>Default:</b>
        <p className="pl-3">
          Value based on a 3% inflation rate since 2011.
        </p>
      </div>
    ),
  },
  {
    id: 'designCodeYear',
    header: 'Design Code',
    body: (
      <div>
        <p>
          Year of the building code governing the building design (e.g. ASCE 7-10).
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Design code parameters</li>
          <li>Automated selection of structural components</li>
          <li>Automated selection of non-structural components</li>
          <li>Computed capacities of non-structural components</li>
          <li>Collapse capacity defaulting</li>
        </ul>
        <b>Note:</b>
        <p>
          If &quot;ASCE 2022/ASCE 2016 (non-struct/struct)&quot; is selected, SP3 automations of
          nonstructural component capacities are based on ASCE 7-22 requirements, but SP3
          automation of structural properties are based on ASCE 7-16 requirements.
        </p>
      </div>
    ),
  },
  {
    id: 'designCodeYearInt',
    header: 'Equivalent Design Code',
    body: (
      <div>
        <p>
          For international sites, an equivalent US design code must be specified to approximate
          how the building is designed. The parameters used in this code (i.e. base shear
          requirements) are determined by the Level of Seismicity.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Design code parameters</li>
          <li>Automated selection of structural components</li>
          <li>Automated selection of non-structural components</li>
          <li>Computed capacities of non-structural components</li>
          <li>Collapse capacity defaulting</li>
        </ul>
        <b>Note:</b>
        <p>
          If &quot;ASCE 2022/ASCE 2016 (non-struct/struct)&quot; is selected, SP3 automations of
          nonstructural component capacities are based on ASCE 7-22 requirements, but SP3
          automation of structural properties are based on ASCE 7-16 requirements.
        </p>
        <p>
          <strong>Knowledge Base: </strong>
          {' '}
          <a target="blank" href="https://support.sp3risk.com/space/KnowledgeBase/2203779073/Modeling+a+Building+at+an+International+Site">
            Modeling a Building at an International Site
          </a>
        </p>
      </div>
    ),
  },
  {
    id: 'designPeriod1',
    header: (
      <span>
        Design Structural Model Period (Design T
        <sub>1</sub>
        )
      </span>
    ),
    body: (
      <div>
        <p>
          Fundamental (translational) period in each direction, computed from the
          structural model that is used to design the building; i.e. the period
          from an eigen analysis - not Ta, CuTa, or other code approximation.
          This period accounts for the lateral force resisting system only and does
          not include additional sources of stiffness that are normally ignored in
          design, such as stiffness from nonstructural components.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>
            Modal periods (T
            <sub>1</sub>
            , T
            <sub>2</sub>
            , T
            <sub>3</sub>
            )
          </li>
        </ul>
        <b>Default:</b>
        <p>
          Based on structural system, building height, design code, and level
          of seismicity.
        </p>
      </div>
    ),
  },
  {
    id: 'detailLevel',
    header: 'Level Of Detailing',
    body: (
      <div>
        <p>
          The level of detailing specified by the building code (or modern equivalent
          to level of detailing if not part of the building code used in design).
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Automated selection of structural components</li>
          <li>Design code parameters</li>
          <li>Structural response prediction (ductile vs. brittle response)</li>
        </ul>
        <b>Default:</b>
        <p className="pl-3">
          Level of detailing based on the year of construction and seismicity of the site.
        </p>
      </div>
    ),
  },
  {
    id: 'diaph_duct',
    header: 'Diaphragm Ductility',
    body: (
      <div>
        <p>
          Peak diaphragm ductility (ratio of diaphragm deflection demand
          to the deflection at which the diaphragm yields)
          demand at each floor and principal direction of the building.
        </p>
      </div>
    ),
  },
  {
    id: 'driftLimit',
    header: 'Drift Design Limit',
    body: (
      <div>
        <p>
          The maximum allowable drift for the design level forces (per Code design
          process for controlling Code). Changing this factor will not affect the risk category.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Global strength and stiffness defaulting</li>
        </ul>
        <b>Default:</b>
        <p className="pl-3">
          Based on assigned Risk Category and Building Type
        </p>
      </div>
    ),
  },
  {
    id: 'equipmentPrequalified',
    header: 'Equipment Prequalified',
    body: (
      <div>
        <p>
          Prequalified equipment is tested to a specified shaking acceleration by the manufacturer
          to verify that it is reliable up to that level of shaking. If this option is selected,
          the strength of equipment will be based on a given level of prequalification acceleration
          (i.e., the acceleration level for which the equipment is prequalified). The level of
          acceleration that is assumed for component prequalified may be determined based on
          minimum building code requirements or user-defined acceleration values. Prequalified
          equipment fragilities adjust median and dispersion such that there is a low probability of
          damage at the qualification acceleration (i.e., prequalification acceleration is not the
          median capacity of the equipment).
        </p>
        <b>Impact on Risk Assessment:</b>
        <ui>
          <li>
            SP3 will auto-populate prequalified equipment fragilities, the strengths of which are
            based on an assumed level of acceleration for equipment prequalification tests, which
            is either determined by the governing building code or is user-defined.
          </li>
        </ui>
      </div>
    ),
  },
  {
    id: 'factorsDelayingTheStartOfRepairs',
    header: 'Factors Delaying the Start of Repairs',
    body: (
      <div>
        <p>
          Various factors influencing the time from the occurrence of the earthquake to when
          repairs on the building commence. All selected factors will be considered in the
          downtime assessment of the building model.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Building Downtime</li>
        </ul>
      </div>
    ),
  },
  {
    id: 'fema154Score',
    header: 'FEMA P-154 Score',
    body: (
      <div>
        <p>
          This is the score used to calculate the probability of collapse at the MCE level shaking.
          A higher score results in a lower probability of collapse.
          See FEMA P-154: Rapid Visual Screening of Buildings for Potential Seismic Hazards,
          available&nbsp;
          <a
            href="https://www.fema.gov/sites/default/files/2020-07/fema_earthquakes_rapid-visual-screening-of-buildings-for-potential-seismic-hazards-a-handbook-third-edition-fema-p-154.pdf"
          >
            here
          </a>
          &nbsp;and the supporting documentation in FEMA P-155, available&nbsp;
          <a
            href="https://www.fema.gov/sites/default/files/2020-07/fema_earthquakes_rapid-visual-screening-of-buildings-for-potential-seismic-hazards-supporting-documentation-third-edition-fema-p-155.pdf"
          >
            here
          </a>
          .
        </p>
        <p>
          With default FEMA P-154 scores, SP3 adds additional modifiers when converting the
          FEMA P-154 score to collapse probability for some cases. If the FEMA P-154 score is
          user-defined, the additional modifications are NOT applied. Cases that SP3 modifies are:
        </p>
        <ul>
          <li>Wood light frame buildings</li>
          <li>Moment frames that use Simpson Strong-Tie Yield Link Connections</li>
          <li>Tilt-ups with BRB internal lines of resistance</li>
          <li>Buildings with importance factors higher than the building code requirement</li>
        </ul>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>
            Collapse capacity
          </li>
        </ul>
        <b>Default:</b>
        <p className="pl-3">
          A simplified FEMA P-154 checklist based on specified structural system type,
          building age, soil type, irregularities, and risk category.
        </p>
      </div>
    ),
  },
  {
    id: 'fittedSUL',
    header: 'Fitted SUL',
    body: (
      <div>
        <p>
          Fitted value of Scenario Upper Loss (SUL) - there is a 90% probability
          that the repair cost will not exceed this value.
        </p>
      </div>
    ),
  },
  {
    id: 'floor',
    header: 'Floors',
    body: (
      <div>
        <p>
          Floors can be expressed as a comma separated
          list of values (G, 2, 3, 5, 6, R) or ranges (G-3, 5-R)
        </p>
        <p>
          G denotes Ground Floor
          {' '}
          <br />
          R denotes Roof
          {' '}
          <br />
        </p>
        <b>Example 3-Story Building</b>
        <div className="pt-3">
          <div className={floorsImage} />
        </div>
      </div>
    ),
  },
  {
    id: 'fullHeightExteriorWallFraction',
    header: 'Full Height Exterior Wall Fraction',
    body: (
      <div>
        <p>
          This is the fraction of exterior wood walls that are full height, i.e. the
          portion without door or window openings.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>
            Global strength and stiffness defaulting
          </li>
        </ul>
        <b>Tip:</b>
        <p>
          Exterior wood wall component fragilities include window and door openings,
          so the length of full height wall available for computing strength and stiffness is
          the product of the component length and the fraction that is full height.
        </p>
        <b>Example:</b>
        <p>
          My building has a 200&#8216; of exterior wall in direction 1. I specify 120&#8216;
          of structural exterior wood wall components and 80&#8216; of nonstructural
          exterior wood wall components in direction 1. If the full height exterior wall
          fraction is 0.6, then SP3 will assume 120&#8216; * 0.6 = 72&#8216; of full
          height exterior structural shear wall and 80&#8216; * 0.6 = 48&#8216; of full
          height exterior nonstructural wall when determining the strength and stiffness
          of the building.
        </p>
      </div>
    ),
  },
  {
    id: 'fullRecovery',
    header: 'Full Recovery',
    body: (
      <div>
        <p>
          Time to repair the damaged building to its pre-earthquake state.
        </p>
      </div>
    ),
  },
  {
    id: 'functionalRecovery',
    header: 'Functional Recovery',
    body: (
      <div>
        <p>
          Time to complete repairs such that the damaged
          building can support its pre-earthquake function.
        </p>
      </div>
    ),
  },
  {
    id: 'groundMotion',
    header: 'Ground Motion Hazard Info',
    body: (
      <div>
        <p>
          Ground motion hazard includes the return periods and corresponding
          spectral acceleration values that define each intensity at which SP3 is run.
        </p>
        <b>Options:</b>
        <ul>
          <li>
            USGS - The user may select return periods of interest
            and acceleration values are filled using USGS data.
          </li>
          <li>
            User Defined - The user may upload return periods and
            associated acceleration values based upon a site specific study.
          </li>
        </ul>
        <b>Default:</b>
        <p>
          The ground motion hazard data are defaulted from the USGS,
          including peak ground acceleration and many spectral acceleration values.
        </p>
      </div>
    ),
  },
  {
    id: 'groundMotionInt',
    header: 'Ground Motion Hazard Info',
    body: (
      <div>
        <p>
          Ground motion hazard includes the return periods and corresponding
          spectral acceleration values that define each intensity at which SP3 is run.
        </p>
        <p>
          For international sites, the hazard is approximate and the return periods will not
          be representative of the actual site. It is recommended that the hazard from a site
          specific study is uploaded if the return periods are important for the analysis.
        </p>
        <b>Options:</b>
        <ul>
          <li>
            Approximate International Hazard – This is an approximate shaking hazard based on the
            specified Level of Seismicity.
          </li>
          <li>
            User Defined - The user may upload return periods and
            associated acceleration values based upon a site specific study.
          </li>
        </ul>
        <b>Default:</b>
        <p>
          The ground motion hazard data are defaulted from the USGS,
          including peak ground acceleration and many spectral acceleration values.
        </p>
        <p>
          <strong>Knowledge Base:</strong>
          {' '}
          <a target="blank" href="https://support.sp3risk.com/space/KnowledgeBase/2203779073/Modeling+a+Building+at+an+International+Site">
            Modeling a Building at an International Site
          </a>
        </p>
      </div>
    ),
  },
  {
    id: 'idr',
    header: 'Peak Story Drift Ratio',
    body: (
      <div>
        <p>
          Peak interstory drift (story displacement/story height)
          at each story and principal direction of the building.
        </p>
      </div>
    ),
  },
  {
    id: 'includeCollapse',
    header: 'Include Collapse',
    body: (
      <div>
        <p>
          Select this option to include the consequence of collapse in the analysis.
        </p>
        <p>
          If included, in each simulation where the building is determined to be collapsed,
          the repair cost is the full replacement cost and the recovery time is
          the building replacement time. This will also affect the number of casualties.
        </p>
      </div>
    ),
  },
  {
    id: 'includeDesignIntensities',
    header: 'Include Design Intensities',
    body: (
      <div>
        <p>
          Select this option to include Design Earthquake (DE)
          and Maximum Considered Earthquake (MCE) intensities in the analysis.
        </p>
        <b>Scenarios:</b>
        <ul>
          <li>
            USGS: The intensities will be auto-calculated and added to the shown return periods.
          </li>
          <li>
            User Defined: User must select the DE/MCE return periods from the list of
            uploaded return periods.
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 'includePerRealizationOutput',
    header: 'Include Per Realization Output',
    body: 'Include per realization outputs',
  },
  {
    id: 'includeResidualDrift',
    header: 'Include Residual Drift Demolition',
    body: (
      <div>
        <p>
          Select this option to incude the consequence of building demolition when
          residual drifts are large.
        </p>
        <p>
          In simulations where the building is determined to have excessive residual drift,
          the damage consequence is building demolition, so the repair cost is the full
          replacement cost and the recovery time is the building replacement time.
        </p>
      </div>
    ),
  },
  {
    id: 'includeSurgeDemand',
    header: 'Include Surge Demand?',
    body: (
      <div>
        <p>
          Include the effects of high demand for material and labor procurement in the event of an
          earthquake that damages a significant number of buildings.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Inclusion will lengthen downtime</li>
        </ul>
      </div>
    ),
  },
  {
    id: 'includeTimeBasedAnalysis',
    header: 'Run Time Based Analysis',
    body: (
      <div>
        <p>
          Select this option to display output metrics for the expected annual loss.
          The expected annual loss is the average cost per year to repair
          or replace the building as a result of earthquake damage.
        </p>
        <p>
          Note that the annualized loss metrics are only meaningful if there is a wide range of
          shaking intensities analyzed, particularly for more frequent ground shaking
          (e.g. 50% in 50 year). See FEMA P-58 Vol. 1, Section 4.4.2
          for more information on intensity selection.
        </p>
      </div>
    ),
  },
  {
    id: 'includeUSRC',
    header: 'Include USRC Report',
    body: (
      <div>
        <p>
          Select this option to output a United States Resiliency Council (USRC)
          rating report for your building model.
        </p>
        <p>
          To learn more about the USRC, please see their website&nbsp;
          <a
            href="http://usrc.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </p>
      </div>
    ),
  },
  {
    id: 'infilledExteriorWallFraction',
    header: 'Infilled Exterior Wall Fraction',
    body: (
      <div>
        <p>
          Fraction of exterior wall line that is infilled with masonry.
        </p>
        <b>Impact on Risk Assessment</b>
        <ul>
          <li>Component population</li>
          <li>Global strength and stiffness defaulting</li>
        </ul>
      </div>
    ),
  },
  {
    id: 'intensity',
    header: 'Intensity',
    body: (
      <div>
        <p>
          The probability of exceedance of ground shaking in a given time period;
          e.g. “10% in 50 years” corresponds to the level of shaking
          that has a 10% probability of being exceeded over a 50-year period.
        </p>
      </div>
    ),
  },
  {
    id: 'isDenseUrbanArea',
    header: 'Is Dense Urban Area?',
    body: (
      <div>
        <p>
          If the building is located in a dense urban area, then the surge demand is expected to
          have a larger affect than if the building is not in a dense urban area.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Inclusion will lengthen downtime</li>
        </ul>
      </div>
    ),
  },
  {
    id: 'lbr',
    header: 'Link Beam Rotation',
    body: (
      <div>
        <p>
          Peak link beam rotation angle at each story in each principal direction of the building.
        </p>
      </div>
    ),
  },
  {
    id: 'seismicityDesign',
    header: 'Design Code Level of Seismicity',
    body: (
      <div>
        <p>
          For international sites, it is required to specify a level of seismicity associated with
          the Equivalent Building Code. This is used to determine the design code factors used in
          the equivalent design code.
        </p>
        <p>
          The levels of seismicity (for modern ASCE 7 codes) are as follows:
        </p>
        <ul>
          <SeismicityLevelLi label="Low" ss="0.2" s1="0.08" />
          <SeismicityLevelLi label="Moderate" ss="0.4" s1="0.16" />
          <SeismicityLevelLi label="Moderately High" ss="0.8" s1="0.32" />
          <SeismicityLevelLi label="High" ss="1.2" s1="0.48" />
          <SeismicityLevelLi label="Very High" ss="2.25" s1="0.9" />
        </ul>
        <p>
          For the older UBC codes, the Level of Seismicity is mapped to the seismic zone of that
          specific code that most closely represents the
          S
          <sub>s</sub>
          and
          S
          <sub>1</sub>
          values specified above. A general rule of thumb is Low maps to Zone 0 and Very High
          maps to UBC Zone 4.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Design code parameters</li>
          <li>Automated selection of structural components</li>
          <li>Automated selection of non-structural components</li>
          <li>Computed capacities of non-structural components</li>
          <li>Collapse capacity defaulting</li>
        </ul>
        <p>
          <strong>Knowledge Base:</strong>
          {' '}
          <a target="blank" href="https://support.sp3risk.com/space/KnowledgeBase/2203779073/Modeling+a+Building+at+an+International+Site">
            Modeling a Building at an International Site
          </a>
        </p>
      </div>
    ),
  },
  {
    id: 'seismicityShaking',
    header: 'Shaking Level of Seismicity',
    body: (
      <div>
        <p>
          This approximates the shaking hazard for the international site (independent
          of the shaking hazard that the building was designed for).
        </p>
        <p>
          The 475 year hazard will be approximately consistent with the following
          S
          <sub>s</sub>
          {' '}
          and S
          <sub>1</sub>
          {' '}
          values:
        </p>
        <ul>
          <SeismicityLevelLi label="Low" ss="0.2" s1="0.08" />
          <SeismicityLevelLi label="Moderate" ss="0.4" s1="0.16" />
          <SeismicityLevelLi label="Moderately High" ss="0.8" s1="0.32" />
          <SeismicityLevelLi label="High" ss="1.2" s1="0.48" />
          <SeismicityLevelLi label="Very High" ss="2.25" s1="0.9" />
        </ul>
        <p>
          If a user defined hazard upload is provided, this field will have no effect.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>International shaking hazard approximation</li>
        </ul>
        <p>
          <strong>Knowledge Base:</strong>
          {' '}
          <a target="blank" href="https://support.sp3risk.com/space/KnowledgeBase/2203779073/Modeling+a+Building+at+an+International+Site">
            Modeling a Building at an International Site
          </a>
        </p>
      </div>
    ),
  },
  {
    id: 'linesOfResistance',
    header: 'Lines of Resistance',
    body: (
      <div>
        <p>
          The number of interior lines of the lateral force resisting system (LFRS)
          that have significant strength and stiffness.
        </p>
        <p>
          Lines are assumed to be evenly spaced.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Structural response prediction</li>
          <li>Global strength and stiffness defaulting</li>
        </ul>
      </div>
    ),
  },
  {
    id: 'includeImpedanceLongLeadTime',
    header: 'Long Lead Time',
    body: (
      <div>
        <p>
          Lead time (or procurement time) is the amount of time elapsing between the
          point when an order is placed for an item and its delivery to the site.
          Long lead times for certain components (e.g., HVAC equipment and elevators) can
          increase the time for functional recovery.
        </p>
        <p>
          Long lead time should be included
          in the analysis unless the component procurement process is circumvented or
          pre-arranged with the component suppliers.
        </p>
        <b>Impact on Risk Assessment:</b>
        <p>
          Functional recovery time. Includes the lead-time repair delay of components
          in the FEMA P-58 database that are tagged as &ldquo;long-lead.&ldquo;
        </p>
      </div>
    ),
  },
  {
    id: 'masonryShearWallsLoadBearing',
    header: 'Bearing Walls',
    body: (
      <div>
        <p>
          Designates walls as bearing walls. More stringent checks are used for red tagging
          of bearing walls than for walls that are not part of the gravity load path.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ui>
          <li>Red tagging</li>
        </ui>
      </div>
    ),
  },
  {
    id: 'maxWalkableStory',
    header: 'Max Walkable Story',
    body: (
      <div>
        <p>
          The highest story to which it is acceptable for occupants to access via stairs while the
          elevators are broken while still considering the building functional.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Downtime</li>
        </ul>
      </div>
    ),
  },
  {
    id: 'meanLoss',
    header: 'Mean Loss (SEL)',
    body: (
      <div>
        <p>
          Scenario Expected Loss - the average repair cost of the building repair/replacement
        </p>
      </div>
    ),
  },
  {
    id: 'meanLossHeader',
    header: 'SEL',
    body: (
      <div>
        <p>
          Scenario Expected Loss (mean loss) for a ground motion with 10% chance of
          exceedance in 50 years.
        </p>
      </div>
    ),
  },
  {
    id: 'medianCapacity',
    header: 'Median Capacity',
    body: (
      <div>
        <p>
          The demand at which the component has a 50% probability of being damaged.
        </p>
      </div>
    ),
  },
  {
    id: 'medianLoss',
    header: 'Median Loss (SEL)',
    body: (
      <div>
        <p>
          There is a 50% probability that the repair cost will not exceed this value.
        </p>
      </div>
    ),
  },
  {
    id: 'minimumNumberOfExitsRequired',
    header: 'Minimum Number of Exits Required for Occupancy',
    body: (
      <p>
        Minimum number of exits required to meet egress requirements for building occupancy.
      </p>
    ),
  },
  {
    id: 'mitigationContractor',
    header: 'Contractor Relationship',
    body: (
      <div>
        <p>
          Relationship with the contractor as it relates to their availability to begin
          repair work following an earthquake.
        </p>
        <b>Options:</b>
        <ul>
          <li>None: little or no pre-existing relationship with the contractor.</li>
          <li>
            Good: there is a good relationship with the contractor, such that
            they will prioritize repairing this building.
          </li>
          <li>
            Retainer: there is a pre-arranged contract with the contractor
            to be available to mobilize for repairs.
          </li>
        </ul>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Affects contractor mobilization time</li>
        </ul>
      </div>
    ),
  },
  {
    id: 'mitigationEngineer',
    header: 'Engineer on Retainer',
    body: (
      <div>
        <p>
          There is a pre-arranged contract with an engineer
          to be available for the design of repairs.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Reduces engineering mobilization time</li>
        </ul>
      </div>
    ),
  },
  {
    id: 'mitigationFactors',
    header: 'Mitigation Factors',
    body: (
      <div>
        <p>
          Various mitigation factors to reduce the delays
          to the start of repair after an earthquake.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Building Downtime</li>
        </ul>
      </div>
    ),
  },
  {
    id: 'mitigationFundingSource',
    header: 'Funding Source',
    body: (
      <div>
        <p>
          Source of funding needed for repairs.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Financing time varies with funding source</li>
        </ul>
      </div>
    ),
  },
  {
    id: 'mitigationInspector',
    header: 'Inspector on Retainer',
    body: (
      <div>
        <p>
          There is a pre-arranged contract with an inspector to be available for post-earthquake
          safety inspection. Similar to BORP requirements.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Reduces inspection time</li>
        </ul>
      </div>
    ),
  },
  {
    id: 'modeShapes',
    header: 'Mode Shapes',
    body: (
      <div>
        <p>
          Lateral floor displacements at each level corresponding to the translational modes
          of vibration. The number of modes used by SP3 is equal to the number of stories or
          three, whichever is smaller.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Structural responses</li>
        </ul>
        <b>Default:</b>
        <p>
          Based on typical mass and stiffness distributions for the building type being analyzed.
        </p>
      </div>
    ),
  },
  {
    id: 'n90thPercentileLoss',
    header: 'Counted 90th Percentile',
    body: (
      <div>
        <p>
          There is a 90% probability that the repair cost will not exceed this value.
        </p>
      </div>
    ),
  },
  {
    id: 'needElevatorForFunction',
    header: 'Need Elevator for Function?',
    body: (
      <div>
        <p>
          If the tenants of the building (at upper floors) need the elevators in an operational
          state to be considered functional. If elevators are needed, then it is acceptable to have
          a limited amount of non-functional elevators as long as the undamaged elevators can
          reasonably transport the occupants to upper floors.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Downtime</li>
        </ul>
      </div>
    ),
  },
  {
    id: 'newPassword',
    header: 'Password Requirements',
    body: (
      <ul>
        <li>At least 8 characters</li>
        <li>At least 1 lowercase alphabetical character</li>
        <li>
          At least 1 of the following special characters: = + - ^ $ * [ ]
          {' '}
          { }
          {' '}
          ( ) ? ! @ # & % | _ ~
        </li>
      </ul>
    ),
  },
  {
    id: 'nonStructuralRetrofitCodeYear',
    header: 'Non Structural Retrofit Design Code',
    body: (
      <div>
        <p>
          Design Code (e.g. 2010 for ASCE 7-10) controlling non structural retrofit design.
        </p>
        <p>
          This impacts structural responses, component selection, quantity, and damage threshold.
        </p>
        <b>Note:</b>
        <p>
          If &quot;ASCE 2022/ASCE 2016 (non-struct/struct)&quot; is selected, SP3 automations of
          nonstructural component capacities are based on ASCE 7-22 requirements, but SP3
          automation of structural properties are based on ASCE 7-16 requirements.
        </p>
      </div>
    ),
  },
  {
    id: 'nonStructuralRetrofitLevel',
    header: 'Percentage Code Compliance',
    body: 'Modifies the design strength of the non-structural components as a percentage of the design code requirement.',
  },
  {
    id: 'nonStructuralRetrofitYear',
    header: 'Non Stuctural Retrofit Year',
    body: 'This impacts the design code to which the non structural components are retrofit.',
  },
  {
    id: 'numRealizations',
    header: 'Number of Earthquake Simulations',
    body: 'Number of Monte Carlo simulations to run per intensity level.',
  },
  {
    id: 'numberOfStories',
    header: 'Number of Stories',
    body: (
      <div>
        <p>Number of above-ground stories (does not include basement stories)</p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Inherent overstrength/additional stiffness defaulting</li>
          <li>Structural response prediction</li>
          <li>Automated selection of structural components</li>
          <li>Automated selection of non-structural components</li>
          <li>Computed capacities of non-structural components</li>
          <li>Collapse capacity defaulting</li>
        </ul>
      </div>
    ),
  },
  {
    id: 'occupancy',
    header: 'Occupancy',
    body: (
      <div>
        <p>The primary use of the building.</p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Automated selection of non-structural components</li>
          <li>Building occupant population modeling</li>
          <li>Estimated building replacement value</li>
        </ul>
      </div>
    ),
  },
  {
    id: 'occupancyCostMultiplier',
    header: 'Occupancy Cost Multiplier',
    body: (
      <div>
        <p>
          Cost multiplier to increase or decrease component repair
          costs based on the occupancy of the building.
        </p>
        <b>Impact of Risk Assessment:</b>
        <ul>
          <li>
            Scales component repair costs.
          </li>
        </ul>
        <b>Example:</b>
        <p className="pl-3">
          Hospitals are more expensive to repair than offices and residential buildings.
          Since, the expected repair costs in the FEMA P58 component database are generally
          representative of residential and office occupancy repairs, the Occupancy Cost Multiplier
          should be greater than 1.0 for hospitals.
        </p>
        <b>Note:</b>
        <p>
          This factor does not scale building replacement cost.
          Occupancy is already considered separately in SP3 building replacement cost defaulting.
        </p>
        <b>Default:</b>
        <p className="pl-3">
          1.0 for most occupancies.
        </p>
      </div>
    ),
  },
  {
    id: 'oop_drift',
    header: 'Out of Plane Drift',
    body: (
      <div>
        <p>
          Peak out of plane drift (ratio of relative deflection at the mid height of
          the wall to the wall span height, see figure) demands on out-of-plane walls
          at each story and principal direction of the building.
        </p>
      </div>
    ),
  },
  {
    id: 'oop_force',
    header: 'Out of Plane Connection Force',
    body: (
      <div>
        <p>
          Peak out of plane force in the connection between walls
          and diaphragm at each floor and principal direction of the building.
        </p>
      </div>
    ),
  },
  {
    id: 'p58Parallel',
    header: 'FEMA P58 Parallel',
    body: (
      <div>
        <p>
          Time to repair the damaged building to its pre-earthquake
          state assuming that all floors are repaired simultaneously.
        </p>
      </div>
    ),
  },
  {
    id: 'p58Serial',
    header: 'FEMA P58 Series',
    body: (
      <div>
        <p>
          Time to repair the damaged building to its pre-earthquake
          state assuming that floors are repaired sequentially.
        </p>
      </div>
    ),
  },
  {
    id: 'percentGlazing',
    header: 'Percent of Building Surface Glazed',
    body: (
      <div>
        <p>
          Percentage of the building envelope that is glazed (floor to ceiling windows).
        </p>
        <p>
          This impacts which components are selected and affects
          strength and stiffness of the building.
        </p>
      </div>
    ),
  },
  {
    id: 'percentOfExitsRequired',
    header: 'Percentage of Exits Required for Occupancy',
    body: (
      <p>
        Minimum percentage of exits required to meet egress requirements for building occupancy.
      </p>
    ),
  },
  {
    id: 'percentOfPartitionsAreFireBrake',
    header: 'Percentage of partitions that are firebreaks',
    body: (
      <div>
        <p>
          Percentage of interior partitions that serve as firebreaks.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ui>
          <li>Downtown</li>
        </ui>
      </div>
    ),
  },
  {
    id: 'performanceGroup',
    header: 'Performance Group',
    body: (
      <div>
        <p>
          A subset of components described by a single fragility that will be exposed
          to the same demand in response to earthquake shaking.
        </p>
        <p>
          More than one performance group may be desirable if you
          have components that are:
        </p>
        <ul>
          <li>
            on the same level, but will experience different demands
            from earthquake shaking (e.g. different drift demands
            on different frame lines)
          </li>
          <li>
            correlated, but only in certain areas of the building
            (e.g. tilt-up wall-to-diaphragm connections that are
            located on opposite sides of the building)
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 'period1',
    header: (
      <span>
        1
        <sup>st</sup>
        {' '}
        Mode Period (T
        <sub>1</sub>
        )
      </span>
    ),
    body: (
      <div>
        <p>
          First mode (translational) period in each direction.
          This period is for the “as-built” condition which includes gravity system
          and non-structural component contributions (not just the lateral force resisting system).
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Structural response prediction</li>
          <li>
            Anchoring of spectral acceleration for intensity
            assessments and collapse fragility
          </li>
        </ul>
        <b>Default:</b>
        <p className="pl-3">
          Based on structural system, building height, design code, and level of seismicity.
        </p>
      </div>
    ),
  },
  {
    id: 'period2',
    header: (
      <span>
        2
        <sup>nd</sup>
        {' '}
        Mode Period (T
        <sub>1</sub>
        )
      </span>
    ),
    body: (
      <div>
        <p>
          Second mode (translational) period in each direction.
          This period is for the “as-built” condition which includes gravity system
          and non-structural component contributions (not just the lateral force resisting system).
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Structural response prediction</li>
          <li>
            Anchoring of spectral acceleration for intensity
            assessments and collapse fragility
          </li>
        </ul>
        <b>Default:</b>
        <p className="pl-3">
          Based on structural system, building height, design code, and level of seismicity.
        </p>
      </div>
    ),
  },
  {
    id: 'period3',
    header: (
      <span>
        3
        <sup>rd</sup>
        {' '}
        Mode Period (T
        <sub>3</sub>
        )
      </span>
    ),
    body: (
      <div>
        <p>
          Third mode (translational) period in each direction.
          This period is for the “as-built” condition which includes gravity system
          and non-structural component contributions (not just the lateral force resisting system).
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Structural response prediction</li>
          <li>
            Anchoring of spectral acceleration for intensity
            assessments and collapse fragility
          </li>
        </ul>
        <b>Default:</b>
        <p className="pl-3">
          Based on structural system, building height, design code, and level of seismicity.
        </p>
      </div>
    ),
  },
  {
    id: 'pfa',
    header: 'Peak Floor Acceleration',
    body: (
      <div>
        <p>
          Peak floor acceleration at each floor and principal direction of the building.
        </p>
      </div>
    ),
  },
  {
    id: 'pfv',
    header: 'Peak Floor Velocity',
    body: (
      <div>
        <p>
          Peak floor velocity at each floor and principal direction of the building.
        </p>
      </div>
    ),
  },
  {
    id: 'pga',
    header: 'Peak Ground Acceleration',
    body: (
      <div>
        <p>
          Peak ground acceleration (PGA) is equal to the maximum ground acceleration that
          occurred during earthquake shaking at a location
        </p>
      </div>
    ),
  },
  {
    id: 'planTorsion',
    header: 'Plan Torsion',
    body: (
      <div>
        <p>
          Indicates if a torsional irregularity exists in the building (per ASCE 7-16 Table 12.3-1).
        </p>
        <b>Options Include:</b>
        <ul>
          <li>None: No irregularity</li>
          <li>Moderate: Torsional Irregularity (1a)</li>
          <li>Extreme: Extreme Torsional Irregularity (1b)</li>
        </ul>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Global strength and stiffness defaulting</li>
          <li>Structural response prediction</li>
          <li>Collapse capacity defaulting</li>
        </ul>
        <b>Default:</b>
        <p className="pl-3">
          No torsion irregularity.
        </p>
      </div>
    ),
  },
  {
    id: 'prequalifiedAccGround',
    header: 'Prequalified Equipment Acceleration Level at Ground Level',
    body: (
      <div>
        <p>
          The level of acceleration at the ground level for which prequalified equipment is
          qualified. SP3 uses this acceleration to estimate the capacity of prequalified equipment
          components at the ground level.
        </p>
        <b>Note:</b>
        <p>
          Accelerations for levels between the ground and roof are
          interpolated. For a given component, SP3 assumes a prequalification
          acceleration based on the highest level on which the component is located.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ui>
          <li>Automation of prequalified equipment component capacities.</li>
        </ui>
      </div>
    ),
  },
  {
    id: 'prequalifiedAccRoof',
    header: 'Prequalified Equipment Acceleration Level at Roof Level',
    body: (
      <div>
        <p>
          The level of acceleration at the roof level for which prequalified equipment is
          qualified. SP3 uses this acceleration to estimate the capacity of prequalified equipment
          components at the roof level.
        </p>
        <b>Note:</b>
        <p>
          Accelerations for levels between the ground and roof are
          interpolated. For a given component, SP3 assumes a prequalification
          acceleration based on the highest level on which the component is located.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ui>
          <li>Automation of prequalified equipment component capacities.</li>
        </ui>
      </div>
    ),
  },
  {
    id: 'prequalifiedDesignCodeYear',
    header: 'Pre-qualified Equipment Design Code Year',
    body: (
      <div>
        <p>
          If this option is used, the level of acceleration that is assumed for
          component prequalification is determined based on minimum building code
          requirements for the building code that is selected.
        </p>
        <b>Note:</b>
        <p>
          If you would like to set these accelerations levels yourself - choose
          &quot;User Defined&quot;
        </p>
        <b>Impact on Risk Assessment:</b>
        <ui>
          <li>Automation of pre-qualified equipment component capacities.</li>
        </ui>
      </div>
    ),
  },
  {
    id: 'probCollapseMce',
    header: 'Probability of Collapse at MCE Intensity',
    body: (
      <div>
        <p>
          Probability that collapse occurs somewhere in the building, given the MCE
          intensity. This is different from the collapse definition used in FEMA P-154,
          which is the probability of experiencing collapse at a given location in the
          building. The FEMA P-154 probability of collapse can be converted to FEMA P-58
          probability of collapse by dividing the FEMA P-154 probability of collapse by
          the expected fraction of the floor area that is affected by collapse.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>
            Collapse capacity
          </li>
        </ul>
        <b>Default:</b>
        <p className="pl-3">
          Based on the default FEMA P-154 score obtained using a simplified FEMA P-154 checklist
          based on specified structural system type, building age, soil type, irregularities,
          and risk category.
        </p>
        <p>
          <strong>Knowledge Base:</strong>
          {' '}
          <a target="blank" href="https://support.sp3risk.com/space/KnowledgeBase/1777633972/Collapse+Automation#Note-on-S-a-(MCE-R-)-Anchoring">
            Note on Sa(MCER) Anchoring
          </a>
        </p>
      </div>
    ),
  },
  {
    id: 'quantityDir1',
    header: 'Direction 1 Quantity',
    body: (
      <div>
        <p>
          This is the component quantity in direction 1 for
          each location specified in the first column.
        </p>
      </div>
    ),
  },
  {
    id: 'quantityDir2',
    header: 'Direction 2 Quantity',
    body: (
      <div>
        <p>
          This is the component quantity in direction 2 for
          each location specified in the first column.
        </p>
      </div>
    ),
  },
  {
    id: 'quantityNonDir',
    header: 'Non Directional Quantity',
    body: (
      <div>
        <p>
          This is the component quantity for each location
          specified in the first column.
        </p>
      </div>
    ),
  },
  {
    id: 'quantityPerUnit',
    header: 'Quantity Per Unit',
    body: (
      <div>
        <p>
          Relates the fragility “unit” to a physical quantity.
        </p>
        <b>Example:</b>
        <p className="pl-3">
          “100 sf” means that for every 100 square feet of
          the component you would populate 1 unit of the component.
        </p>
      </div>
    ),
  },
  {
    id: 'rediOutput',
    header: 'REDi Repair Time (2013) (without impeding factors)',
    body: (
      <p>
        The recovery times shown here do
        {' '}
        <strong>not</strong>
        {' '}
        include the impeding factors that are
        selected by the user. The REDi method impeding factor calculation is only calibrated to the
        475/Design level event. The REDi downtime estimation for the 475/Design level event is
        available in the PDF reports.
      </p>
    ),
  },
  {
    id: 'regionCostMultiplier',
    header: 'Region Cost Multiplier',
    body: (
      <div>
        <p>
          Cost multiplier to account for regional variation in construction and repair costs.
        </p>
        <b>Impact of Risk Assessment</b>
        <ul>
          <li>
            Default building cost per square foot and component repair costs.
          </li>
        </ul>
        <b>Default:</b>
        <p className="pl-3">
          Value based on location (state).
        </p>
      </div>
    ),
  },
  {
    id: 'reoccupancy',
    header: 'Re-Occupancy',
    body: (
      <div>
        <p>
          Time to complete repairs such that the damaged building is safe to use as shelter.
        </p>
      </div>
    ),
  },
  {
    id: 'repairCostBreakdown',
    header: 'Repair Cost Breakdown',
    body: (
      <div>
        <p>
          The expected cost to repair the damaged building to its pre-earthquake state disaggregated
          by category (i.e. losses due to collapse, residual drift, and each category of building
          components). This is presented as a percent of the total building replacement value.
        </p>
      </div>
    ),
  },
  {
    id: 'repairCostByIntensity',
    header: 'Repair Cost By Intensity',
    body: (
      <div>
        <p>
          The cost to repair the damaged building to its pre-earthquake state
          presented as a percent of the total building replacement value.
        </p>
      </div>
    ),
  },
  {
    id: 'repairCostPerUnit',
    header: 'Repair Cost Per Unit',
    body: (
      <div>
        <p>
          The amount it will cost to repair a damaged unit of the component,
          including the effects of volume discounting (as more units
          are repaired, they may become cheaper to repair).
        </p>
        <p>
          The displayed cost is in 2011 US dollars (because that
          is when the FEMA P-58 database was created) and a
          date-based inflation factor is used to increase cost to current day.
        </p>
        <p>
          For damaged quantities that fall between the “Low” and “High”
          quantity thresholds, the per-unit repair cost is linearly interpolated.
        </p>
      </div>
    ),
  },
  {
    id: 'repairCostExposureHeader',
    header: 'Repair Cost - Exposure',
    body: (
      <div>
        <p>
          For each component group, this is the cost to completely replace the
          components throughout the building, assuming the most expensive
          damage state for all components (includes volume discounting).
        </p>
        <p>
          Note: this does not need to add up to the total building replacement
          value, but rather gives a sense of how much potential each component
          group has to contribute to the loss.
        </p>
      </div>
    ),
  },
  {
    id: 'repairCostsScaleWithBuildingValue',
    header: 'Scale component repair costs with building value',
    body: (
      <div>
        <p>
          This option scales component costs so that their repair
          costs relative to the value of the building is consistent
          with expected values, based on SP3 defaults.
        </p>
        <b>Example:</b>
        <p>
          If the user-defined Total Cost per Square
          Foot is double the SP3 default Total Cost per Square
          Foot, then a factor of 2.0 is applied to component
          repair costs.
        </p>
        <b>Note:</b>
        <p>
          Additional cost modifiers for component groups,
          which compound with this effect, can be specified in
          the building components page.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Building value modifier applied to component repair costs.</li>
        </ul>
      </div>
    ),
  },
  {
    id: 'repairTimeMethod',
    header: 'Recovery time method',
    body: (
      <div>
        <p>
          Select the method that is used to calculate the building recovery time.
        </p>
        <ul>
          <li>
            REDi (2013): The REDi methodology was published by Arup in 2013. This method estimates
            building re-occupancy, functional recovery, and full recovery times based on
            component-level damage; impedance factors (inspection, contractor, etc.) are only
            included at one intensity level (the 475 or DE).
          </li>
          <li>
            ATC-138 Beta: The ATC-138 functional recovery method estimates building
            re-occupancy, functional recovery, and full recovery times through a series of fault
            trees to explicitly map component damage to system operation and building function.
            Downtimes, including impedance factors, are assessed  at every shaking intensity. This
            method is currently under development.
          </li>
        </ul>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Recovery Time (down time) Calculation</li>
        </ul>
      </div>
    ),
  },
  {
    id: 'repairTimePerUnit',
    header: 'Repair Time Per Unit',
    body: (
      <div>
        <p>
          The worker days it will take to repair a damaged unit of the component, including economy
          of scale (as more units are repaired, they may become faster to repair per unit).
        </p>
        <p>
          For damaged quantities that fall between the &ldquo;Low&rdquo; and &ldquo;High&rdquo;
          quantity thresholds, the per-unit repair time is linearly interpolated.
        </p>
      </div>
    ),
  },
  {
    id: 'returnPeriodDE',
    header: 'DE',
    body: (
      <div>
        <p>
          The Design Earthquake (DE) shaking determined based on the most current design code
          and hazard curves.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>
            If the REDi recovery time option is selected,
            the recovery time will be calculated at the
            more intense of the DE and 475 year intensity.
          </li>
          <li>
            The USRC rating is determined at the more intense of the DE and 475 year shaking.
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 'returnPeriodMCE',
    header: 'MCE',
    body: (
      <div>
        <p>
          The current code Maximum Considered Earthquake return period.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>
            The probability of collapse at MCE calculated form FEMA P-154 is anchored
            using this shaking hazard (otherwise the code MCE spectra is used).
          </li>
        </ul>
      </div>
    ),
  },
  {
    id: 'returnPeriods',
    header: 'Return Period',
    body: (
      <div>
        <p>
          The average time (in years) between ground motions equal
          to or greater than the specified intensity.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>
            Intensity-based risk analysis results (e.g. repair cost, recovery time, etc.)
            will be output for each return period specified.
          </li>
          <li>Time-based assessments are also based upon the return periods defined here.</li>
        </ul>
        <b>Default:</b>
        <p>
          Nine return periods are defaulted between 22 and 2475 years.
          This range of intensities supports intensity-based
          assessment as well as time-based assessment.
        </p>
      </div>
    ),
  },
  {
    id: 'returnPeriodsForGroundMotionUpload',
    header: 'Return Period',
    body: (
      <div>
        <p>
          The average time (in years) between ground motions equal
          to or greater than the specified intensity.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>
            Intensity-based risk analysis results (e.g. repair cost, recovery time, etc.)
            will be output for each return period specified.
          </li>
          <li>Time-based assessments are also based upon the return periods defined here.</li>
        </ul>
      </div>
    ),
  },
  {
    id: 'ridr',
    header: 'Residual Story Drift Ratio',
    body: (
      <div>
        <p>
          Residual story drift ratio (perminent story displacement/story height)
          at each story and principal direction of the building.
        </p>
      </div>
    ),
  },
  {
    id: 'riskCategory',
    header: 'Risk Category',
    body: (
      <div>
        <p>
          Code Risk Category that affects design requirements (see ASCE 7-16 Table 1.5.1).
          This will only affect building codes UBC 1976 and later.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Design code parameters</li>
          <li>Automated selection of non-structural components (e.g. ceilings)</li>
          <li>Computed capacities of non-structural components</li>
          <li>Collapse capacity defaulting (capacity increase for higher risk categories)</li>
        </ul>
        <b>Default:</b>
        <p className="pl-3">
          Based on building occupancy
        </p>
      </div>
    ),
  },
  {
    id: 'rollupLocations',
    header: 'Locations',
    body: (
      <div>
        <p>
          Location of all components across the height of the building.
          The displayed locations include all performance groups associated with the component.
        </p>
        <b>Example 3-story building locations:</b>
        <div className="pt-3">
          <div className={locationsImage} />
        </div>
      </div>
    ),
  },
  {
    id: 'saOverVUlt',
    header: (
      <span>
        S
        <sub>a</sub>
        (T
        <sub>1</sub>
        )/
        v
        <sub>ult</sub>
      </span>
    ),
    body: (
      <div>
        <p>
          The ratio of spectral acceleration at the fundamental period of
          the building to ultimate base shear strength.
        </p>
      </div>
    ),
  },
  {
    id: 'rollupTotalQuantity',
    header: 'Quantity',
    body: (
      <div>
        <p>
          Total quantity of components across all levels, directions, and performance groups.
        </p>
      </div>
    ),
  },
  {
    id: 'seismicImportanceFactor',
    header: (
      <span>
        Seismic Importance Factor, I
        <small>e</small>
      </span>
    ),
    body: (
      <div>
        <p>
          Code factor for strength design (see ASCE 7-16 Section 1.5).
          Changing this factor will not affect the risk category.
          This will only affect building codes UBC 1976 and later.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Global strength and stiffness defaulting</li>
          <li>Collapse capacity increase for higher Ie</li>
        </ul>
        <b>Default:</b>
        <p className="pl-3">
          Based on assigned Risk Category
        </p>
      </div>
    ),
  },
  {
    id: 'serviceLocations',
    header: 'Service Locations',
    body: (
      <div>
        <p>
          The service locations for a component are the
          <strong>stories</strong>
          {' '}
          serviced by that
          component. Service locations can be expressed as a comma separated list of stories
          (1, 2, 3) or ranges (1-3, 5-7) of stories
        </p>
      </div>
    ),
  },
  {
    id: 'siteAddress',
    header: 'Site Address',
    body: 'Used to find the coordinates for the building being analyzed.',
  },
  {
    id: 'siteClass',
    header: 'Site Class',
    body: (
      <div>
        <p>Soil type at the site (defined by ASCE 7-16 Section 20.3).</p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Design code parameters</li>
          <li>USGS seismic shaking hazard lookup</li>
        </ul>
        <b>Default:</b>
        <p className="pl-3">
          Based on the&nbsp;
          <a
            href="https://earthquake.usgs.gov/data/vs30"
            target="_blank"
            rel="noopener noreferrer"
          >
            USGS Global Vs30 Model
          </a>
        </p>
      </div>
    ),
  },
  {
    id: 'siteCoordinates',
    header: 'Site Coordinates',
    body: (
      <div>
        <p>
          Modifying the coordinates here will not
          change the address that is associated with the model.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Soil properties defaulting</li>
          <li>USGS seismic shaking hazard lookup</li>
          <li>Design code parameters</li>
        </ul>
      </div>
    ),
  },
  {
    id: 'story',
    header: 'Stories',
    body: (
      <div>
        <p>
          Stories can be expressed as a comma separated
          list of values (1, 2, 3) or ranges (1-3, 5-7)
        </p>
        <b>Example 3-Story Building</b>
        <div className="pt-3">
          <div className={storiesImage} />
        </div>
      </div>
    ),
  },
  {
    id: 'structuralRetrofitCodeYear',
    header: 'Structural Retrofit Design Code',
    body: (
      <div>
        <p>
          Design Code (e.g. 2010 for ASCE 7-10) controlling structural retrofit design.
        </p>
        <p>
          This impacts structural responses, component selection, quantity, and damage threshold.
        </p>
        <b>Note:</b>
        <p>
          If &quot;ASCE 2022/ASCE 2016 (non-struct/struct)&quot; is selected, SP3 automations of
          nonstructural component capacities are based on ASCE 7-22 requirements, but SP3
          automation of structural properties are based on ASCE 7-16 requirements.
        </p>
      </div>
    ),
  },
  {
    id: 'structuralRetrofitLevel',
    header: 'Percentage Code Compliance',
    body: 'Modifies the design strength as a percentage of the design code requirement.',
  },
  {
    id: 'structuralRetrofitYear',
    header: 'Structural Retrofit Year',
    body: 'This impacts structural responses, component selection, quantity, and damage threshold.',
  },
  {
    id: 'structuralSystem',
    header: 'Structural System',
    body: (
      <div>
        <b>Acronyms:</b>
        <ul>
          <li>BRBF: Buckling Restrained Brace Frame</li>
          <li>CBF: Concentric Braced Frame</li>
          <li>PRESS: Precast Seismic Structural System</li>
          <li>RC: Reinforced Concrete</li>
          <li>RM: Reinforced Masonry</li>
          <li>WLF: Wood Light Frame</li>
        </ul>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Design code parameters</li>
          <li>Expected overstrength/additional stiffness defaulting</li>
          <li>Structural response prediction</li>
          <li>Automated selection of structural components</li>
          <li>Collapse capacity defaulting</li>
        </ul>
      </div>
    ),
  },
  {
    id: 'tdmfBraceConfig',
    header: 'Damper Configuration',
    body: (
      <div>
        <p>
          Configuration of the damper bays, i.e., Chevron, Single Diagonal, or Modified Chevron
          with horizontal dampers. Note, V-bracing and 2-story X-bracing are modeled as
          Chevron in SP3, because the damper angle and damper design demands are the same.
        </p>
        <b>Impact on Risk Assessment</b>
        <ul>
          <li>Component population</li>
        </ul>
        <b>Default:</b>
        <p className="pl-3">
          Chevron
        </p>
      </div>
    ),
  },
  {
    id: 'tdmfBayLength',
    header: 'Bay Length',
    body: (
      <div>
        <p>
          Typical length of damper frame bays. This does not affect the gravity system spacing
          that SP3 assumes for defaulting gravity system components. SP3 uses this information
          to determine the damper orientation that is used for defaulting appropriate damper
          properties (i.e., force capacity and stroke).
        </p>
        <b>Impact on Risk Assessment</b>
        <ul>
          <li>Component population</li>
        </ul>
        <b>Default:</b>
        <p className="pl-3">
          25 feet
        </p>
      </div>
    ),
  },
  {
    id: 'tdmfDamperExposure',
    header: 'Damper Exposure',
    body: (
      <div>
        <p>
          Used to specify whether the Taylor Damped Moment Frame damper assemblies
          are concealed within a wall or exposed. Exposed, in this case, does not mean exposed to
          the elements just that it is accessible for repairs without needing to remove drywall
          and wall framing.
        </p>
        <b>Impact on Risk Assessment</b>
        <ul>
          <li>
            Component population. Repair costs and repair times for exposed damper assemblies
            are lower than when they are concealed within a wall.
          </li>
        </ul>
        <b>Default:</b>
        <p className="pl-3">
          In wall
        </p>
      </div>
    ),
  },
  {
    id: 'timeScaleFactor',
    header: 'Time Scale Factor',
    body: (
      <div>
        <p>
          Scale factor to apply to the component repair time consequences;
          e.g. a scale factor of 1.5 would increase the component repair time by 50%.
        </p>
        <b>Default:</b>
        <p className="pl-3">
          Default scale factor is 1.
        </p>
      </div>
    ),
  },
  {
    id: 'typeOfResistance',
    header: 'Type of Resistance',
    body: (
      <div>
        <p>
          The source of significant strength and stiffness for the internal lines
          of the lateral force resisting system (LFRS).
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Structural response prediction</li>
          <li>Global strength and stiffness defaulting</li>
        </ul>
      </div>
    ),
  },
  {
    id: 'useSmartEquipmentComponents',
    header: 'Use Smart Equipment Components',
    body: (
      <div>
        <p>
          &quot;Smart&quot; components use multiple fragility functions to model an individual
          building component, such that various failure modes (e.g., failure of
          anchorage only, damage to equipment only, or damage to both anchorage and
          equipment) are captured more precisely. Components are modeled with
          separate fragilities that represent anchorage, unanchored equipment (or
          piping), and anchored equipment (or piping). For cases where the anchorage
          fails, the equipment (or piping) is modeled using the unanchored fragility
          function. For cases in which the anchorage does not fail, the fully
          anchored equipment (or piping) fragility is used.
        </p>
      </div>
    ),
  },
  {
    id: 'useSmartPipingComponents',
    header: 'Use Smart Piping Components',
    body: (
      <div>
        <p>
          &quot;Smart&quot; piping components use multiple fragility functions
          to model an individual building component, such that various failure modes
          (e.g., failure of bracing only, damage to piping only, or damage to both bracing
          and piping) are captured more precisely. Components are modeled with separate
          fragilities that represent bracing, unbraced piping, and braced piping. For
          cases where the bracing fails, the piping is modeled using the unbraced fragility
          function. For cases in which the bracing does not fail, the braced piping fragility
          is used.
        </p>
      </div>
    ),
  },
  {
    id: 'useUpdatedElevatorComponents',
    header: 'Use Updated Elevator Components',
    body: (
      <div>
        <p>
          Component modeling for elevators is undergoing substantial improvement as part
          of the ATC-138 project. These improvements include separating the elevator system
          into multiple components and refining the capacities of those components to be
          more consistent with ASME elevator design and construction practices. Updated
          elevator components are intended for buildings that have elevator design and
          installation conforming to ASME 17.1 2007 or similar.
        </p>
      </div>
    ),
  },
  {
    id: 'verticalIrregularity',
    header: 'Soft or Weak Story Vertical Irregularity',
    body: (
      <div>
        <p>
          Indicates if a weak or soft story exists in the
          building (per ASCE 7-16 Table 12.3-2).
        </p>
        <b>Options Include:</b>
        <ul>
          <li>None: No irregularity</li>
          <li>
            Moderate: Indicates “Stiffness-Soft Story Irregularity” (1a) and/or
            “Discontinuity in Lateral Strength-Weak Story Irregularity” (5a)
          </li>
          <li>
            Extreme: Indicates “Stiffness-Extreme Soft Story Irregularity” (1b)
            and/or “Discontinuity in Lateral Strength-Extreme Weak Story Irregularity” (5b)
          </li>
        </ul>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Global strength and stiffness defaulting</li>
          <li>Structural response prediction</li>
          <li>Automated selection of structural components</li>
          <li>Collapse capacity defaulting</li>
        </ul>
        <b>Default:</b>
        <p className="pl-3">
          No soft or weak story.
        </p>
      </div>
    ),
  },
  {
    id: 'vs30',
    header: 'Vs30',
    body: 'Average shear wave velocity in the first 30 meters below the ground surface.',
  },
  {
    id: 'yearOfConstruction',
    header: 'Year of Construction',
    body: (
      <div>
        <p>
          The year that the building was constructed, which is then used to approximate
          what design code was used.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Design code parameters</li>
          <li>Automated selection of structural components</li>
          <li>Automated selection of non-structural components</li>
          <li>Computed capacities of non-structural components</li>
          <li>Collapse capacity defaulting</li>
        </ul>
      </div>
    ),
  },
  {
    id: 'yieldStoryDrift',
    header: 'Yield Story Drift',
    body: (
      <div>
        <p>Story drift at which yielding first occurs.</p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Structural response prediction (strong influence on residual drift prediction)</li>
        </ul>
        <b>Default:</b>
        <p className="pl-3">
          Based on structural system.
        </p>
      </div>
    ),
  },
  {
    id: 'ylbfBayLength',
    header: 'Bay Length',
    body: (
      <div>
        <p>
          Typical length of braced frame bays. This does not affect the gravity system spacing that
          SP3 assumes for defaulting gravity system components. SP3 uses this information to select
          the appropriate structural components and to compute the brace orientation that is used
          for defaulting structural properties.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Component population</li>
          <li>Global strength and stiffness defaulting</li>
        </ul>
        <b>Default:</b>
        <p className="pl-3">
          25 feet.
        </p>
      </div>
    ),
  },
  {
    id: 'ylbfBraceConfig',
    header: 'Brace Configuration',
    body: (
      <div>
        <p>
          Configuration of the braced bays, i.e. Chevron, Diagonal, or
          Single-Story X. Note, V-bracing and 2-story X-bracing are modeled as
          Chevron in SP3, because the brace angle and brace design demands are the same.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Component population</li>
          <li>Global strength and stiffness defaulting</li>
        </ul>
        <b>Default:</b>
        <p className="pl-3">
          Chevron
        </p>
      </div>
    ),
  },
  {
    id: 'ylbfBraceExposure',
    header: 'Brace Exposure',
    body: (
      <div>
        <p>
          This input is used to specify whether the Simpson Strong-Tie Yield Link
          brace assemblies are concealed within a wall or exposed. Exposed,
          in this case, does not mean exposed to the elements- just that it
          is accessible for repairs without needing to remove drywall and
          wall framing.
        </p>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>
            Component population. Component population. Repair costs and repair
            times for exposed brace assemblies are lower than when they are
            concealed within a wall.
          </li>
        </ul>
        <b>Default:</b>
        <p className="pl-3">
          In Wall
        </p>
      </div>
    ),
  },
  {
    id: 'ylbfNumFusesPerBrace',
    header: 'Number of Simpson Yield Linki Connections per Brace',
    body: (
      <div>
        <ul>
          <li>
            1 – Simpson Strong-Tie Yield-Link connection at one end of each brace.
          </li>
          <li>
            2 – Simpson Strong-Tie Yield-Link connection at both ends of each brace.
          </li>
        </ul>
        <b>Impact on Risk Assessment:</b>
        <ul>
          <li>Component population</li>
          <li>Global stiffness defaulting</li>
        </ul>
        <b>Default:</b>
        <p className="pl-3">
          Based on seismic design category. Low seismic sites default to one yield
          link connection per brace and high seismic sites default to two yield
          link connections per brace.
        </p>
      </div>
    ),
  },
  {
    id: 'example1',
    header: 'Example 1',
    body: (
      <div>
        This is tooltip example 1
      </div>
    ),
  },
  {
    id: 'example2',
    header: 'Example 2',
    body: (
      <div>
        This is tooltip example 2
      </div>
    ),
  },
  {
    id: 'example3',
    header: 'Example 3',
    body: (
      <div>
        This is tooltip example 3
      </div>
    ),
  },
  {
    id: 'example4',
    header: 'Example 4',
    body: (
      <div>
        This is tooltip example 4
      </div>
    ),
  },
  {
    id: 'example5',
    header: 'Example 5',
    body: (
      <div>
        This is tooltip example 5
      </div>
    ),
  },
];

const tooltipsById = keyBy('id', tips);

export default tooltipsById;
