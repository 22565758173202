/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { connect } from 'react-redux';
import {
  EDP_LOCATION,
  IS_DIRECTIONAL,
} from '@hbrisk/sp3-risk-model-support/components/translated/attributes/names/index.js';
import { YES_NO_VALUES } from '@hbrisk/sp3-risk-model-support/components/translated/attributes/values/index.js';
import { selectComponentByUuid } from '#selectors/entities/components.js';
import renderLocations from './renderLocations.jsx';

const mapStateToProps = (state, ownProps) => {
  const { componentUuid } = ownProps;
  const component = selectComponentByUuid(state, { uuid: componentUuid });
  const edpLocation = component ? component[EDP_LOCATION] : null;
  const isDirectional = component ? component[IS_DIRECTIONAL] : null;
  return ({
    edpLocationType: edpLocation,
    isDirectional: isDirectional === YES_NO_VALUES.YES,
  });
};
export default connect(mapStateToProps)(renderLocations);
