// @flow
import type { Element, Node } from 'react';

import React from 'react';
import { Input } from 'reactstrap';
import { isEmpty } from 'lodash';

type Props = {|
  input: {
    onBlur: Function,
    value: string,
  },
  id: string,
  type: string,
  accept: string,
  disabled: boolean,
  meta: {
    touched: boolean,
    error: string,
  },
  children?: Node,
  inputClassName: string,
|};

const RenderUncontrolledInput = (props: Props): Element<typeof Input> => {
  const {
    disabled,
    input,
    id,
    type,
    accept,
    meta,
    children,
    inputClassName,
  } = props;

  const { touched, error } = meta;
  const hasError = touched && !isEmpty(error);

  const { value, onBlur, ...restInput } = input;
  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <Input
      {...restInput}
      id={id}
      type={type}
      invalid={hasError}
      className={inputClassName}
      disabled={disabled}
      accept={accept}
      onBlur={() => onBlur(value)}
    >
      {children}
    </Input>
  );
  /* eslint-enable react/jsx-props-no-spreading */
};

RenderUncontrolledInput.defaultProps = {
  children: null,
};

export default RenderUncontrolledInput;
