// @flow
import classNames from 'classnames';
import React from 'react';

import type { Element } from 'react';
import BreadCrumb from '#components/support/layout/SubNav/BreadCrumbTrail/BreadCrumb/index.jsx';
import { breadCrumbDivider } from './index.module.scss';

type Props = {
  className?: string,
};

const BreadCrumbDivider = ({ className }: Props): Element<typeof BreadCrumb> => (
  <BreadCrumb className={classNames(breadCrumbDivider, 'bread-crumb-divider', className)}>&gt;</BreadCrumb>
);

BreadCrumbDivider.defaultProps = {
  className: '',
};

export default BreadCrumbDivider;
