// @flow
import type { ComponentType } from 'react';
import React, { useEffect } from 'react';

type Props = {|
  pollComponentCategories: Function,
|};

type GetComponent = (ComponentType<Object>) => ComponentType<Props>;

const getComponent: GetComponent = (C) => (
  (props: Props) => {
    const { pollComponentCategories, ...rest } = props;
    useEffect(pollComponentCategories);
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <C {...rest} />
    );
  }
);

export default getComponent;
