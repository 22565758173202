/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import { touch } from 'redux-form';
import {
  AUTOFILL_MODEL_FORM_FIELD,
  SHOW_FIELD, HIDE_FIELD,
  REDUX_FORM_UPDATE_SYNC_ERRORS,
  INITIALIZE_FORM,
  UNAUTOFILL_MODEL_FORM_FIELD,
} from '#constants/actionTypes.js';
import { modelTypesByForm } from '#constants/models/modelTypes/index.js';
import calculateFieldArrayFieldNames from '#src/js/support/utility/form/calculateFieldArrayFieldNames/index.js';

export const showModelFormField = (form, field) => ({
  type: SHOW_FIELD,
  meta: {
    form,
  },
  payload: {
    name: field,
  },
});

export const hideModelFormField = (form, field) => ({
  type: HIDE_FIELD,
  meta: {
    form,
  },
  payload: {
    name: field,
  },
});

export const initializeModelFormFields = (form, values, visibleFields, autofilled) => ({
  type: INITIALIZE_FORM,
  meta: {
    form,
  },
  payload: {
    values,
    visibleFields,
    autofilled,
  },
});

export const autofillModelFormField = (form, field, value) => ({
  type: AUTOFILL_MODEL_FORM_FIELD,
  meta: { form, field },
  payload: value,
});

export const unautofillModelFormField = (form, field) => ({
  type: UNAUTOFILL_MODEL_FORM_FIELD,
  meta: { form, field },
});

export const touchAllModelFormFields = (modelFormName, values) => {
  const { fields } = modelTypesByForm[modelFormName];
  const toTouch = fields.reduce(
    (acc, field) => {
      const { itemSchema, name, type } = field;
      return type === 'FieldArray'
        ? [...acc, ...calculateFieldArrayFieldNames(name, itemSchema, values)]
        : [...acc, name];
    },
    []
  );
  return touch(modelFormName, ...toTouch);
};

export const updateSyncErrors = (form, syncErrors, error) => ({
  type: REDUX_FORM_UPDATE_SYNC_ERRORS,
  meta: { form },
  payload: {
    syncErrors,
    error,
  },
});
