/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import capitalize from 'lodash/fp/capitalize.js';
import classNames from 'classnames';
import React from 'react';
import ReactTable from 'react-table';
import LinkCell from './LinkCell/index.jsx';
import {
  responsesTable,
  responsesTableTd,
  responsesTableTh,
  td,
  th,
} from './StructuralResponsesTable.module.scss';

const columnsWithDefaults = (withoutDefaults: any) => withoutDefaults.map((column) => (
  {
    ...{
      className: classNames(td, responsesTableTd),
      headerClassName: classNames('font-weight-bold', th, responsesTableTh),
    },
    ...column,
  }
));

const myColumns = columnsWithDefaults([
  {
    id: 'name',
    accessor: 'name',
    Header: 'Structural Response',
    maxWidth: 325,
    Cell: LinkCell,
  },
  {
    id: 'methodDescription',
    accessor: 'methodDescription',
    Header: 'Source',
    maxWidth: 300,
    className: classNames(td, responsesTableTd),
  },
  {
    id: 'edpUnit',
    accessor: 'edpUnit',
    Header: 'Unit',
    maxWidth: 75,
    className: classNames(td, responsesTableTd),
  },
  {
    id: 'locationType',
    accessor: 'locationType',
    Header: 'Dimension',
    maxWidth: 100,
    Cell: (data) => {
      const { original: { locationType } } = data;
      return (
        <span>
          {capitalize(locationType)}
        </span>
      );
    },
  },
]);

type StructuralResponse = {
  index: number,
  edpKey: string,
  name: string,
  method: string,
  methodDescription: string,
};

type Props = {|
  data: Array<StructuralResponse>,
  error: string,
|}

const StructuralResponsesTable = (props: Props): Element<typeof ReactTable | 'div'> => {
  const { error, data } = props;

  if (error) {
    return (<div className="text-danger">{error}</div>);
  }

  return (
    <ReactTable
      className={classNames(['responses-table', responsesTable, '-striped', 'rounded'])}
      columns={myColumns}
      data={data}
      defaultPageSize={100000}
      showPagination={false}
      minRows={0}
      resizable={false}
    />
  );
};
export default StructuralResponsesTable;
