/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
// @flow
import type { Element } from 'react';

import React from 'react';
import { Row, Col } from 'reactstrap';
import { FieldArray } from 'redux-form';
import type { EdpKey } from '#constants/edps/index.js';
import EdpStructuralResponse from './EdpStructuralResponse/index.js';

type RenderEdpResponsesProps = {|
  modelId: string,
  edpKey: EdpKey,
  fields: {
    get: Function,
    getAll: Function,
    map: Function,
    push: Function,
  },
  form: string,
|}

const renderEdpStructuralResponses = ({
  edpKey, fields, form, modelId,
}: RenderEdpResponsesProps): any => {
  const edpResponses = fields.map((field, index) => {
    const { edpKey: id, method, upload } = fields.get(index);
    return id === edpKey
      ? (
        <EdpStructuralResponse
          modelId={modelId}
          form={form}
          field={field}
          edpKey={edpKey}
          method={method}
          responseId={upload}
          key={edpKey}
        />
      ) : null;
  });
  return (
    <Row>
      <Col>
        {edpResponses}
      </Col>
    </Row>
  );
};

type Props = {|
  edpKey: EdpKey,
  modelId: string,
  form: string,
|};

const StructuralResponseView = (props: Props): Element<typeof FieldArray> => {
  const { edpKey, modelId, form } = props;
  return (
    <FieldArray
      form={form}
      edpKey={edpKey}
      modelId={modelId}
      name="structuralResponses"
      component={renderEdpStructuralResponses}
    />
  );
};

export default StructuralResponseView;
