import * as values from '@hbrisk/sp3-risk-model-support/components/translated/attributes/values/index.js';
import * as displayText from '#constants/components/form/fields/optionDisplayText.js';

const groupToStructuralOrNonStructural = (group) => {
  if (!group) {
    return undefined;
  }
  if (group === values.GROUP_VALUES.STRUCTURAL) {
    return displayText.GROUP_STRUCTURAL;
  }
  return displayText.GROUP_NON_STRUCTURAL;
};

export default groupToStructuralOrNonStructural;
