import React from 'react';
import {
  Route, Routes,
} from 'react-router-dom';
import {
  MainContentArea,
} from '#src/js/components/support/layout/SidebarLayout/index.js';
import ComponentSummary from '#components/pages/Components/Dashboard/Main/ComponentSummary/index.js';
import { COMPONENT_UUID } from '#constants/pathStrings.js';

const ComponentsDashboardSidebar = () => (
  <MainContentArea md="8" top="40px">
    <Routes>
      <Route path={`:${COMPONENT_UUID}`} element={<ComponentSummary />} />
      <Route path="/*" element={<ComponentSummary />} />
    </Routes>
  </MainContentArea>
);

export default ComponentsDashboardSidebar;
