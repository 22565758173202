// @flow
import type { Element } from 'react';

import { Nav, NavItem } from 'reactstrap';
import React from 'react';
import { Sidebar, SidebarNavLink } from '#components/support/layout/SidebarLayout/index.js';
import {
  COMPONENT_GROUPING,
  GENERAL,
  FRAGILITY_INFO,
  CAPACITY_CALCULATION,
  RECOVERY_TIME,
  SYSTEM_SPECIFIC_INFO,
  DAMAGE_STATES,
  CONSEQUENCES,
  REPAIR_COST,
  REPAIR_TIME,
  LIFE_SAFETY,
  OTHER,
} from '#constants/pathStrings.js';

type Props = {
  top?: string,
  md: string,
};
const ComponentSidebar = ({ md, top }: Props): Element<typeof Sidebar> => (
  <Sidebar md={md} top={top}>
    <Nav vertical>
      <NavItem>
        <SidebarNavLink to={`${GENERAL}`}>
          General
        </SidebarNavLink>
      </NavItem>
      <NavItem>
        <SidebarNavLink to={`${FRAGILITY_INFO}`}>
          Fragility Info
        </SidebarNavLink>
      </NavItem>
      <NavItem>
        <SidebarNavLink to={`${COMPONENT_GROUPING}`}>
          Component Grouping
        </SidebarNavLink>
      </NavItem>
      <NavItem>
        <SidebarNavLink to={`${CAPACITY_CALCULATION}`}>
          Capacity Calculation
        </SidebarNavLink>
      </NavItem>
      <NavItem>
        <SidebarNavLink to={`${RECOVERY_TIME}`}>
          Recovery Time
        </SidebarNavLink>
      </NavItem>
      <NavItem>
        <SidebarNavLink to={`${SYSTEM_SPECIFIC_INFO}`}>
          System Specific Info
        </SidebarNavLink>
      </NavItem>
      <NavItem>
        <SidebarNavLink to={`${DAMAGE_STATES}`}>
          Damage States
        </SidebarNavLink>
      </NavItem>
      <NavItem>
        <SidebarNavLink to={`${CONSEQUENCES}`}>
          Consequences
        </SidebarNavLink>
      </NavItem>
      <NavItem>
        <SidebarNavLink to={`${CONSEQUENCES}/${REPAIR_COST}`} level={2}>
          Repair Cost
        </SidebarNavLink>
      </NavItem>
      <SidebarNavLink to={`${CONSEQUENCES}/${REPAIR_TIME}`} level={2}>
        Repair Time
      </SidebarNavLink>
      <NavItem>
        <SidebarNavLink to={`${CONSEQUENCES}/${LIFE_SAFETY}`} level={2}>
          Life Safety
        </SidebarNavLink>
      </NavItem>
      <NavItem>
        <SidebarNavLink to={`${CONSEQUENCES}/${RECOVERY_TIME}`} level={2}>
          Recovery Time
        </SidebarNavLink>
      </NavItem>
      <NavItem>
        <SidebarNavLink to={`${CONSEQUENCES}/${OTHER}`} level={2}>
          Other
        </SidebarNavLink>
      </NavItem>
    </Nav>
  </Sidebar>
);

ComponentSidebar.defaultProps = {
  top: undefined,
};

export default ComponentSidebar;
