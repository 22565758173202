import { merge } from 'lodash';
import { normalize, schema } from 'normalizr';
import {
  FETCH_ACTION,
  FETCH_COMPONENTS_LAST_UPDATED_REQUEST,
  FETCH_COMPONENTS_LAST_UPDATED_RESPONSE,
  FETCH_COMPONENTS_BY_RANGE_REQUEST,
  FETCH_COMPONENTS_BY_RANGE_RESPONSE,
} from '#constants/actionTypes.js';
import { selectComponentLastUpdated } from '#selectors/entities/components.js';

const componentSchema = new schema.Entity('components', undefined, {
  idAttribute: 'uuid',
});

const fetchComponentLastUpdated = (orgId) => ({
  [FETCH_ACTION]: {
    types: [FETCH_COMPONENTS_LAST_UPDATED_REQUEST, FETCH_COMPONENTS_LAST_UPDATED_RESPONSE],
    endpoint: `/org/${orgId}/component/lastUpdated`,
    options: {
      method: 'GET',
    },
  },
});

const fetchComponentsByRange = (orgId, offset, limit) => ({
  [FETCH_ACTION]: {
    types: [FETCH_COMPONENTS_BY_RANGE_REQUEST, FETCH_COMPONENTS_BY_RANGE_RESPONSE],
    endpoint: `/org/${orgId}/component/${offset}/${limit}`,
    options: {
      method: 'GET',
    },
    meta: { offset, limit },
    dataTransform: (json) => {
      const defaultEntities = { components: {} };
      const { result, entities } = normalize(json, [componentSchema]);
      return {
        result,
        entities: merge(defaultEntities, entities),
      };
    },
  },
});

export const fetchComponents = (orgId) => async (dispatch, getState) => {
  const BIN_SIZE = 300;
  const state = getState();
  const previousLastUpdated = selectComponentLastUpdated(state);

  const { payload } = await dispatch(fetchComponentLastUpdated(orgId));
  const { lastUpdated, componentCount } = payload;

  if (!previousLastUpdated || new Date(lastUpdated) > new Date(previousLastUpdated)) {
    const numBins = Math.ceil(componentCount / BIN_SIZE);
    for (let i = 0; i < numBins; i += 1) {
      dispatch(fetchComponentsByRange(orgId, i * BIN_SIZE, BIN_SIZE));
    }
  }
};
