import keyBy from 'lodash/fp/keyBy.js';
import * as names from '@hbrisk/sp3-risk-model-support/components/translated/attributes/names/index.js';
import * as values from '@hbrisk/sp3-risk-model-support/components/translated/attributes/values/index.js';
import * as sections from '#constants/components/componentPage/sections/index.js';
import damageStates
  from '#constants/components/form/fields/fieldArrays/damageStates/index.js';
import * as options from '#constants/components/form/fields/options/index.js';

import { REDUX_FORM_CHANGE } from '#constants/actionTypes.js';
import groupToStructuralOrNonStructural from '#support/components/form/groupToStructuralOrNonStructural.js';
import addFieldDefaults
  from '#support/components/form/addFieldDefaults.js';

export * from '#constants/components/form/fields/fieldArrays/damageStates/index.js';
export * as options from '#constants/components/form/fields/options/index.js';

const fieldsWithoutDefaults = [
  {
    name: names.COMPONENT_ID,
    label: 'Component ID',
    section: sections.GENERAL,
    type: 'text',
  },
  {
    name: names.UNIT,
    section: sections.GENERAL,
    type: 'text',
  },
  {
    name: names.NAME,
    section: sections.GENERAL,
    type: 'text',
  },
  {
    name: names.UNIT_QUANTITY,
    section: sections.GENERAL,
    type: 'text',
  },
  {
    name: names.SHORT_NAME,
    section: sections.GENERAL,
    type: 'text',
  },
  {
    name: names.AUTHOR,
    section: sections.GENERAL,
    type: 'text',
  },
  {
    name: names.DESCRIPTION,
    section: sections.GENERAL,
    type: 'textarea',
  },
  {
    name: names.DATA_QUALITY,
    section: sections.GENERAL,
    type: 'select',
    options: options.RATINGS_OPTIONS,
  },
  {
    name: names.DATA_RELEVANCE,
    section: sections.GENERAL,
    type: 'select',
    options: options.RATINGS_OPTIONS,
  },
  {
    name: names.DOCUMENTATION,
    section: sections.GENERAL,
    type: 'select',
    options: options.RATINGS_OPTIONS,
  },
  {
    name: names.RATIONALITY,
    section: sections.GENERAL,
    type: 'select',
    options: options.RATINGS_OPTIONS,
  },
  {
    name: names.IS_CORRELATED,
    label: 'Is Correlated?',
    section: sections.FRAGILITY_INFO,
    type: 'select',
    options: options.YES_NO_OPTIONS,
  },
  {
    name: names.IS_DIRECTIONAL,
    label: 'Is Directional?',
    section: sections.FRAGILITY_INFO,
    type: 'select',
    options: options.YES_NO_OPTIONS,
  },
  {
    name: names.DEFAULT_EDP,
    label: 'Default EDP',
    section: sections.FRAGILITY_INFO,
    type: 'select',
    options: options.EDP_OPTIONS,
  },
  {
    name: names.EDP_UNIT,
    label: 'EDP Unit',
    section: sections.FRAGILITY_INFO,
    type: 'text',
  },
  {
    name: names.EDP_LOCATION,
    label: 'EDP Location',
    section: sections.FRAGILITY_INFO,
    type: 'select',
    options: options.EDP_LOCATION_OPTIONS,
  },
  {
    name: names.IS_OFFICIAL_P58,
    label: 'Is Official P58?',
    section: sections.FRAGILITY_INFO,
    type: 'select',
    options: options.YES_NO_OPTIONS,
  },

  {
    name: names.GROUP,
    label: 'Loss Aggregation',
    section: sections.COMPONENT_GROUPING,
    type: 'select',
    options: options.GROUP_OPTIONS,
  },
  {
    name: names.MAJOR,
    label: 'System',
    section: sections.COMPONENT_GROUPING,
    type: 'select',
    options: options.MAJOR_OPTIONS,
    reducers: {
      visible: (prev) => {
        const { [names.GROUP]: value } = prev.values;
        return !!value;
      },
      value: (prev, action) => {
        const {
          values: {
            [names.MAJOR]: prevValue,
          },
        } = prev;
        if (action) {
          const { meta, type } = action;
          if (
            type === REDUX_FORM_CHANGE
            && meta
            && meta.field === names.GROUP
          ) {
            return null;
          }
        }
        return prevValue;
      },
    },
  },
  {
    name: names.MINOR,
    label: 'Element',
    section: sections.COMPONENT_GROUPING,
    type: 'select',
    options: options.MINOR_OPTIONS,
    reducers: {
      visible: (prev) => {
        const {
          [names.GROUP]: group,
          [names.MAJOR]: major,
        } = prev.values;
        return !!(major && groupToStructuralOrNonStructural(group));
      },
      value: (prev, action) => {
        const {
          values: {
            [names.MINOR]: prevValue,
          },
        } = prev;
        if (action) {
          const { meta, type } = action;
          if (
            type === REDUX_FORM_CHANGE
            && meta
            && [names.GROUP, names.MAJOR].includes(meta.field)
          ) {
            return null;
          }
        }
        return prevValue;
      },
    },
  },
  {
    name: names.IS_CALCULATED,
    label: 'Is Calculated?',
    section: sections.CAPACITY_CALCULATION,
    type: 'select',
    options: options.YES_NO_OPTIONS,
  },
  {
    name: names.CALCULATION_TYPE,
    section: sections.CAPACITY_CALCULATION,
    type: 'select',
    options: options.CAPACITY_CALCULATION_TYPE_OPTIONS,
    reducers: {
      visible: (state) => {
        const {
          [names.IS_CALCULATED]: isCalculated,
        } = state.values;
        return isCalculated !== values.YES_NO_VALUES.NO;
      },
    },
  },
  {
    name: names.ANCHORAGE_DUCTILITY,
    section: sections.CAPACITY_CALCULATION,
    type: 'select',
    options: options.ANCHORAGE_DUCTILITY_OPTIONS,
    reducers: {
      visible: (state) => {
        const {
          [names.CALCULATION_TYPE]: calculationType,
          [names.IS_CALCULATED]: isCalculated,
        } = state.values;
        return isCalculated !== values.YES_NO_VALUES.NO
          && values.anchorageDuctilitySupportingCalcTypes.includes(calculationType);
      },
    },
  },
  {
    name: names.ID_COMPONENT_CLASS,
    label: 'ID Component Class',
    section: sections.CAPACITY_CALCULATION,
    type: 'select',
    options: options.ID_COMPONENT_CLASS_OPTIONS,
    reducers: {
      visible: (state) => {
        const {
          [names.IS_CALCULATED]: isCalculated,
          [names.CALCULATION_TYPE]: calculationType,
        } = state.values;

        return (
          isCalculated !== values.YES_NO_VALUES.NO
          && values.idComponentClassSupportingCalcTypes.includes(calculationType)
        );
      },
    },
  },
  {
    name: names.SYSTEM,
    section: sections.RECOVERY_TIME,
    type: 'select',
    options: options.RECOVERY_TIME_SYSTEM_OPTIONS,
  },

  {
    name: names.SUBSYSTEM,
    label: 'Subsystem',
    section: sections.RECOVERY_TIME,
    type: 'select',
    options: options.RECOVERY_TIME_SUB_SYSTEM_OPTIONS,
    reducers: {
      visible: (prev) => {
        const { [names.SYSTEM]: value } = prev.values;
        return !!value;
      },
      value: (prev, action) => {
        const { [names.SUBSYSTEM]: prevValue } = prev.values;
        if (action) {
          const { meta, type } = action;
          if (
            type === REDUX_FORM_CHANGE
            && meta
            && meta.field === names.SYSTEM
          ) {
            return null;
          }
        }
        return prevValue;
      },
    },
  },
  {
    name: names.REQUIRES_SERVICE_LOCATIONS,
    section: sections.RECOVERY_TIME,
    type: 'select',
    options: options.YES_NO_OPTIONS,
    reducers: {
      visible: (state) => {
        const { [names.SYSTEM]: value } = state.values;
        return value === values.SYSTEM_VALUES.HVAC;
      },
    },
  },
  {
    name: names.SERVICE_LOCATION,
    section: sections.RECOVERY_TIME,
    type: 'select',
    options: options.SERVICE_LOCATION_OPTIONS,
    reducers: {
      visible: (state) => {
        const { [names.SYSTEM]: value } = state.values;
        return values.serviceLocationSupportingSystems.includes(value);
      },
    },
  },
  {
    name: names.STRUCTURAL_SYSTEM,
    section: sections.RECOVERY_TIME,
    type: 'select',
    options: options.STRUCTURAL_SYSTEM_OPTIONS,
    reducers: {
      visible: (state) => {
        const { [names.SYSTEM]: value } = state.values;
        return value === values.SYSTEM_VALUES.STRUCTURAL;
      },
    },
  },
  {
    name: names.STRUCTURAL_SYSTEM_ALT,
    section: sections.RECOVERY_TIME,
    type: 'select',
    options: options.STRUCTURAL_SYSTEM_OPTIONS,
    reducers: {
      visible: (state) => {
        const { [names.SYSTEM]: value } = state.values;
        return value === values.SYSTEM_VALUES.STRUCTURAL;
      },
    },
  },
  {
    name: names.STRUCTURAL_SERIES,
    section: sections.RECOVERY_TIME,
    type: 'select',
    options: options.STRUCTURAL_SERIES_OPTIONS,
    reducers: {
      visible: (state) => {
        const { [names.SYSTEM]: value } = state.values;
        return value === values.SYSTEM_VALUES.STRUCTURAL;
      },
    },
  },
  {
    name: names.REDI_SEQUENCE,
    label: 'REDi Sequence',
    section: sections.RECOVERY_TIME,
    type: 'select',
    options: options.REDI_SEQUENCE_OPTIONS,
  },
  {
    name: names.EGRESS_FLAG,
    section: sections.RECOVERY_TIME,
    type: 'select',
    options: options.EGRESS_FLAG_OPTIONS,
  },
  {
    name: names.HAS_WLF_PROPERTIES,
    label: 'Has Wood Light Frame Properties?',
    section: sections.SYSTEM_SPECIFIC_INFO,
    type: 'select',
    options: options.YES_NO_OPTIONS,
  },
  {
    name: names.WLF_AVAILABLE_FOR_LFRS,
    label: 'Available for LFRS?',
    section: sections.SYSTEM_SPECIFIC_INFO,
    type: 'text',
    reducers: {
      visible: (state) => {
        const { [names.HAS_WLF_PROPERTIES]: value } = state.values;
        return value !== values.YES_NO_VALUES.NO;
      },
    },
  },
  {
    name: names.WLF_EXTERIOR,
    label: 'Exterior',
    section: sections.SYSTEM_SPECIFIC_INFO,
    type: 'select',
    options: options.YES_NO_OPTIONS,
    reducers: {
      visible: (state) => {
        const { [names.HAS_WLF_PROPERTIES]: value } = state.values;
        return value !== values.YES_NO_VALUES.NO;
      },
    },
  },
  {
    name: names.WLF_INTERIOR,
    label: 'Interior',
    section: sections.SYSTEM_SPECIFIC_INFO,
    type: 'select',
    options: options.YES_NO_OPTIONS,
    reducers: {
      visible: (state) => {
        const { [names.HAS_WLF_PROPERTIES]: value } = state.values;
        return value !== values.YES_NO_VALUES.NO;
      },
    },
  },
  {
    name: names.WLF_HAS_NON_STRUCT_OSB,
    label: 'Has Nonstructural OSB?',
    section: sections.SYSTEM_SPECIFIC_INFO,
    type: 'select',
    options: options.YES_NO_OPTIONS,
    reducers: {
      visible: (state) => {
        const { [names.HAS_WLF_PROPERTIES]: value } = state.values;
        return value !== values.YES_NO_VALUES.NO;
      },
    },
  },
  {
    name: names.WLF_IS_STUCCO,
    label: 'Has Stucco?',
    section: sections.SYSTEM_SPECIFIC_INFO,
    type: 'select',
    options: options.YES_NO_OPTIONS,
    reducers: {
      visible: (state) => {
        const { [names.HAS_WLF_PROPERTIES]: value } = state.values;
        return value !== values.YES_NO_VALUES.NO;
      },
    },
  },
  damageStates,
];

const fields = addFieldDefaults(fieldsWithoutDefaults);

export const fieldsByName = keyBy('name', fields);

const getFieldArraysByName = (f) => f.reduce((acc, field) => {
  if (field.type !== 'FieldArray') {
    return acc;
  }
  return {
    ...acc,
    [field.name]: field,
    ...getFieldArraysByName(field.itemSchema),
  };
}, {});

export const fieldArraysByName = getFieldArraysByName(fields);

export default fields;
