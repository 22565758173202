// @flow
import type { Element } from 'react';

import React from 'react';
import { Form } from 'reactstrap';
import {
  SYSTEM,
  SUBSYSTEM,
  REQUIRES_SERVICE_LOCATIONS,
  SERVICE_LOCATION,
  STRUCTURAL_SYSTEM,
  STRUCTURAL_SYSTEM_ALT,
  STRUCTURAL_SERIES,
  REDI_SEQUENCE,
  EGRESS_FLAG,
} from '@hbrisk/sp3-risk-model-support/components/translated/attributes/names/index.js';
import type { SectionComponentProps } from '#components/pages/Components/Component/sections/support/types/componentPagerSection';
import {
  Field, Col, FormGroup, Fieldset,
} from '#components/pages/Components/support/form/index.js';
import BackButton
  from '#components/pages/Components/Component/sections/support/buttons/BackButton.jsx';
import NextButton
  from '#components/pages/Components/Component/sections/support/buttons/NextButton.jsx';
import { fieldsByName } from '#constants/components/form/fields/index.js';

const RecoveryTime = ({
  onBack, onNext, readOnly,
}: SectionComponentProps): Element<'div'> => (
  <Form noValidate>
    <Fieldset>
      <legend>ATC-138</legend>
      <FormGroup fields={[SYSTEM]} row>
        <Col lg="6">
          <Field field={fieldsByName[SYSTEM]} readOnly={readOnly} />
        </Col>
      </FormGroup>
      <FormGroup fields={[SUBSYSTEM]} row>
        <Col lg="6">
          <Field field={fieldsByName[SUBSYSTEM]} readOnly={readOnly} />
        </Col>
      </FormGroup>
      <FormGroup fields={[REQUIRES_SERVICE_LOCATIONS]} row>
        <Col lg="6">
          <Field field={fieldsByName[REQUIRES_SERVICE_LOCATIONS]} readOnly={readOnly} />
        </Col>
      </FormGroup>
      <FormGroup fields={[SERVICE_LOCATION]} row>
        <Col lg="6">
          <Field field={fieldsByName[SERVICE_LOCATION]} readOnly={readOnly} />
        </Col>
      </FormGroup>
      <FormGroup fields={[STRUCTURAL_SYSTEM]} row>
        <Col lg="6">
          <Field field={fieldsByName[STRUCTURAL_SYSTEM]} readOnly={readOnly} />
        </Col>
      </FormGroup>
      <FormGroup fields={[STRUCTURAL_SYSTEM_ALT]} row>
        <Col lg="6">
          <Field field={fieldsByName[STRUCTURAL_SYSTEM_ALT]} readOnly={readOnly} />
        </Col>
      </FormGroup>
      <FormGroup fields={[STRUCTURAL_SERIES]} row>
        <Col lg="6">
          <Field field={fieldsByName[STRUCTURAL_SERIES]} readOnly={readOnly} />
        </Col>
      </FormGroup>
    </Fieldset>
    <Fieldset>
      <legend>REDi</legend>
      <FormGroup fields={[REDI_SEQUENCE]} row>
        <Col lg="6">
          <Field field={fieldsByName[REDI_SEQUENCE]} readOnly={readOnly} />
        </Col>
      </FormGroup>
      <FormGroup fields={[EGRESS_FLAG]} row>
        <Col lg="6">
          <Field field={fieldsByName[EGRESS_FLAG]} readOnly={readOnly} />
        </Col>
      </FormGroup>
    </Fieldset>
    <BackButton onClick={onBack} />
    <NextButton onClick={onNext} />
  </Form>
);

export default RecoveryTime;
