// @flow
import type { Element } from 'react';

import React from 'react';
import { Form } from 'reactstrap';
import {
  HAS_WLF_PROPERTIES,
  WLF_AVAILABLE_FOR_LFRS,
  WLF_EXTERIOR,
  WLF_INTERIOR,
  WLF_HAS_NON_STRUCT_OSB,
  WLF_IS_STUCCO,
} from '@hbrisk/sp3-risk-model-support/components/translated/attributes/names/index.js';
import type { SectionComponentProps } from '#components/pages/Components/Component/sections/support/types/componentPagerSection';
import {
  Field, Col, FormGroup, Fieldset,
} from '#components/pages/Components/support/form/index.js';
import BackButton
  from '#components/pages/Components/Component/sections/support/buttons/BackButton.jsx';
import NextButton
  from '#components/pages/Components/Component/sections/support/buttons/NextButton.jsx';
import { fieldsByName } from '#constants/components/form/fields/index.js';

const SystemSpecificInfo = ({
  onBack, onNext, readOnly,
}: SectionComponentProps): Element<'div'> => (
  <Form noValidate>
    <Fieldset>
      <legend>General</legend>
      <FormGroup fields={[HAS_WLF_PROPERTIES]} row>
        <Col lg="6">
          <Field field={fieldsByName[HAS_WLF_PROPERTIES]} readOnly={readOnly} />
        </Col>
      </FormGroup>
      <FormGroup fields={[WLF_AVAILABLE_FOR_LFRS]} row>
        <Col lg="6">
          <Field field={fieldsByName[WLF_AVAILABLE_FOR_LFRS]} readOnly={readOnly} />
        </Col>
      </FormGroup>
      <FormGroup fields={[WLF_EXTERIOR]} row>
        <Col lg="6">
          <Field field={fieldsByName[WLF_EXTERIOR]} readOnly={readOnly} />
        </Col>
      </FormGroup>
      <FormGroup fields={[WLF_INTERIOR]} row>
        <Col lg="6">
          <Field field={fieldsByName[WLF_INTERIOR]} readOnly={readOnly} />
        </Col>
      </FormGroup>
      <FormGroup fields={[WLF_HAS_NON_STRUCT_OSB]} row>
        <Col lg="6">
          <Field field={fieldsByName[WLF_HAS_NON_STRUCT_OSB]} readOnly={readOnly} />
        </Col>
      </FormGroup>
      <FormGroup fields={[WLF_IS_STUCCO]} row>
        <Col lg="6">
          <Field field={fieldsByName[WLF_IS_STUCCO]} readOnly={readOnly} />
        </Col>
      </FormGroup>
    </Fieldset>
    <BackButton onClick={onBack} />
    <NextButton onClick={onNext} />
  </Form>
);

export default SystemSpecificInfo;
