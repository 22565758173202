/**
 * Owner: Haselton Baker Risk Group, LLC
 * Copyright All Rights Reserved
 */
import pipe from 'lodash/fp/pipe.js';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { modelTypesById } from '#src/js/constants/models/modelTypes/index.js';
import calculateModelPutPayload from '#support/models/calculateModelPutPayload.js';
import { fetchModel, postRun, putModel } from '#actions/models/model.js';
import withLocation from '#components/support/routing/withLocation/index.jsx';
import withNavigate from '#components/support/routing/withNavigate/index.jsx';
import { PROBLEM_WITH_REQUEST } from '#constants/errorMessages.js';
import { MODELS, OUTPUTS, REPAIR_COST_BY_INTENSITY } from '#constants/pathStrings.js';

const withOnSubmit = (C) => {
  const mapStateToProps = (state, ownProps) => {
    const { modelType } = ownProps;
    const { selectAutofilledFormFields, selectFieldsVisibility } = modelTypesById[modelType];
    return ({
      autofilled: selectAutofilledFormFields(state),
      fieldsVisibility: selectFieldsVisibility(state),
    });
  };

  const mapDispatchToProps = (dispatch, ownProps) => {
    const {
      location,
      modelId,
      modelType,
      navigate,
    } = ownProps;
    const { shouldRedirectOnSubmit } = modelTypesById[modelType];
    return ({
      makeOnSubmit: (autofilled, visibility) => (values) => {
        const formInputs = calculateModelPutPayload(autofilled, visibility, values);
        return dispatch(putModel(modelId, { formInputs }))
          .then(() => dispatch(postRun(modelId)))
          .then(() => dispatch(fetchModel(modelId)))
          .then(() => {
            if (shouldRedirectOnSubmit) {
              // Only redirect if not already in an outputs section
              const inOutputsSection = location.pathname.split('/')[3] === 'outputs';
              if (!inOutputsSection) {
                const redirectUrl = `/${MODELS}/${modelId}/${OUTPUTS}/${REPAIR_COST_BY_INTENSITY}`;
                navigate(redirectUrl);
              }
            }
          })
          .catch(() => Promise.reject(new SubmissionError({
            _error: PROBLEM_WITH_REQUEST,
          })));
      },
    });
  };

  const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { autofilled, fieldsVisibility } = stateProps;
    const { makeOnSubmit } = dispatchProps;
    return {
      ...ownProps,
      onSubmit: makeOnSubmit(autofilled, fieldsVisibility),
    };
  };

  return pipe(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    withNavigate,
    withLocation,
  )(C);
};

export default withOnSubmit;
