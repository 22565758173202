// @flow
import type { Element } from 'react';

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Summary from '#components/pages/Models/Component/Summary/index.jsx';
import Detail from '#components/pages/Models/Component/Detail/index.jsx';

const ComponentWindow = (): Element<'div'> => (
  <Routes>
    <Route path="/" element={<Summary />} />
    <Route path="*" element={<Detail />} />
  </Routes>
);

export default ComponentWindow;
